import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { BsTrash } from 'react-icons/bs';
import { GrAttachment } from 'react-icons/gr';
import { useIntl, FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { dateFormat } from '../../helpers/DateFormatter';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'space-between',
    paddingTop: theme.spacing(5)
  },
  list_container: {
    borderRadius: '15px',
    background: '#eceff1',
    height: '50vh',
    width: '500px'
  },
  root: {
    padding: theme.spacing(2),
    borderRadius: '15px 15px 0px 0px',
    height: '40vh',
    width: '500px',
    overflow: 'auto'
  },
  list_item: {
    display: 'flex ',

    justifyContent: 'space-between'
  },
  documentHead: {
    borderBottom: '1px solid #525A65'
  },
  documentRow: {
    borderBottom: '0.25px solid #525A65',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(89, 84, 105, 0.5)'
    }
  },
  dashed: {
    border: '2px dashed #E4E6E7',
    padding: '1vh 1vw',
    margin: '1vh 1vw',
    cursor: 'pointer'
  }
}));

const InfoText = styled.p`
  color: rgba(89, 84, 105, 1) !important;
  font-size: calc(4px + 0.5vh + 0.5vw);
  line-height: calc((4px + 0.5vh + 0.5vw) * 1.618);
  letter-spacing: calc((4px + 0.5vh + 0.5vw) / (1.618 * 20));
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
`;
const HeadText = styled.p`
  color: #a8acb2 !important;
  font-size: calc(4px + 0.5vh + 0.5vw);
  line-height: calc((4px + 0.5vh + 0.5vw) * 1.618);
  letter-spacing: calc((4px + 0.5vh + 0.5vw) / (1.618 * 20));
`;
const BodyText = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(89, 84, 105, 1) !important;
  font-size: calc(4px + 0.5vh + 0.5vw);
  line-height: calc((4px + 0.5vh + 0.5vw) * 1.618);
  letter-spacing: calc((4px + 0.5vh + 0.5vw) / (1.618 * 20));
`;

const CaptionText = styled.div`
  color: rgba(250, 100, 0, 1);
  text-align: left;
  font-size: calc(4px + 0.75vw + 0.75vh);
  line-height: calc((4px + 0.75vh + 0.75vw) * 1.618);
  letter-spacing: calc((4px + 0.75vh + 0.75vw) / (1.618 * 20));
`;

const Documents = (props) => {
  const { edit, documents, onAddFile, onDeleteFile } = props;

  const classes = useStyles();

  const fileInput = useRef(null);

  const intl = useIntl();

  // active disable input for file upload
  const handleClick = (e) => {
    e.preventDefault();
    if (edit) {
      fileInput.current.click();
    }
  };

  const handleChange = (e) => {
    const { files } = e.target;

    const file = files[0];

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const fileName = uuidv4();
      let extension = file.name;
      let [original_name] = extension.split('.');
      const lastDotPosition = file.name.lastIndexOf('.');
      if (lastDotPosition !== -1)
        extension = extension.substr(lastDotPosition, file.name.length);
      const newPhoto = {
        file,
        data: reader.result,
        fileName: `${fileName}---${original_name}${extension}`,
        fileSize: file.size
      };

      onAddFile(newPhoto);
      /* dispatch(addDocument(original_form_id, fileInfo)).then(() => onDone()); */
    };
  };

  const handleDelete = (index) => {
    onDeleteFile(index);
    /* dispatch(deleteDocuments(original_form_id, file_name)).then(() => onDone()); */
  };

  return (
    <>
      <CaptionText>
        <FormattedMessage id="DOCUMENTS" />
      </CaptionText>
      <Grid
        container
        direction="row"
        spacing={2}
        justify="center"
        alignItems="flex-start"
      >
        <Grid
          item
          direction="column"
          spacing={1}
          justify="center"
          alignItems="center"
          className={classes.dashed}
          onClick={handleClick}
        >
          <Grid item>
            <div
              style={{
                width: '92px',
                height: '92px',
                borderRadius: '50%',
                backgroundColor: '#E5E5E5',
                padding: '16px',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              <GrAttachment size={60} color="#525A65" />
            </div>
          </Grid>
          <Grid item>
            <InfoText>
              {intl.formatMessage({ id: 'BROWSE_FILES_ATTACH' })}
            </InfoText>
          </Grid>
        </Grid>
        {documents.length ? (
          <Grid
            container
            item
            xs
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
              className={classes.documentHead}
            >
              <Grid item xs={1} />
              <Grid item xs={3}>
                <HeadText>FILE NAME</HeadText>
              </Grid>
              <Grid item xs={3}>
                <HeadText>UPLOADED BY</HeadText>
              </Grid>
              <Grid item xs={3}>
                <HeadText>UPLOADED AT</HeadText>
              </Grid>
              <Grid item xs={2} />
            </Grid>
            {documents.map((document, documentIndex) => {
              let fileExt;
              if (document.name) {
                fileExt = document.name.split('.').pop().toLowerCase();
              }
              if (document.file_name) {
                fileExt = document.file_name.split('.').pop().toLowerCase();
              }
              if (document.fileName) {
                fileExt = document.fileName.split('.').pop().toLowerCase();
              }

              const base64Doc = document.data;

              return (
                <Grid
                  key={`document-${document.id}`}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={1}
                  className={classes.documentRow}
                >
                  <Grid
                    item
                    xs={1}
                    onClick={() => {
                      let filePrefix = '';
                      if (fileExt === 'jpeg') filePrefix = 'image/jpeg';
                      else if (fileExt === 'jpg') filePrefix = 'image/jpg';
                      else if (fileExt === 'png') filePrefix = 'image/png';
                      else if (fileExt === 'mp4') {
                        filePrefix = 'data:video/mp4;base64,';
                        const downloadLink = window.document.createElement('a');
                        const fileName =
                          document.file_name ??
                          document.name ??
                          document.fileName;
                        const linkSource = document.data;
                        downloadLink.href = filePrefix + linkSource;
                        downloadLink.download = fileName;
                        downloadLink.click();
                      } else filePrefix = `application/${fileExt}`;

                      const linkSource = `data:application/${fileExt};base64,${base64Doc}`;
                      const downloadLink = window.document.createElement('a');
                      const fileName =
                        document.file_name ??
                        document.name ??
                        document.fileName;
                      downloadLink.href = linkSource;
                      downloadLink.download = fileName;
                      downloadLink.click();
                    }}
                  >
                    <div
                      style={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        backgroundColor: '#E5E5E5',
                        padding: '4px'
                      }}
                    >
                      <GrAttachment size={16} color="#525A65" />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    onClick={() => {
                      let filePrefix = '';
                      if (fileExt === 'jpeg') filePrefix = 'image/jpeg';
                      else if (fileExt === 'jpg') filePrefix = 'image/jpg';
                      else if (fileExt === 'png') filePrefix = 'image/png';
                      else if (fileExt === 'mp4') {
                        filePrefix = 'data:video/mp4;base64,';
                        const downloadLink = window.document.createElement('a');
                        const fileName =
                          document.file_name ??
                          document.name ??
                          document.fileName;
                        const linkSource = document.data;
                        downloadLink.href = filePrefix + linkSource;
                        downloadLink.download = fileName;
                        downloadLink.click();
                      } else filePrefix = `application/${fileExt}`;

                      const linkSource = `data:application/${fileExt};base64,${base64Doc}`;
                      const downloadLink = window.document.createElement('a');
                      const fileName =
                        document.file_name ??
                        document.name ??
                        document.fileName;
                      downloadLink.href = linkSource;
                      downloadLink.download = fileName;
                      downloadLink.click();
                    }}
                  >
                    <BodyText>
                      {document.file_name?.split('---').pop() ??
                        document.name?.split('---').pop() ??
                        document.fileName?.split('---').pop()}
                    </BodyText>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    onClick={() => {
                      let filePrefix = '';
                      if (fileExt === 'jpeg') filePrefix = 'image/jpeg';
                      else if (fileExt === 'jpg') filePrefix = 'image/jpg';
                      else if (fileExt === 'png') filePrefix = 'image/png';
                      else if (fileExt === 'mp4') {
                        filePrefix = 'data:video/mp4;base64,';
                        const downloadLink = window.document.createElement('a');
                        const fileName =
                          document.file_name ??
                          document.name ??
                          document.fileName;
                        const linkSource = document.data;
                        downloadLink.href = filePrefix + linkSource;
                        downloadLink.download = fileName;
                        downloadLink.click();
                      } else filePrefix = `application/${fileExt}`;

                      const linkSource = `data:application/${fileExt};base64,${base64Doc}`;
                      const downloadLink = window.document.createElement('a');
                      const fileName =
                        document.file_name ??
                        document.name ??
                        document.fileName;
                      downloadLink.href = linkSource;
                      downloadLink.download = fileName;
                      downloadLink.click();
                    }}
                  >
                    <BodyText>
                      {document.created_name ??
                        intl.formatMessage({ id: 'NOT_SAVED_YET' })}
                    </BodyText>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    onClick={() => {
                      let filePrefix = '';
                      if (fileExt === 'jpeg') filePrefix = 'image/jpeg';
                      else if (fileExt === 'jpg') filePrefix = 'image/jpg';
                      else if (fileExt === 'png') filePrefix = 'image/png';
                      else if (fileExt === 'mp4') {
                        filePrefix = 'data:video/mp4;base64,';
                        const downloadLink = window.document.createElement('a');
                        const fileName =
                          document.file_name ??
                          document.name ??
                          document.fileName;
                        const linkSource = document.data;
                        downloadLink.href = filePrefix + linkSource;
                        downloadLink.download = fileName;
                        downloadLink.click();
                      } else filePrefix = `application/${fileExt}`;

                      const linkSource = `data:application/${fileExt};base64,${base64Doc}`;
                      const downloadLink = window.document.createElement('a');
                      const fileName =
                        document.file_name ??
                        document.name ??
                        document.fileName;
                      downloadLink.href = linkSource;
                      downloadLink.download = fileName;
                      downloadLink.click();
                    }}
                  >
                    <BodyText>
                      {document.created_at
                        ? dateFormat(document.created_at, 'DD.MM.YYYY HH:mm')
                        : ''}
                    </BodyText>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    onClick={
                      edit ? () => handleDelete(documentIndex) : () => {}
                    }
                  >
                    <div
                      style={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        backgroundColor: '#E5E5E5',
                        padding: '4px'
                      }}
                    >
                      <BsTrash size={16} color="#525A65" />
                    </div>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ) : null}
        <input
          type="file"
          accept="*"
          onChange={handleChange}
          ref={fileInput}
          style={{ display: 'none' }}
        />
      </Grid>
    </>
  );
};

export default Documents;
