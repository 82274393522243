import React from 'react';
import styled from 'styled-components';

import { FormList } from 'components/ProjectForms';

const FormListContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: auto;
  background-color: rgba(245, 245, 245, 1);
`;

export default React.forwardRef((props, ref) => (
  <FormListContainer>
    <FormList {...props} ref={ref} />
  </FormListContainer>
));
