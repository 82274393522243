import React from 'react';
import styled from 'styled-components';

import { FormEditor } from 'components/ProjectForms';

const FormEditorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: auto;
  background-color: rgba(245, 245, 245, 1);
`;

export default React.forwardRef((props, ref) => (
  <FormEditorContainer>
    <FormEditor {...props} ref={ref} />
  </FormEditorContainer>
));
