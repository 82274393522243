import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import { Button, Grid, Paper, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { InputLabel } from '../Containers';

const SelectManyInputs = (props) => {
  let { options, values, extras, onSelect, disabled } = props;

  values = Array.isArray(values) ? values : Object.values(values || {});
  const selectedValue = values
    ? values?.length === 1
      ? values[0] === ''
        ? []
        : [...values]
      : [...values]
    : [];
  const intl = useIntl();

  return (
    <div
      style={{
        flexGrow: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        alignContent: 'center',
        borderWidth: '1px',
        marginTop: extras ? '2px' : null
      }}
    >
      {options.length > 3 ? (
        <Autocomplete
          multiple
          disabled={disabled}
          id="select-one-element-autocomplete"
          size="small"
          options={options}
          value={selectedValue}
          getOptionSelected={(option, val) => {
            if (!val) return false;
            return val === option.val;
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') return option;
            return option && option.val ? option.val : ' ';
          }}
          onChange={(event, val) => {
            onSelect(
              val.map((el) => {
                if (typeof el === 'string') {
                  return `${el}`;
                }
                if (el.val) {
                  return `${el.val}`;
                }
                return `${el}`;
              })
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={`${intl.formatMessage({
                id: 'PLEASE_SELECT'
              })}${intl.formatMessage({ id: 'OPTION' })}${intl.formatMessage({
                id: 'PLEASE_SELECT_CONTINUE'
              })}`}
              variant="outlined"
              size="small"
              fullWidth
            />
          )}
        />
      ) : (
        options.map((option, index) => (
          <Button
            key={option.val}
            style={{
              padding: '1vh 2vw',
              borderTopLeftRadius:
                index === 0 ||
                (selectedValue && !selectedValue.includes(option.val))
                  ? '0'
                  : '8px',
              borderBottomLeftRadius:
                index === 0 ||
                (selectedValue && !selectedValue.includes(option.val))
                  ? '0'
                  : '8px',
              borderTopRightRadius:
                index === options.length - 1 ||
                (selectedValue && !selectedValue.includes(option.val))
                  ? '0'
                  : '8px',
              borderBottomRightRadius:
                index === options.length - 1 ||
                (selectedValue && !selectedValue.includes(option.val))
                  ? '0'
                  : '8px',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                selectedValue && selectedValue.includes(option.val)
                  ? 'rgba(250,100,0,1)'
                  : '#F2F2F2'
            }}
            onClick={() => onSelect(option.val)}
          >
            <span
              style={{
                color:
                  selectedValue && selectedValue.includes(option.val)
                    ? '#F2F2F2'
                    : 'rgba(89, 84, 105, 1)'
              }}
            >
              {option.text['0']}
            </span>
          </Button>
        ))
      )}
    </div>
  );
};

export default ({
  input,
  value,
  extraInputs,
  formName,
  controlName,
  disabled,
  stateIndex
}) => {
  const intl = useIntl();

  let { name, label, hint, options, required } = input;

  if (!Array.isArray(options)) {
    options = Object.values(options);
  }
  const [extraInputsList] = useState(extraInputs);
  useEffect(() => {}, [extraInputsList]);

  const hintText = (hint && hint['0']) || '';
  const labelText = (label && label['0']) || '';

  let fieldName = `${formName}`;
  if (controlName) {
    fieldName = fieldName.concat(`.${controlName}`);
  }
  fieldName = fieldName.concat(`.${name}`);

  return (
    <Field
      name={fieldName}
      validate={(vv) => {
        if (required && !disabled) {
          if (extraInputsList && extraInputsList.length > 0) {
            let message = '';
            vv.map((vvv) => {
              if (vvv.length === 0) {
                message = intl.formatMessage({ id: 'REQUIRED_THIS_FIELD' });
              }
            });
            return message;
          }
          if (vv.length === 0) {
            return intl.formatMessage({ id: 'REQUIRED_THIS_FIELD' });
          }
        }
      }}
    >
      {(props) => {
        const { field, form, meta } = props;
        return (
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={3}>
              <InputLabel>
                {' '}
                {`${labelText}${required ? ' (*)' : ''}`}
              </InputLabel>
            </Grid>
            <Grid item xs={9}>
              <Paper elevation={3}>
                <div
                  error={meta.error}
                  style={{
                    border: '1px',
                    borderStyle: 'solid',
                    borderColor: meta.error ? 'red' : '#ccc',
                    padding: '1vh 1vw'
                  }}
                >
                  <SelectManyInputs
                    disabled={disabled}
                    errors={meta.error}
                    options={options}
                    values={
                      extraInputsList ? field.value[stateIndex] : field.value
                    }
                    onSelect={(selected) => {
                      if (!disabled) {
                        let newField;

                        if (options.length > 3) {
                          newField = [...selected];
                        } else {
                          if (extraInputsList && extraInputsList.length > 0) {
                            const tempField = field.value[stateIndex];
                            if (tempField.length === 1 && tempField[0] === '')
                              newField = [];
                            else newField = [...field.value[stateIndex]];
                          } else {
                            const tempField = field.value;
                            if (tempField.length === 1 && tempField[0] === '')
                              newField = [];
                            else newField = [...field.value];
                          }
                          if (newField.includes(selected)) {
                            newField = newField.filter(
                              (item) => item !== selected
                            );
                          } else {
                            newField.push(selected);
                          }
                        }

                        form.setFieldValue(
                          extraInputsList && extraInputsList.length > 0
                            ? `${fieldName}.${stateIndex}`
                            : fieldName,
                          newField
                        );
                      }
                    }}
                  />

                  {meta.error && (
                    <ErrorMessage
                      name={fieldName}
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  )}
                </div>
              </Paper>
            </Grid>
          </Grid>
        );
      }}
    </Field>
  );
};
