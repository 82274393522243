import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button, Checkbox, FormControlLabel, Grid, Select, TextField,
} from '@material-ui/core';
import { IoIosCheckboxOutline, IoIosSnow } from 'react-icons/io';
import { Option } from '../ProjectForms/EditFormInfo';


const Container = styled.div`
  position: relative;
  padding: 5px;
  min-height: 40vh;
`;

const Title = styled.div`
    font-size: calc(4px + 0.5vh + 0.5vw );
    color: rgba(0, 0, 0, 0.5);
    line-height: calc(8px + 0.5vh + 0.5vw );;
    text-align: left;
    padding-left: 8px;
    margin: 10px 0px;
`;

const AddButton = styled(Button)`
   background: linear-gradient(to right, rgba(255,30,0,1), rgba(255,100,0,1)) !important;
    margin: 15px 5px 15vh 5px !important;
`;

const StyledTextField = styled(TextField)`
  margin: 0px !important;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  display: block !important;
`;

const StepResults = (props) => {
  const { values, handleChange } = props;

  const [results, setResults] = useState([]);

  const addResult = () => {
    setResults([...results,
      {
        result_code: '',
        result: 0,
        result_step: 0,
      },
    ]);
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={4} justify="center">
          {results.map((result) => (
            <>
              <Title>SONUÇ KODU </Title>
              <StyledTextField
                variant="outlined"
                margin="dense"
                id="result_code"
                name="result_code"

              />
            </>
          ))}
        </Grid>
        <Grid item xs={4} justify="center">
          {results.map((result) => (
            <>
              <Title>SONUÇ </Title>
              <Select
                margin="dense"
                variant="outlined"
                name="result"
                id="result"
                value={values.result}
                fullWidth
                onChange={handleChange}
              >
                <Option
                  value={0}
                >
                  REJECTED
                </Option>
                <Option
                  value={1}
                >
                  ACCEPTED
                </Option>
              </Select>
            </>
          ))}
        </Grid>
        <Grid item xs={4} justify="center">
          {results.map((result) => (
            <>
              <Title>SONUÇ ADIMI </Title>
              <Select
                margin="dense"
                variant="outlined"
                name="result_step"
                id="result_step"
                value={values.result_step}
                fullWidth
                onChange={handleChange}
              >
                <Option
                  value={0}
                >
                  INITIALIZATION
                </Option>
                <Option
                  value={1}
                >
                  CLIENT REVIEW
                </Option>
              </Select>
            </>
          ))}
        </Grid>
      </Grid>
      <AddButton
        variant="contained"
        onClick={addResult}
        startIcon={<IoIosSnow />}
      >
        SONUÇ EKLE
      </AddButton>
      <StyledFormControlLabel

        control={(
          <Checkbox
            name="is_rejected"
            id="is_rejected"
            checked={values.is_rejected}
            onChange={handleChange}
            checkedIcon={<IoIosCheckboxOutline />}
            color="default"
          />
        )}
        label="Reddedildiğinde bir sonraki revizyona geçilsin."
      />
    </Container>
  );
};

export default StepResults;
