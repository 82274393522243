import APIHelper from '../../helpers/APIHelper';

export async function getStatusSetsFunction(proje_id) {
  const response = await APIHelper().get(
    `status_sets/list?project_id=${proje_id}`
  );
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('GET STATUS_SETS RESPONSE =>', response.status_sets);
  return response.status_sets;
}

export async function searchStatusSetsFunction(search) {
  const response = await APIHelper().post('status_sets/search', null, {
    search
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('GET STATUS_SETS SEARCH RESPONSE =>', response.statusSets);
  return response.statusSets;
}

export async function findByIdStatusSetssFunction(status_set_id) {
  const response = await APIHelper().post('status_sets/find', null, {
    status_set_id
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('FIND STATUS_SET BY ID RESPONSE =>', response.status_set);
  return response.status_set;
}

export async function addStatusSetFunction(statusSetObj) {
  const response = await APIHelper().post('status_sets/insert', null, {
    ...statusSetObj
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('ADD STATUS_SET RESPONSE =>', response.status_set);
  return response.status_set;
}

export async function updateStatusSetFunction(statusSetObj) {
  const response = await APIHelper().post('status_sets/update', null, {
    ...statusSetObj
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('UPDATE STATUS_SET RESPONSE =>', response);
  return response.status;
}

export async function deleteStatusSetFunction(status_set_id) {
  const response = await APIHelper().post('status_sets/delete', null, {
    status_set_id
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('DELETE STATUS_SET RESPONSE =>', response);
  return response.status;
}

export async function addStatusSetDetailFunction(statusSetDetailObj) {
  const response = await APIHelper().post('status_sets/detail/insert', null, {
    ...statusSetDetailObj
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('ADD STATUS_SET_DETAIL RESPONSE =>', response.status_set_details);
  return response.status_set_details;
}

export async function deleteStatusSetDetailFunction(status_set_detail_id) {
  const response = await APIHelper().post('status_sets/detail/delete', null, {
    status_set_detail_id
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('DELETE STATUS_SET_DETAIL RESPONSE =>', response);
  return response;
}

export async function fetchStatusSetDetailRoleFunc(status_id) {
  const response = await APIHelper().post(
    'status_sets/detail/role_select',
    null,
    { status_id }
  );
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('ADD STATUS_SET_DETAIL ROLE RESPONSE =>', response);
  return response.status_set_detail_roles;
}

export async function addStatusSetDetailRoleFunc(status_id, role_id) {
  const response = await APIHelper().post(
    'status_sets/detail/role_insert',
    null,
    { status_id, role_id }
  );
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('ADD STATUS_SET_DETAIL ROLE RESPONSE =>', response);
  return response.status_set_details_roles;
}

export async function deleteStatusSetDetailRoleFunc(status_id, role_id) {
  const response = await APIHelper().post(
    'status_sets/detail/role_delete',
    null,
    { status_id, role_id }
  );
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('DELETE STATUS_SET_DETAIL ROLE RESPONSE =>', response);
  return response;
}

export async function updateStatusSetDetailFunction(status_detail_id, name) {
  const response = await APIHelper().post('statuss/detail/update', null, {
    status_detail_id,
    name
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('UPDATE STATUS_SET_DETAIL RESPONSE =>', response);
  return response;
}
export async function updateStatusSetTitleFunction(set_id, set_name) {
  const response = await APIHelper().post('status_sets/update', null, {
    set_id,
    set_name
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('UPDATE Status_SET_DETAIL RESPONSE =>', response);
  return response;
}
