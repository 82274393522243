import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { Divider } from '@material-ui/core';

import { FormattedMessage } from 'react-intl';

import { authentication } from 'helpers/authentication';
import { findRoleByProjectIdCompanyFunc } from 'api';

import { RenderFields } from './Fields';

import {
  FormContent,
  CaptionText,
  Form,
  FormTitle
} from '../../styles/forms/formEditorValueStyle';

const EditFormValue = React.forwardRef((props, ref) => {
  const { form, formValues, formElements, disabled, projectId } = props;

  const formikRef = useRef();
  useImperativeHandle(ref, () => formikRef.current);

  return (
    <div spacing={3}>
      <CaptionText>
        <FormattedMessage id="FORM_DATA" />
      </CaptionText>
      {formValues && formElements && (
        <SubForms
          formValues={formValues}
          subForms={formElements.subForms}
          disabled={disabled}
          projectId={projectId}
          form={form}
        />
      )}
    </div>
  );
});

export default EditFormValue;

const SubForms = ({ subForms, formValues, disabled, projectId, form }) => {
  const mountedRef = useRef(true);
  const [roles, setRoles] = useState([]);

  const auth = authentication.userValue;
  const { role: user_role, company_id, groups } = authentication.userValue;

  useEffect(() => {
    if (projectId) {
      findRoleByProjectIdCompanyFunc(projectId, mountedRef.current).then(
        (res) => {
          const _roles = res.filter((role) => role.company_id === company_id);
          setRoles(_roles);
        }
      );
    }

    return () => {
      mountedRef.current = false;
    };
  }, [projectId, company_id]);

  if (!subForms) return null;
  return subForms.map((subForm) => {
    const { name, controls, role_id } = subForm;

    return (
      <Form>
        <FormTitle>{name?.toUpperCase() ?? ''}</FormTitle>
        <Divider />
        <FormContent>
          {controls && (
            <RenderFields
              form={form}
              subForms={subForms}
              roles={roles}
              auth={auth}
              inputs={controls}
              values={formValues[name]}
              formName={'formValues.' + name}
              disabled={
                user_role === 5
                  ? disabled
                  : role_id
                  ? roles.every((role) => role.role_id !== Number(role_id)) ||
                    groups.every(
                      (group) => group.role_id !== Number(role_id)
                    ) ||
                    disabled
                  : disabled
              }
            />
          )}
        </FormContent>
      </Form>
    );
  });
};
