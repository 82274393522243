export const tableHeads = [
  { title: 'POST_ID', props: {} },
  { title: 'SENDER', props: {} },
  { title: 'SENDER_COMPANY', props: {} },
  { title: 'RECEIVER', props: {} },
  { title: 'SUBJECT', props: {} }
];

export const tableBody = [
  { name: 'mail_no', props: {} },
  { name: 'from_name', props: {} },
  { name: 'from_company_name', props: {} },
  { name: 'name', props: {} },
  { name: 'title', props: {} }
];
