/* eslint-disable */
import React, { memo } from 'react';
import { Document, Page, View, Text, Image, Font } from '@react-pdf/renderer';
import moment from 'moment';
import { render } from '@testing-library/react';
import { isValid } from 'date-fns/esm';

Font.register({
  family: 'Roboto',
  src:
    'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf'
});

export default memo(({ state, multiple }) => {
  var sitelist = state.formData?.site_details?.site_list || [];
  var siteset = state.formData.site_details.site_set.set_name;

  const metadatas = Object.entries(state?.formData?.metadata || {});

  const renderGroup = (groups, index, temps) => {
    return Object.entries(groups).map(([key, valueArray]) => {
      const tmp = temps.find((t) => t.name === key);
      const type = tmp?.type;
      const label = tmp.label?.[0] || tmp?.name;

      return (
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {type !== 'inputImage' && (
            <View
              style={{
                width: 120,
                minWidth: 100,
                padding: 5,
                borderBottomWidth: 1,
                borderRightWidth: 1
              }}
            >
              <Text> {label} </Text>
            </View>
          )}
          <View style={{ borderBottomWidth: 1, flex: 2 }}>
            {type === 'inputImage' && (
              <Text style={{ padding: '0px 4px' }}>{label}</Text>
            )}
            {renderValue(valueArray[index], label)}
          </View>
        </View>
      );
    });
  };

  const renderValue = (subFormFieldValue, value, isRepeat) => {
    switch (typeof subFormFieldValue) {
      case 'string':
        return <Text style={{ padding: 5 }}>{subFormFieldValue}</Text>;
      case 'number':
        return (
          <Text style={{ padding: 5 }}>{subFormFieldValue.toString()}</Text>
        );
      case 'object':
        if (!subFormFieldValue) {
          return <Text> {value} bulunamadı</Text>;
        } else if (typeof subFormFieldValue.signedBy === 'string') {
          return (
            <View
              style={{
                borderBottomWidth: 1,
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <View style={{ borderRightWidth: 1 }}>
                <Image
                  src={subFormFieldValue.signature}
                  allowDangerousPaths
                  style={{ maxWidth: 70, maxHeight: 70 }}
                />
              </View>
              <View
                style={{
                  display: 'flex',
                  flex: 2,
                  fontSize: 10,
                  flexDirection: 'column'
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View
                    style={{
                      borderBottomWidth: 1,
                      flex: 1,
                      borderRightWidth: 1
                    }}
                  >
                    <Text>Signed By</Text>
                  </View>
                  <View style={{ borderBottomWidth: 1, flex: 2 }}>
                    <Text>{subFormFieldValue.signedBy}</Text>
                  </View>
                </View>

                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View
                    style={{
                      borderBottomWidth: 1,
                      flex: 1,
                      borderRightWidth: 1
                    }}
                  >
                    <Text>Signed At</Text>
                  </View>
                  <View style={{ borderBottomWidth: 1, flex: 2 }}>
                    <Text>{subFormFieldValue.signedAt}</Text>
                  </View>
                </View>
              </View>
            </View>
          );
        } else if (Array.isArray(subFormFieldValue)) {
          if (typeof subFormFieldValue[0] === 'string') {
            // console.log(subFormFieldValue.join(', '));
            return (
              <Text style={{ padding: 5 }}>{subFormFieldValue.join(', ')}</Text>
            );
          } else if (typeof subFormFieldValue[0] === 'object') {
            let images = isRepeat ? subFormFieldValue[0] : subFormFieldValue;
            return (
              <View
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  flex: 1,
                  width: '490px'
                }}
              >
                {images
                  .filter((image) => !!image?.fileUrl)
                  .map((image, i) => (
                    <View
                      wrap={false}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 110,
                        overflow: 'hidden',
                        margin: '3px 5px'
                      }}
                    >
                      <Image
                        src={{
                          uri: image.fileUrl,
                          method: 'GET',
                          headers: { 'Cache-Control': 'no-cache' },
                          body: ''
                        }}
                        allowDangerousPaths
                        style={{
                          width: 110,
                          height: 120,
                          objectFit: 'cover',
                          objectPosition: 'center'
                        }}
                      />

                      <View
                        style={{
                          display: 'flex',
                          fontSize: 8,
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%'
                        }}
                      >
                        <Text>{image.status}</Text>
                        <Text style={{ fontWeight: 'bold', margin: '0px 2px' }}>
                          {' '}
                          {'-'}
                        </Text>

                        <Text>{image.created_at}</Text>
                      </View>

                      <View
                        style={{
                          display: 'flex',
                          fontSize: 8,
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%'
                        }}
                      >
                        <Text>{image.created_by}</Text>
                        <Text style={{ fontWeight: 'bold', margin: '0px 2px' }}>
                          {'-'}
                        </Text>

                        <Text>{image.company_name}</Text>
                      </View>
                    </View>
                  ))}
              </View>
            );
          }
          // return <Text style={{ padding: 5 }}> {value} bulunamadi </Text>;
        } else {
          return Object.entries(subFormFieldValue).map(([subKey, subValue]) => {
            return renderValue(subValue, subKey);
          });
        }
      default:
        return <Text>{value} bulunamadi ---</Text>;
    }
  };

  const renderForm = (form_values) =>
    Object.entries(form_values).map(([formName, formValues], index) => (
      // console.log(index),
      <View style={{ marginTop: 5, borderWidth: 1 }}>
        <Text
          style={{
            fontWeight: '700',
            backgroundColor: '#ddd',
            borderBottomWidth: 1
          }}
        >
          {formName}
        </Text>

        {Object.entries(formValues).map(
          ([subFormFieldName, subFormFieldValue]) => {
            if (template)
              template.template.subForms[index].controls.length > count
                ? console.log((count += 1))
                : console.log((count = 0));
            const tmp = template?.template?.subForms?.[index]?.controls?.find(
              (control) => control?.name === subFormFieldName
            );
            return (
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                {tmp?.type !== 'inputImage' && (
                  <View
                    style={{
                      width: 120,
                      minWidth: 100,
                      padding: 5,
                      borderBottomWidth: 1,
                      borderRightWidth: 1
                    }}
                  >
                    {template && (
                      <Text style={{ flex: 1 }}>
                        {tmp?.label?.[0] || tmp?.name}
                      </Text>
                    )}
                  </View>
                )}
                <View
                  style={{
                    borderBottomWidth: 1,
                    flex: 2
                  }}
                >
                  {tmp?.type === 'inputImage' ? (
                    <Text style={{ padding: '0px 4px' }}>
                      {tmp?.label?.[0] || tmp?.name}
                    </Text>
                  ) : null}
                  {template.template.subForms[index].controls[count]?.type !==
                  'group' ? (
                    renderValue(subFormFieldValue, tmp?.label?.[0] || tmp?.name)
                  ) : (
                    <View>
                      {Object.values(subFormFieldValue)[0].map((s, i) =>
                        renderGroup(
                          subFormFieldValue,
                          i,
                          template.template.subForms[index].controls[count][
                            'children'
                          ]
                        )
                      )}
                    </View>
                  )}
                </View>
              </View>
            );
          }
        )}
      </View>
    ));

  try {
    var fromData = state.formData.status_name;
    var formData = state.formData;

    var Objfromdata = Object.keys(formData);
  } catch (error) {
    console.log('formPDF', 'froData bulamadik');
  }
  var count = -1;
  var controls = 0;

  try {
    var template = state.template;
  } catch (error) {
    console.log('formPDF', 'template bulnamadi');
  }
  // if(state.formValues.Signature.signature!==undefined){console.log(state.formValues.Signature.signature)}
  // else{console.log("imza bulunamadi")}

  const renderFnc = () => (
    <>
      <Page
        size="A4"
        wrap
        style={{ padding: 50, fontSize: 10, fontFamily: 'Roboto' }}
      >
        <View style={{ display: 'flex', flexDirection: 'row', borderWidth: 1 }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              borderRightWidth: 1,
              flex: 1
            }}
          >
            <Image
              src={`data:image/png;base64,${state.selectedProject?.project_logo}`}
              alt="project logo"
              allowDangerousPaths
              style={{ maxWidth: 200, maxHeight: 50, objectFit: 'cover' }}
            />
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                borderBottomWidth: 1,
                justifyContent: 'center',
                alignItems: 'center',
                flex: 2,
                width: '100%',
                margin: 0,
                position: 'relative'
              }}
            >
              <View
                style={{
                  position: 'absolute',
                  left: '2px',
                  top: '2px',
                  fontWeight: 'bold',
                  fontSize: 8
                }}
              >
                <Text>Project:</Text>
              </View>

              <Text>
                {state.selectedProject
                  ? state.selectedProject.project_name
                  : state.project_name}
              </Text>
            </View>

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                width: '100%',
                margin: 0
              }}
            >
              <Text style={{ fontWeight: 'bold' }}>
                {`${state?.formData?.template_name?.toUpperCase()} REPORT`}
              </Text>
            </View>
          </View>
          <View
            style={{
              borderLeftWidth: 1,
              flex: 1,
              display: 'flex'
            }}
          >
            <View
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                minHeight: '35px',
                position: 'relative',
                borderBottomWidth: 1
              }}
            >
              <View
                style={{
                  position: 'absolute',
                  left: '2px',
                  top: '2px',
                  fontSize: 8,
                  fontWeight: 'bold'
                }}
              >
                <Text>Contractor</Text>
              </View>
              <Text style={{ fontWeight: 'bold' }}>
                {' '}
                {state?.company?.company_name}
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
              <View
                style={{
                  borderBottomWidth: 1,
                  width: 60,
                  fontSize: 8,

                  borderRightWidth: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold'
                }}
              >
                <Text>Form No</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  borderBottomWidth: 1,
                  alignItems: 'center'
                }}
              >
                <Text>{state.formNo || state.formData.form_no}</Text>
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
              <View
                style={{
                  borderBottomWidth: 1,
                  width: 60,
                  fontSize: 8,
                  borderRightWidth: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold'
                }}
              >
                <Text>Form Name</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderBottomWidth: 1
                }}
              >
                <Text>{state.formData.form_name}</Text>
              </View>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
              <View
                style={{
                  width: 60,
                  fontSize: 8,
                  borderRightWidth: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold'
                }}
              >
                <Text>Form Status</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Text>{state.formData.status_name}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ borderWidth: 1, marginTop: 2 }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '2px 0px',
              borderBottomWidth: 1,
              fontSize: 8
            }}
          >
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                fontWeight: 'bold',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              Issue Date:
            </Text>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                fontWeight: 'bold',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              {moment(state.formData.created_at).format('DD.MM.YYYY')}
            </Text>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                fontWeight: 'bold',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              Issues By:
            </Text>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                fontWeight: 'bold',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              {state?.createdBy?.name}
            </Text>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                fontWeight: 'bold',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              Closed Date:
            </Text>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                fontWeight: 'bold',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              {' '}
            </Text>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                fontWeight: 'bold',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              Closed By:
            </Text>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                fontWeight: 'bold',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            ></Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              borderBottomWidth: 1,
              flexWrap: 'wrap',
              flex: 1,
              width: '100%',
              padding: '0px 4px'
            }}
          >
            {metadatas.map(([key, { metadata_detail_name }]) => (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  width: '25%',
                  padding: '2px',
                  fontSize: 9
                }}
              >
                <Text
                  style={{
                    fontWeight: 'bold',
                    flex: 1
                  }}
                >
                  {key} :
                </Text>
                <Text
                  style={{
                    fontWeight: 'bold',
                    flex: 1,
                    textAlign: 'center'
                  }}
                >
                  {metadata_detail_name}
                </Text>
              </View>
            ))}
          </View>
          <View
            style={{
              padding: 'auto 4px',
              fontSize: 9,
              fontWeight: 'bold'
            }}
          >
            <Text>
              {' '}
              Location :{' '}
              {sitelist.map(
                ({ name }, index) =>
                  `${name} ${index !== sitelist.length - 1 ? '> ' : ''}  `
              )}{' '}
            </Text>
          </View>
        </View>
        {renderForm(state.formValues)}
      </Page>
    </>
  );
  if (multiple) return renderFnc();
  else return <Document>{renderFnc()}</Document>;
});
