import React, { useRef, Fragment } from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';

import { MainHeader } from 'components/Header';
import Sidebar from 'components/Sidebar';

const MainContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  background-color: rgba(245, 245, 245, 1);
  max-width: 100vw;
  min-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
`;

const MainContent = styled.div`
  margin: 0px;
  padding: 10px;
  max-width: calc(100vw - 90px);
  min-width: calc(100vw - 90px);
  max-height: calc(100vh - 75px);
  min-height: calc(100vh - 75px);
  margin-top: 71px;
  margin-left: 85px;
  z-index: 1;
  overflow: auto;
`;

const Layout = React.forwardRef((props, ref) => {
  const layoutRef = useRef();

  return (
    <MainContainer>
      <MainHeader />
      <Sidebar />
      <MainContent ref={layoutRef} id="main_content">
        <>{props.children}</>
      </MainContent>
    </MainContainer>
  );
});

export default Layout;
