import APIHelper from '../helpers/APIHelper';

export async function getTasksByProjectFunction(project_id) {
  const response = await APIHelper().post('tasks/find_by_project', null, { project_id });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('GET TASKS BY PROJECT RESPONSE =>', response.tasks);
  return response.tasks.sort((a, b) => (b.id - a.id));
}

export async function getTasksByWorkerFunction(project_id, worker_id) {
  const response = await APIHelper().post('tasks/find_by_project_worker', null, { project_id, worker_id });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('GET TASKS BY WORKER RESPONSE =>', response.tasks);
  return response.tasks.sort((a, b) => (b.id - a.id));
}

export async function getTaskTemplatesByProjectFunction(project_id, worker_id) {
  // const response = await APIHelper().post('tasks/find_by_project_worker', null, { project_id, worker_id });
  // if (response.error) {
  //  throw new Error(response.error);
  // }
  // console.log('GET TASKS BY WORKER RESPONSE =>', response.tasks);
  // return response.tasks;
  return mockTemplate;
}

export async function insertTaskFunction({
  projectId,
  formId,
  workerId,
  workerName,
  dueDate,
  stepNo,
  status,
  taskType,
  taskGuid,
}) {
  const response = await APIHelper().post('tasks/insert', null, {
    project_id: projectId,
    form_id: formId,
    worker_id: workerId,
    worker_name: workerName,
    due_date: dueDate,
    step_no: stepNo || 1,
    status: status || 0,
    task_type: taskType || 1,
    task_guid: taskGuid
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('INSERT TASK RESPONSE =>', response);
  return response;
}

export async function updateTaskFunction({
  projectId,
  taskId,
  formId,
  workerId,
  workerName,
  dueDate,
  stepNo,
  status,
}) {
  const response = await APIHelper().post('tasks/update', null, {
    project_id: projectId,
    task_id: taskId,
    form_id: formId,
    worker_id: workerId,
    worker_name: workerName,
    due_date: dueDate,
    step_no: stepNo,
    status: status || 0,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('UPDATE TASK RESPONSE =>', response);
  return response;
}

const mockTemplate = [
  {
    id: 1,
    template_name: 'abcdef',
    status: 1,
    step_no: 2,
    created_at: new Date('2020-09-06'),
    update_at: new Date('2020-09-06'),

  },
  {
    id: 2,
    template_name: 'zdeldasdsa',
    status: 2,
    step_no: 1,
    created_at: new Date('2020-09-06'),
    update_at: new Date('2020-09-06'),

  },
  {
    id: 3,
    template_name: 'dasdasişl',
    status: 3,
    step_no: 5,
    created_at: new Date('2020-09-06'),
    update_at: new Date('2020-09-06'),

  },
];
