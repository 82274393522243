import React, { useState } from 'react';
import styled from 'styled-components';
import { Breadcrumbs, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import SearchBar from 'components/Common/SearchBar';

import AlertDialog from 'components/Common/AlertDialog';
import { useSelector } from 'react-redux';
import { projectsSelector } from 'slices';
import { FormattedMessage } from 'react-intl';
import MailList from './MailList';

const MailsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(245, 245, 245, 1);
`;

const TitleContainer = styled.div`
  background-color: rgba(255, 255, 255, 0);
  height: auto;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 30px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Helvetica', Helvetica, Arial, serif;
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(89, 84, 105, 1);
  line-height: 26px;
`;

const ContentLink = styled.span`
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
  line-height: 25px;
  align-self: center;
  color: #fa6400;
`;

const ContentTitle = styled.span`
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
  align-self: center;
  color: #595469;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin: 0px;
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  height: 100%;
`;

export default () => {
  const [alert, setAlert] = useState(false);

  const { selectedProject } = useSelector(projectsSelector);

  const alertText = '';

  return (
    <MailsContainer>
      <TitleContainer>
        <Breadcrumbs separator={<>&gt;</>} aria-label="breadcrumb">
          <Link component={RouterLink} to="/projects">
            <ContentLink>
              {selectedProject && selectedProject.project_name}
            </ContentLink>
          </Link>
          <ContentTitle>
            <FormattedMessage id="FORM_POST_BOX" />{' '}
          </ContentTitle>
        </Breadcrumbs>
        <ButtonContainer>
          <SearchBar searchFunction={() => {}} />
        </ButtonContainer>
      </TitleContainer>
      <MainContainer>
        <MailList />
      </MainContainer>
      <AlertDialog open={alert} setOpen={setAlert} alertText={alertText} />
    </MailsContainer>
  );
};
