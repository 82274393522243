import React, { useState, useEffect } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import styled from 'styled-components';
import { MdClose, MdCheck } from 'react-icons/md';

const Container = styled.div`
    padding: 10px 40px;
    height : 58vh;
    overflow: auto;
`;
const MetadataContainer = styled.div`
    margin: 30px 0px;
`;

const MetadataTıtle = styled.div`
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 19px;
    text-align: left;
    padding-left: 8px;
    margin: 10px 0px;
`;

const DetailList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const Detail = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <MdClose onClick={onDelete} />
  </div>
))`
    font-size: 12px;
    color: #fff;
    background: #555;
    padding: 6px 24px;
    margin: 5px 10px;
    border-radius: 8px;
    display: flex;


`;


const InputWrapper = styled('div')`
  width: 300px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  margin-right: 30px;
  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Listbox = styled('ul')`
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

const EditFormMetaData = (props) => {
  const { metadataList, setMetadataList, allMetadata } = props;


  const [selected, handleSelect] = useState({ id: 0, list: [] });


  useEffect(() => {
    const _metadataList = metadataList.filter((metadata) => selected.id !== metadata.metadata_id);
    setMetadataList(_metadataList.concat(selected.list));
  }, [selected]);


  return (
    <Container>
      {allMetadata.map((set) => (
        <CustomAutoComplate
          multiple
          set={set}
          details={set.metadata_details}
          metadataList={metadataList}
          setMetadataList={handleSelect}
          defaults={set.metadata_details.filter((detail) => metadataList.some((metadata) => metadata.metadata_detail_id === detail.id))}
        />
      ))}
    </Container>
  );
};

export default EditFormMetaData;

const CustomAutoComplate = (props) => {
  const {
    set, details, setMetadataList, defaults, multiple,
  } = props;


  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    defaultValue: defaults,
    multiple,
    options: details,
    getOptionLabel: (option) => option.name,

  });

  useEffect(() => {
    if (Array.isArray(value)) setMetadataList({ id: set.id, list: value.map((v) => ({ ...v, metadata_id: set.id, metadata_detail_id: v.id })) });
    else setMetadataList({ id: set.id, list: { ...value, metadata_id: set.id, metadata_detail_id: value.id } });
  }, [value]);

  return (
    <MetadataContainer {...getRootProps()}>
      <MetadataTıtle {...getInputLabelProps()}>{set.metadata_name}</MetadataTıtle>
      <InputContainer>
        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
          {groupedOptions.length > 0 ? (
            <Listbox {...getListboxProps()}>
              {groupedOptions.map((option, index) => (
                <li {...getOptionProps({ option, index })}>
                  <span>{option.name}</span>
                  <MdCheck fontSize="small" />
                </li>
              ))}
            </Listbox>
          ) : null}
          <input {...getInputProps()} />
        </InputWrapper>

      </InputContainer>
      <DetailList>
        {value && !Array.isArray(value)
          ? <Detail label={value.name} {...getTagProps({ index: 0 })} />
          : value.map((option, index) => (
            <Detail label={option.name} {...getTagProps({ index })} />
          ))}
      </DetailList>
    </MetadataContainer>
  );
};
