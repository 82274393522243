import React from 'react';
import { IconButton } from '@material-ui/core';
import { MdRemoveRedEye, MdCreate } from 'react-icons/md';

const TaskTemplateActions = ({ template_id }) => (
  <>
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <MdRemoveRedEye
        size="calc(4px + 0.5vh + 0.5vw)"
      />
    </IconButton>
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <MdCreate
        size="calc(4px + 0.5vh + 0.5vw)"
      />
    </IconButton>
  </>
);

export default TaskTemplateActions;
