import APIHelper from '../../helpers/APIHelper';

export async function getRevisionSetsFunction(proje_id) {
  const response = await APIHelper().get(
    `revision_sets/list?project_id=${proje_id}`
  );
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('GET REVISION_SETS RESPONSE =>', response.revision_sets);
  return response.revision_sets;
}

export async function searchRevisionSetsFunction(search) {
  const response = await APIHelper().post('revision_sets/search', null, {
    search
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('GET REVISION_SET SEARCH RESPONSE =>', response.revisionSets);
  return response.revisionSets;
}

export async function findByIdRevisionSetssFunction(revision_set_id) {
  const response = await APIHelper().post('revision_sets/find', null, {
    revision_set_id
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('FIND REVISION_SET BY ID RESPONSE =>', response.revision_set);
  return response.revision_set;
}

export async function addRevisionSetFunction(revisionSetObj) {
  const response = await APIHelper().post('revision_sets/insert', null, {
    ...revisionSetObj
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('ADD REVISION_SET RESPONSE =>', response.revision_set);
  return response.revision_set;
}

export async function updateRevisionSetFunction(revisionSetObj) {
  const response = await APIHelper().post('revision_sets/update', null, {
    ...revisionSetObj
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('UPDATE REVISION_SET RESPONSE =>', response);
  return response.status;
}

export async function deleteRevisionSetFunction(revision_set_id) {
  const response = await APIHelper().post('revision_sets/delete', null, {
    revision_set_id
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('DELETE REVISION_SET RESPONSE =>', response);
  return response.status;
}

export async function addRevisionSetDetailFunction(revisionSetDetailObj) {
  const response = await APIHelper().post('revision_sets/detail/insert', null, {
    ...revisionSetDetailObj
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log(
    'ADD REVISION_SET_DETAIL RESPONSE =>',
    response.revision_set_details
  );
  return response.revision_set_details;
}

export async function deleteRevisionSetDetailFunction(revision_set_detail_id) {
  const response = await APIHelper().post('revision_sets/detail/delete', null, {
    revision_set_detail_id
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('DELETE REVISION_SET_DETAIL RESPONSE =>', response);
  return response.status;
}

export async function updateRevisionSetDetailFunction(detail_id, name) {
  const response = await APIHelper().post('revision_sets/detail/update', null, {
    detail_id,
    name
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('UPDATE REVISION_SET_DETAIL RESPONSE =>', response);
  return response;
}
export async function updateRevisionSetTitleFunction(set_id, set_name) {
  const response = await APIHelper().post('revision_sets/update', null, {
    set_id,
    set_name
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('UPDATE Revision_SET_DETAIL RESPONSE =>', response);
  return response;
}
