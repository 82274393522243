import axios from 'axios';
import { authentication } from './authentication';

function formatAxiosError(error) {
  const data = error.response ? error.response.data : null;
  const errorObj = {
    data,
    message: error.message,
    code:
      (data && data.code)
      || error.code
      || (error.response && error.response.status),
    cancelled: axios.isCancel(error),
  };

  if (errorObj.code === 401) {
    authentication.logout();
    return { error: 'UNAUTHORIZED' };
  }

  if (errorObj.code === 500) {
    if (errorObj.data && errorObj.data.auth === false) {
      console.log('auth logout');
      authentication.logout();
      return { error: 'UNAUTHORIZED' };
    }
  }

  if (errorObj.code === 404) {
    return { error: '404_NOTFOUND' };
  }

  return { error: { ...errorObj } };
}

class APIHelper {
  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });

    const { token } = authentication.userValue;
    this.headers = {
      'x-access-token': token,
    };

    // this.instance.interceptors.request.use(async (request) => {
    //   console.log('***INTERCEPTORS***', 'Starting Request', request);

    //   /*

    //           LOGGING AND MONITORING AREA

    //   */

    //   return request;
    // });

    // this.instance.interceptors.response.use(
    //   async (response) => {
    //     console.log('***INTERCEPTORS***', 'Response:', response);

    //     /*

    //           LOGGING AND MONITORING AREA

    //     */

    //     return response;
    //   },
    //   async (error) => {
    //     console.log('***INTERCEPTORS***', 'Error:', {error});

    //     /*

    //           LOGGING AND MONITORING AREA

    //     */

    //     return Promise.reject(error);
    //   },
    // );
  }

  async get(url, headers) {
    try {
      const response = await this.instance.get(url, {
        headers: { ...this.headers, ...headers },
      });

      if (response.data) {
        if (response.data.code === 200) {
          const { code, status, ...returnObj } = response.data;
          return returnObj;
        }
        if (response.data.done) {
          const { code, status, ...returnObj } = response.data;
          return returnObj;
        }
        return { error: 'UNKNOWN_ERROR' };
      }
      return { error: 'UNKNOWN_ERROR2' };
    } catch (thrown) {
      return formatAxiosError(thrown);
    }
  }

  async post(url, headers, data) {
    try {
      const response = await this.instance.post(
        url,
        { ...data },
        { headers: { ...this.headers, ...headers } },
      );

      if (response.data) {
        if (response.data.code === 200) {
          const { code, status, ...returnObj } = response.data;
          return returnObj;
        }
        if (response.data.done) {
          const { code, status, ...returnObj } = response.data;
          return returnObj;
        }
        if (typeof response.data === 'string') {
          return response.data;
        }
        if (response.data.status === 'error') {
          return { error: response.data.message };
        }
        return { error: 'UNKNOWN_ERROR' };
      }
      return { error: 'UNKNOWN_ERROR2' };
    } catch (thrown) {
      return formatAxiosError(thrown);
    }
  }
}

const request = (options) => new APIHelper(options);

export default request;
