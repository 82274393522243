import { useEffect, useRef } from 'react';

export function deepCompareEquals(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function useDeepCompareMemoize(value) {
  const tempRef = useRef();

  if (!deepCompareEquals(value, tempRef.current)) {
    tempRef.current = value;
  }

  return tempRef.current;
}

export function useDeepCompareEffect(callback, dependencies) {
  useEffect(callback, useDeepCompareMemoize(dependencies));
}
