import { createSlice } from '@reduxjs/toolkit';
import {
  getSiteSetsFunction,
  addSiteSetFunction,
  updateSiteSetFunction,
  deleteSiteSetFunction,
  addSiteSetDetailFunction,
  deleteSiteSetDetailFunction,
  updateSiteSetDetailFunction,
  updateSiteSetTitleFunction
} from 'api/sets/siteSets';

const initialState = {
  loading: {
    getSiteSets: false,
    addSiteSet: false,
    updateSiteSet: false,
    deleteSiteSet: false,
    addSiteSetDetail: false,
    deleteSiteSetDetail: false,
    updateSiteSetDetail: false
  },
  hasErrors: {
    getSiteSets: false,
    addSiteSet: false,
    updateSiteSet: false,
    deleteSiteSet: false,
    addSiteSetDetail: false,
    deleteSiteSetDetail: false,
    updateSiteSetDetail: false
  },
  siteSets: []
};

const siteSetsSlice = createSlice({
  name: 'siteSets',
  initialState,
  reducers: {
    asyncStart: (state, { payload }) => {
      state.loading[payload] = true;
    },
    asyncFailure: (state, { payload }) => {
      state.loading[payload] = false;
      state.hasErrors[payload] = true;
    },
    getSiteSetsSuccess: (state, { payload }) => {
      state.siteSets = payload;
      state.loading.getSiteSets = false;
      state.hasErrors.getSiteSets = false;
    },
    addSiteSetSuccess: (state, { payload }) => {
      state.siteSets.unshift(payload);
      state.loading.addSiteSet = false;
      state.hasErrors.addSiteSet = false;
    },
    updateSiteSetSuccess: (state, { payload }) => {
      state.siteSets = state.siteSets.map((siteSet) => {
        if (siteSet.id === payload.id) {
          return payload;
        }
        return siteSet;
      });
      state.loading.updateSiteSet = false;
      state.hasErrors.updateSiteSet = false;
    },
    deleteSiteSetSuccess: (state, { payload }) => {
      state.siteSets = state.siteSets.filter(
        (siteSet) => siteSet.id !== payload
      );
      state.loading.deleteSiteSet = false;
      state.hasErrors.deleteSiteSet = false;
    },
    addSiteSet_Detail_Success: (state, { payload }) => {
      state.siteSets.forEach((siteSet) => {
        if (siteSet.id == payload.site_id) {
          siteSet.site_set_details.push(payload);
        }
      });
      state.loading.addSiteSetDetail = false;
      state.hasErrors.addSiteSetDetail = false;
    },
    deleteSiteSet_Detail_Success: (state, { payload }) => {
      state.siteSets.forEach((siteSet) => {
        if (siteSet.site_set_details.some((detail) => detail.id == payload)) {
          siteSet.site_set_details = siteSet.site_set_details.filter(
            (detail) => detail.id != payload
          );
        }
      });
      state.loading.deleteSiteSetDetail = false;
      state.hasErrors.deleteSiteSetDetail = false;
    },
    deleteSiteSet_Update_Success: (state, { payload }) => {
      state.siteSets.forEach((siteSet) => {
        if (
          siteSet.site_set_details.some(
            (detail) => detail.id == payload.detail_id
          )
        ) {
          siteSet.site_set_details = siteSet.site_set_details.map((detail) => {
            if (detail.id === payload.detail_id)
              return { ...detail, name: payload.name };
            return detail;
          });
        }
      });
      state.loading.updateSiteSetDetail = false;
      state.hasErrors.updateSiteSetDetail = false;
    },
    siteSet_Title_Update_Success: (state, { payload: { set_id, name } }) => {
      state.siteSets.forEach((siteSet) => {
        if (siteSet.id === set_id) {
          siteSet.set_name = name;
        }
      });
    }
  }
});

export const {
  asyncStart,
  asyncFailure,
  getSiteSetsSuccess,
  addSiteSetSuccess,
  updateSiteSetSuccess,
  deleteSiteSetSuccess,
  addSiteSet_Detail_Success,
  deleteSiteSet_Detail_Success,
  deleteSiteSet_Update_Success,
  siteSet_Title_Update_Success
} = siteSetsSlice.actions;

export default siteSetsSlice.reducer;

export const siteSetsSelector = (state) => state.siteSets;

export const fetchSiteSets = (proje_id) => async (dispatch) => {
  dispatch(asyncStart('getSiteSets'));

  try {
    const _siteSets = await getSiteSetsFunction(proje_id);
    dispatch(getSiteSetsSuccess(_siteSets));
  } catch (error) {
    console.log('an error occurred while fetching siteSets', error);
    dispatch(asyncFailure('getSiteSets'));
  }
};

export const addSiteSet = (siteSetObj) => async (dispatch) => {
  dispatch(asyncStart('addSiteSet'));
  try {
    const newSiteSet = await addSiteSetFunction(siteSetObj);
    dispatch(addSiteSetSuccess({ ...newSiteSet, site_set_details: [] }));
  } catch (error) {
    console.log('an error occurred while adding siteSet', error);
    dispatch(asyncFailure('addSiteSet'));
  }
};

export const updateSiteSet = (siteSetObj) => async (dispatch) => {
  dispatch(asyncStart('updateSiteSet'));
  try {
    await updateSiteSetFunction(siteSetObj);
    dispatch(updateSiteSetSuccess(siteSetObj));
  } catch (error) {
    console.log('an error occurred while updating siteSet', error);
    dispatch(asyncFailure('updateSiteSet'));
  }
};

export const deleteSiteSet = (siteSet_id) => async (dispatch) => {
  dispatch(asyncStart('deleteSiteSet'));
  try {
    await deleteSiteSetFunction(siteSet_id);
    dispatch(deleteSiteSetSuccess(siteSet_id));
  } catch (error) {
    console.log('an error occurred while deleting siteSet', error);
    dispatch(asyncFailure('deleteSiteSet'));
  }
};

export const addSiteSetDetail = (site_set_detail_obj) => {
  site_set_detail_obj.site_id = site_set_detail_obj.set_id;
  delete site_set_detail_obj.set_id;
  return async (dispatch) => {
    dispatch(asyncStart('addSiteSetDetail'));
    try {
      const new_detail_obj = await addSiteSetDetailFunction(
        site_set_detail_obj
      );
      dispatch(addSiteSet_Detail_Success(new_detail_obj));
    } catch (error) {
      console.log('an error occurred while adding siteSet detail', error);
      dispatch(asyncFailure('addSiteSetDetail'));
    }
  };
};

export const deleteSiteSetDetail = (site_set_detail_id) => async (dispatch) => {
  dispatch(asyncStart('deleteSiteSetDetail'));
  try {
    await deleteSiteSetDetailFunction(site_set_detail_id);
    dispatch(deleteSiteSet_Detail_Success(site_set_detail_id));
  } catch (error) {
    console.log('an error occurred while deleting siteSet detail', error);
    dispatch(asyncFailure('deleteSiteSetDetail'));
  }
};
export const updateSiteSetDetail = (detail_id, name) => async (dispatch) => {
  // dispatch(asyncStart('updateSiteSetDetail'));
  try {
    await updateSiteSetDetailFunction(detail_id, name);
    dispatch(deleteSiteSet_Update_Success({ detail_id, name }));
  } catch (error) {
    console.log('an error occurred while update siteSet detail', error);
    dispatch(asyncFailure('updateSiteSetDetail'));
  }
};

export const updateSiteSetTitle = (set_id, name) => async (dispatch) => {
  try {
    await updateSiteSetTitleFunction(set_id, name);
    dispatch(siteSet_Title_Update_Success({ set_id, name }));
  } catch (error) {
    console.log('an error occurred while update siteSet title', error);
  }
};
