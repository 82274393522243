import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ItemContainer from 'components/Common/ItemContainer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  MdAccountBalance,
} from 'react-icons/md';
import { FaUser } from 'react-icons/fa';
import Box from '@material-ui/core/Box';

import { useDispatch, useSelector } from 'react-redux';
import { findByIdCompaniessFunction, findProjectToCompanyFunction } from 'api/companies';
import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  companiesSelector,
  updateCompanyLogo,
} from '../../slices/companiesSlice';
import Users from './Users';
import CompanyInfo from './CompanyInfo';
import Groups from './Groups';

import headerLogo from '../../assets/header/smallLogo.png';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  dialogGrid: {
    margin: '0px',
    padding: '0px',
    '& > p': {
      fontSize: '12px',
      margin: '3px',
      marginTop: '0px',
      padding: '0px',
      fontWeight: 'bold',
      color: '#555',
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  dialogButton: {
    margin: '5px 15px',
  },
}));

const LeftBar = styled(Paper)`
  width: 20%;
  height: 80vh;
`;
const RightContent = styled(Paper)`
  width: 75%;
  margin-left: 3%;
`;
const LogoContainer = styled.div`
  padding: 12px;
  width: 100%;
  height: 170px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  width: auto;
  height: 80%;
  max-width: 18vw;
  max-height: 150px;
`;

const ChangeLogo = styled.a`
margin-top: 16px;
font-family: Helvetica;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
display: flex;
align-items: center;
text-align: center;

color: #0091FF;
`;

const NavContainer = styled.div`
  height: 100;
`;
const TabLabel = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const TablabeLIcon = styled.div`
  margin-left: 10px;
  width: 30px;
  height: 30px;
  padding: 3px;
  border-radius: 100%;
  background: rgba(250, 100, 0, 1);
`;
const TablabelLink = styled.div`
  margin-left: 10px !important;
  font-family: Lucida Grande !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 15px !important;
  text-transform: none !important;
  color: #595469 !important;
`;
const NavLink = ({ icon, link }) => (
  <TabLabel>
    <TablabeLIcon>{icon}</TablabeLIcon>
    <TablabelLink>{link}</TablabelLink>
  </TabLabel>
);

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    left: '0 !important',
    width: '5px !important',
    zIndex: 200,
    backgroundColor: 'rgba(250, 100, 0, 1)',
    '& > div': {
      width: '100%',
    },
  },
})((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <div />,
    }}
  />
));

export default (props) => {
  const fileInput = useRef(null);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [company, setCompany] = useState({});
  const [logoFile, setLogoFile] = useState(null);

  const ref = useRef(true);

  const dispatch = useDispatch();

  const intl = useIntl();

  const { companies, loading } = useSelector(companiesSelector);

  useEffect(() => {
    if (logoFile) {
      dispatch(updateCompanyLogo({ company_id: company.id, logo: logoFile.base64 }));
    }
  }, [logoFile]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { id } = useParams();

  useEffect(() => {
    if (companies.length) {
      const _company = companies.filter((company) => company.id == id);
      findProjectToCompanyFunction(id, ref.current)
        .then((company_projects) => setCompany({ ..._company[0], company_projects }));
    } else {
      findByIdCompaniessFunction(id, ref.current)
        .then((res) => {
          findProjectToCompanyFunction(id, ref.current)
            .then((company_projects) => setCompany({ ...res[0], company_projects }));
        });
    }

    return () => {
      ref.current = false;
    };
  }, [companies]);


  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleLogoChange = (e) => {
    const { files } = e.target;

    const file = files[0];

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      if (file.type.substr(0, 6) === 'image/') {
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: `${Math.round(file.size / 1000)} kB`,
          base64: reader.result,
          file,
        };

        setLogoFile(fileInfo);
      } else {
        window.alert(intl.formatMessage({ id: 'ENTER_ONLY_IMAGE_FILE' }));
      }
    };
  };

  return (
    <>
      {!company
        ? <CircularProgress />
        : (
          <ItemContainer
            itemName={company.company_name}
            breadcrumbs={[{ name: intl.formatMessage({ id: 'COMPANIES' }), to: '/companies' }]}
          >
            <div className={classes.root}>
              <LeftBar>
                {loading.updateCompanyLogo && (
                  <LogoContainer>
                    <CircularProgress />
                  </LogoContainer>
                )}
                {!loading.updateCompanyLogo && (
                <LogoContainer>
                  <Logo src={company.logo !== '' ? company.logo : headerLogo} alt={company.Name} />
                  <ChangeLogo href="#" onClick={handleClick}>
                    <FormattedMessage id="UPDATE_COMPANY_LOGO" />
                  </ChangeLogo>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleLogoChange}
                    ref={fileInput}
                    style={{ display: 'none' }}
                  />
                </LogoContainer>
                )}
                <NavContainer>
                  <StyledTabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs companies"
                    className={classes.tabs}
                  >
                    <Tab
                      label={(
                        <NavLink
                          icon={<MdAccountBalance color="#fff" />}
                          link={intl.formatMessage({ id: 'COMPANY_INFO' })}
                        />
                  )}
                      selected
                    />
                    <Tab
                      label={(
                        <NavLink
                          icon={<FaUser color="#fff" />}
                          link={intl.formatMessage({ id: 'USERS' })}
                        />
                  )}
                    />
                    <Tab
                      label={(
                        <NavLink
                          icon={<MdAccountBalance color="#fff" />}
                          link={intl.formatMessage({ id: 'GROUPS' })}
                        />
                  )}
                    />
                  </StyledTabs>
                </NavContainer>
              </LeftBar>
              <RightContent>
                <TabPanel value={value} index={0}>
                  <CompanyInfo company={company} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Users company={company} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Groups company={company} />
                </TabPanel>
              </RightContent>
            </div>
          </ItemContainer>

        )}
    </>
  );
};
