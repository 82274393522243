import React, { forwardRef } from 'react';

import MailList from 'components/Mails';
import { ContentContainer } from 'styles/common';


export default forwardRef((props, ref) => (
  <ContentContainer>
    <MailList {...props} />
  </ContentContainer>
));
