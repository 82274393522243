import styled from 'styled-components';

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12px;
  background-color: rgba(245, 245, 245, 1);
`;

export const SelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
`;

export const TasksContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const TaskTitle = styled.span`
  font-family: 'Helvetica', Helvetica, Arial, serif;
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
  border-bottom: 5px solid rgba(255, 100, 0, 1);
`;
