import React, { useEffect, useRef, useState } from 'react';

import {
  TitleContainer,
  ContentContainer,
  ContentLink,
  ContentTitle,
  MainContainer
} from 'styles/common';
import { Breadcrumbs, Link } from '@material-ui/core';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  projectsSelector,
  mailsSelector,
  getInboxMails,
  getSendboxMails,
  findCompanyById,
  findByIdForm,
  formsSelector,
  fetchDocuments
} from 'slices';
import { authentication } from 'helpers/authentication';
import { FormattedMessage } from 'react-intl';
import MailContent from './MailContent';

export default (props) => {
  const { selectedProject } = useSelector(projectsSelector);

  const { form, documents } = useSelector(formsSelector);

  const { inBox, sendBox } = useSelector(mailsSelector);

  const { id, type } = useParams();

  const history = useHistory();

  const mountedRef = useRef(true);

  const { id: user_id } = authentication.userValue;

  const dispatch = useDispatch();

  const [mailContent, setContent] = useState(null);
  const [fromCompany, setFromCompany] = useState(null);
  const [toCompany, setToCompany] = useState(null);

  async function fetchMailAllMails(project_id) {
    if (type === 'inBox' && !inBox.length) {
      dispatch(getInboxMails(project_id, user_id));
    } else if (type === 'sendBox' && !sendBox.length) {
      dispatch(getSendboxMails(project_id, user_id));
    }
  }

  async function fetchCompanies(mail) {
    const _fromCompany = await dispatch(findCompanyById(mail.from_company_id));
    const _toCompany = mail.to_json.users
      ? await dispatch(findCompanyById(mail.to_json.users[0].companyId))
      : null;
    await dispatch(findByIdForm(mail.form_id));
    setFromCompany(_fromCompany && _fromCompany[0]);
    setToCompany(_toCompany && _toCompany[0]);
  }

  useEffect(() => {
    if (selectedProject) {
      fetchMailAllMails(selectedProject.id, mountedRef.current);
      return () => {
        mountedRef.current = false;
      };
    }
    history.push('/mails');
  }, [selectedProject]);

  useEffect(() => {
    if (form) dispatch(fetchDocuments(form.original_form_id));
  }, [form]);

  useEffect(() => {
    if (selectedProject) {
      if (type === 'inBox') {
        if (inBox.length) {
          inBox.forEach((mail) => {
            if (mail.id == id) {
              setContent(mail);
            }
          });
        } else {
          fetchMailAllMails(selectedProject.id, mountedRef.current).then(() => {
            inBox.forEach((mail) => {
              if (mail.id == id) {
                setContent(mail);
              }
            });
          });
        }
      } else if (type === 'sendBox') {
        if (sendBox.length) {
          sendBox.forEach((mail) => {
            if (mail.id == id) {
              setContent(mail);
            }
          });
        } else {
          fetchMailAllMails(selectedProject.id, mountedRef.current).then(() => {
            sendBox.forEach((mail) => {
              if (mail.id == id) {
                setContent(mail);
              }
            });
          });
        }
      }
    }
  }, [selectedProject, sendBox, inBox]);

  useEffect(() => {
    if (mailContent) {
      fetchCompanies(mailContent, mountedRef.current);
    }
    return () => {
      mountedRef.current = false;
    };
  }, [mailContent]);


  return (
    <div style={{ width: '100%', height: '100%' }}>
      <TitleContainer>
        <Breadcrumbs separator={<>&gt;</>} aria-label="breadcrumb">
          <Link component={RouterLink} to="/projects">
            <ContentLink>
              {selectedProject && selectedProject.project_name}
            </ContentLink>
          </Link>
          <ContentTitle>
            <FormattedMessage id="FORM_MAIL_BOX" />{' '}
          </ContentTitle>
        </Breadcrumbs>
      </TitleContainer>
      <MailContent
        mail={mailContent}
        fromCompany={fromCompany}
        toCompany={toCompany}
        documents={documents}
        type={type}
      />
    </div>
  );
};
