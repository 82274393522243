import React from 'react';
import styled from 'styled-components';
import { List, ListItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';


const Container = styled.div`
    display: flex;
    flex: 1;
`;

const ListTitle = styled.div`
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 19px;
    margin: 10px 0px;

`;
const GroupName = styled.span`
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
    line-height: 19px;

`;
const CompanyName = styled.span`
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 19px;

`;

const GroupList = styled(List)`
    border: 1px solid black;
    border-radius: 10px;
    margin: 5px 20px;
    width: 280px;
    height: 50vh;
    overflow: auto !important;
`;


const FormPermission = ({ groups }) => (
  <Container>
    <div>
      <ListTitle>
        <FormattedMessage id="AUTHORİZED_GROUPS" />
      </ListTitle>
      <GroupList>
        {groups && groups.map((group, index) => (
          <ListItem key={`group-key-${group.id}-${index}-`}>
            <GroupName>{group.group_name}</GroupName>
            <CompanyName>({group.company_name})</CompanyName>
          </ListItem>
        ))}
      </GroupList>
    </div>
  </Container>
);

export default FormPermission;
