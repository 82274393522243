import React, { forwardRef } from 'react';

import Mail from 'components/Mail';
import { ContentContainer } from 'styles/common';


export default forwardRef((props, ref) => (
  <ContentContainer>
    <Mail {...props} />
  </ContentContainer>
));
