import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';

import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import CommonContainer from 'components/Common/CommonContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import {
  IconButton,
  Button,
  TextField,
  CircularProgress
} from '@material-ui/core';
import { getRandomColor } from 'helpers';

import { MdRemoveRedEye, MdCreate, MdDelete } from 'react-icons/md';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Grid from '@material-ui/core/Grid';

import { FormattedMessage, useIntl } from 'react-intl';
import { Autocomplete } from '@material-ui/lab';
import { findRoleByProjectIdCompanyFunc } from 'api';
import {
  groupsSelector,
  fetchGroupsByCompanyId,
  addGroup,
  updateGroup,
  deleteGroup,
  fetchUsersByCompanyId,
  usersSelector,
  addGroupToUser,
  deleteGroupOfUser,
} from '../../slices';
import AlertDialog from 'components/Common/AlertDialog';

import { authentication, changeValues } from '../../helpers/authentication';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(36),
      height: theme.spacing(24)
    }
  },
  dialogGrid: {
    margin: '0px',
    padding: '0px',
    '& > p': {
      fontSize: '12px',
      margin: '3px',
      marginTop: '0px',
      padding: '0px',
      fontWeight: 'bold',
      color: '#555'
    }
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  dialogButton: {
    margin: '5px 15px'
  },
  table: {
    minWidth: 650
  },
  userAvatar: {
    background: getRandomColor()
  }
}));

const Actions = styled.div`
  display: flex;
  justify-content: space-around;
`;

const TableCellStyled = styled(TableCell)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #8a849b !important;
  display: ${(props) => (props.id ? 'flex !important' : null)};
  justify-content: ${(props) => (props.id ? 'center !important' : null)};
  align-items: ${(props) => (props.id ? 'center !important' : null)};
  text-transform: uppercase;
`;

const TableContainerStyled = styled(TableContainer)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
`;

export default ({ company }) => {
  const classes = useStyles();

  const [isEdit, setIsEdit] = useState(false);
  const [groupName, setGroupName] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [roles, setRoles] = useState([]);
  const [alertModal, setAlertModal] = useState(false);

  const dispatch = useDispatch();

  const intl = useIntl();
  const mountedRef = useRef();
  const { groups, loading } = useSelector(groupsSelector);
  const { users } = useSelector(usersSelector);
  
  const userValues = authentication.userValue;

  useEffect(() => {
    if (company) {
      const { id: company_id, project_id } = company;
      findRoleByProjectIdCompanyFunc(project_id, mountedRef.current).then(
        (res) => {
          const _roles = res.filter((role) => role.company_id === company_id);
          setRoles(_roles);
        }
      );
    }
    return () => {
      mountedRef.current = false;
    };
  }, [company]);

  useEffect(() => {
    if (!groups.length && !loading.getGroup) {
      dispatch(fetchGroupsByCompanyId(company.id));
    }
    if (!users.length) {
      dispatch(fetchUsersByCompanyId(company.id));
    }
  }, []);
  console.log('$$$ --> ', users);
  useEffect(() => {}, [groups]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getActions = (group) => (
    <>
      <IconButton>
        <MdRemoveRedEye size={16} />
      </IconButton>
      <IconButton
        onClick={() => {
          setIsEdit(true);
          setGroupName(group);
          setShowModal(true);
        }}
      >
        <MdCreate size={16} />
      </IconButton>
      <IconButton
        onClick={() => {
          dispatch(deleteGroup(group.id));
        }}
      >
        <MdDelete size={16} />
      </IconButton>
    </>
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: 'REQUIRED_GROUP_NAME' })
    ),
    role: Yup.object()
      .nullable()
      .required(intl.formatMessage({ id: 'REQUIRED_GROUP_ROLE' }))
  });

  const initialValues = {
    name: isEdit ? groupName.group_name : '',
    role: isEdit ? roles.find((r) => r.role_id === groupName.role_id) : '',
    users: isEdit ? users.filter((u) => u.groups.some((ug) => ug.group_id === groupName.id)) : [],
  };
  return (
    <>
      <CommonContainer
        containerName={intl.formatMessage({ id: 'GROUPS' })}
        addButton={intl.formatMessage({ id: 'CREATE_GROUP' })}
        setShowModal={() => {
          if (roles.length) {
            setIsEdit(false);
            setGroupName(null);
            setShowModal(true);
          } else {
            setAlertModal(true);
          }
        }}
        page="groups"
        inside="true"
      >
        {!loading.getGroups && groups && (
          <>
            <TableContainerStyled component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCellStyled align="center" width="60px">
                      #
                    </TableCellStyled>
                    <TableCellStyled>
                      <FormattedMessage id="GROUP_NAME" />
                    </TableCellStyled>
                    <TableCellStyled align="center" width="100px">
                      <FormattedMessage id="USER_COUNT" />
                    </TableCellStyled>
                    <TableCellStyled align="center" width="175px">
                      <FormattedMessage id="CRETAED_AT" />
                    </TableCellStyled>
                    <TableCellStyled align="center" width="100px">
                      <FormattedMessage id="ACTIONS" />
                    </TableCellStyled>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groups
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((group) => {
                      if (group) {
                        const tempDate = new Date(group.created_at);

                        const dd =
                          (tempDate.getDate() < 10 ? '0' : '') +
                          tempDate.getDate();
                        const MM =
                          (tempDate.getMonth() + 1 < 10 ? '0' : '') +
                          (tempDate.getMonth() + 1);
                        const yyyy = tempDate.getFullYear();

                        const date = `${dd}.${MM}.${yyyy}`;
                        return (
                          <TableRow key={`${group.id}-${group.name}`}>
                            <TableCellStyled align="center" width="60px">
                              <div
                                style={{
                                  width: '40px',
                                  height: '40px',
                                  borderRadius: '50%',
                                  backgroundColor: `#${group.color}`,
                                  borderWidth: '1px',
                                  borderColor: '#A2A2A2'
                                }}
                              />
                            </TableCellStyled>
                            <TableCellStyled>
                              {group.group_name}
                            </TableCellStyled>
                            <TableCellStyled align="center" width="100px">
                              {group.user_count}
                            </TableCellStyled>
                            <TableCellStyled align="center" width="175px">
                              <div
                                style={{
                                  color: '#fa6400'
                                }}
                              >
                                {date}
                              </div>
                            </TableCellStyled>
                            <TableCellStyled align="center" width="100px">
                              <Actions>{getActions(group)}</Actions>
                            </TableCellStyled>
                          </TableRow>
                        );
                      }
                    })}
                </TableBody>
              </Table>
            </TableContainerStyled>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={groups.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        )}
      </CommonContainer>

      <Dialog
        open={showModal}
        onClose={() => {
          dispatch(fetchGroupsByCompanyId(company.id));
          dispatch(fetchUsersByCompanyId(company.id));
          
          setIsEdit(false);
          setGroupName(null);
          setShowModal(false);
        }}
        aria-labelledby="form-dialog-title"
        fullWidth
        scroll="body"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          <div className={classes.dialogTitle}>
            {isEdit ? (
              <FormattedMessage id="UPDATE_GROUP" />
            ) : (
              <FormattedMessage id="ADD_NEW_GROUP" />
            )}

            <IconButton
              onClick={() => {
                dispatch(fetchGroupsByCompanyId(company.id));
                dispatch(fetchUsersByCompanyId(company.id));
                
                setIsEdit(false);
                setGroupName(null);
                setShowModal(false);
              }}
            >
              <AiOutlineCloseCircle />
            </IconButton>
          </div>
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (isEdit) {
              const groupObj = {
                group_name: values.name,
                color: groupName.color,
                group_id: groupName.id,
                role_id: values.role.role_id
              };

              dispatch(updateGroup(groupObj)).then((res) => {
                const oldGroupUsers = users.filter((u) => u.groups.some((ug) => ug.group_id === groupName.id));
                const newGroupUsers = [...values.users];

                const newUsers = [];
                const willDeleteUsers = [];

                const justControlIt = Promise.all(
                  newGroupUsers.map((user) => {
                    const willAdd = oldGroupUsers.find((oldUser) => oldUser.id === user.id);
                    if (!willAdd) {
                      newUsers.push(user);
                    }
                  }),
                  oldGroupUsers.map((user) => {
                    const willDelete = newGroupUsers.find((newUser) => newUser.id === user.id);
                    if (!willDelete) {
                      willDeleteUsers.push(user);
                    }
                  }),
                );

                justControlIt.then(() => {
                  const justDoIt = Promise.all(
                    newUsers.map(async (newUser) => dispatch(addGroupToUser(newUser.id, groupName.id))),
                    willDeleteUsers.map(async (deleteUser) => dispatch(deleteGroupOfUser(deleteUser.id, groupName.id))),
                  );

                  justDoIt.then(async () => {
                    dispatch(fetchGroupsByCompanyId(company.id));
                    dispatch(fetchUsersByCompanyId(company.id));

                    if (newUsers.filter((u) => u.id === userValues.id).length > 0
                      || willDeleteUsers.filter((u) => u.id === userValues.id).length > 0) {
                      const newGroup = await axios
                        .post(
                          `${process.env.REACT_APP_API_URL}users/find_groups`,
                          {
                            user_id: userValues.id
                          },
                          {
                            headers: {
                              'x-access-token': userValues.token,
                              'Content-Type': 'application/json'
                            }
                          }
                        )
                        .then(async (groupsResponse) => {
                          const { data: groupsData } = groupsResponse;
                          const {
                            code: groupsCode,
                            status: groupsStatus,
                            user_groups
                          } = groupsData;
                          if (
                            groupsCode === 200 &&
                            groupsStatus === 'success' &&
                            user_groups.length > 0
                          ) {
                            return [...user_groups];
                          }

                          return [];
                        })
                        .catch((error) => []);
                      changeValues({ groups: newGroup });
                    }

                    setIsEdit(false);
                    setGroupName(null);
                    setShowModal(false);
                  });
                });
              });
            } else {
              const groupObj = {
                group_name: values.name,
                color: getRandomColor(),
                company_id: company.id,
                role_id: values.role.role_id
              };

              dispatch(addGroup(groupObj)).then((res) => {
                const newGroupUsers = [...values.users];

                const newUsers = [];

                const justControlIt = Promise.all(
                  newGroupUsers.map((user) => {
                    newUsers.push(user);
                  }),
                );

                justControlIt.then(() => {
                  const justDoIt = Promise.all(
                    newUsers.map(async (newUser) => dispatch(addGroupToUser(newUser.id, Array.isArray(res) ? res[0].id : res.revision_set ? res.revision_set.id : res.id))),
                  );

                  justDoIt.then(async () => {
                    dispatch(fetchGroupsByCompanyId(company.id));
                    dispatch(fetchUsersByCompanyId(company.id));

                    if (newUsers.filter((u) => u.id === userValues.id).length > 0) {
                      const newGroup = await axios
                        .post(
                          `${process.env.REACT_APP_API_URL}users/find_groups`,
                          {
                            user_id: userValues.id
                          },
                          {
                            headers: {
                              'x-access-token': userValues.token,
                              'Content-Type': 'application/json'
                            }
                          }
                        )
                        .then(async (groupsResponse) => {
                          const { data: groupsData } = groupsResponse;
                          const {
                            code: groupsCode,
                            status: groupsStatus,
                            user_groups
                          } = groupsData;
                          if (
                            groupsCode === 200 &&
                            groupsStatus === 'success' &&
                            user_groups.length > 0
                          ) {
                            return [...user_groups];
                          }

                          return [];
                        })
                        .catch((error) => []);
                      changeValues({ groups: newGroup });
                    }

                    setIsEdit(false);
                    setGroupName(null);
                    setShowModal(false);
                  });
                });
              });
            }
          }}
        >
          {({
            values,
            handleChange,
            handleReset,
            setFieldValue,
            handleSubmit
          }) => (
            <Form>
              <DialogContent dividers>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    classes={{ root: classes.dialogGrid }}
                  >
                    <p style={{ textTransform: 'uppercase' }}>
                      <FormattedMessage id="GROUP_NAME" />
                    </p>
                    <TextField
                      id="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({
                        id: 'ENTER_GROUP_NAME'
                      })}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    classes={{ root: classes.dialogGrid }}
                  >
                    <p style={{ textTransform: 'uppercase' }}>
                      <FormattedMessage id="GROUP_ROLE" />
                    </p>
                    <Autocomplete
                      id="role"
                      name="role"
                      size="small"
                      options={roles}
                      value={values.role}
                      getOptionSelected={(option, value) =>
                        option.role_id === value.role_id
                      }
                      getOptionLabel={(option) => option.role_name}
                      onChange={(event, value) => {
                        setFieldValue('role', value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={intl.formatMessage({
                            id: 'ENTER_GROUP_ROLE'
                          })}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                    <ErrorMessage
                      name="role"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
                    <p style={{ textTransform: 'uppercase' }}>
                      <FormattedMessage id="USERS" />
                    </p>
                    <Autocomplete
                      id="users"
                      name="users"
                      size="small"
                      multiple
                      options={users}
                      value={values.users}
                      getOptionSelected={(option, value) => option.id === value.id}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        setFieldValue('users', value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={intl.formatMessage({
                            id: 'SELECT_USERS'
                          })}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                    <ErrorMessage
                      name="users"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <div className={classes.dialogTitle}>
                  <Button
                    onClick={() => {
                      dispatch(fetchGroupsByCompanyId(company.id));
                      dispatch(fetchUsersByCompanyId(company.id));
                      
                      setIsEdit(false);
                      setGroupName(null);
                      setShowModal(false);
                    }}
                    variant="contained"
                    className={classes.dialogButton}
                  >
                    <FormattedMessage id="CANCEL" />
                  </Button>
                  <div>
                    <Button
                      onClick={handleSubmit}
                      color="primary"
                      variant="contained"
                      className={classes.dialogButton}
                    >
                      {loading.addGroup && (
                        <CircularProgress size="sm" color="inherit" />
                      )}
                      <FormattedMessage id="APPLY" />
                    </Button>
                  </div>
                </div>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      <AlertDialog
        alertText={<FormattedMessage id="NOT_FOUND_ROLE" />}
        open={alertModal}
        setOpen={setAlertModal}
      />
    </>
  );
};
