import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ItemContainer from 'components/Common/ItemContainer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from '@material-ui/core';
import uuid from 'uuid/dist/v4';
import { AiFillPlusCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import AlertDialog from 'components/Common/AlertDialog';
import CompanyForm from 'components/Company/CompanyForm';
import { useIntl, FormattedMessage } from 'react-intl';
import Set from './Set';

import {
  fetchSiteSets,
  siteSetsSelector,
  projectsSelector,
  fetchProjects,
  addSiteSetDetail,
  deleteSiteSetDetail,
  addSiteSet,
  deleteSiteSet,
  revisionSetsSelector,
  addRevisionSet,
  addRevisionSetDetail,
  deleteRevisionSet,
  deleteRevisionSetDetail,
  fetchRevisionSets,
  statusSetsSelector,
  addStatusSetDetail,
  deleteStatusSetDetail,
  deleteStatusSet,
  addStatusSet,
  fetchStatusSets,
  addMetadataSet,
  metadataSetsSelector,
  fetchMetadataSets,
  addMetadataSetDetail,
  deleteMetadataSetDetail,
  deleteMetadataSet,
  fetchRoleSetsByProject,
  fetchCompanyRoleSetsByProject,
  addRoleSet,
  updateRoleSet,
  deleteRoleSet,
  roleSetsSelector,
  addRoleSetCompany,
  deleteRoleSetCompany,
  fetchCompanies,
  fetchCompanyByProject,
  companiesSelector,
  addProjectToCompany,
  addCompany
} from '../../slices';

import { Checkbox } from '../Form';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    border: '1px solid rgba(0,0,0,0.2)',
    background: '#FFF',
    marginTop: '15px;',
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: 'normal'
  },
  dialogGrid: {
    margin: '0px',
    padding: '0px',
    '& > p': {
      fontSize: '12px',
      margin: '3px',
      marginTop: '0px',
      padding: '0px',
      fontWeight: 'bold',
      color: '#555'
    }
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  dialogButton: {
    margin: '5px 15px'
  },
  title: {
    display: 'flex'
  },
  autocomplete: {
    fontSize: '16px',
    color: 'rgba(250,100,0,1) !important'
  },
  title_span: {
    fontSize: '16px'
  },
  indicator: {
    background: 'rgba(250,100,0,1) !important',
    height: '6px'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}
const TabHeader = styled.div`
  width: 100%;
  margin: 0px;
  background: #ffffff;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.12);
`;

const TabBody = styled.div`
  width: 100%;
  height: 85%;
  display: flex;
`;

const TabStyled = styled(Tab)`
  font-size: 12px !important;
  line-height: 14px !important;
  color: rgba(0, 0, 0, 0.5) !important;
`;

const PanelContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 21px;
  color: #595469;
  margin-right: 12px;
`;

const PlusButton = styled(IconButton)`
  color: rgba(13, 164, 247, 1) !important;
`;

const ButtonLabel = styled.span`
  font-size: 16px;
  cursor: pointer;
`;

const TableHeadStyled = styled(TableCell)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #8a849b !important;
`;

const TableCellStyled = styled(TableCell)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #000000 !important;
  display: ${(props) => (props.id ? 'flex !important' : null)};
  justify-content: ${(props) => (props.id ? 'center !important' : null)};
  align-items: ${(props) => (props.id ? 'center !important' : null)};
  text-transform: uppercase;
`;

const TableContainerStyled = styled(TableContainer)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
`;

export default () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [alertModal, setAlertModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [nameOfSet, handleSetName] = useState('');
  const [error, setError] = useState('');
  const [roleName, handleRoleName] = useState({ role_name: '', role_id: 0 });

  const [addFunc, setAddFunc] = useState('site_set');
  const [selectedCompanies, setCompanies] = useState([]);

  const dispatch = useDispatch();
  const { siteSets } = useSelector(siteSetsSelector);
  const {
    selectedProject,
    loading: { getProjects },
    projects
  } = useSelector(projectsSelector);
  const { revisionSets } = useSelector(revisionSetsSelector);
  const { metadataSets } = useSelector(metadataSetsSelector);
  const { statusSets, statusRoles } = useSelector(statusSetsSelector);
  const { companies, loading: companyLoading } = useSelector(companiesSelector);
  const { rolesSets, rolesSetsCompany, rolesSetsCompanyRoles } = useSelector(
    roleSetsSelector
  );

  const intl = useIntl();

  useEffect(() => {
    if (!projects.length && getProjects) {
      dispatch(fetchProjects());
    }
    if (!companies && !companyLoading.getCompanies) {
      dispatch(fetchCompanies());
    }
    if (selectedProject) {
      dispatch(fetchSiteSets(selectedProject.id));
      dispatch(fetchRevisionSets(selectedProject.id));
      dispatch(fetchStatusSets(selectedProject.id));
      dispatch(fetchMetadataSets(selectedProject.id));
      dispatch(fetchRoleSetsByProject(selectedProject.id));
      dispatch(fetchCompanyByProject(selectedProject.id));
      dispatch(fetchCompanyRoleSetsByProject(selectedProject.id));
    }
  }, [selectedProject, companies]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSave = () => {
    if (nameOfSet.trim() === '') {
      if (addFunc === 'roles_set') {
        setError(intl.formatMessage({ id: 'REQUIRED_ROLE_NAME' }));
      } else {
        setError(intl.formatMessage({ id: 'REQUIRED_SET_NAME' }));
      }
    } else {
      if (addFunc === 'site_set') {
        dispatch(
          addSiteSet({ set_name: nameOfSet, project_id: selectedProject.id })
        );
      } else if (addFunc === 'revision_set') {
        dispatch(
          addRevisionSet({
            revision_name: nameOfSet,
            project_id: selectedProject.id
          })
        );
      } else if (addFunc === 'status_set') {
        dispatch(
          addStatusSet({
            statu_name: nameOfSet,
            project_id: selectedProject.id
          })
        );
      } else if (addFunc === 'metadata_set') {
        dispatch(
          addMetadataSet({
            metadata_name: nameOfSet,
            project_id: selectedProject.id,
            multi_select: 0
          })
        );
      } else if (addFunc === 'roles_set') {
        dispatch(
          addRoleSet({ role_name: nameOfSet, project_id: selectedProject.id })
        );
      }
      setShowModal(false);
      handleSetName('');
      setError('');
    }
  };

  const handleEditSave = () => {
    const newRoleObj = {
      role_name: roleName.role_name,
      role_id: roleName.role_id
    };

    dispatch(updateRoleSet(newRoleObj));
    setShowEditModal(false);
    handleRoleName({ role_name: '', role_id: 0 });
  };

  const handleAddSet = () => {
    if (!selectedProject) {
      setAlertModal(true);
    } else {
      setShowModal(true);
    }
  };

  const handleNewCompany = () => {
    if (!selectedProject) {
      setAlertModal(true);
    } else {
      setShowCompanyModal(true);
    }
  };

  const handleAddCompany = (values) => {
    const logo = '';
    const { company_projects } = values;
    delete values.company_projects;

    dispatch(addCompany({ ...values, logo })).then((res) => {
      if (res) {
        for (const project of company_projects) {
          dispatch(addProjectToCompany(res.id, project.id));
        }
      }
    });
    setShowCompanyModal(false);
  };

  const alertText = intl.formatMessage({ id: 'PROJECT_SELECTION_ALERT' });

  return (
    <>
      <AlertDialog
        alertText={alertText}
        open={alertModal}
        setOpen={setAlertModal}
      />
      <ItemContainer>
        <div className={classes.root}>
          <TabHeader>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="setting tabs "
              classes={{ indicator: classes.indicator }}
            >
              <TabStyled
                label={
                  <FormattedMessage
                    style={{ textTransform: 'uppercase' }}
                    id="SITE_SETS"
                  />
                }
                onClick={() => setAddFunc('site_set')}
              />
              <TabStyled
                label={
                  <FormattedMessage
                    style={{ textTransform: 'uppercase' }}
                    id="STATUS_SETS"
                  />
                }
                onClick={() => setAddFunc('status_set')}
              />
              <TabStyled
                label={
                  <FormattedMessage
                    style={{ textTransform: 'uppercase' }}
                    id="REVISION_SETS"
                  />
                }
                onClick={() => setAddFunc('revision_set')}
              />
              <TabStyled
                label={
                  <FormattedMessage
                    style={{ textTransform: 'uppercase' }}
                    id="COMPANIES_AND_ROLES"
                  />
                }
                onClick={() => setAddFunc('roles_set')}
              />
              <TabStyled
                label={
                  <FormattedMessage
                    style={{ textTransform: 'uppercase' }}
                    id="METADATA_SETS"
                  />
                }
                onClick={() => setAddFunc('metadata_set')}
              />
            </Tabs>
          </TabHeader>
          <TabBody>
            <TabPanel value={value} index={0}>
              <div style={{ padding: '0px', marginTop: '0px', width: '100%' }}>
                <NewItemButton
                  label={intl.formatMessage({ id: 'CREATE_NEW_SET' })}
                  handleAddSet={handleAddSet}
                />
                <PanelContent>
                  {siteSets.map((set) => (
                    <Set
                      key={set.id}
                      local
                      typeOfSet="local"
                      id={set.id}
                      title={set.set_name}
                      node_list={set.site_set_details}
                      set={set}
                      dispatch={dispatch}
                      addSetDetail={addSiteSetDetail}
                      deleteSetDetail={deleteSiteSetDetail}
                      deleteSet={deleteSiteSet}
                    />
                  ))}
                </PanelContent>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div>
                <NewItemButton
                  label={intl.formatMessage({ id: 'CREATE_NEW_SET' })}
                  handleAddSet={handleAddSet}
                />
                <PanelContent>
                  {statusSets.map((set) => (
                    <Set
                      key={uuid()}
                      local={false}
                      status
                      typeOfSet="status"
                      id={set.id}
                      title={set.statu_name}
                      node_list={set.status_set_details}
                      dispatch={dispatch}
                      addSetDetail={addStatusSetDetail}
                      deleteSetDetail={deleteStatusSetDetail}
                      deleteSet={deleteStatusSet}
                      roles={statusRoles.filter(
                        (role) => role.set_id === set.id
                      )}
                    />
                  ))}
                </PanelContent>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div>
                <NewItemButton
                  label={intl.formatMessage({ id: 'CREATE_NEW_SET' })}
                  handleAddSet={handleAddSet}
                />
                <PanelContent>
                  {revisionSets.map((set) => (
                    <Set
                      key={uuid()}
                      local={false}
                      status={false}
                      typeOfSet="revision"
                      id={set.id}
                      title={set.revision_name}
                      node_list={set.revision_set_details}
                      dispatch={dispatch}
                      addSetDetail={addRevisionSetDetail}
                      deleteSetDetail={deleteRevisionSetDetail}
                      deleteSet={deleteRevisionSet}
                    />
                  ))}
                </PanelContent>
              </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <NewItemButton
                    label={intl.formatMessage({ id: 'CREATE_NEW_ROLE' })}
                    handleAddSet={handleAddSet}
                  />
                  <NewItemButton
                    label={intl.formatMessage({ id: 'CREATE_NEW_COMPANY' })}
                    handleAddSet={handleNewCompany}
                  />
                </div>
                <PanelContent>
                  <TableContainerStyled component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableHeadStyled align="left" width="300px">
                            <FormattedMessage id="COMPANIES" />
                          </TableHeadStyled>
                          <TableHeadStyled align="left" width="300px">
                            <FormattedMessage id="COMPANY_SHORT_NAME" />
                          </TableHeadStyled>
                          {rolesSets &&
                            rolesSets.length > 0 &&
                            rolesSets.map((roleSet) => {
                              if (!roleSet) return null;
                              return (
                                <TableHeadStyled
                                  key={roleSet.id}
                                  align="center"
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {roleSet.role_name}
                                    <IconButton
                                      aria-controls="fade-menu"
                                      aria-haspopup="true"
                                      style={{ padding: '0px !important' }}
                                      onClick={() => {
                                        handleRoleName({
                                          role_name: roleSet.role_name,
                                          role_id: roleSet.id
                                        });
                                        setShowEditModal(true);
                                      }}
                                    >
                                      <FaPencilAlt size={12} />
                                    </IconButton>
                                    <IconButton
                                      aria-controls="fade-menu"
                                      aria-haspopup="true"
                                      style={{ padding: '0px !important' }}
                                      onClick={() => {
                                        dispatch(deleteRoleSet(roleSet.id));
                                      }}
                                    >
                                      <FaTrash size={12} />
                                    </IconButton>
                                  </div>
                                </TableHeadStyled>
                              );
                            })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rolesSetsCompany.map((company) => (
                          <TableRow key={company.id}>
                            <TableCellStyled align="left" width="300px">
                              {company.company.company_name}
                            </TableCellStyled>
                            <TableCellStyled align="left" width="300px">
                              {company.company.short_name}
                            </TableCellStyled>
                            {rolesSets &&
                              rolesSets.length > 0 &&
                              rolesSets.map((roleSet) => {
                                if (!roleSet) return null;
                                return (
                                  <TableCellStyled align="center" width="100px">
                                    <label>
                                      <Checkbox
                                        checkedColor="rgba(60,60,60,1)"
                                        color="rgba(180,180,180,1)"
                                        checked={
                                          rolesSetsCompanyRoles.filter(
                                            (role) =>
                                              role.company_id ===
                                                company.company_id &&
                                              role.role_id === roleSet.id
                                          ).length > 0
                                        }
                                        onChange={() => {
                                          if (
                                            rolesSetsCompanyRoles.filter(
                                              (role) =>
                                                role.company_id ===
                                                  company.company_id &&
                                                role.role_id === roleSet.id
                                            ).length > 0
                                          ) {
                                            dispatch(
                                              deleteRoleSetCompany({
                                                project_id: selectedProject.id,
                                                company_id: company.company_id,
                                                role_id: roleSet.id
                                              })
                                            );
                                          } else {
                                            dispatch(
                                              addRoleSetCompany({
                                                project_id: selectedProject.id,
                                                company_id: company.company_id,
                                                role_id: roleSet.id
                                              })
                                            );
                                          }
                                        }}
                                      />
                                    </label>
                                  </TableCellStyled>
                                );
                              })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainerStyled>
                </PanelContent>
              </div>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <div>
                <NewItemButton
                  label={intl.formatMessage({ id: 'CREATE_NEW_SET' })}
                  handleAddSet={handleAddSet}
                />
                <PanelContent>
                  {metadataSets.map((set) => (
                    <Set
                      key={uuid()}
                      local={false}
                      status={false}
                      metadata
                      typeOfSet="metadata"
                      id={set.id}
                      title={set.metadata_name}
                      node_list={set.metadata_details}
                      dispatch={dispatch}
                      addSetDetail={addMetadataSetDetail}
                      deleteSetDetail={deleteMetadataSetDetail}
                      deleteSet={deleteMetadataSet}
                      project_id={set.project_id}
                      multi_select={set.multi_select}
                    />
                  ))}
                </PanelContent>
              </div>
            </TabPanel>
          </TabBody>
        </div>
      </ItemContainer>
      <Dialog
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setError('');
        }}
        aria-labelledby="form-dialog-Setings"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="settings-dialog-title">
          <div className={classes.dialogTitle}>
            {addFunc === 'roles_set' && (
              <FormattedMessage id="CREATE_NEW_ROLE" />
            )}
            {addFunc !== 'roles_set' && (
              <FormattedMessage id="CREATE_NEW_SET" />
            )}
            <IconButton onClick={() => setShowModal(false)}>
              <AiOutlineCloseCircle />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            variant="outlined"
            placeholder={intl.formatMessage({ id: 'ENTER_SET_NAME' })}
            label={addFunc === 'roles_set' ? 'Rol Adı' : 'Set Adı'}
            fullWidth
            value={nameOfSet}
            onChange={(e) => handleSetName(e.target.value)}
          />
          {error ? (
            <div style={{ color: 'red', marginTop: '8px' }}>{error}</div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <div className={classes.dialogTitle}>
            <Button
              onClick={() => {
                setShowModal(false);
                setError('');
              }}
              variant="contained"
              className={classes.dialogButton}
            >
              <FormattedMessage id="CANCEL" />
            </Button>
            <div>
              <Button
                onClick={handleSave}
                color="primary"
                variant="contained"
                className={classes.dialogButton}
              >
                <FormattedMessage id="SAVE" />
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        aria-labelledby="form-dialog-Setings"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="settings-dialog-title">
          <div className={classes.dialogTitle}>
            <FormattedMessage id="UPDATE_ROLE" />
            <IconButton onClick={() => setShowEditModal(false)}>
              <AiOutlineCloseCircle />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            variant="outlined"
            placeholder={intl.formatMessage({ id: 'ENTER_ROLE_NAME' })}
            label={addFunc === 'roles_set' ? 'Rol Adı' : 'Set Adı'}
            fullWidth
            value={roleName.role_name}
            onChange={(e) =>
              handleRoleName({ ...roleName, role_name: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <div className={classes.dialogTitle}>
            <Button
              onClick={() => setShowEditModal(false)}
              variant="contained"
              className={classes.dialogButton}
            >
              <FormattedMessage id="CANCEL" />
            </Button>
            <div>
              <Button
                onClick={handleEditSave}
                color="primary"
                variant="contained"
                className={classes.dialogButton}
              >
                <FormattedMessage id="SAVE" />
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      <CompanyForm
        showModal={showCompanyModal}
        setShowModal={setShowCompanyModal}
        loading={companyLoading.addCompany}
        initialValues={{
          company_name: '',
          short_name: '',
          telephone: '',
          contact_email: '',
          web: '',
          address: ''
        }}
        submitFunc={handleAddCompany}
        dialogTitle={intl.formatMessage({ id: 'CREATE_NEW_COMPANY' })}
      />
    </>
  );
};

const NewItemButton = (props) => (
  <ButtonContainer>
    <PlusButton onClick={props.handleAddSet}>
      <AiFillPlusCircle size={40} />
    </PlusButton>
    <ButtonLabel onClick={props.handleAddSet}> {props.label} </ButtonLabel>
  </ButtonContainer>
);
