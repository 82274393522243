import React from 'react';
import styled from 'styled-components';

import { Paper, Avatar } from '@material-ui/core';

import { FormattedMessage } from 'react-intl';
import { dateFormat } from '../../helpers/DateFormatter';

const ProjectPaper = styled(Paper)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  border-bottom: 6px solid ${(props) => props.papercolor};
`;

const ProjeAvatar = styled(Avatar)`
  color: #595469 !important;
  background: #efefef !important;
  fontsize: 24px !important;
  lineheight: 28px !important;
  border: 3px solid ${(props) => props.papercolor} !important;
`;

const ProjeTitle = styled.span`
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #595469;
`;

const ProjeSubTitle = styled.span`
  margin-top: 2px;
  font-size: 16px;
  line-height: 18px;
  color: rgba(89, 84, 105, 0.7);
`;

const ProjeDate = styled.span`
  margin-top: 4px;
  font-size: 16px;
  line-height: 18px;
  color: #fa6400;
`;

function ProjectBox(props) {
  const { projectList, handleClick } = props;
  return projectList.map((project, index) => {
    const nameArray = project.project_name.split(' ');
    let Avatar = '';
    !!project.project_name &&
      nameArray.length &&
      nameArray.forEach((item) => (Avatar += item.charAt(0).toUpperCase()));
    return (
      <ProjectPaper
        papercolor={`#${project.color}` || 'f2f'}
        elevation={3}
        key={`projects-page-projet-key-${index}`}
        onClick={() => handleClick(project)}
      >
        {project.project_logo ? (
          <ProjeAvatar
            papercolor={`#${project.color}` || 'f2f'}
            src={`data:image/png;base64,${project.project_logo}`}
          />
        ) : (
          <ProjeAvatar papercolor={`#${project.color}` || 'f2f'}>
            {Avatar}
          </ProjeAvatar>
        )}

        <ProjeTitle>{project.project_name}</ProjeTitle>
        <ProjeSubTitle>
          <FormattedMessage id="COMPANIES" /> : {project.company_count}
        </ProjeSubTitle>
        <ProjeSubTitle>
          <FormattedMessage id="FORMS" />: {project.template_count}
        </ProjeSubTitle>
        <ProjeDate>
          <FormattedMessage id="CREATED_AT" />:{' '}
          {dateFormat(project.created_at, 'DD.MM.YYYY HH:mm')}{' '}
        </ProjeDate>
      </ProjectPaper>
    );
  });
}

export default ProjectBox;
