import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: auto;
  background-color: rgba(245, 245, 245, 1);
`;

export const ProgressContainer = styled.div`
  width: 100%;
  height: 30vh;
`;

export const TitleContainer = styled.div`
  background-color: rgba(255, 255, 255, 0);
  height: 48px;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 40px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Helvetica', Helvetica, Arial, serif;
  font-size: calc(12px + 0.5vw + 0.5vh);
  line-height: calc((12px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((12px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(89, 84, 105, 1);
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  height: 100%;
`;

export const HistoryListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  height:70vh;
  overflow: auto;

`;

export const History = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  width: 100%;
  cursor: pointer;
  transition: all 300ms;
  background-color: ${(props) => (props.now ? 'rgb(100, 104, 110, 0.2)' : null)};

  &:hover {
    background-color: rgb(100, 104, 110, 0.2);
  }
`;

export const HistoryChip = styled.div`
  background-image: ${(props) => (props.status === 1
    ? 'linear-gradient(to right, #75cabb, #50a5e2)'
    : props.status === 2
      ? 'linear-gradient(to right, #64686e, #343739)'
      : props.status === 3
        ? 'linear-gradient(to right, #78ce1b, #549c04)'
        : null)};
  margin: 6px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 6px;
  font-family: 'Helvetica-Bold', Helvetica, Arial, serif;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 14px;
`;


export const CancelButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to left, #e0e0e0, #eeeeee);
  color: #000000 !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;

  &:hover {
    opacity: 1;
  }
`;

export const ResetButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to left, #e0e0e0, #eeeeee);
  color: #000000 !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;
  margin-right: 40px !important;

  &:hover {
    opacity: 1;
  }
`;

export const SaveButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to left, #75cabb, #50a5e2);
  color: #ffffff !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;

  &:hover {
    opacity: 1;
  }
`;


export const EditButton = styled(Button)`
  align-items: center;
  justify-content: center;
  margin: 10px !important;
  margin-right: 0 !important;
  opacity: 0.75;
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;

export const ContentTitle = styled.span`
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
  align-self: center;
  color: #595469;
  margin: auto 5px;
`;

export const ContentLink = styled.span`
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
  align-self: center;
  color: #fa6400;
`;

export const StyledButton = styled(Button)`
  align-items: center;
  justify-content: center;
  margin: 5px  !important;
  opacity: 0.75;
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin: 0px;
`;
