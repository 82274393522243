import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Autocomplete, TreeItem } from '@material-ui/lab';
import {
  makeStyles,
  TextField,
  withStyles,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import styled from 'styled-components';

import {
  updateSiteSetDetail,
  updateMetadataSet,
  roleSetsSelector,
  addStatusSetDetailRole,
  fetchStatusSets,
  fetchCompanies,
  deleteStatusSetDetailRole,
  updateRevisionSetDetail,
  updateMetadataSetDetail
} from 'slices';
import { MdCheck, MdAdd, MdRemove } from 'react-icons/md';

import { compare_name, renderTree } from './helper';

const useStyletoTreeItem = makeStyles(() => ({
  treeItim: (props) => ({
    background: props.group ? '#D8D8D8 !important' : '#EAEAEA !important',
    textAlign: 'left',
    width: props.status ? '50%' : '100%'
  })
}));

const TreeLabelDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
`;

const TreeLabel = styled.span`
  font-size: calc(6px + 0.5vh + 0.5vw);
  line-height: calc(8px + 0.5vh + 0.5vw);
  display: flex;
  align-items: center;
  color: #696577;
  width: 100%;
`;

const SetItem = (props) => {
  const {
    nodes,
    group,
    index,
    parent,
    list,
    addDetail,
    deleteDetail,
    status,
    detail_roles,
    typeObject
  } = props;

  const { isLocal, isStatus, isMetadata, isRevision } = typeObject;

  const classItem = useStyletoTreeItem({
    group: isLocal && !nodes.parent_id,
    index,
    status: isStatus
  });

  const { rolesSets, rolesSetsCompany, rolesSetsCompanyRoles } = useSelector(
    roleSetsSelector
  );

  const intl = useIntl();

  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [newRow, updateRow] = useState(nodes.name);
  const [error, setError] = useState('');
  const [roles, setRoles] = useState([]);
  const [bir, setBir] = useState();
  const [updating, setUpdating] = useState(false);

  // let __roles = detail_roles && detail_roles.filter(role => role.detail_id === nodes.id)[0]

  useEffect(() => {
    if (detail_roles) {
      setBir(detail_roles.filter((role) => role.detail_id === nodes.id)[0]);
    }
  }, [nodes, detail_roles]);

  useEffect(() => {
    if (status && bir) {
      setRoles(bir.roles);
    }
  }, [nodes, status, bir]);

  const dispatch = useDispatch();

  const closeUpdateLine = () => {
    // setUpdating(false);
    updateRow('');
    setError('');
    setShowModal(false);
  };

  const handleUpdateLine = () => {
    if (newRow.trim() === '') {
      setError(intl.formatMessage({ id: 'REQUIRED_ROW_NAME' }));
    } else {
      setUpdating(true);
      switch (typeObject.currentType) {
        case 'local':
          dispatch(updateSiteSetDetail(nodes.id, newRow)).then(() =>
            closeUpdateLine()
          );
          break;
        case 'status':
          console.log('update-status-started');
          dispatch(updateSiteSetDetail(nodes.id, newRow)).then(() =>
            closeUpdateLine()
          );
          break;
        case 'metadata':
          dispatch(
            updateMetadataSetDetail(nodes.id, newRow, nodes.metadata_id)
          ).then(() => closeUpdateLine());
          break;
        case 'revision':
          dispatch(updateRevisionSetDetail(nodes.id, newRow)).then(() =>
            closeUpdateLine()
          );
          break;
      }
    }
  };

  const TreeItemLabel = (label, id, children, typeObject) => (
    <TreeLabelDiv>
      {showModal ? (
        <>
          <TextField
            margin="dense"
            value={newRow}
            onChange={(e) => updateRow(e.target.value)}
            multiline
          />
          {updating ? (
            <CircularProgress size="calc(1vh + 1vw)" />
          ) : (
            <MdCheck onClick={handleUpdateLine} />
          )}
        </>
      ) : (
        <>
          <TreeLabel
            onDoubleClick={() => {
              setShowModal(true);
            }}
          >
            {label}
          </TreeLabel>
          <div style={{ display: 'flex' }}>
            {typeObject.isLocal && (
              <MdAdd
                onClick={() => {
                  addDetail(id);
                }}
              />
            )}
            <MdRemove
              onClick={() => {
                setDeleteModal(true);
              }}
            />
          </div>
        </>
      )}
    </TreeLabelDiv>
  );

  const _list = [...list];
  if (parent !== nodes.parent_id) {
    return null;
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <StyledTreeItem
        nodeId={`${nodes.id}`}
        label={TreeItemLabel(nodes.name, nodes.id, nodes.children, typeObject)}
        classes={{ root: classItem.treeItim }}
      >
        {list.some((item) => item.parent_id == nodes.id)
          ? _list
              .sort(compare_name)
              .map((node) =>
                renderTree(
                  node,
                  null,
                  group,
                  nodes.id,
                  list,
                  addDetail,
                  deleteDetail,
                  status,
                  typeObject
                )
              )
          : null}
      </StyledTreeItem>
      {status && bir && (
        <Autocomplete
          multiple
          id="role_id"
          size="small"
          options={
            rolesSets.map((role) => ({ ...role, role_id: role.id })) || []
          }
          getOptionLabel={(option) => option.role_name}
          defaultValue={bir ? bir.roles : []}
          filterOptions={(options, a) =>
            options.filter(
              (item) => !roles.some((role) => role.role_id === item.role_id)
            )
          }
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={intl.formatMessage({ id: 'GROUPS_TO_USE_STATUS' })}
              style={{ width: '30vw' }}
              margin="dense"
            />
          )}
          onChange={(event, value, reason) => {
            if (reason === 'create-option' || reason === 'select-option') {
              const newRole = value.filter(
                (item) => !roles.some((role) => role.role_id === item.role_id)
              )[0];
              dispatch(
                addStatusSetDetailRole(
                  nodes.id,
                  newRole.role_id,
                  nodes.status_id
                )
              ).then((res) => {
                setRoles((prevroles) => [...prevroles, res]);
              });
            } else if (reason === 'remove-option') {
              const deletedRole = roles.filter(
                (item) => !value.some((role) => role.role_id === item.role_id)
              )[0];
              dispatch(
                deleteStatusSetDetailRole(
                  deletedRole.status_id,
                  deletedRole.role_id
                )
              );
            } else if (reason === 'clear') {
              for (const role of roles) {
                dispatch(
                  deleteStatusSetDetailRole(role.status_id, role.role_id)
                );
              }
            }
          }}
        />
      )}
      <Dialog
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        aria-labelledby="form-dialog-delete-set-item-detail"
      >
        <DialogContent>
          <FormattedMessage id="ARE_YOU_SURE_DELETE" />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteModal(false)}
            color="default"
            variant="contained"
          >
            <FormattedMessage id="CANCEL" />
          </Button>
          <Button
            onClick={() => deleteDetail(nodes.id)}
            color="secondary"
            variant="contained"
          >
            <FormattedMessage id="DELETE" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SetItem;

const Item = React.memo((props) => {
  return <TreeItem {...props} />;
});

const StyledTreeItem = withStyles((props) => ({
  root: {},
  iconContainer: {
    marginLeft: '8px',
    '& .close': {
      opacity: 0.3
    }
  },
  group: {
    marginLeft: 0,
    paddingLeft: 17,
    backgroundColor: '#EAEAEA'
  }
}))((props) => <Item {...props} />);
