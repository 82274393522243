import React, { useState } from 'react';

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

import { Paper, IconButton, Grid, TextField } from '@material-ui/core';
import {
  AiFillPlusCircle,
  AiFillCaretLeft,
  AiOutlineSetting
} from 'react-icons/ai';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';

import { GrUserSettings } from 'react-icons/gr';

import { MdClose } from 'react-icons/md';
import StepConfig from './StepConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row'
  },
  designPage: {
    margin: theme.spacing(2),
    height: '72vh',
    position: 'relative'
  },
  paper: {
    flex: 1,
    marginLeft: theme.spacing(1),
    height: '100%'
  },
  actions: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
    alignContent: 'center',
    height: '6vh',
    padding: theme.spacing(1)
  },
  header: {
    height: theme.spacing(7),
    padding: theme.spacing(3),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Helvetica, Helvetica, Arial, serif',
    fontSize: 'calc(12px + 0.5vh + 0.5vw)',
    color: 'rgba(0, 0, 0, 0.5)',
    background: 'rgba(245, 245, 245, 1)',
    lineHeight: 'calc(14px + 0.5vh + 0.5vw)',
    borderBottom: `${theme.spacing(0.6)}px solid rgba(250, 100, 0, 1)`,
    position: 'absolute',
    top: 0,
    borderRadius: '5px 5px 0px 0px',
    zIndex: 100
  },
  footer: {
    height: theme.spacing(7),
    padding: theme.spacing(3),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Helvetica, Helvetica, Arial, serif',
    fontSize: 'calc(12px + 0.5vh + 0.5vw)',
    color: 'rgba(0, 0, 0, 0.5)',
    background: 'rgba(245, 245, 245, 1)',
    lineHeight: 'calc(14px + 0.5vh + 0.5vw)',
    borderTop: `${theme.spacing(0.6)}px solid rgba(250, 100, 0, 1)`,
    position: 'absolute',
    bottom: 0,
    borderRadius: '0px 0px 5px 5px',
    zIndex: 100
  },

  content: {
    position: 'relative',
    paddingTop: theme.spacing(7),
    overflow: 'auto',
    height: '82%'
  },
  finishArrow: {
    fontSize: '2vw',
    color: 'rgba(250, 100, 105, 1.0)',
    position: 'absolute',
    left: '49%',
    bottom: theme.spacing(6),
    zIndex: '15',
    padding: '0',
    margin: '0'
  },

  historyleftside: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1
    )}px 0`
  },
  historyrightside: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(
      1
    )}px`
  },

  formname: {
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
    fontSize: 'calc(12px + 0.5vh + 0.5vw)',
    color: 'rgba(0,0,0, 0.8)',
    textAlign: 'center',
    padding: `${theme.spacing(2)}px 0px`,
    margin: `${theme.spacing(3)}px 0px`,
    alignItems: 'center',
    background: '#dfdfdf'
  },
  document: {
    fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
    fontSize: 'calc(6px + 0.5vh + 0.5vw)',
    color: 'rgba(0,0,0, 0.8)',
    textAlign: 'center',
    padding: `${theme.spacing(1)}px 0px`,
    margin: `${theme.spacing(2)}px 0px`,
    alignItems: 'center',
    background: 'rgba(245, 245, 245, 1)'
  },
  historyrightone: {
    fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
    fontSize: 14,
    color: 'rgba(250, 100, 105, 1.0)',
    textAlign: 'right',
    lineHeight: '17.0px',
    margin: theme.spacing(0.75)
  }
}));

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12px;
  background-color: rgba(245, 245, 245, 1);
`;

const PlusButton = styled(IconButton)`
  color: rgba(250, 100, 0, 1) !important;
  margin: 0px;
  padding: 0px !important;
  border: 0px !important;
  background-color: #fff !important;
  z-index: 15;
`;

const FinishLabel = styled.div`
  position: absolute;
  right: 42%;
  top: 25%;
  color: rgba(250, 100, 0, 1) !important;
  font-size: calc(6px + 0.5vh + 0.5vw);
`;

const StepArrow = styled(TiArrowSortedDown)`
  color: rgb(250, 100, 0);
  font-size: 2vw;
  margin: 0px;
  padding: 0px;
`;

const UpArrow = styled(TiArrowSortedUp)`
  color: rgb(250, 100, 0);
  font-size: 2vw;
  margin: 0px;
  padding: 0px;
  position: absolute;
  left: 4vw;
  top: 0px;
`;
const LeftArrow = styled(AiFillCaretLeft)`
  color: rgb(250, 100, 0);
  font-size: 2vw;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: -1vw;
  left: 0px;
`;

const Title = styled.div`
  font-size: calc(6px + 0.5vh + 0.5vw);
  line-height: calc(8px + 0.5vh + 0.5vw);
`;

const CenterLine = styled.div`
  height: 90%;
  border-left: 3px solid rgba(250, 100, 105, 1);
  border-top: 0px;
  border-bottom: 0px;
  position: absolute;
  right: 50%;
  padding: 0px;
  z-index: 0;
`;

const StepContainer = styled.div`
  font-size: 2vw;
  bottom: 3.3em;
`;

const Step = styled(Paper)`
  background-color: #fff !important;
  position: relative;
  z-index: 25;
  margin-bottom: 10px;
`;

const StepHeader = styled.div`
  height: 5vh;
  background: rgba(245, 245, 245, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vw 5px;
  z-index: 20;
`;

const StepName = styled.div`
  display: flex;
  align-items: center;
  font-size: calc(8px + 0.5vh + 0.5vw);
  border-radius: 10px 10px 0px 0px;
`;

const StepContent = styled.div`
  height: 12vh;
`;

const StepAction = styled.div`
  border-bottom: 3px solid #aaa;
  margin: 0px 5px;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(255, 100, 0, 1);
  text-align: right;
  height: 3vh;
`;

const StepMainContent = styled.div`
  margin: 0px 12px;
  padding: 5px;
  height: 9vh;
  border: 1px solid #aaa;
  border-radius: 0px 0px 15px 15px;
  border-top: 0px;
`;

const StepFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
`;

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  font-size: calc(6px + 0.5vh + 0.5vw);
  color: #000;
`;

const TimeInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(6px + 0.5vh + 0.5vw);
  color: #000;
`;

const MidBackArrowContainer = styled.div`
  position: relative;
  margin-bottom: 8vh;
`;

const MidBackArrow = styled.div`
  height: 15vh;
  width: 5vw;
  border: 1px solid rgba(250, 100, 0, 1);
  border-top: 0px;
  border-left: 0px;
`;

const FullBackArrowContainer = styled.div`
  position: relative;
  margin-bottom: 8vh;
`;

const FullBackArrow = styled.div`
  height: 30vh;
  width: 5vw;
  border: 1px solid rgba(250, 100, 0, 1);
  border-left: 0px;
`;

const TaskDesign = () => {
  const [steps, setSteps] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const classes = useStyles();

  const addNewStep = () => {
    setSteps([
      ...steps,
      {
        id: steps.length,
        name: 'Yeni Adım',
        time: 0
      }
    ]);
  };

  const removeStep = (id) => {
    setSteps(steps.filter((step) => step.id !== id));
  };

  const configStep = (id) => {
    setShowModal(true);
  };

  return (
    <Container>
      {/* Step Config Modal */}
      <StepConfig showModal={showModal} setShowModal={setShowModal} />
      {/* Design Page */}
      <Paper className={classes.designPage}>
        <div className={classes.header}>
          <Title>BAŞLANGIÇ</Title>
          <Title>TASARIM ONAY SÜRECİ</Title>
        </div>
        <div className={classes.content}>
          <CenterLine />
          <Grid container>
            <Grid item xs={4} />
            <Grid item xs={4} justify="center">
              <NewStepButton handleAddSetep={addNewStep} />
              {steps.map((step) => (
                <StepItem
                  step={step}
                  handleAddSetep={addNewStep}
                  handleRemoveStep={removeStep}
                  handeleConfigStep={configStep}
                />
              ))}
            </Grid>
            <Grid item xs={4} justify="flex-start">
              {steps.map((step, index) => (
                <BackArrow index={index} />
              ))}
            </Grid>
          </Grid>
        </div>
        <div className={classes.footer}>
          <Title>BİTİŞ</Title>
          <Title>TOPLAM SÜRE: </Title>
        </div>
      </Paper>

      {/* Action page */}
      <Paper className={classes.actions} />
    </Container>
  );
};

export default TaskDesign;

const NewStepButton = (props) => (
  <>
    <PlusButton onClick={props.handleAddSetep}>
      <AiFillPlusCircle size={40} style={{ zIndex: '100' }} />
    </PlusButton>
    <div style={{ padding: '0px', marginRight: '0.2vw' }}>
      <StepArrow />
    </div>
  </>
);

const StepItem = ({
  step,
  handleAddSetep,
  handleRemoveStep,
  handeleConfigStep
}) => {
  const { id, name, time } = step;

  return (
    <StepContainer>
      <Step elevation={3}>
        <StepHeader>
          <StepName>
            <GrUserSettings size={40} />
            <span>
              {name}- {id}
            </span>
          </StepName>
          <IconButton onClick={() => handeleConfigStep(id)}>
            <AiOutlineSetting size={40} />
          </IconButton>
        </StepHeader>
        <StepContent>
          <StepAction>For Action</StepAction>
          <StepMainContent />
        </StepContent>
        <StepFooter>
          <TimeContainer>
            <div>Süre: </div>
            <TimeInput>
              <TextField
                variant="outlined"
                style={{ width: '4vw', margin: 'auto 5px' }}
                type="number"
                margin="dense"
                value={time}
              />
              <span>Gün</span>
            </TimeInput>
          </TimeContainer>
          <IconButton onClick={() => handleRemoveStep(id)}>
            <MdClose color="rgba(250, 100, 0, 1)" size={40} />
          </IconButton>
        </StepFooter>
      </Step>
      <NewStepButton handleAddSetep={handleAddSetep} />
    </StepContainer>
  );
};

const BackArrow = ({ index }) => {
  if (!index) {
    return (
      <MidBackArrowContainer>
        <UpArrow />
        <MidBackArrow />
      </MidBackArrowContainer>
    );
  }
  return (
    <FullBackArrowContainer>
      <LeftArrow />
      <FullBackArrow />
    </FullBackArrowContainer>
  );
};
