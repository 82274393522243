import React from 'react';
import { IntlProvider } from 'react-intl';

import enMessages from './messages/en';
import trMessages from './messages/tr';

const allMessages = {
  en: enMessages,
  tr: trMessages,
};
const I18nProider = React.forwardRef(({ locale, children }, ref) => {
  const messages = allMessages[locale];
  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
});
export default I18nProider;
