import styled from 'styled-components';

import { MenuItem, Button } from '@material-ui/core';

export const Option = styled(MenuItem)`
  font-weight: bold;
  font-size: 10px;
  color: rgba(89, 84, 105, 1) !important;
  line-height: 12px;
`;

export const CancelButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to left, #e0e0e0, #eeeeee);
  color: #000000 !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;

  &:hover {
    opacity: 1;
  }
`;

export const ResetButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to left, #e0e0e0, #eeeeee);
  color: #000000 !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;
  margin-right: 40px !important;

  &:hover {
    opacity: 1;
  }
`;

export const SaveButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to right, #ff963e, #fa6400);
  color: #ffffff !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;

  &:hover {
    opacity: 1;
  }
`;

export const SendButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to right, #ff963e, #fa6400);
  color: #ffffff !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;

  &:hover {
    opacity: 1;
  }
`;

export const LoadingFade = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1000;
  top: 0;
  left: 0;
`;

export const IconSvg = styled.svg`
  margin-left: auto;
  transition: transform 300ms ease;
  transform: ${(props) => (props.setRotate ? 'rotate(90deg)' : null)};
`;

export const AccordionButton = styled.button`
  background-color: transparent;
  padding-top: 16px;
  align-self: flex-start;
  color: #444;
  cursor: pointer;
  border: none;
  outline: none;
  height: 100%;
  transition: all 300ms;
  &:hover {
    background-color: #ddd;
  }
`;

export const AccordionContainer = styled.div`
  color: #444;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  outline: none;
  background-color: ${(props) => (props.now ? '#ccc' : '#eee')};
  transition: all 300ms;
  &:hover {
    background-color: #ddd;
  }
`;
