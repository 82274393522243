import styled from 'styled-components';

import { TableContainer, TableCell, OutlinedInput } from '@material-ui/core';

export const TableContainerStyled = styled(TableContainer)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
`;

export const TableCellStyled = styled(TableCell)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: ${(props) =>
    props.datecolor ? 'rgba(250,100,0,1) !important' : '#8a849b!important'};
  display: ${(props) => (props.id ? 'flex !important' : null)};
  justify-content: ${(props) => (props.id ? 'center !important' : null)};
  align-items: ${(props) => (props.id ? 'center !important' : null)};
  text-transform: uppercase;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-around;
`;
export const FormName = styled.div`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: rgba(0, 0, 0, 0.5);
`;
export const Space = styled.div`
  height: 47px;
`;
export const FormType = styled.div`
  font-family: Helvetica !important;
  font-style: bold !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 18px !important;
  color: #000;
`;

export const Location = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Set = styled.span`
  font-family: Helvetica, Helvetica, Arial, serif;
  margin: 0px 10px;
  font-size: calc(2px + 0.5vw + 0.5vh);
  line-height: calc((2px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((2px + 0.5vw + 0.5vh) / (1.618 * 20));
  white-space: nowrap;
`;

export const SearchInput = styled(OutlinedInput)`
  width: 15vw;
  padding: 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 5px;
`;
