import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Formik, Form, ErrorMessage } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import { getRandomColor } from 'helpers';
import * as Yup from 'yup';
import {
  fetchGroupsByCompanyId,
  usersSelector,
  groupsSelector,
  updateUser,
  updateUserPassword,
  addGroupToUser,
  deleteGroupOfUser,
  fetchUsersByCompanyId
} from 'slices';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(36),
      height: theme.spacing(24)
    }
  },
  dialogGrid: {
    margin: '0px',
    padding: '0px',
    '& > p': {
      fontSize: '12px',
      margin: '3px',
      marginTop: '0px',
      padding: '0px',
      fontWeight: 'bold',
      color: '#555'
    }
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  dialogButton: {
    margin: '5px 15px'
  },
  table: {
    minWidth: 650
  },
  userAvatar: {
    background: getRandomColor()
  }
}));

const defaultUserName = {
  name: '',
  email: '',
  title: '',
  role: null,
  groups: [],
  status: 1,
  password: 'not_updated'
};

const UserForm = (props) => {
  const { showEditModal, setShowEditModal, user, company } = props;

  const [changed, setChanged] = useState(false);
  const [userGroupsList, setUserGroupsList] = useState([]);
  const [userName, setUserName] = useState(defaultUserName);

  const dispatch = useDispatch();

  const classes = useStyles();

  const intl = useIntl();

  const authorityList = [
    { id: '4', name: intl.formatMessage({ id: 'MANAGER' }) },
    { id: '3', name: intl.formatMessage({ id: 'FORM_MANAGER' }) },
    { id: '2', name: intl.formatMessage({ id: 'FORM_USER' }) },
    { id: '1', name: intl.formatMessage({ id: 'VIEWER' }) }
  ];
  const editInitialValues = {
    id: userName.id,
    name: userName.name,
    email: userName.email,
    password: 'Not_Updated1',
    passwordConfirmation: 'Not_Updated1',
    title: userName.title,
    role: {
      id: userName.role,
      name: authorityList.filter((el) => el.id === userName.role)[0]
        ? authorityList.filter((el) => el.id === userName.role)[0].name
        : ''
    },
    groups: userGroupsList,
    status: userName.status
  };

  const { userGroups, loading } = useSelector(usersSelector);
  const { groups } = useSelector(groupsSelector);

  useEffect(() => {
    if (user) {
      setUserName(user);
    }
  }, [user]);

  useEffect(() => {
    if (userGroupsList !== userGroups) {
      setUserGroupsList(userGroups);
    }
  }, [userGroups]);

  const editValidationSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: 'REQUIRED_NAME_AND_SURNAME' })
    ),
    email: Yup.string().required(
      intl.formatMessage({ id: 'REQUIRED_NAME_AND_MAIL' })
    ),
    role: Yup.string().required(intl.formatMessage({ id: 'REQUIRED_ROLE' })),
    password: Yup.string()
      .required(intl.formatMessage({ id: 'REQUIRED_PASSWORD' }))
      .min(8, intl.formatMessage({ id: 'PASSWORD_IS_TOO_SHORT' }))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        intl.formatMessage({ id: 'PASSWORD_MUST_CONTAIN' })
      )
  });

  const handleUpdateUser = (values) => {
    const userObj = {
      name: values.name,
      email: values.email,
      title: values.title,
      role: values.role.id,
      company_id: company.id,
      user_id: userName.id
    };
    const newGroups = [];
    const willDeleteGroups = [];

    const justDoIt = Promise.all(
      values.groups.map((group) => {
        const willAdd = !!userGroupsList.find(
          (v) =>
            (!group.group_id ? group.id : group.group_id) ===
            (!v.group_id ? v.id : v.group_id)
        );
        if (!willAdd) {
          newGroups.push(group);
        }
      }),
      userGroupsList.map((group) => {
        const willDelete = !!values.groups.find(
          (v) =>
            (!group.group_id ? group.id : group.group_id) ===
            (!v.group_id ? v.id : v.group_id)
        );
        if (!willDelete) {
          willDeleteGroups.push(group);
        }
      })
    );

    justDoIt.then(() => {
      dispatch(updateUser(userObj)).then(async () => {
        if (values.password !== '') {
          const newUserPassObj = {
            user_id: userName.id,
            password: values.passwordConfirmation
          };
          dispatch(updateUserPassword(newUserPassObj))
        }
        if (newGroups.length > 0) {
          for (const newGroup of newGroups) {
            dispatch(
              addGroupToUser(
                userName.id,
                !newGroup.group_id ? newGroup.id : newGroup.group_id
              )
            );
          }
        }
        if (willDeleteGroups.length > 0) {
          for (const willDeleteGroup of willDeleteGroups) {
            dispatch(
              deleteGroupOfUser(
                userName.id,
                !willDeleteGroup.group_id
                  ? willDeleteGroup.id
                  : willDeleteGroup.group_id
              )
            );
          }
        }

        dispatch(fetchUsersByCompanyId(company.id));
        setUserName(defaultUserName);
        setUserGroupsList([]);
        setShowEditModal(false);
      });
    });
  };

  return (
    <Dialog
      open={showEditModal}
      onClose={() => {
        // dispatch(fetchUsersByCompanyId(company.id));
        if (!editInitialValues.id) {
          setUserName(defaultUserName);
          setUserGroupsList([]);
        }

        setShowEditModal(false);
      }}
      aria-labelledby="form-dialog-title"
      fullWidth
      scroll="body"
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        <div className={classes.dialogTitle}>
          <FormattedMessage id="UPDATE_USER" />
          <IconButton
            onClick={() => {
              // dispatch(fetchUsersByCompanyId(company.id));
              if (!editInitialValues.id) {
                setUserName(defaultUserName);
                setUserGroupsList([]);
              }
              setShowEditModal(false);
            }}
          >
            <AiOutlineCloseCircle />
          </IconButton>
        </div>
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={editInitialValues}
        validationSchema={editValidationSchema}
        onSubmit={(values, { setErrors }) => {
          if (values.password !== values.passwordConfirmation) {
            setErrors({
              passwordConfirmation: intl.formatMessage({
                id: 'REQUIRED_PASSWORD_HAVE_TO_MATCH'
              })
            });
          } else {
            if (values.password === 'Not_Updated1') {
              delete values.password;
              delete values.passwordConfirmation;
            }
            handleUpdateUser(values);
          }
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => {
          if (values.groups !== userGroupsList && !changed) {
            setFieldValue('groups', userGroupsList);
          }
          return (
            <Form>
              <DialogContent dividers>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    classes={{ root: classes.dialogGrid }}
                  >
                    <p className={classes.uppercase}>
                      <FormattedMessage id="NAME_SURNAME" />{' '}
                    </p>
                    <TextField
                      id="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({
                        id: 'ENTER_NAME_SURNAME'
                      })}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    classes={{ root: classes.dialogGrid }}
                  >
                    <p className={classes.uppercase}>
                      <FormattedMessage id="MAIL" />{' '}
                    </p>
                    <TextField
                      id="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({ id: 'ENTER_MAIL' })}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    classes={{ root: classes.dialogGrid }}
                  >
                    <p className={classes.uppercase}>
                      <FormattedMessage id="PASSWORD" />{' '}
                    </p>
                    <TextField
                      id="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({ id: 'ENTER_PASSWORD' })}
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="password"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    classes={{ root: classes.dialogGrid }}
                  >
                    <p className={classes.uppercase}>
                      <FormattedMessage id="CONFIRM_PASSWORD" />
                    </p>
                    <TextField
                      id="passwordConfirmation"
                      name="passwordConfirmation"
                      value={values.passwordConfirmation}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({
                        id: 'ENTER_PASSWORD_TO_CONFIRM_PASSWORD'
                      })}
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="password"
                    />
                    <ErrorMessage
                      name="passwordConfirmation"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    classes={{ root: classes.dialogGrid }}
                  >
                    <p className={classes.uppercase}>
                      <FormattedMessage id="TITLE" />
                    </p>
                    <TextField
                      id="title"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({
                        id: 'ENTER_TITLE_IN_CONPANY'
                      })}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    classes={{ root: classes.dialogGrid }}
                  >
                    <p className={classes.uppercase}>
                      <FormattedMessage id="ROLE" />
                    </p>
                    <Autocomplete
                      id="role"
                      size="small"
                      options={authorityList}
                      value={values.role}
                      getOptionSelected={(option, value) => {
                        if (!value) return false;
                        return option.id === value.id;
                      }}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        if (value) {
                          setFieldValue('role', value);
                        } else {
                          setFieldValue('role', null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={intl.formatMessage({
                            id: 'SELECT_ROLE'
                          })}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                    <ErrorMessage
                      name="role"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
                    <p className={classes.uppercase}>
                      <FormattedMessage id="GROUPS" />
                    </p>
                    <Autocomplete
                      multiple
                      id="groups"
                      size="small"
                      value={values.groups}
                      options={groups.filter((v) => {
                        const isIncluded = values.groups.find((group) => {
                          if (!group.group_id) {
                            return (
                              group.id === (!v.group_id ? v.id : v.group_id)
                            );
                          }
                          return (
                            group.group_id === (!v.group_id ? v.id : v.group_id)
                          );
                        });
                        return !isIncluded;
                      })}
                      getOptionSelected={(option, value) => {
                        if (!value) return false;
                        return option.id === value.group_id;
                      }}
                      getOptionLabel={(option) => {
                        if (option[0] && typeof option[0] === 'object') {
                          return option[0].group_name
                            ? option[0].group_name
                            : '';
                        }
                        return option.group_name ? option.group_name : '';
                      }}
                      onChange={(event, value) => {
                        const newValue = [];

                        setChanged(true);

                        const justDoIt = Promise.all(
                          value.map(async (v) => {
                            if (!v.group_id) {
                              newValue.push(v);
                            } else {
                              const newGroup = groups.filter(
                                (group) => group.id === v.group_id
                              );
                              newValue.push(newGroup[0]);
                            }
                          })
                        );

                        justDoIt.then(() => {
                          setFieldValue('groups', newValue);
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={intl.formatMessage({
                            id: 'SELECT_GROUPS_FOR_USER'
                          })}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                    <ErrorMessage
                      name="groups"
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <div className={classes.dialogTitle}>
                  <Button
                    onClick={() => {
                      dispatch(fetchGroupsByCompanyId(company.id));
                      setUserName(defaultUserName);
                      setUserGroupsList([]);
                      setShowEditModal(false);
                    }}
                    variant="contained"
                    className={classes.dialogButton}
                  >
                    <FormattedMessage id="CANCEL" />
                  </Button>
                  <div>
                    <Button
                      onClick={handleSubmit}
                      color="primary"
                      variant="contained"
                      className={classes.dialogButton}
                    >
                      {loading.addUser && (
                        <CircularProgress size="12" color="inherit" />
                      )}
                      <FormattedMessage id="APPLY" />
                    </Button>
                  </div>
                </div>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default UserForm;
