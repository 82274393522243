import React, { forwardRef } from 'react';

import ProjectList from 'components/Projects';
import { ContentContainer } from 'styles/common';

export default forwardRef((props, ref) => (
  <ContentContainer>
    <ProjectList {...props} />
  </ContentContainer>
));
