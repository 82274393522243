import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FiChevronDown } from 'react-icons/fi';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  redText: {
    color: 'red',
  },
}));

const InputContainer = styled.div`
flex: 1;
display: flex;
flex-direction: row;
justify-content: flex-start;
`;


export default ({
  label, list, selectedValue, valueIndicator, onSelect, labelIndicator,
}) => {
  const classes = useStyles();

  const [value, setValue] = React.useState(null);


  if ((selectedValue && !value) || (selectedValue && selectedValue[valueIndicator] && selectedValue[valueIndicator] !== value)) {
    setValue(selectedValue[valueIndicator]);
  }

  if (!list || !Array.isArray(list) || (Array.isArray(list) && list.length === 0)) return null;
  return (
    <InputContainer>
      <Autocomplete
        options={list}

        defaultValue={[list[0][valueIndicator]]}
        getOptionLabel={(option) => option[labelIndicator] || option[valueIndicator] || (typeof option !== 'object' && option) || ''}
        id="blur-on-select"
        blurOnSelect
        disableClearable
        className={classes.textField}
        value={value}
        inputlabelprops={{ shrink: false }}
        onChange={(e, v) => {
          if (v) {
            setValue(v);
            onSelect(v);
          }
        }}
        popupIcon={<FiChevronDown />}
        renderInput={(params) => (
          <TextField
            {...params}
            inputlabelprops={{ ...params.inputlabelprops, shrink: false }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            label={value === null ? label : ''}
            margin="dense"
            variant="standard"
            fullWidth
          />
        )}
      />
    </InputContainer>
  );
};
