import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Grid, IconButton, Paper } from '@material-ui/core';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';

import DateFormatter from 'helpers/DateFormatter';

import {
  InputTextElement,
  InputNumericElement,
  InputDateElement,
  InputSelectOneElement,
  InputSelectManyElement,
  InputImageElement,
  InputSignatureElement
} from '../InputElements';

const SubTitle = styled.p`
  color: rgba(89, 84, 105, 1) !important;
  text-align: center;
  font-weight: bold;
  font-size: calc(4px + 0.5vh + 0.5vw);
  line-height: calc((4px + 0.5vh + 0.5vw) * 1.618);
  letter-spacing: calc((4px + 0.5vh + 0.5vw) / (1.618 * 20));
`;

const StyledGrid = styled(Grid)`
  margin: 2.5vh 1vw !important;
`;

const RenderGroupFields = ({
  form,
  inputs,
  values,
  formName,
  controlName,
  disabled,
  subForms,
  roles,
  auth
}) => {
  const tempStateValues =
    form &&
    form.values &&
    form.values.formValues &&
    form.values.formValues[formName.split('.')[1]] &&
    form.values.formValues[formName.split('.')[1]][controlName]
      ? form.values.formValues[formName.split('.')[1]][controlName]
      : values;

  const [stateValues, setStateValues] = useState(tempStateValues);

  useEffect(() => {}, [stateValues]);

  if (!inputs) return null;

  return (
    <Paper elevation={3}>
      {stateValues[Object.keys(stateValues)[0]].map((el, stateIndex) => (
        <Paper
          elevation={3}
          variant="outlined"
          key={`grop fields key --${stateIndex}`}
        >
          {inputs.map((input, index) => {
            const { type, name } = input;

            const extraInputs = [];

            const value = stateValues[input.name];
            if (value && Array.isArray(value))
              value.map((v) => extraInputs.push(v));
            return (
              <div
                key={`key-- ${name}`}
                style={{
                  flexDirection: 'column'
                }}
              >
                <div
                  style={{
                    flexDirection: 'row',
                    margin: '15px 0px'
                  }}
                >
                  <div
                    style={{
                      flex: 1
                    }}
                  >
                    {type === 'inputText' && (
                      <StyledGrid
                        item
                        xs={12}
                        key={`edit form info field key - ${index}`}
                      >
                        <InputTextElement
                          disabled={disabled}
                          input={input}
                          value={value}
                          extraInputs={extraInputs}
                          formName={formName}
                          controlName={controlName}
                          stateIndex={stateIndex}
                        />
                      </StyledGrid>
                    )}
                    {type === 'inputNumeric' && (
                      <StyledGrid
                        item
                        xs={12}
                        key={`edit form info field key - ${index}`}
                      >
                        <InputNumericElement
                          disabled={disabled}
                          input={input}
                          value={value}
                          extraInputs={extraInputs}
                          formName={formName}
                          controlName={controlName}
                          stateIndex={stateIndex}
                        />
                      </StyledGrid>
                    )}
                    {type === 'inputDate' && (
                      <StyledGrid
                        item
                        xs={12}
                        key={`edit form info field key - ${index}`}
                      >
                        <InputDateElement
                          disabled={disabled}
                          input={input}
                          value={value}
                          extraInputs={extraInputs}
                          formName={formName}
                          controlName={controlName}
                          stateIndex={stateIndex}
                        />
                      </StyledGrid>
                    )}
                    {type === 'inputSelectOne' && (
                      <StyledGrid
                        item
                        xs={12}
                        key={`edit form info field key - ${index}`}
                      >
                        <InputSelectOneElement
                          disabled={disabled}
                          input={input}
                          value={value}
                          extraInputs={extraInputs}
                          formName={formName}
                          controlName={controlName}
                          stateIndex={stateIndex}
                        />
                      </StyledGrid>
                    )}
                    {type === 'inputSelectMany' && (
                      <StyledGrid
                        item
                        xs={12}
                        key={`edit form info field key - ${index}`}
                      >
                        <InputSelectManyElement
                          disabled={disabled}
                          input={input}
                          value={value}
                          extraInputs={extraInputs}
                          formName={formName}
                          controlName={controlName}
                          stateIndex={stateIndex}
                        />
                      </StyledGrid>
                    )}
                    {type === 'inputImage' && (
                      <StyledGrid
                        item
                        xs={12}
                        key={`edit form info field key - ${index}`}
                      >
                        <InputImageElement
                          disabled={disabled}
                          input={input}
                          value={value}
                          extraInputs={extraInputs}
                          formName={formName}
                          controlName={controlName}
                          stateIndex={stateIndex}
                        />
                      </StyledGrid>
                    )}
                    {type === 'inputSignature' && (
                      <StyledGrid
                        item
                        xs={12}
                        key={`edit form info field key - ${index}`}
                      >
                        <InputSignatureElement
                          disabled={disabled}
                          input={input}
                          value={value}
                          extraInputs={extraInputs}
                          formName={formName}
                          controlName={controlName}
                          stateIndex={stateIndex}
                        />
                      </StyledGrid>
                    )}
                    {type === 'inputTitle' && (
                      <StyledGrid
                        item
                        xs={12}
                        key={`edit form info field key - ${index}`}
                      >
                        <SubTitle>{input.name}</SubTitle>
                      </StyledGrid>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          {!disabled && stateIndex !== 0 ? (
            <IconButton
              style={{
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onClick={() => {
                const oldValues = {
                  ...form.values.formValues
                };

                Object.keys(
                  oldValues[formName.split('.')[1]][controlName]
                ).forEach((key) => {
                  let fieldName = `${formName}`;
                  fieldName = fieldName.concat(`.${controlName}`);
                  fieldName = fieldName.concat(`.${key}`);

                  oldValues[formName.split('.')[1]][controlName][key].splice(
                    stateIndex,
                    1
                  );
                  form.setFieldValue(
                    fieldName,
                    oldValues[formName.split('.')[1]][controlName][key]
                  );
                });

                setStateValues({
                  ...oldValues[formName.split('.')[1]][controlName]
                });
              }}
            >
              <AiOutlineMinusCircle
                name="minus"
                color="rgba(255,100,0,1)"
                size={24}
              />
            </IconButton>
          ) : null}
        </Paper>
      ))}
      {!disabled && (
        <IconButton
          style={{
            paddingVertical: '1.5vh',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={() => {
            const oldValues = {
              ...form.values.formValues
            };

            let newControls = {};
            Object.keys(oldValues[formName.split('.')[1]][controlName]).forEach(
              (key) => {
                let fieldName = `${formName}`;
                fieldName = fieldName.concat(`.${controlName}`);
                fieldName = fieldName.concat(`.${key}`);
                const subForm = subForms.find(
                  (el) => el.name === formName.split('.')[1]
                );
                const control = subForm.controls.find(
                  (el) => el.name === controlName
                );
                const child = control.children.find((el) => el.name === key);

                let value = child.defaultValue ? child.defaultValue : '';
                if (child.type === 'inputSelectMany') {
                  value = [child.defaultValue];
                }
                if (child.type === 'inputDate') {
                  let newDate;
                  if (child.defaultValue) {
                    newDate = child.defaultValue;
                  } else {
                    switch (child.kind) {
                      case 'fullDate':
                        newDate = DateFormatter(new Date(), 'date');
                        break;
                      case 'fullDateAndTime':
                        newDate = DateFormatter(new Date());
                        break;
                      case 'monthAndYear':
                        newDate = DateFormatter(new Date(), 'monthAndYear');
                        break;
                      case 'year':
                        newDate = DateFormatter(new Date(), 'year');
                        break;
                      case 'time':
                        newDate = DateFormatter(new Date(), 'time');
                        break;
                      default:
                        newDate = DateFormatter(new Date(), 'date');
                        break;
                    }
                    newDate = null;
                  }
                  newDate = null;
                }
                if (child.type === 'inputImage') {
                  value = [];
                }
                if (child.type === 'inputSignature') {
                  const isAbleToComplete =
                    roles.filter((el) => el.role_id === subForm.role_id)
                      .length > 0;

                  if (isAbleToComplete) {
                    value = {
                      signedBy: auth.name
                    };
                  } else {
                    value = {
                      signedBy: ''
                    };
                  }
                }

                let newValues = [
                  ...oldValues[formName.split('.')[1]][controlName][key],
                  value
                ];
                form.setFieldValue(fieldName, newValues);
                newControls = { ...newControls, [key]: newValues };
              }
            );

            setStateValues({
              ...newControls
            });
          }}
        >
          <AiOutlinePlusCircle color="rgba(255,100,0,1)" size={24} />
        </IconButton>
      )}
    </Paper>
  );
};

const GroupElements = ({
  form,
  subForms,
  input,
  values,
  formName,
  disabled,
  roles,
  auth
}) => {
  const { children, name } = input;

  if (!children) {
    return null;
  }
  return (
    <RenderGroupFields
      form={form}
      subForms={subForms}
      disabled={disabled}
      inputs={children}
      values={values}
      formName={formName}
      controlName={name}
      roles={roles}
      auth={auth}
    />
  );
};

export const RenderFields = ({
  inputs,
  values,
  formName,
  disabled,
  form,
  subForms,
  roles,
  auth
}) => {
  if (!inputs) return null;
  return inputs.map((input, index) => {
    let { type, name } = input;
    const value = values[input.name];
    name = 'formValues.' + name;

    if (type === 'inputText') {
      return (
        <StyledGrid item xs={12} key={`edit form info field key - ${index}`}>
          <InputTextElement
            disabled={disabled}
            key={name}
            input={input}
            value={value}
            formName={formName}
          />
        </StyledGrid>
      );
    }
    if (type === 'inputNumeric') {
      return (
        <StyledGrid item xs={12} key={`edit form info field key - ${index}`}>
          <InputNumericElement
            disabled={disabled}
            key={name}
            input={input}
            value={value}
            formName={formName}
          />
        </StyledGrid>
      );
    }
    if (type === 'inputDate') {
      return (
        <StyledGrid item xs={12} key={`edit form info field key - ${index}`}>
          <InputDateElement
            disabled={disabled}
            key={name}
            input={input}
            value={value}
            formName={formName}
          />
        </StyledGrid>
      );
    }
    if (type === 'inputSelectOne') {
      return (
        <StyledGrid item xs={12} key={`edit form info field key - ${index}`}>
          <InputSelectOneElement
            disabled={disabled}
            key={name}
            input={input}
            value={value}
            formName={formName}
          />
        </StyledGrid>
      );
    }
    if (type === 'inputSelectMany') {
      return (
        <StyledGrid item xs={12} key={`edit form info field key - ${index}`}>
          <InputSelectManyElement
            disabled={disabled}
            key={name}
            input={input}
            value={value}
            formName={formName}
          />
        </StyledGrid>
      );
    }
    if (type === 'metadata') {
      return null;
    }
    if (type === 'group') {
      return (
        <StyledGrid item xs={12} key={`edit form info field key - ${index}`}>
          <GroupElements
            form={form}
            subForms={subForms}
            roles={roles}
            auth={auth}
            disabled={disabled}
            key={name}
            input={input}
            values={value}
            formName={formName}
          />
        </StyledGrid>
      );
    }
    if (type === 'inputSignature') {
      return (
        <StyledGrid item xs={12} key={`edit form info field key - ${index}`}>
          <InputSignatureElement
            key={`edit form info field key - ${index}`}
            disabled={disabled}
            key={name}
            input={input}
            value={value}
            formName={formName}
          />
        </StyledGrid>
      );
    }
    if (type === 'inputImage') {
      return (
        <StyledGrid item xs={12} key={`edit form info field key - ${index}`}>
          <InputImageElement
            key={`edit form info field key - ${index}`}
            disabled={disabled}
            key={name}
            input={input}
            value={value}
            formName={formName}
          />
        </StyledGrid>
      );
    }
    if (type === 'inputTitle') {
      return (
        <StyledGrid item xs={12} key={`edit form info field key - ${index}`}>
          <SubTitle key={`edit form info field key - ${index}`}>
            {input.name}
          </SubTitle>
        </StyledGrid>
      );
    }
    return (
      <StyledGrid item xs={12} key={`edit form info field key - ${index}`}>
        <div>UNKNOWN INPUT{type}</div>
      </StyledGrid>
    );
  });
};
