import React from 'react';
import styled from 'styled-components';

import Account from 'components/Account';

const AccountContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: auto;
  background-color: rgba(245, 245, 245, 1);
`;

export default (props) => (
  <AccountContainer>
    <Account {...props} />
  </AccountContainer>
);
