import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useIntl, FormattedMessage } from 'react-intl';
import { authentication } from '../../helpers/authentication';
import device from '../../helpers/device';

import CraneLogoHeader from '../Header/CraneLogoHeader';
import { Checkbox } from '../Form';
import { Footer } from '../Footer';

const ColorCircularProgress = withStyles({
  root: {
    color: '#f7981c',
  },
})(CircularProgress);

const LoginAdmin = styled.div`
height: 100vh;
width: 100%;
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
overflow: hidden;
margin: 0px;
background-color: rgba(255, 255, 255, 1);
color: rgba(138, 132, 155, 1);
text-align: center;
opacity: 1;
`;

const Container = styled.div`
flex: 1;
width: 100%;
padding: 1vh 1vw;
z-index: 10;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;
`;

const FormContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
`;

const RulesAndTermsContainer = styled.div`
background-color: rgba(255, 255, 255, 0);
height: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: 90vw;

@media ${device.tablet} {
  width: 75vw;
}

@media ${device.laptop} {
  width: 50vw;
}
`;

const ErrorContainer = styled.div`
width: 100%;
padding: 8px;
border-radius: 5px;
color: rgba(255, 255, 255, 1);
background-color: rgba(207, 0, 15, 0.75);
font-size: calc(0px + 1vw);
line-height: calc(1px + 1vw);
color: rgba(255, 255, 255, 1);
text-align: center;
`;

const TitleContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: auto;
color: rgba(138, 132, 155, 1);
font-family: Lato;
font-style: normal;
font-weight: bold;
text-align: center;
padding: 4vh 0 4vh 0;
font-size: calc(8px + 0.5vw + 0.5vh);
line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
`;

const InputContainer = styled.div`
  background-color: rgba(255, 255, 255, 0);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90vw;

  @media ${device.tablet} {
    width: 50vw;
  }

  @media ${device.laptop} {
    width: 25vw;
  }
`;

const LoginInput = styled(Field)`
width: 100%;
background-color: rgba(255,255,255, 1);
box-sizing: border-box;
border-radius: 4px;
border-style: solid;
border-width: ${(props) => (props.iserror ? '2px' : '1px')};
border-color: ${(props) => (props.iserror ? 'rgba(207, 0, 15, 1)' : 'rgba(138, 132, 155, 1)')};
margin: 1vh;
padding: 1.25vh 1.75vh 1.5vh 2vh;
font-family: 'Lato', Helvetica, Arial, serif;
font-weight: bold;
font-style: normal;
font-size: calc(4px + 0.5vw + 0.5vh);
line-height: calc((4px + 0.5vw + 0.5vh) * 1.618);
letter-spacing: calc((4px + 0.5vw + 0.5vh) / (1.618 * 20));
color: rgba(92, 93, 93, 1);
text-align: left;
cursor: pointer;
opacity: 0.75;
transition: all 300ms;

&:hover {
  opacity: 1;
  background-color: rgba(233, 237, 241, 1);
}
&:focus {
  opacity: 1;
  background-color: rgba(233, 237, 241, 1);
}
&:active {
  opacity: 1;
  background-color: rgba(233, 237, 241, 1);
}
`;

const LoginButton = styled.button`
cursor: pointer;
background-image: linear-gradient(180deg, #F7981C 2.04%, #F76B1C 100%);
box-sizing: border-box;
border-radius: 5px;
border-style: solid;
border-width: 1px;
border-color: rgba(223, 227, 233, 0.1);
width: 100%;
height: auto;
margin: 1vh;
padding: 1vh;
font-weight: bold;
font-style: normal;
font-size: calc(6px + 0.5vw + 0.5vh);
line-height: calc((6px + 0.5vw + 0.5vh) * 1.618);
letter-spacing: calc((6px + 0.5vw + 0.5vh) / (1.618 * 20));
color: rgba(255, 255, 255, 1);
text-align: center;
transition: all 300ms;

&:hover {
  background-image: linear-gradient(180deg, #F76B1C 2.04%, #d25e25 100%);
}
`;

const LoginButton2 = styled.button`
  cursor: pointer;
  background-image: linear-gradient(180deg, #F7981C 2.04%, #F76B1C 100%);
  box-sizing: border-box;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(223, 227, 233, 0.1);
  height: auto;
  margin: 1vh;
  padding: 1vh;
  font-weight: bold;
  font-style: normal;
  font-size: calc(6px + 0.5vw + 0.5vh);
  line-height: calc((6px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((6px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(255, 255, 255, 1);
  text-align: center;
  transition: all 300ms;
  width: 100%;

  @media ${device.tablet} {
    width: 50%;
  }

  &:hover {
    background-image: linear-gradient(180deg, #F76B1C 2.04%, #d25e25 100%);
  }
`;

const RulesAndTerms = styled.div`
  flex: 1;
  width: 100%;
  margin-bottom: 2vh;
  padding: 1vh 1vw;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(150, 150, 150, 1);
  font-family: 'Helvetica-Bold', Helvetica, Arial, serif;
  color: rgba(89, 84, 105, 1);
  font-style: normal;
  font-size: calc(16px + 0.5vw + 0.5vh);
  line-height: calc((16px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((16px + 0.5vw + 0.5vh) / (1.618 * 20));
  text-align: center;
`;

const AcceptTerms = styled.div`
  margin 1vh 0;
  text-align: left;
  opacity: 0.75;
  cursor: pointer;
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;

const AcceptSpan = styled.span`
  font-family: 'Lato', Helvetica, Arial, serif;
  font-weight: bold;
  font-style: normal;
  font-size: calc(4px + 0.5vw + 0.5vh);
  line-height: calc((4px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((4px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(92, 93, 93, 1);
  cursor: pointer;
  margin-left: 0.5vw;
`;

export default (props) => {
  const [termsChecked, setTermsChecked] = useState(false);
  const [error, setError] = useState();
  const [registerStep, setRegisterStep] = useState(-1);
  const [user, setUser] = useState(null);

  const intl = useIntl();

  if (authentication.userValue) {
    const { from } = props.location.state || { from: { pathname: '/' } };
    props.history.push(from);
  }

  /*

        TOKEN CHECKER

                        */

  /* setUser({ fullName: 'Elif Koru' }); */

  setTimeout(() => {
    if (registerStep === -1) {
      setUser({ fullName: 'Elif Koru', email: 'elifkoru@gmail.com' });
      setRegisterStep(0);
    }
  }, 2500);

  const initialValues = {
    username: '',
    password: '',
    passwordConfirmation: '',
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required(intl.formatMessage({ id: 'REQIRED_USERNAME' })),
    password: Yup.string()
      .required(intl.formatMessage({ id: 'REQIRED_PASSWORD' })),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password')], intl.formatMessage({ id: 'REQUIRED_PASSWORD_HAVE_TO_MATCH' })),
  });

  const handleSubmit = ({ username, password }, { setSubmitting }) => {
    setError();
    setRegisterStep(1);
    setUser({ ...user, username, password });
    setSubmitting(false);
  };

  const termsChanged = (event) => {
    setTermsChecked(event.target.checked);
  };

  const termsAccept = () => {
    if (termsChecked) {
      const { from } = props.location.state || { from: { pathname: '/' } };
      props.history.push(from);
    } else {
      setError(intl.formatMessage({ id: 'DO_NOT_ENTER_BEFORE_ACCEPT' }));
    }
  };

  return (
    <LoginAdmin>
      <Container>
        <CraneLogoHeader to="/login" which={registerStep !== 1 ? 'login' : null} />
        {!user && (
        <ColorCircularProgress size={30} thickness={5} />
        )}
        {(user && registerStep === 0) && (
          <FormContainer>
            <TitleContainer><FormattedMessage id="WELCOME" />  {user.fullName}<br />
              <FormattedMessage id="COMPLATE_REGISTERION" />

            </TitleContainer>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <InputContainer>
                    {error && <ErrorContainer>{error}</ErrorContainer>}
                    <LoginInput
                      placeholder={intl.formatMessage({ id: 'ENTER_USERNAME' })}
                      type="text"
                      name="username"
                      iserror={errors.username && touched.username ? 1 : 0}
                    />
                    <ErrorMessage
                      name="username"
                      component="div"
                      render={(msg) => <ErrorContainer>{msg}</ErrorContainer>}
                    />
                    <LoginInput
                      placeholder={intl.formatMessage({ id: 'ENTER_PASSWORD' })}
                      type="password"
                      name="password"
                      iserror={errors.password && touched.password ? 1 : 0}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      render={(msg) => <ErrorContainer>{msg}</ErrorContainer>}
                    />
                    <LoginInput
                      placeholder={intl.formatMessage({ id: 'ENTER_PASSWORD_TO_CONFIRM_PASSWORD' })}
                      type="password"
                      name="passwordConfirmation"
                      iserror={errors.passwordConfirmation && touched.passwordConfirmation ? 1 : 0}
                    />
                    <ErrorMessage
                      name="passwordConfirmation"
                      component="div"
                      render={(msg) => <ErrorContainer>{msg}</ErrorContainer>}
                    />
                    <LoginButton
                      registerStep={registerStep}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <FormattedMessage id="COMPLATE_REGISTRATION" />
                    </LoginButton>
                  </InputContainer>
                </Form>
              )}
            </Formik>
          </FormContainer>
        )}
        {registerStep === 1 && user && (
          <RulesAndTermsContainer>
            <TitleContainer> <FormattedMessage id="WELCOME" />  {user.email},</TitleContainer>
            <RulesAndTerms><FormattedMessage id="TERMS_AND_CONDITIONS" /> </RulesAndTerms>
              {error && <ErrorContainer>{error}</ErrorContainer>}
            <AcceptTerms>
              <label>
                <Checkbox checked={termsChecked} onChange={termsChanged} />
                <AcceptSpan>
                  <FormattedMessage id="ACCEPT_TO_TERMS_AND_CONDITIONS" />
                </AcceptSpan>
              </label>
            </AcceptTerms>
            <LoginButton2 onClick={termsAccept}>
              <FormattedMessage id="LOGIN" />
            </LoginButton2>
          </RulesAndTermsContainer>
        )}
      </Container>
      <Footer />
    </LoginAdmin>
  );
};
