import React from 'react';
import styled from 'styled-components';

import Dashboard from 'components/Dashboard';

const DashboardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: auto;
  background-color: rgba(245, 245, 245, 1);
`;

export default (props) => (
  <DashboardContainer>
    <Dashboard {...props} />
  </DashboardContainer>
);
