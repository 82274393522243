const compare_id = (drc) => (a, b) =>{
  // a should come before b in the sorted order
  if (a.id < b.id) {
    return drc ? 1 : -1;
    // a should come after b in the sorted order
  } if (a.id > b.id) {
    return drc ? -1 : 1;
    // and and b are the same
  }
  return 0;
}

const compare_name = (drc) => (a, b) =>{
    // a should come before b in the sorted order
  if (a.name < b.name) {
    return -1;
    // a should come after b in the sorted order
  } if (a.name > b.name) {
    return 1;
    // and and b are the same
  }
  return 0;
}

// if order Direction is true set inc, if is false set dec 
export default function sortFnc(array, fnc, orderDrc) {
  return array.sort(fnc === 'name' ? compare_name(orderDrc) : compare_id(orderDrc));
}
