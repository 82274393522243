import { createSlice } from '@reduxjs/toolkit';
import {
  getMetadataSetsFunction,
  addMetadataSetFunction,
  updateMetadataSetFunction,
  deleteMetadataSetFunction,
  addMetadataSetDetailFunction,
  deleteMetadataSetDetailFunction,
  updateMetadataSetDetailFunction,
  updateMetadataSetTitleFunction,
  getMetadataSetsByTemplateFunction,
} from 'api/sets/metadataSets';

const initialState = {
  loading: {
    getMetadataSets: false,
    getMetadataSetsTemplate: false,
    addMetadataSet: false,
    updateMetadataSet: false,
    deleteMetadataSet: false,
    addMetadataSetDetail: false,
    deleteMetadataSetDetail: false,
    updatingMetadataSetDetail: false,
  },
  hasErrors: {
    getMetadataSets: false,
    getMetadataSetsTemplate: false,
    addMetadataSet: false,
    updateMetadataSet: false,
    deleteMetadataSet: false,
    addMetadataSetDetail: false,
    deleteMetadataSetDetail: false,
    updatingMetadataSetDetail: false,
  },
  metadataSets: [],
  metadataSetsTemplate: []
};

const metadataSetsSlice = createSlice({
  name: 'metadataSets',
  initialState,
  reducers: {
    asyncStart: (state, { payload }) => {
      state.loading[payload] = true;
    },
    asyncFailure: (state, { payload }) => {
      state.loading[payload] = false;
      state.hasErrors[payload] = true;
    },
    getMetadataSetsSuccess: (state, { payload }) => {
      state.metadataSets = payload;
      state.loading.getMetadataSets = false;
      state.hasErrors.getMetadataSets = false;
    },
    getMetadataSetsTemplateSuccess: (state, { payload }) => {
      state.metadataSetsTemplate = payload;
      state.loading.getMetadataSetsTemplate = false;
      state.hasErrors.getMetadataSetsTemplate = false;
    },
    addMetadataSetSuccess: (state, { payload }) => {
      state.metadataSets.unshift(payload);
      state.loading.addMetadataSet = false;
      state.hasErrors.addMetadataSet = false;
    },
    updateMetadataSetSuccess: (state, { payload }) => {
      state.metadataSets = state.metadataSets.map((metadataSet) => {
        if (metadataSet.id === payload.id)
          return { ...metadataSet, ...payload };
        return metadataSet;
      });
      state.loading.updateMetadataSet = false;
      state.hasErrors.updateMetadataSet = false;
    },
    deleteMetadataSetSuccess: (state, { payload }) => {
      state.metadataSets = state.metadataSets.filter(
        (metadataSet) => metadataSet.id !== payload
      );
      state.loading.deleteMetadataSet = false;
      state.hasErrors.deleteMetadataSet = false;
    },
    addMetadataSet_Detail_Success: (state, { payload }) => {
      state.metadataSets.forEach((metadataSet) => {
        if (metadataSet.id == payload.metadata_id) {
          metadataSet.metadata_details.push(payload);
        }
      });
      state.loading.addMetadataSetDetail = false;
      state.hasErrors.addMetadataSetDetail = false;
    },
    deleteMetadataSet_Detail_Success: (state, { payload }) => {
      state.metadataSets.forEach((metadataSet) => {
        if (
          metadataSet.metadata_details.some((detail) => detail.id == payload)
        ) {
          metadataSet.metadata_details = metadataSet.metadata_details.filter(
            (detail) => detail.id != payload
          );
        }
      });
      state.loading.deleteMetadataSetDetail = false;
      state.hasErrors.deleteMetadataSetDetail = false;
    },
    updateMetadataSet_Detail_Success: (state, { payload }) => {
      state.metadataSets.forEach((metadataSet) => {
        if (
          metadataSet.metadata_details.some(
            (detail) => detail.id == payload.detail_id
          )
        ) {
          metadataSet.metadata_details = metadataSet.metadata_details.map(
            (detail) => {
              if (detail.id === payload.detail_id)
                return { ...detail, name: payload.name };
              return detail;
            }
          );
        }
      });
      state.loading.updatingMetadataSetDetail = false;
      state.hasErrors.updatingMetadataSetDetail = false;
    },
    metadataSet_Title_Update_Success: (
      state,
      { payload: { set_id, name } }
    ) => {
      state.metadataSets.forEach((metadataSet) => {
        if (metadataSet.id === set_id) {
          metadataSet.metadata_name = name;
        }
      });
    }
  }
});

export const {
  asyncStart,
  asyncFailure,
  getMetadataSetsSuccess,
  getMetadataSetsTemplateSuccess,
  addMetadataSetSuccess,
  updateMetadataSetSuccess,
  deleteMetadataSetSuccess,
  addMetadataSet_Detail_Success,
  deleteMetadataSet_Detail_Success,
  updateMetadataSet_Detail_Success,
  metadataSet_Title_Update_Success
} = metadataSetsSlice.actions;

export default metadataSetsSlice.reducer;

export const metadataSetsSelector = (state) => state.metadataSets;

export const fetchMetadataSets = (proje_id) => async (dispatch) => {
  dispatch(asyncStart('getMetadataSets'));

  try {
    const _metadataSets = await getMetadataSetsFunction(proje_id);
    dispatch(getMetadataSetsSuccess(_metadataSets));
  } catch (error) {
    console.log('an error occurred while fetching metadataSets', error);
    dispatch(asyncFailure('getMetadataSets'));
  }
};

export const fetchMetadataSetsByTemplate = (template_id) => async (dispatch) => {
  dispatch(asyncStart('getMetadataSetsTemplate'));

  try {
    const _metadataSets = await getMetadataSetsByTemplateFunction(template_id);

    const metadatasList = {
    };
  
    for (const metadata of _metadataSets) {
      if (!metadatasList[metadata.metadata_name]) {
        metadatasList[metadata.metadata_name] = await [{
          ...metadata,
        }];
      } else {
        await metadatasList[metadata.metadata_name].push(metadata);
      }
    }
    // await NetworkUtils.setCache(cacheKey, metadatasList);
    return dispatch(getMetadataSetsTemplateSuccess(metadatasList));
  } catch (error) {
    console.log('an error occurred while fetching getMetadataSetsTemplate', error);
    dispatch(asyncFailure('getMetadataSetsTemplate'));
  }
};

export const addMetadataSet = (metadataSetObj) => async (dispatch) => {
  dispatch(asyncStart('addMetadataSet'));
  try {
    const newMetadataSet = await addMetadataSetFunction(metadataSetObj);
    dispatch(
      addMetadataSetSuccess({ ...newMetadataSet, metadata_details: [] })
    );
  } catch (error) {
    console.log('an error occurred while adding metadataSet', error);
    dispatch(asyncFailure('addMetadataSet'));
  }
};

export const updateMetadataSet = (metadataSetObj) => async (dispatch) => {
  dispatch(asyncStart('updateMetadataSet'));
  try {
    await updateMetadataSetFunction(metadataSetObj);
    dispatch(updateMetadataSetSuccess(metadataSetObj));
  } catch (error) {
    console.log('an error occurred while updating metadataSet', error);
    dispatch(asyncFailure('updateMetadataSet'));
  }
};

export const deleteMetadataSet = (metadataSet_id) => async (dispatch) => {
  dispatch(asyncStart('deleteMetadataSet'));
  try {
    await deleteMetadataSetFunction(metadataSet_id);
    dispatch(deleteMetadataSetSuccess(metadataSet_id));
  } catch (error) {
    console.log('an error occurred while deleting metadataSet', error);
    dispatch(asyncFailure('deleteMetadataSet'));
  }
};

export const addMetadataSetDetail = (metadata_set_detail_obj) => {
  metadata_set_detail_obj.metadata_id = metadata_set_detail_obj.set_id;
  delete metadata_set_detail_obj.set_id;
  return async (dispatch) => {
    dispatch(asyncStart('addMetadataSetDetail'));
    try {
      const new_detail_obj = await addMetadataSetDetailFunction(
        metadata_set_detail_obj
      );
      dispatch(addMetadataSet_Detail_Success(new_detail_obj));
    } catch (error) {
      console.log('an error occurred while adding metadataSet detail', error);
      dispatch(asyncFailure('addMetadataSetDetail'));
    }
  };
};

export const deleteMetadataSetDetail = (metadata_set_detail_id) => async (
  dispatch
) => {
  dispatch(asyncStart('deleteMetadataSetDetail'));
  try {
    await deleteMetadataSetDetailFunction(metadata_set_detail_id);
    dispatch(deleteMetadataSet_Detail_Success(metadata_set_detail_id));
  } catch (error) {
    console.log('an error occurred while deleting metadataSet detail', error);
    dispatch(asyncFailure('deleteMetadataSetDetail'));
  }
};

export const updateMetadataSetDetail = (detail_id, name, metadata_id) => async (
  dispatch
) => {
  // dispatch(asyncStart('updatingMetadataSetDetail'));
  try {
    await updateMetadataSetDetailFunction(detail_id, name, metadata_id);
    dispatch(updateMetadataSet_Detail_Success({ detail_id, name }));
  } catch (error) {
    console.log('an error occurred while update MetadataSet detail', error);
    dispatch(asyncFailure('updatingMetadataSetDetail'));
  }
  return true;
};

export const updateMetadataSetTitle = (set_id, name) => async (dispatch) => {
  try {
    await updateMetadataSetTitleFunction(set_id, name);
    dispatch(metadataSet_Title_Update_Success({ set_id, name }));
  } catch (error) {
    console.log('an error occurred while update MetadataSet title', error);
  }
};
