export const tableHeads = [
  { title: 'POST_NUMBER', props: {} },
  { title: 'SENDER', props: {} },
  { title: 'RECEIVER', props: {} },
  { title: 'RECEIVER_COMPANY', props: {} },
  { title: 'SUBJECT', props: {} }
];

export const tableBody = [
  { name: 'mail_no', props: {} },
  { name: 'from_name', props: {} },
  { name: 'name', props: {} },
  { name: 'companyName', props: {} },
  { name: 'title', props: {} }
];
