import APIHelper from '../../helpers/APIHelper';

export async function getMetadataSetsFunction(proje_id) {
  const response = await APIHelper().get(
    `metadatas/list?project_id=${proje_id}`
  );
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('GET METADATA_SETS RESPONSE =>', response.metadatas);
  return response.metadatas;
}

export async function getMetadataSetsByTemplateFunction(template_id) {
  const response = await APIHelper().post(
    `templates/get_metadata`, null, { template_id }
  );
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('GET METADATA_SETS RESPONSE =>', response.templatemetadatas);
  return response.templatemetadatas;
}

export async function searchMetadataSetsFunction(search) {
  const response = await APIHelper().post('metadatas/search', null, { search });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('GET METADATA_SET SEARCH RESPONSE =>', response.metadataSets);
  return response.metadataSets;
}

export async function findByIdMetadataSetssFunction(metadata_set_id) {
  const response = await APIHelper().post('metadatas/find', null, {
    metadata_set_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('FIND METADATA_SET BY ID RESPONSE =>', response.metadataSets);
  return response.metadataSets;
}

export async function addMetadataSetFunction(metadataSetObj) {
  const response = await APIHelper().post('metadatas/insert', null, {
    ...metadataSetObj
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('ADD METADATA_SET RESPONSE =>', response.metadatas);
  return response.metadatas;
}

export async function updateMetadataSetFunction(metadataSetObj) {
  const response = await APIHelper().post('metadatas/update', null, {
    ...metadataSetObj
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('UPDATE METADATA_SET RESPONSE =>', response);
  return response.status;
}

export async function deleteMetadataSetFunction(metadata_id) {
  const response = await APIHelper().post('metadatas/delete', null, {
    metadata_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('DELETE METADATA_SET RESPONSE =>', response);
  return response.status;
}

export async function addMetadataSetDetailFunction(metadataSetDetailObj) {
  const response = await APIHelper().post('metadatas/detail/insert', null, {
    ...metadataSetDetailObj
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('ADD METADATA_SET_DETAIL RESPONSE =>', response.metadata_details);
  return response.metadata_details;
}

export async function deleteMetadataSetDetailFunction(metadata_detail_id) {
  const response = await APIHelper().post('metadatas/detail/delete', null, {
    metadata_detail_id
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('DELETE METADATA_SET_DETAIL RESPONSE =>', response);
  return response.status;
}

export async function updateMetadataSetDetailFunction(
  metadata_detail_id,
  name,
  metadata_id
) {
  const response = await APIHelper().post('metadatas/detail/update', null, {
    metadata_id,
    metadata_detail_id,
    name
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('UPDATE METADATA_SET_DETAIL RESPONSE =>', response);
  return response;
}

export async function updateMetadataSetTitleFunction(
  metadata_id,
  metadata_name
) {
  const response = await APIHelper().post('metadatas/update', null, {
    metadata_id,
    metadata_name
  });
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
  console.log('UPDATE Metadata_SET_DETAIL RESPONSE =>', response);
  return response;
}
