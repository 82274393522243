import React from 'react';

import Setting from 'components/Settings';
import { ContentContainer } from 'styles/common';

export default (props) => (
  <ContentContainer>
    <Setting {...props} />
  </ContentContainer>
);
