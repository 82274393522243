import React from 'react';
import { makeStyles, withStyles, Tabs, Tab, Box } from '@material-ui/core';

export const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    bottom: '3px',
    height: '5px',
    zIndex: 200,
    backgroundColor: 'rgba(250, 100, 0, 1)',
    '& > div': {
      width: '100%',
    },
  },
})((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <div />,
    }}
  />
));

export const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    backgroundColor: '#fff',
    color: '#595469',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    '&:focus': {
      opacity: 1,
    }
  },
  first: {
    marginLeft: 'auto',
  }
}))((props) => <Tab disableRipple {...props} className={props.first ? props.classes.first : null} />);

export const TabPanel = (props) => {
  const {
    children, value, index, boxWidth, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box width="100%" p={3}>
          {children}
        </Box>
      )}
    </div>
  );
};