import React from 'react';
import styled from 'styled-components';

import { FaInstagram, FaTwitter, FaFacebookF } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

const FooterContainer = styled.div`
  padding: 1% 2%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const LeftSide = styled.div`
  flex: 1;
  text-align: left;
  color: rgba(92, 93, 93, 1);
  font-size: calc(4px + 0.5vw + 0.5vh);
  line-height: calc((4px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((4px + 0.5vw + 0.5vh) / (1.618 * 20));
`;
const CenterSide = styled.div`
  flex: 1;
  text-align: center;
  color: rgba(92, 93, 93, 1);
  font-size: calc(4px + 0.5vw + 0.5vh);
  line-height: calc((4px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((4px + 0.5vw + 0.5vh) / (1.618 * 20));
`;
const RightSide = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const FaInstagramStyled = styled(FaInstagram)`
  margin-right: 5%;
  color: rgba(92, 93, 93, 1);
  font-size: calc(4px + 0.5vw + 0.5vh);
  line-height: calc((4px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((4px + 0.5vw + 0.5vh) / (1.618 * 20));
  cursor: pointer;
  transition: all 300ms;

  &:hover {
    color: rgba(0, 0, 0, 1);
    font-size: calc(12px + 0.5vw + 0.5vh);
    line-height: calc((12px + 0.5vw + 0.5vh) * 1.618);
    letter-spacing: calc((12px + 0.5vw + 0.5vh) / (1.618 * 20));
  }
`;

const FaTwitterStyled = styled(FaTwitter)`
  margin-right: 5%;
  color: rgba(92, 93, 93, 1);
  font-size: calc(4px + 0.5vw + 0.5vh);
  line-height: calc((4px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((4px + 0.5vw + 0.5vh) / (1.618 * 20));
  cursor: pointer;
  transition: all 300ms;

  &:hover {
    color: rgba(0, 0, 0, 1);
    font-size: calc(12px + 0.5vw + 0.5vh);
    line-height: calc((12px + 0.5vw + 0.5vh) * 1.618);
    letter-spacing: calc((12px + 0.5vw + 0.5vh) / (1.618 * 20));
  }
`;

const FaFacebookFStyled = styled(FaFacebookF)`
  color: rgba(92, 93, 93, 1);
  font-size: calc(4px + 0.5vw + 0.5vh);
  line-height: calc((4px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((4px + 0.5vw + 0.5vh) / (1.618 * 20));
  cursor: pointer;
  transition: all 300ms;

  &:hover {
    color: rgba(0, 0, 0, 1);
    font-size: calc(12px + 0.5vw + 0.5vh);
    line-height: calc((12px + 0.5vw + 0.5vh) * 1.618);
    letter-spacing: calc((12px + 0.5vw + 0.5vh) / (1.618 * 20));
  }
`;

const Footer = () => (
  <FooterContainer>
    <LeftSide>Copyright © 2020</LeftSide>
    <CenterSide><FormattedMessage id="TERMS_AND_CONDITIONS" /></CenterSide>
    <RightSide>
      <FaInstagramStyled />
      <FaTwitterStyled />
      <FaFacebookFStyled />
    </RightSide>
  </FooterContainer>
);

export { Footer };
