import React, { useState } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { FormattedMessage } from 'react-intl';
import { dateFormat } from '../../helpers/DateFormatter';

import TaskTemplateActions from './TaskTemplateActions';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4)
  }
}));

const TableRowStyled = styled(TableRow)`
  cursor: pointer;
  transition: all 300ms;

  &:hover {
    background-color: ${(props) => `${props.hovercolor} !important` || null};
  }
`;
const FormName = styled.div`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: rgba(0, 0, 0, 0.5);
`;
const FormType = styled.div`
  font-family: Helvetica !important;
  font-style: bold !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 18px !important;
`;
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  const returnValue =
    order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  return returnValue;
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  const returnArray = stabilizedThis.map((el) => el[0]);
  return returnArray;
}

function EnhancedTableHead(props) {
  const { headCells, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="none" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding="default"
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ zIndex: 1 }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{
                justifyContent: 'center !important',
                textTransform: 'uppercase'
              }}
            >
              <FormattedMessage id={headCell.label} />
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
export default ({
  headCells,
  list,
  handleClick,
  templateList,
  taskList,
  formList,
  onlyName,
  taskTemplateList
}) => {
  const classes = useStyles();

  const nameVariable = templateList
    ? 'template_name'
    : taskList
    ? 'surec_name'
    : 'name';
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(nameVariable);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <EnhancedTableHead
          headCells={headCells}
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        {taskList && (
          <TableBody>
            {stableSort(list, getComparator(order, orderBy)).map(
              (row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                let backgroundColor;
                let sideColor;

                switch (row.step_no) {
                  case 2:
                    backgroundColor = '#ff5f52';
                    sideColor = '#c62828';
                    break;
                  case 1:
                    backgroundColor = '#fdd0b2';
                    sideColor = '#fa6300';
                    break;
                  case 3:
                    backgroundColor = '#d1f2b4';
                    sideColor = '#68d407';
                }

                if (moment(Date.now()).isAfter(moment(row.due_date))) {
                  backgroundColor = '#ff5f52';
                  sideColor = '#c62828';
                }

                return (
                  <TableRowStyled
                    hover
                    onClick={(event) =>
                      row.step_no !== 3 ? handleClick(event, row) : null
                    }
                    tabIndex={-1}
                    key={`${index}-${row.id}`}
                    hovercolor={backgroundColor}
                  >
                    <TableCell
                      style={{
                        width: '5px',
                        backgroundColor: sideColor
                      }}
                      padding="none"
                    />
                    <TableCell component="th" id={labelId} scope="row">
                      {row.id}
                    </TableCell>

                    <TableCell align="center">{row.form_name}</TableCell>
                    <TableCell align="center">{row.form_no}</TableCell>
                    <TableCell align="center">{row.statusName}</TableCell>
                    <TableCell align="center">
                      {row.worker_name.toUpperCase()}
                    </TableCell>
                    <TableCell align="center">
                      {dateFormat(row.due_date, 'DD.MM.YYYY')}
                    </TableCell>
                  </TableRowStyled>
                );
              }
            )}
          </TableBody>
        )}
        {taskTemplateList && (
          <TableBody>
            {stableSort(list, getComparator(order, orderBy)).map(
              (row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                let backgroundColor;
                let sideColor;
                let statusName;
                switch (row.status) {
                  case 1:
                    backgroundColor = '#BADFFD';
                    sideColor = '#1c97fa';
                    statusName = 'DRAFT';
                    break;
                  case 2:
                    backgroundColor = '#fdd0b2';
                    sideColor = '#fa6300';
                    statusName = 'INACTIVE';
                    break;
                  default:
                    backgroundColor = '#d1f2b4';
                    sideColor = '#68d407';
                    statusName = 'PUBLISHED';
                }

                return (
                  <TableRowStyled
                    hover
                    onClick={(event) => handleClick(event, row)}
                    tabIndex={-1}
                    key={`${index}-${row.id}`}
                    hovercolor={backgroundColor}
                  >
                    <TableCell
                      style={{
                        width: '5px',
                        backgroundColor: sideColor
                      }}
                      padding="none"
                    />
                    <TableCell component="th" id={labelId} scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row">
                      {row.template_name}
                    </TableCell>
                    <TableCell align="center">
                      <span style={{ color: sideColor, fontWeight: 'bold' }}>
                        {statusName}
                      </span>
                    </TableCell>
                    <TableCell align="center">{row.step_no}</TableCell>
                    <TableCell align="center">
                      {dateFormat(row.created_at, 'DD.MM.YYYY HH:mm')}
                    </TableCell>
                    <TableCell align="center">
                      {dateFormat(row.updated_at, 'DD.MM.YYYY HH:mm')}
                    </TableCell>
                    <TableCell align="center">
                      <TaskTemplateActions />{' '}
                    </TableCell>
                  </TableRowStyled>
                );
              }
            )}
          </TableBody>
        )}
        {templateList && (
          <TableBody>
            {stableSort(list, getComparator(order, orderBy)).map(
              (row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                let backgroundColor;
                let sideColor;
                let statusName;

                switch (row.status) {
                  case 1:
                    backgroundColor = '#BADFFD';
                    sideColor = '#1c97fa';
                    statusName = 'DRAFT';
                    break;
                  case 2:
                    backgroundColor = '#fdd0b2';
                    sideColor = '#fa6300';
                    statusName = 'INACTIVE';
                    break;
                  default:
                    backgroundColor = '#d1f2b4';
                    sideColor = '#68d407';
                    statusName = 'PUBLISHED';
                }

                return (
                  <TableRowStyled
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    tabIndex={-1}
                    key={labelId}
                    hovercolor={backgroundColor}
                  >
                    <TableCell
                      style={{
                        width: '5px',
                        backgroundColor: sideColor
                      }}
                      padding="none"
                    />

                    <TableCell component="th" id={labelId} scope="row">
                      {row.template_name}
                    </TableCell>
                    <TableCell align="center">
                      {dateFormat(row.created_at, 'DD.MM.YYYY HH:mm')}
                    </TableCell>
                    <TableCell align="center">
                      {dateFormat(row.updated_at, 'DD.MM.YYYY HH:mm')}
                    </TableCell>
                    <TableCell align="center">{row.version}</TableCell>
                    <TableCell align="center">
                      <span style={{ color: sideColor, fontWeight: 'bold' }}>
                        {statusName}
                      </span>
                    </TableCell>
                  </TableRowStyled>
                );
              }
            )}
          </TableBody>
        )}
        {formList && (
          <TableBody>
            {stableSort(list, getComparator(order, orderBy)).map(
              (row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const backgroundColor = 'rgba(250,100,0,0.5)';
                const sideColor = 'rgba(250,100,0,1)';

                return (
                  <TableRowStyled
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    tabIndex={-1}
                    key={labelId}
                    hovercolor={backgroundColor}
                  >
                    <TableCell
                      style={{
                        width: '5px',
                        backgroundColor: sideColor
                      }}
                      padding="none"
                    />

                    <TableCell component="th" id={labelId} scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="center">
                      <FormType>{row.form_type}</FormType>
                      <FormName>{row.template_name}</FormName>
                    </TableCell>
                    <TableCell align="center">{row.location}</TableCell>
                    <TableCell align="center">
                      {dateFormat(row.updated_at, 'DD.MM.YYYY HH:mm')}
                    </TableCell>
                    <TableCell align="center">{row.version}</TableCell>
                    <TableCell align="center">
                      <FormattedMessage id="ACTIONS" />
                    </TableCell>
                  </TableRowStyled>
                );
              }
            )}
          </TableBody>
        )}
        {onlyName && (
          <TableBody>
            {stableSort(list, getComparator(order, orderBy)).map(
              (row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                let backgroundColor;
                let sideColor;
                let statusName;

                switch (row.status) {
                  case 1:
                    backgroundColor = '#BADFFD';
                    sideColor = '#1c97fa';
                    statusName = 'DRAFT';
                    break;
                  case 2:
                    backgroundColor = '#fdd0b2';
                    sideColor = '#fa6300';
                    statusName = 'INACTIVE';
                    break;
                  default:
                    backgroundColor = '#d1f2b4';
                    sideColor = '#68d407';
                    statusName = 'PUBLISHED';
                }

                return (
                  <TableRowStyled
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    tabIndex={-1}
                    key={labelId}
                    hovercolor={backgroundColor}
                  >
                    <TableCell component="th" id={labelId} scope="row">
                      {row.template_name}
                    </TableCell>
                  </TableRowStyled>
                );
              }
            )}
          </TableBody>
        )}
        {formList && (
          <TableBody>
            {stableSort(list, getComparator(order, orderBy)).map(
              (row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const backgroundColor = 'rgba(250,100,0,0.5)';
                const sideColor = 'rgba(250,100,0,1)';

                return (
                  <TableRowStyled
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    tabIndex={-1}
                    key={labelId}
                    hovercolor={backgroundColor}
                  >
                    <TableCell
                      style={{
                        width: '5px',
                        backgroundColor: sideColor
                      }}
                      padding="none"
                    />

                    <TableCell component="th" id={labelId} scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="center">
                      <FormType>{row.form_type}</FormType>
                      <FormName>{row.template_name}</FormName>
                    </TableCell>
                    <TableCell align="center">{row.location}</TableCell>
                    <TableCell align="center">
                      {dateFormat(row.updated_at, 'DD.MM.YYYY HH:mm')}
                    </TableCell>
                    <TableCell align="center">{row.version}</TableCell>
                    <TableCell align="center">actions</TableCell>
                  </TableRowStyled>
                );
              }
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};
