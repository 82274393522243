import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CommonContainer from 'components/Common/CommonContainer';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import CompanyForm from 'components/Company/CompanyForm';
import AlertDialog from 'components/Common/AlertDialog';
import ProgressCircle from 'components/Common/ProgressCircle';
import { FormattedMessage } from 'react-intl';
import {
  companiesSelector,
  fetchCompanies,
  addCompany,
  addProjectToCompany,
  projectsSelector,
  findCompaniesByProjectId,
  searchCompanies,
  findCompanyById
} from '../../slices';

import CompanyBox from './CompanyBox';
import CompanyTable from './CompanyTable';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(36),
      height: theme.spacing(24)
    }
  },
  dialogGrid: {
    margin: '0px',
    padding: '0px',
    '& > p': {
      fontSize: '12px',
      margin: '3px',
      marginTop: '0px',
      padding: '0px',
      fontWeight: 'bold',
      color: '#555'
    }
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  dialogButton: {
    margin: '5px 15px'
  }
}));

export default () => {
  const classes = useStyles();

  const [showModal, setShowModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);

  const [viewSetting, setViewSetting] = useState(true);

  const dispatch = useDispatch();
  const { companies, loading } = useSelector(companiesSelector);
  const { selectedProject } = useSelector(projectsSelector);

  const [searchText, setSerarchText] = useState('');
  const handleSearch = useCallback(
    (value) => {
      const search = value.trim().toLowerCase();
      setSerarchText(value);
      dispatch(searchCompanies(search, selectedProject && selectedProject.id));
    },
    [searchText, selectedProject]
  );

  const history = useHistory();

  useEffect(() => {
    const { company_id, role } = JSON.parse(localStorage.getItem('user'));
    if (company_id && role !== '5') {
      dispatch(findCompanyById(company_id, true));
    } else if (selectedProject && !searchText) {
      dispatch(findCompaniesByProjectId(selectedProject.id));
    } else if (!selectedProject) {
      dispatch(fetchCompanies());
    } else if (searchText) {
      const search = searchText.trim().toLowerCase();
      dispatch(
        searchCompanies(
          search,
          selectedProject && selectedProject.id,
          company_id
        )
      );
    }
  }, [selectedProject]);

  const handleAddCompany = (values) => {
    const logo = '';
    const { company_projects } = values;
    delete values.company_projects;

    dispatch(addCompany({ ...values, logo })).then((res) => {
      if (res) {
        for (const project of company_projects) {
          dispatch(addProjectToCompany(res.id, project.id));
        }
      }
    });
    setShowModal(false);
  };

  const handleCompany = (id) => {
    history.push(`/companies/${id}`);
  };

  const handleNewCompany = () => {
    if (!selectedProject) {
      setAlertModal(true);
    } else {
      setShowModal(true);
    }
  };

  const alertText = <FormattedMessage id="PROJECT_SELECTION_ALERT" />;
  return (
    <>
      <AlertDialog
        alertText={alertText}
        open={alertModal}
        setOpen={setAlertModal}
      />
      <CommonContainer
        containerName={<FormattedMessage id="COMPANIES" />}
        addButton={<FormattedMessage id="NEW_COMPANY" />}
        setShowModal={handleNewCompany}
        handleSearch={handleSearch}
        viewSetting={viewSetting}
        setViewSetting={setViewSetting}
        search
        page="companies"
      >
        {loading.getCompanies || loading.searchCompanies ? (
          <ProgressCircle />
        ) : companies.length > 0 ? (
          viewSetting ? (
            <div className={classes.root}>
              <CompanyBox
                companyList={companies}
                handleCompany={handleCompany}
              />
            </div>
          ) : (
            <CompanyTable
              companyList={companies}
              handleCompany={handleCompany}
            />
          )
        ) : (
          <h4>
            <FormattedMessage id="NO_DATA" />
          </h4>
        )}
      </CommonContainer>
      <CompanyForm
        showModal={showModal}
        setShowModal={setShowModal}
        loading={loading.addCompany}
        initialValues={{
          company_name: '',
          short_name: '',
          telephone: '',
          contact_email: '',
          web: '',
          address: ''
        }}
        submitFunc={handleAddCompany}
        dialogTitle={<FormattedMessage id="CREATE_NEW_COMPANY" />}
      />
    </>
  );
};
