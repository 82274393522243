import React, {
  useState,
  useEffect,
  useRef,
  createRef,
  useReducer
} from 'react';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';

import {
  Breadcrumbs,
  Link,
  Paper,
  Grid,
  FormHelperText,
  FormControl,
  makeStyles,
  Select
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  formsSelector,
  fetchDraftList,
  findByIdForm,
  fetchMetadataSets,
  fetchMetadataSetsByTemplate,
  fetchStatusSets,
  fetchRevisionSets,
  metadataSetsSelector,
  revisionSetsSelector,
  statusSetsSelector,
  projectsSelector,
  fetchDocuments,
  updateForm,
  addForm,
  tasksSelector,
  getAllTasks,
  getWorkerTasks,
  insertTask,
  updateTask,
  insertMail,
  addDocument,
  deleteDocuments,
  resetDocuments,
  fetchLogs
} from 'slices';
import ProgressCircle from 'components/Common/ProgressCircle';
import { Formik, Form } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import {
  findByIdTemplatessFunction,
  findByIdUsersFunction,
  findRoleByProjectIdCompanyFunc
} from 'api';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';
import Documents from './Documents';
import EditFormValue from './EditFormValue';
import AddTaskModal from './AddTaskModal';
import FormHeader from './New/FormHeader';
import { StyledTabs, StyledTab, TabPanel } from './Tabs';
import {
  FormEditorContainer,
  TitleContainer,
  MainContainer,
  HistoryListContainer
} from './Containers';

import {
  HistoryChip,
  ContentTitle,
  ContentLink
} from '../ProjectForms/FormEditor';

import Logs from './Logs';

import permissionHelper from '../../helpers/permission';

import { authentication } from '../../helpers/authentication';

import DateFormatter from '../../helpers/DateFormatter';
/* import { useScrollPosition } from '@n8tb1t/use-scroll-position' */

import {
  Option,
  CancelButton,
  SaveButton,
  SendButton,
  LoadingFade,
  IconSvg,
  AccordionButton,
  AccordionContainer
} from '../../styles/forms/formEditorStyle';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4)
  },
  leftside: {
    width: '20vw',
    marginRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    height: '100%'
  },
  rightside: {
    marginLeft: theme.spacing(1),
    minHeight: (props) => (props.inEditPage ? '68vh' : '75vh')
  },
  paper: {
    flex: 1,
    marginLeft: theme.spacing(1),
    height: '100%'
  },
  actions: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginRight: 0,
    alignContent: 'center',
    height: '8%',
    padding: theme.spacing(1)
  },
  header: {
    height: theme.spacing(3),
    flex: 1,
    fontFamily: 'Helvetica, Helvetica, Arial, serif',
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.5)',
    textAlign: 'center',
    lineHeight: '17.0px',
    borderBottom: `${theme.spacing(0.6)}px solid rgba(250, 100, 0, 1)`
  },
  historyleftside: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1
    )}px 0`
  },
  historyrightside: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(
      1
    )}px`
  },
  historyleftone: {
    flex: 1,
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
    fontSize: 'calc(4px + 0.5vh + 0.5vw)',
    color: 'rgba(89, 84, 105, 1.0)',
    textAlign: 'left',
    lineHeight: '14.0px',
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1
    )}px 0`,
    alignItems: 'center'
  },
  historyrightone: {
    fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
    fontSize: 14,
    color: 'rgba(250, 100, 105, 1.0)',
    textAlign: 'right',
    lineHeight: '17.0px',
    margin: theme.spacing(0.75)
  },
  options: {
    textAlign: 'left !important',
    color: 'rgba(89, 84, 105, 1.0)',
    fontSize: 'calc(4px + 0.5vh + 0.5vw) !important',
    lineHeight: 'calc((4px + 0.5vh + 0.5vw) * 1.618) !important',
    letterSpacing: 'calc((4px + 0.5vh + 0.5vw) / (1.618 * 20)) !important'
  },
  placeholder: {
    color: 'rgba(89, 84, 105, 0.5)'
  },
  sticky: {
    position: 'fixed',
    top: 70,
    zIndex: 1000,
    backgroundColor: 'rgba(245, 245, 245, 1)',
    minWidth: (props) =>
      props.id === 'new' ? 'calc(100vw - 170px)' : `calc(100vw - 35vw)`,
    maxWidth: (props) =>
      props.id === 'new' ? 'calc(100vw - 170px)' : `calc(100vw - 35vw)`
  }
}));

const initialState = {
  openTaskModal: false,
  tabIndex: 0,
  inEditPage: false,
  isEditing: false,
  formData: {},
  selectedStatus: null,
  selectedRevision: null,
  initialRevision: null,
  selectedMetadatas: {},
  formElements: null,
  template: null,
  formNo: null,
  createdBy: null,
  updatedBy: null,
  permission: false,
  sentValues: null,
  roles: [],
  documentList: []
};

function reducer(state, { type, payload }) {
  switch (type) {
    case 'setOpenTaskModal':
      return (state = { ...state, openTaskModal: payload });
    case 'setTabIndex':
      return (state = { ...state, tabIndex: payload });
    case 'setInEditPage':
      return (state = { ...state, inEditPage: payload });
    case 'setEditing':
      return (state = { ...state, isEditing: payload });
    case 'setFormData':
      return (state = { ...state, formData: payload });
    case 'setSelectedStatus':
      return (state = { ...state, selectedStatus: payload });
    case 'setSelectedRevision':
      return (state = { ...state, selectedRevision: payload });
    case 'setInitialRevision':
      return (state = { ...state, initialRevision: payload });
    case 'setSelectedMetadatas':
      return (state = { ...state, selectedMetadatas: payload });
    case 'setFormValues':
      return (state = { ...state, formValues: payload });
    case 'setTemplate':
      return (state = { ...state, template: payload });
    case 'setFormElements':
      return (state = { ...state, formElements: payload });
    case 'setFormNo':
      return (state = { ...state, formNo: payload });
    case 'setCreatedBy':
      return (state = { ...state, createdBy: payload });
    case 'setUpdatedBy':
      return (state = { ...state, updatedBy: payload });
    case 'setPermission':
      return (state = { ...state, permission: payload });
    case 'setSentValues':
      return (state = { ...state, sentValues: payload });
    case 'setRoles':
      return (state = { ...state, roles: payload });
    case 'setDocumentList':
      return (state = { ...state, documentList: payload });
    default:
      return state;
  }
}

const FormEditor = React.forwardRef(() => {
  const dispatch = useDispatch();

  const [selectedTask, selectTask] = useState(null);

  const [statusChanged, setStatusChanged] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [state, setState] = useReducer(reducer, initialState);

  const {
    openTaskModal,
    inEditPage,
    isEditing,
    tabIndex,
    formData,
    formValues,
    selectedStatus,
    selectedRevision,
    initialRevision,
    selectedMetadatas,
    template,
    formElements,
    formNo,
    permission,
    sentValues,
    roles,
    documentList
  } = state;

  const auth = authentication.userValue;

  useEffect(() => {
    setState({
      type: 'setPermission',
      payload: permissionHelper('forms').editPermission()
    });

    return () => {
      dispatch(findByIdForm(null, null));
      dispatch(fetchDocuments(null));
    };
  }, []);

  // Form Id at Params
  const { id } = useParams();

  const history = useHistory();

  const intl = useIntl();

  // ref for edit form info formik submit
  const inputRef = useRef(null);

  const mountedRef = useRef(true);

  const formikRef = useRef(null);

  const containerRef = useRef(null);

  const headerRef = useRef(null);

  const classes = useStyles({ inEditPage, id });

  const {
    draftList,
    form,
    loading,
    documents,
    selectedTemplate,
    selectedSiteSet,
    site_list,
    forms
  } = useSelector(formsSelector);

  const { metadataSets, metadataSetsTemplate } = useSelector(
    metadataSetsSelector
  );
  const { revisionSets } = useSelector(revisionSetsSelector);
  const { statusSets, statusRoles } = useSelector(statusSetsSelector);
  const { selectedProject } = useSelector(projectsSelector);
  const { tasks, workerTasks, loading: loadingTasks } = useSelector(
    tasksSelector
  );

  useEffect(() => {
    const maincontent = document.getElementById('main_content');

    function scrollFunction() {
      if (headerRef.current) {
        const sticky = Number(headerRef.current?.offsetTop) - 70;

        if (maincontent.scrollTop > sticky) {
          headerRef.current.classList = classes.sticky;
        } else {
          headerRef.current.classList = 's';
        }
      }
    }
    maincontent.addEventListener('scroll', scrollFunction);

    return () => {
      maincontent.removeEventListener('scroll', scrollFunction);
    };
  }, [headerRef.current]);

  useEffect(() => {
    console.log('---DOCUMENT LIST ----', documents);
    if (documents.length) {
      if (id === 'new') dispatch(resetDocuments());
      else setState({ type: 'setDocumentList', payload: documents });
    }
  }, [documents]);

  useEffect(() => {
    if (tasks && tasks.length > 0 && formData) {
      const filtered = tasks.filter(
        (element) => element.form_id === formData.id
      );
      if (filtered.length > 0) {
        const isNotLocked =
          Number(auth.role) !== 1 &&
          ((form.updated_by === auth.id &&
            tasks.filter(
              (task) =>
                Number(task.step_no) !== 3 &&
                task.form_id === formData.id &&
                task.worker_id !== auth.id
            ).length === 0) ||
            (tasks.filter(
              (task) =>
                Number(task.step_no) === 3 &&
                task.form_id === formData.id &&
                (task.worker_id === auth.id || task.created_by === auth.id)
            ).length > 0 &&
              tasks.filter(
                (task) =>
                  Number(task.step_no) !== 3 &&
                  task.form_id === formData.id &&
                  task.worker_id !== auth.id
              ).length === 0) ||
            workerTasks.filter(
              (task) =>
                Number(task.step_no) !== 3 &&
                task.form_id === formData.id &&
                task.worker_id === auth.id
            ).length > 0);

        selectTask({
          ...filtered[0],
          ownTask: isNotLocked
        });
      } else if (filtered.length > 1) {
        const isNotLocked =
          Number(auth.role) !== 1 &&
          ((form.updated_by === auth.id &&
            tasks.filter(
              (task) =>
                Number(task.step_no) !== 3 &&
                task.form_id === formData.id &&
                task.worker_id !== auth.id
            ).length === 0) ||
            (tasks.filter(
              (task) =>
                Number(task.step_no) === 3 &&
                task.form_id === formData.id &&
                (task.worker_id === auth.id || task.created_by === auth.id)
            ).length > 0 &&
              tasks.filter(
                (task) =>
                  Number(task.step_no) !== 3 &&
                  task.form_id === formData.id &&
                  task.worker_id !== auth.id
              ).length === 0) ||
            workerTasks.filter(
              (task) =>
                Number(task.step_no) !== 3 &&
                task.form_id === formData.id &&
                task.worker_id === auth.id
            ).length > 0);

        selectTask({
          ...filtered[0],
          ownTask: isNotLocked,
          workers: filtered.map((element) => ({
            worker_id: element.worker_id,
            worker_name: element.worker_name
          }))
        });
      } else {
        selectTask(filtered);
      }
    }
  }, [tasks, formData]);

  useEffect(() => {
    if (selectedProject) {
      findRoleByProjectIdCompanyFunc(selectedProject.id).then((res) => {
        const _roles = res.filter(
          (role) => role.company_id === auth.company_id
        );
        setState({ type: 'setRoles', payload: _roles });
      });
      dispatch(getWorkerTasks(selectedProject.id, auth.id));
      dispatch(getAllTasks(selectedProject.id));
    }
  }, [selectedProject]);

  // Open Edit Form Page
  const handleEdit = () => {
    // dispatch(getTemplateSuccess(templateList[0]));

    setState({ type: 'setInEditPage', payload: 1 });
  };

  // Handle Tab Change For Right Side
  const handleTabChange = (event, newValue) => {
    setState({ type: 'setTabIndex', payload: newValue });
    // setInEditPage(0);
    // ref.current.scrollTo(0, 0);
  };

  const initials = {
    id: 'new',
    created_by: 0,
    updated_by: 0,
    deleted_by: 0,
    template_id: 0,
    project_id: 0,
    original_form_id: 0,
    template_name: '',
    form_name: '',
    created_at: '',
    updated_at: '',
    values: {},
    site_details: {},
    revision_set_id: 0,
    status_set_id: 0
  };

  useEffect(() => {
    if (formData.template_id) {
      findByIdTemplatessFunction(formData.template_id, mountedRef.current).then(
        (res) => {
          setState({ type: 'setTemplate', payload: res });
          setState({ type: 'setFormElements', payload: { ...res.template } });
        }
      );
    }

    if (formData.created_by) {
      if (id !== 'new') {
        findByIdUsersFunction(form.created_by, mountedRef.current).then(
          (res) => {
            setState({ type: 'setCreatedBy', payload: res });
          }
        );
      } else {
        setState({ type: 'setCreatedBy', payload: formData.created_by });
      }
    }

    if (formData.updated_by) {
      findByIdUsersFunction(form.updated_by, mountedRef.current).then((res) => {
        setState({ type: 'setUpdatedBy', payload: res });
      });
    }

    return () => {
      mountedRef.current = false;
    };
  }, [formData]);

  // set metadata for new form
  const getMetadataForm = (metadata) => {
    let _metadata = {};
    metadata.forEach((meta) => {
      const _item = {
        [meta.metadata_name]: {
          metadata_id: meta.metadta_id,
          metadata_detail_id: meta.sets[0].id,
          metadata_detail_name: meta.sets[0].name
        }
      };
      _metadata = { ..._metadata, ..._item };
    });

    return _metadata;
  };

  // set status name for new form

  const formInfoSchema = Yup.object().shape({
    form_no: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: 'REQUIRED_FORM_NO' })),
    form_name: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: 'REQUIRED_FORM_NAME' })),
    status_set_id: Yup.number()
      .required(intl.formatMessage({ id: 'REQUIRED_STATUS_SET' }))
      .test(
        'test',
        intl.formatMessage({ id: 'REQUIRED_STATUS_SET' }),
        (value) => value !== 0
      ),
    revision_set_id: Yup.number()
      .required(intl.formatMessage({ id: 'REQUIRED_REVISION_SET' }))
      .test(
        'test',
        intl.formatMessage({ id: 'REQUIRED_REVISION_SET' }),
        (value) => value !== 0
      )
  });

  // Select curent form by id if id equel to 'new', create new form
  useEffect(() => {
    if (id === 'new') {
      if (!selectedTemplate) {
        history.push('/forms');
      } else {
        const template = selectedTemplate.drafts[0];

        const initialNewForm = {
          ...initials,
          template_id: template.id,
          project_id: template.project_id,
          template_name: template.template_name,
          form_name: template.template_name,
          form_no: `${template.prefix.replace(/^\-/, '').replace(/\-$/, '')}`,
          counter_format: `${template.counter_format
            .replace(/^\-/, '')
            .replace(/\-$/, '')}`,
          status_id: template.status_set_id,
          status_name: '',
          revision_id: template.revision_set_id,
          revision_name: '',
          site_details: {
            site_set: {
              id: selectedSiteSet.id,
              set_name: selectedSiteSet.set_name
            },
            site_list: site_list.map((element) => ({
              id: element.id,
              name: element.name,
              parent_id: element.parent_id,
              site_id: element.site_id
            }))
          },
          metadata: getMetadataForm(template.template.metadata)
        };
        dispatch(findByIdForm(id, initialNewForm));
      }
    } else {
      dispatch(findByIdForm(id));
    }
  }, [id]);

  // check current draft list is true , if not true fetch new draft list with original_id
  useEffect(() => {
    if (form && revisionSets.length) {
      if (id !== 'new') {
        const { original_form_id } = form;
        /* let {
          revisionList: [{ original_form_id: getDrafts }]
        } = draftList[0] || { revisionList: [{ original_form_id: null }] };
        if (!getDrafts || getDrafts !== original_form_id) {
          dispatch(fetchDraftList(original_form_id));
        } */
        dispatch(fetchDraftList(original_form_id));
        dispatch(fetchDocuments(original_form_id));
        dispatch(fetchLogs(original_form_id));
      }
      const _form = form;
      setState({ type: 'setFormData', payload: _form });
    }
  }, [form, revisionSets]);

  useEffect(() => {
    // fetch documents when form edith by original form id
    if (form && id !== 'new') dispatch(fetchDocuments(form.original_form_id));
    // Reset documents if is not empty when create new document
    else if (id === 'new' && !documents.length) dispatch(resetDocuments());
  }, []);

  // fetch all sets
  useEffect(() => {
    if (form && form.project_id) {
      const { project_id, template_id } = form;
      dispatch(fetchMetadataSets(project_id));
      dispatch(fetchMetadataSetsByTemplate(template_id));
      dispatch(fetchStatusSets(project_id));
      dispatch(fetchRevisionSets(project_id));
    }
  }, [form]);

  useEffect(() => {
    if (formElements && form) {
      setState({
        type: 'setFormValues',
        payload: getInitialValues(form, formElements.subForms, roles)
      });
      setState({ type: 'setSelectedStatus', payload: form.status_id });
      setState({ type: 'setSelectedRevision', payload: form.revision_id });
      setState({ type: 'setInitialRevision', payload: form.revision_set_id });
      setState({ type: 'setSelectedMetadatas', payload: form.metadata });

      const tempRuleList = {};
      formElements.subForms.map((subForm) => {
        const { name: formName, controls, role_id } = subForm;

        tempRuleList[formName] = {};
        Promise.all(
          controls.map((control) => {
            const { name: controlName, type, children } = control;

            tempRuleList[formName][controlName] = {};
            if (type === 'group') {
              children.map((element) => {
                const {
                  name: elementName,
                  range,
                  required,
                  length,
                  type
                } = element;

                tempRuleList[formName][controlName][elementName] = {
                  rules: {}
                };
                if (range) {
                  tempRuleList[formName][controlName][
                    elementName
                  ].rules.range = range;
                }
                if (required) {
                  tempRuleList[formName][controlName][
                    elementName
                  ].rules.required = required;
                }
                if (length) {
                  tempRuleList[formName][controlName][
                    elementName
                  ].rules.length = length;
                }
                tempRuleList[formName][controlName][
                  elementName
                ].rules.type = type;
              });
            } else {
              const { range, required, length, type } = control;

              tempRuleList[formName][controlName] = {
                rules: {}
              };
              if (range) {
                tempRuleList[formName][controlName].rules.range = range;
              }
              if (required) {
                tempRuleList[formName][controlName].rules.required = required;
              }
              if (length) {
                tempRuleList[formName][controlName].rules.length = length;
              }
              tempRuleList[formName][controlName].rules.type = type;
            }
          })
        ).then(() => {
          // setRuleList(tempRuleList);
        });
      });
    }
  }, [formElements, form]);

  const getInitialValues = (form, subForms, roles) => {
    // eslint-disable-next-line prefer-const
    let a = { ...form.values };
    let initialValues = {
      ...a
    };

    subForms.forEach((subForm) => {
      if (!initialValues[subForm.name]) {
        initialValues[subForm.name] = {};
      } else {
        initialValues[subForm.name] = {
          ...initialValues[subForm.name]
        };
      }
      subForm.controls.forEach((control) => {
        if (control.type === 'group') {
          if (!initialValues[subForm.name][control.name]) {
            initialValues[subForm.name][control.name] = {};
          }
          control.children.forEach((child) => {
            if (!initialValues[subForm.name][control.name][child.name]) {
              initialValues[subForm.name][control.name][
                child.name
              ] = child.defaultValue ? [child.defaultValue] : [''];
            } else {
              return;
            }
            if (child.type === 'inputSelectMany') {
              initialValues[subForm.name][control.name][child.name] = [
                [child.defaultValue]
              ];
            }
            if (child.type === 'inputDate') {
              let newDate;
              if (child.defaultValue) {
                newDate = [child.defaultValue];
              } else {
                switch (child.kind) {
                  case 'fullDate':
                    newDate = [DateFormatter(new Date(), 'date')];
                    break;
                  case 'fullDateAndTime':
                    newDate = [DateFormatter(new Date())];
                    break;
                  case 'monthAndYear':
                    newDate = [DateFormatter(new Date(), 'monthAndYear')];
                    break;
                  case 'year':
                    newDate = [DateFormatter(new Date(), 'year')];
                    break;
                  case 'time':
                    newDate = [DateFormatter(new Date(), 'time')];
                    break;
                  default:
                    newDate = [DateFormatter(new Date(), 'date')];
                    break;
                }
                newDate = null;
              }

              initialValues[subForm.name][control.name][child.name] = newDate;
            }
            if (child.type === 'inputImage') {
              initialValues[subForm.name][control.name][child.name] = [[]];
            }
            if (child.type === 'inputSignature') {
              initialValues[subForm.name][control.name][child.name] = [{}];
            }
          });
        } else {
          if (!initialValues[subForm.name][control.name]) {
            initialValues[subForm.name][control.name] =
              control.defaultValue || '';
          } else {
            return;
          }
          if (control.type === 'inputSelectMany') {
            initialValues[subForm.name][control.name] = [control.defaultValue];
          }
          if (control.type === 'inputDate') {
            let newDate;
            if (control.defaultValue) {
              newDate = control.defaultValue;
            } else {
              switch (control.kind) {
                case 'fullDate':
                  newDate = DateFormatter(new Date(), 'date');
                  break;
                case 'fullDateAndTime':
                  newDate = DateFormatter(new Date());
                  break;
                case 'monthAndYear':
                  newDate = DateFormatter(new Date(), 'monthAndYear');
                  break;
                case 'year':
                  newDate = DateFormatter(new Date(), 'year');
                  break;
                case 'time':
                  newDate = DateFormatter(new Date(), 'time');
                  break;
                default:
                  newDate = DateFormatter(new Date(), 'date');
                  break;
              }
              newDate = null;
            }
            initialValues[subForm.name][control.name] = newDate;
          }
          if (control.type === 'inputImage') {
            initialValues[subForm.name][control.name] = [];
          }
          if (control.type === 'inputSignature') {
            initialValues[subForm.name][control.name] = [{}];
          }
        }
      });
    });

    return initialValues;
  };

  // useEffect(() => {
  //   if (inputRef.current && submit && tabIndex === 0) {
  //     inputRef.current.submitForm();
  //   }
  // }, [inputRef, submit, formikRef, tabIndex]);

  useEffect(() => {
    if (revisionSets.length && id === 'new')
      setState({ type: 'setInEditPage', payload: true });
  }, [revisionSets]);

  useEffect(() => {
    if (form && template) {
      if (id === 'new') {
        /* const allForms = forms.filter(
          (el) => el.template_id === form.template_id,
        );
        let _counter = 0;
        allForms.length
          && allForms.forEach((el) => {
            _counter += el.drafts ? el.drafts.length : 1;
          });

        let counterFormat = `${_counter + 1}`;

        while (
          counterFormat.length <= (template.counter_format.length - 1 || 4)
        ) {
          counterFormat = `0${counterFormat}`;
        }
        setState({ type: 'setFormNo', payload: `${template.prefix}-${counterFormat}` }); */
        setState({ type: 'setFormNo', payload: form.form_no });
      } else if (!!form?.form_no)
        setState({ type: 'setFormNo', payload: `${form.form_no}` });
    }
  }, [template, form, forms]);

  useEffect(() => {
    if (!forms.length && selectedProject) {
      //dispatch(fetchForms(auth.id, selectedProject.id));
    }
  }, []);

  return (
    <FormEditorContainer>
      {isEditing ? <LoadingFade /> : null}
      <TitleContainer>
        <Breadcrumbs separator={<>&gt;</>} aria-label="breadcrumb">
          <Link component={RouterLink} to="/forms">
            <ContentLink>
              <FormattedMessage id="FORM_DATA" />{' '}
            </ContentLink>
          </Link>
          {id === 'new' ? (
            <ContentTitle>
              <FormattedMessage id="NEW_FORM" />{' '}
            </ContentTitle>
          ) : (
            <ContentTitle>{form && form.template_name}</ContentTitle>
          )}
        </Breadcrumbs>
      </TitleContainer>
      <div className={classes.root}>
        <MainContainer>
          {id === 'new' ? null : (
            <Paper className={` ${classes.leftside}`}>
              <div className={classes.header}>
                <span
                  style={{
                    backgroundColor: '#faf8f8',
                    color: '#595469',
                    textTransform: 'uppercase'
                  }}
                >
                  <FormattedMessage id="HISTORY_OF_REVISON" />
                </span>
              </div>
              <HistoryListContainer>
                {id !== 'new' && (
                  <HistoryList
                    data={draftList}
                    clickEvent={(value) => {
                      dispatch(findByIdForm(value));
                    }}
                    now={form ? form.id : -1}
                  />
                )}
              </HistoryListContainer>
            </Paper>
          )}
          {!revisionSets.length ||
          !statusSets.length ||
          !formData.form_no ||
          !form ||
          !formNo ||
          !formValues ||
          Object.keys(formValues).length === 0 ? (
            <ProgressCircle />
          ) : (
            <Formik
              enableReinitialize
              validationSchema={formInfoSchema}
              innerRef={formikRef}
              initialValues={{
                formValues: { ...formValues },
                ...form
              }}
              onSubmit={(values, { setFieldError }) => {
                if (!statusChanged) {
                  alert(intl.formatMessage({ id: 'REQUIRED_STATUS_SET' }));
                  return;
                }

                setState({ type: 'setEditing', payload: true });
                const { formValues: fValues, ...others } = values;

                if (id === 'new') {
                  dispatch(
                    addForm({
                      ...others,
                      form_no: formNo,
                      values: { ...fValues }
                    })
                  )
                    .then(async (insertedForm) => {
                      for (const document of documentList) {
                        const response = await dispatch(
                          addDocument(
                            insertedForm.original_form_id || insertedForm.id,
                            document
                          )
                        );
                      }

                      let mailResponse = null;

                      if (sentValues) {
                        const toId = 0;
                        const toName = '';
                        const toCompanyId = 0;
                        const toCompanyName = '';

                        let toJSON = {
                          users: []
                        };

                        let ccJSON = {
                          users: []
                        };

                        if (sentValues.taskType === 1) {
                          const taskGuid = uuidv4();

                          if (sentValues.actionList.length > 0) {
                            const tempUsers = [];
                            sentValues.actionList.forEach((user) => {
                              tempUsers.push({
                                id: user.id,
                                name: user.name,
                                companyId: user.company_id,
                                companyName: user.companyName
                              });
                            });
                            toJSON = {
                              users: [...tempUsers]
                            };

                            tempUsers.forEach(async (user) => {
                              const response = await dispatch(
                                insertTask({
                                  projectId: selectedProject.id,
                                  formId: insertedForm.id,
                                  workerId: user.id,
                                  workerName: user.name,
                                  dueDate: sentValues.dueDate,
                                  taskType: sentValues.taskType,
                                  taskGuid
                                })
                              );
                            });
                          }
                          if (sentValues.infoList.length > 0) {
                            const tempUsers = [];
                            sentValues.infoList.forEach((user) => {
                              tempUsers.push({
                                id: user.id,
                                name: user.name,
                                companyId: user.company_id,
                                companyName: user.companyName
                              });
                            });
                            ccJSON = {
                              users: [...tempUsers]
                            };
                          }
                          mailResponse = await dispatch(
                            insertMail({
                              projectId: selectedProject.id,
                              formId: insertedForm.id,
                              formNo: insertedForm.form_no,
                              formName: insertedForm.form_name,
                              fromId: auth.id,
                              fromName: auth.name,
                              toId,
                              toName,
                              toJSON,
                              ccJSON,
                              description: sentValues.message,
                              fromCompanyId: auth.company_id,
                              fromCompanyName: auth.company_name,
                              toCompanyId,
                              toCompanyName
                            })
                          );
                        } else {
                          const taskGuid = uuidv4();

                          const response = await dispatch(
                            insertTask({
                              projectId: selectedProject.id,
                              formId: insertedForm.id,
                              workerId: auth.id,
                              workerName: auth.name,
                              dueDate: sentValues.dueDate,
                              stepNo: 3,
                              taskType: sentValues.taskType,
                              taskGuid
                            })
                          );

                          if (sentValues.infoList.length > 0) {
                            const users = [];
                            sentValues.infoList.forEach(async (user) => {
                              users.push({
                                id: user.id,
                                name: user.name,
                                companyId: user.company_id,
                                companyName: user.companyName
                              });
                              const insertResponse = await dispatch(
                                insertTask({
                                  projectId: selectedProject.id,
                                  formId: insertedForm.id,
                                  workerId: user.id,
                                  workerName: user.name,
                                  dueDate: sentValues.dueDate,
                                  stepNo: 3,
                                  taskType: sentValues.taskType,
                                  taskGuid
                                })
                              );
                            });
                            ccJSON = {
                              users: [...users]
                            };
                          }
                          mailResponse = await dispatch(
                            insertMail({
                              projectId: selectedProject.id,
                              formId: insertedForm.id,
                              formNo: insertedForm.form_no,
                              formName: insertedForm.form_name,
                              fromId: auth.id,
                              fromName: auth.name,
                              toId,
                              toName,
                              toJSON: ccJSON,
                              ccJSON: {
                                users: []
                              },
                              description: sentValues.message,
                              fromCompanyId: auth.company_id,
                              fromCompanyName: auth.company_name,
                              toCompanyId,
                              toCompanyName
                            })
                          );
                        }
                      }
                      setState({ type: 'setOpenTaskModal', payload: false });
                      setState({ type: 'setInEditPage', payload: false });
                      setState({ type: 'setEditing', payload: false });

                      if (mailResponse) {
                        history.push(`/mails/${mailResponse.id}/sendBox`);
                      } else {
                        history.push(`/forms/${insertedForm.id}`);
                      }
                    })
                    .catch((error) => {
                      setState({ type: 'setOpenTaskModal', payload: false });
                      setState({ type: 'setEditing', payload: false });

                      alert(
                        intl.formatMessage({
                          id: 'SAVE_ERROR'
                        })
                      );
                    });
                } else {
                  dispatch(
                    updateForm({
                      ...others,
                      form_no: form.form_no,
                      form_id: others.id,
                      values: { ...fValues }
                    })
                  )
                    .then(async (updatedForm) => {
                      const willAddFiles = documentList.filter(
                        (element) => !element.id
                      );
                      const willDeleteFiles = documents.filter(
                        (element) =>
                          documentList.filter((el) => el.id === element.id)
                            .length === 0
                      );

                      for (const deletedFile of willDeleteFiles) {
                        const response = await dispatch(
                          deleteDocuments(
                            updatedForm.original_form_id || updatedForm.id,
                            deletedFile.file_name
                          )
                        );
                      }

                      for (const willAddFile of willAddFiles) {
                        const response = await dispatch(
                          addDocument(
                            updatedForm.original_form_id || updatedForm.id,
                            willAddFile
                          )
                        );
                      }

                      let mailResponse = null;

                      if (sentValues) {
                        const oldTask = tasks.filter(
                          (el) => el.step_no !== 3 && el.form_id === form.id
                        );
                        if (oldTask.length > 0) {
                          const updateTaskResult = await dispatch(
                            updateTask({
                              projectId: selectedProject.id,
                              taskId: oldTask[0].id,
                              formId: updatedForm.id,
                              stepNo: 3,
                              workerId: auth.id,
                              workerName: auth.name,
                              dueDate: sentValues.dueDate
                            })
                          );
                        }
                        const toId = 0;
                        const toName = '';
                        const toCompanyId = 0;
                        const toCompanyName = '';

                        let toJSON = {
                          users: []
                        };

                        let ccJSON = {
                          users: []
                        };

                        if (sentValues.taskType === 1) {
                          const taskGuid = uuidv4();

                          if (sentValues.actionList.length > 0) {
                            const users = [];
                            sentValues.actionList.forEach((user) => {
                              users.push({
                                id: user.id,
                                name: user.name,
                                companyId: user.company_id,
                                companyName: user.companyName
                              });
                            });
                            toJSON = {
                              users: [...users]
                            };

                            users.forEach(async (user) => {
                              const response = await dispatch(
                                insertTask({
                                  projectId: selectedProject.id,
                                  formId: updatedForm.id,
                                  workerId: user.id,
                                  workerName: user.name,
                                  dueDate: sentValues.dueDate,
                                  taskType: sentValues.taskType,
                                  taskGuid
                                })
                              );
                            });
                          }
                          if (sentValues.infoList.length > 0) {
                            const users = [];
                            sentValues.infoList.forEach((user) => {
                              users.push({
                                id: user.id,
                                name: user.name,
                                companyId: user.company_id,
                                companyName: user.companyName
                              });
                            });
                            ccJSON = {
                              users: [...users]
                            };
                          }
                          mailResponse = await dispatch(
                            insertMail({
                              projectId: selectedProject.id,
                              formId: updatedForm.id,
                              formNo: updatedForm.form_no,
                              formName: updatedForm.form_name,
                              fromId: auth.id,
                              fromName: auth.name,
                              toId,
                              toName,
                              toJSON,
                              ccJSON,
                              description: sentValues.message,
                              fromCompanyId: auth.company_id,
                              fromCompanyName: auth.company_name,
                              toCompanyId,
                              toCompanyName
                            })
                          );
                        } else {
                          const taskGuid = uuidv4();

                          if (oldTask.length === 0) {
                            const response = await dispatch(
                              insertTask({
                                projectId: selectedProject.id,
                                formId: updatedForm.id,
                                workerId: auth.id,
                                workerName: auth.name,
                                dueDate: sentValues.dueDate,
                                stepNo: 3,
                                taskType: sentValues.taskType,
                                taskGuid
                              })
                            );
                          }

                          if (sentValues.infoList.length > 0) {
                            const users = [];
                            sentValues.infoList.forEach(async (user) => {
                              users.push({
                                id: user.id,
                                name: user.name,
                                companyId: user.company_id,
                                companyName: user.companyName
                              });
                              const insertResponse = await dispatch(
                                insertTask({
                                  projectId: selectedProject.id,
                                  formId: updatedForm.id,
                                  workerId: user.id,
                                  workerName: user.name,
                                  dueDate: sentValues.dueDate,
                                  stepNo: 3,
                                  taskType: sentValues.taskType,
                                  taskGuid
                                })
                              );
                            });
                            ccJSON = {
                              users: [...users]
                            };
                          }
                          mailResponse = await dispatch(
                            insertMail({
                              projectId: selectedProject.id,
                              formId: updatedForm.id,
                              formNo: updatedForm.form_no,
                              formName: updatedForm.form_name,
                              fromId: auth.id,
                              fromName: auth.name,
                              toId,
                              toName,
                              toJSON: ccJSON,
                              ccJSON: {
                                users: []
                              },
                              description: sentValues.message,
                              fromCompanyId: auth.company_id,
                              fromCompanyName: auth.company_name,
                              toCompanyId,
                              toCompanyName
                            })
                          );
                        }
                      }
                      setState({ type: 'setOpenTaskModal', payload: false });
                      setState({ type: 'setInEditPage', payload: false });
                      setState({ type: 'setEditing', payload: false });

                      if (mailResponse) {
                        history.push(`/mails/${mailResponse.id}/sendBox`);
                      } else {
                        history.push(`/forms/${updatedForm.id}`);
                      }
                    })
                    .catch((error) => {
                      setState({ type: 'setOpenTaskModal', payload: false });
                      setState({ type: 'setEditing', payload: false });

                      alert(
                        intl.formatMessage({
                          id: 'SAVE_ERROR'
                        })
                      );
                    });
                }
              }}
            >
              {(formikProps) => (
                <Form style={{ width: '100%' }}>
                  <div
                    style={{ display: 'flex', flexDirection: 'column' }}
                    ref={containerRef}
                  >
                    {openTaskModal && (
                      <AddTaskModal
                        setState={setState}
                        willOpen={openTaskModal}
                        onSend={(newTaskValues) => {
                          setState({
                            type: 'setSentValues',
                            payload: newTaskValues
                          });
                          formikProps.isValid
                            ? formikProps.handleSubmit()
                            : alert(
                                intl.formatMessage({
                                  id: 'UNFILLED_FORM_ALERT'
                                })
                              );
                        }}
                      />
                    )}
                    {inEditPage ? (
                      <div ref={headerRef}>
                        <Paper
                          className={`${classes.paper} ${classes.actions}`}
                        >
                          <Grid
                            container
                            spacing={8}
                            justify="space-between"
                            alignItems="center"
                            direction="row"
                          >
                            <Grid item>
                              <CancelButton
                                variant="contained"
                                size="medium"
                                onClick={() => {
                                  if (id == 'new') {
                                    history.push('/forms');
                                  } else if (formChanged) {
                                    window.confirm(
                                      intl.formatMessage({
                                        id: 'UNSAVED_FORM_ALERT'
                                      })
                                    ) &&
                                      setState({
                                        type: 'setInEditPage',
                                        payload: 0
                                      });
                                  } else {
                                    setState({
                                      type: 'setInEditPage',
                                      payload: 0
                                    });
                                  }
                                }}
                              >
                                <FormattedMessage id="CANCEL" />
                              </CancelButton>
                            </Grid>
                            {!inEditPage || !selectedStatus ? null : (
                              <Grid item xs>
                                <FormControl
                                  fullWidth
                                  variant="outlined"
                                  error={
                                    formikProps.errors.status_set_id &&
                                    formikProps.touched.status_set_id
                                  }
                                >
                                  <Select
                                    margin="dense"
                                    variant="outlined"
                                    name="status_set_id"
                                    value={
                                      statusChanged
                                        ? formikProps.values.status_set_id
                                        : 0
                                    }
                                    fullWidth
                                    displayEmpty
                                    onChange={(a) => {
                                      setStatusChanged(true);
                                      formikProps.handleChange(a);
                                    }}
                                    className={clsx(
                                      classes.options,
                                      formikProps.values.status_set_id === 0
                                        ? classes.placeholder
                                        : null
                                    )}
                                  >
                                    <Option value={0} disabled>
                                      {`${intl.formatMessage({
                                        id: 'PLEASE_SELECT'
                                      })}${intl.formatMessage({
                                        id: 'STATUS'
                                      })}${intl.formatMessage({
                                        id: 'PLEASE_SELECT_CONTINUE'
                                      })}`}
                                    </Option>

                                    {selectedStatus &&
                                      statusSets
                                        .find(
                                          ({ id }) =>
                                            id === Number(selectedStatus)
                                        )
                                        ?.status_set_details.map(
                                          (setDetail) => ({
                                            ...setDetail,
                                            disabled:
                                              statusRoles
                                                .filter(
                                                  (statusRole) =>
                                                    statusRole.detail_id ===
                                                    setDetail.id
                                                )[0]
                                                .roles.filter(
                                                  (role) =>
                                                    roles.filter(
                                                      (roleElement) =>
                                                        roleElement.role_id ===
                                                        role.role_id
                                                    ).length > 0
                                                ).length > 0
                                          })
                                        )
                                        .map((detail) =>
                                          detail.disabled ? (
                                            <Option
                                              key={detail.id}
                                              value={detail.id}
                                            >
                                              {detail.name}{' '}
                                            </Option>
                                          ) : null
                                        )}
                                  </Select>
                                  <FormHelperText>
                                    {formikProps.touched.status_set_id &&
                                      formikProps.errors.status_set_id}
                                  </FormHelperText>
                                </FormControl>
                              </Grid>
                            )}
                            <Grid item>
                              {!isEditing ? (
                                <SaveButton
                                  variant="contained"
                                  size="medium"
                                  onClick={() => {
                                    async function doValidate() {
                                      formikProps.validateForm();
                                    }

                                    doValidate().then(() => {
                                      setState({
                                        type: 'setTabIndex',
                                        payload: 0
                                      });
                                      if (formikProps.isValid) {
                                        formikProps.handleSubmit();
                                      } else {
                                        alert(
                                          intl.formatMessage({
                                            id: 'UNFILLED_FORM_ALERT'
                                          })
                                        );
                                      }
                                    });
                                  }}
                                >
                                  <FormattedMessage id="SAVE" />
                                </SaveButton>
                              ) : (
                                <ProgressCircle
                                  height="15vh"
                                  size={30}
                                  overflow="none"
                                />
                              )}
                            </Grid>
                            <Grid item>
                              {!isEditing ? (
                                <SendButton
                                  variant="contained"
                                  size="medium"
                                  onClick={() => {
                                    formikProps.isValid
                                      ? setState({
                                          type: 'setOpenTaskModal',
                                          payload: true
                                        })
                                      : alert(
                                          intl.formatMessage({
                                            id: 'UNFILLED_FORM_ALERT'
                                          })
                                        );
                                  }}
                                >
                                  <FormattedMessage id="SEND" />
                                </SendButton>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Paper>
                      </div>
                    ) : null}
                    <Paper className={`${classes.paper} ${classes.rightside}`}>
                      <FormHeader
                        state={state}
                        isNewForm={id === 'new'}
                        formNo={formNo}
                        permission={permission}
                        isEditing={inEditPage}
                        setEditPage={(bool) =>
                          setState({
                            type: 'setInEditPage',
                            payload: bool ? 1 : 0
                          })
                        }
                        task={selectedTask}
                        formikProps={formikProps}
                        initials={formData}
                        metadataSets={metadataSetsTemplate}
                        allMetadataSets={metadataSets}
                        revisionSets={revisionSets}
                        statusRoles={statusRoles}
                        roles={roles}
                        selectedRevision={
                          revisionSets.filter(
                            (revision) =>
                              revision.id == Number(selectedRevision)
                          )[0]
                        }
                        initialRevision={initialRevision}
                        selectedStatus={
                          statusSets.filter(
                            (status) => status.id == Number(selectedStatus)
                          )[0]
                        }
                        selectedMetadata={metadataSets.filter((meta) => {
                          const metadatas = Object.keys(selectedMetadatas);
                          for (const metadata of metadatas) {
                            if (metadata === meta.metadata_name) {
                              return true;
                            }
                          }
                          return false;
                        })}
                      />
                      <StyledTabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        aria-label="form-tabs"
                      >
                        <StyledTab
                          label={
                            <span style={{ textTransform: 'uppercase' }}>
                              <FormattedMessage id="FORM_DATA" />
                            </span>
                          }
                          first="true"
                        />
                        {id === 'new' ? null : (
                          <StyledTab
                            label={
                              <span style={{ textTransform: 'uppercase' }}>
                                <FormattedMessage id="HISTORY" />
                              </span>
                            }
                          />
                        )}
                      </StyledTabs>
                      <TabPanel value={tabIndex} index={0}>
                        <>
                          <EditFormValue
                            ref={formikRef}
                            form={formikProps}
                            formValues={formValues}
                            formElements={formElements}
                            disabled={!inEditPage}
                            projectId={form.project_id}
                          />

                          <Documents
                            edit={inEditPage}
                            documents={documentList}
                            onAddFile={(fileInfo) => {
                              const tempDocumentList = [...documentList];

                              tempDocumentList.push(fileInfo);

                              setState({
                                type: 'setDocumentList',
                                payload: tempDocumentList
                              });
                            }}
                            onDeleteFile={(index) => {
                              let tempDocumentList;

                              if (documentList.length > 1) {
                                tempDocumentList = [...documentList];
                                tempDocumentList.splice(index, 1);
                              } else {
                                tempDocumentList = [];
                              }

                              setState({
                                type: 'setDocumentList',
                                payload: tempDocumentList
                              });
                            }}
                          />
                        </>
                      </TabPanel>
                      {id === 'new' ? null : (
                        <TabPanel value={tabIndex} index={1}>
                          <Logs />
                        </TabPanel>
                      )}
                    </Paper>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </MainContainer>
      </div>
    </FormEditorContainer>
  );
});

export default FormEditor;

const Accordion = ({ data, clickEvent, now }) => {
  const classes = useStyles();

  const [setActive, setActiveState] = useState(false);
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState(false);

  const content = createRef();

  const toggleAccordion = () => {
    const tempActive = setActive;
    setActiveState(!tempActive);
    setHeightState(tempActive ? '0px' : `${content.current.scrollHeight}px`);
    setRotateState(!tempActive);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <AccordionContainer
        setActive={setActive}
        now={now === data.revisionList[0].id}
      >
        {data.revisionList.length > 1 && (
          <AccordionButton onClick={toggleAccordion}>
            <IconSvg
              height={15}
              width={15}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              setRotate={setRotate}
            >
              <path
                fill="#777"
                d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
              />
            </IconSvg>
          </AccordionButton>
        )}
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: data.revisionList.length > 1 ? 0 : 27
          }}
          onClick={() => {
            clickEvent(data.revisionList[0].id);
          }}
        >
          <div className={classes.historyleftside}>
            <div className={classes.historyleftone}>
              Rev: {data.revisionSetName}
            </div>
            <div className={classes.historyleftone}>
              #{data.revisionList[0].version}
            </div>
          </div>
          <div className={classes.historyrightside}>
            <div className={classes.historyrightone}>
              {moment(
                data.revisionList[0].updated_at,
                moment.DATETIME_LOCAL_MS
              ).format('DD.MM.YYYY HH:mm')}
            </div>
            <HistoryChip status={1}>
              {data.revisionList[0].status_name}
            </HistoryChip>
          </div>
        </div>
      </AccordionContainer>
      <div
        ref={content}
        style={{
          backgroundColor: 'white',
          transition: 'max-height 0.6s ease',
          overflow: 'hidden',
          maxHeight: `${setHeight}`
        }}
      >
        {data.revisionList.map((revision, index) => {
          if (index === 0) return null;
          return (
            <div
              key={`revison list key --${index}`}
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                paddingLeft: 27,
                backgroundColor: now === revision.id ? '#ccc' : 'white',
                transition: 'all 300ms'
              }}
              onClick={() => {
                clickEvent(revision.id);
              }}
            >
              <div className={classes.historyleftside}>
                <div className={classes.historyleftone}>
                  Rev: {data.revisionSetName}
                </div>
                <div className={classes.historyleftone}>
                  #{revision.version}
                </div>
              </div>
              <div className={classes.historyrightside}>
                <div className={classes.historyrightone}>
                  {moment(revision.updated_at, moment.DATETIME_LOCAL_MS).format(
                    'DD.MM.YYYY HH:mm'
                  )}
                </div>
                <HistoryChip status={1}>{revision.status_name}</HistoryChip>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const HistoryList = ({ data, clickEvent, now }) => {
  if (data.length === 0) return null;
  return (
    <>
      {data.map((datum, index) => (
        <Accordion
          key={`acordion${datum.revisionSetId}`}
          data={datum}
          clickEvent={clickEvent}
          now={now}
        />
      ))}
    </>
  );
};
