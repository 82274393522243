import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import AccountForm from './AccountForm';

import { authentication } from '../../helpers/authentication';

const InfoHeader = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 10px;
    border-bottom: 1px solid #555;
`;

const InfoTıtle = styled.div`
    font-size: 20px;
    margin-left: 15px;
`;

const EditButton = styled.div`
    margin-right: 15px;
`;

const Label = styled.div`
    font-size: 12px;
    color: #333;
    margin-top: 15px;
`;

const Info = styled.div`
    font-size: 16px;
    font-weight: bold;
    min-height: 35px
`;

export default () => {
  const [showEditModal, setShowEditModal] = useState(false);

  const { name, email, title, company_name: companyName } = authentication.userValue;

  return (
    <>
      <InfoHeader>
        <InfoTıtle>
          <FormattedMessage id="PERSONAL_INFORMATION" />
        </InfoTıtle>
        <EditButton>
          <Button variant="contained" size="small " onClick={() => setShowEditModal(true)}>
            <FormattedMessage id="UPDATE" />
          </Button>
        </EditButton>
      </InfoHeader>
      <Grid container>
        <Grid item xs={6}>
          <Label><FormattedMessage id="NAME_SURNAME" />  </Label>
          <Info>{name}</Info>
          <Label><FormattedMessage id="MAIL" /> </Label>
          <Info>{email}</Info>
        </Grid>
        <Grid item xs={6}>
          <Label><FormattedMessage id="TITLE" /> </Label>
          <Info>{title}</Info>
          <Label><FormattedMessage id="COMPANY" /> </Label>
          <Info>{companyName}</Info>
        </Grid>
      </Grid>

      <AccountForm
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
      />
    </>
  );
};
