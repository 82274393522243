import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { useIntl, FormattedMessage } from 'react-intl';
import { authentication } from '../../helpers/authentication';
import device from '../../helpers/device';

import CraneLogoHeader from '../Header/CraneLogoHeader';
import { Footer } from '../Footer';

const LoginAdmin = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(138, 132, 155, 1);
  text-align: center;
  opacity: 1;
`;

const Container = styled.div`
  flex: 1;
  width: 100%;
  padding: 1vh 1vw;
  z-index: 10;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const MessageContainer = styled.div`
  background-color: rgba(255, 255, 255, 0);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90vw;

  @media ${device.tablet} {
    width: 75vw;
  }

  @media ${device.laptop} {
    width: 50vw;
  }
`;

const ErrorContainer = styled.div`
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(207, 0, 15, 0.75);
  font-size: calc(0px + 1vw);
  line-height: calc(1px + 1vw);
  color: rgba(255, 255, 255, 1);
  text-align: center;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  color: rgba(138, 132, 155, 1);
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  padding: 4vh 0 4vh 0;
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
`;

const MessageTitleContainer = styled.span`
  height: auto;
  color: rgba(138, 132, 155, 1);
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  padding: 4vh 0 4vh 0;
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
`;

const Bold = styled.span`
  color: rgba(138, 132, 155, 1);
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
`;

const InputContainer = styled.div`
  background-color: rgba(255, 255, 255, 0);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90vw;

  @media ${device.tablet} {
    width: 50vw;
  }

  @media ${device.laptop} {
    width: 25vw;
  }
`;

const LoginInput = styled(Field)`
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  border-radius: 4px;
  border-style: solid;
  border-width: ${(props) => (props.iserror ? '2px' : '1px')};
  border-color: ${(props) =>
    props.iserror ? 'rgba(207, 0, 15, 1)' : 'rgba(138, 132, 155, 1)'};
  margin: 1vh;
  padding: 1.25vh 1.75vh 1.5vh 2vh;
  font-family: 'Lato', Helvetica, Arial, serif;
  font-weight: bold;
  font-style: normal;
  font-size: calc(4px + 0.5vw + 0.5vh);
  line-height: calc((4px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((4px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(92, 93, 93, 1);
  text-align: left;
  cursor: pointer;
  opacity: 0.75;
  transition: all 300ms;

  &:hover {
    opacity: 1;
    background-color: rgba(233, 237, 241, 1);
  }
  &:focus {
    opacity: 1;
    background-color: rgba(233, 237, 241, 1);
  }
  &:active {
    opacity: 1;
    background-color: rgba(233, 237, 241, 1);
  }
`;

const LoginButton = styled.button`
  cursor: pointer;
  background-image: linear-gradient(180deg, #f7981c 2.04%, #f76b1c 100%);
  box-sizing: border-box;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(223, 227, 233, 0.1);
  width: 100%;
  height: auto;
  margin: 1vh;
  padding: 1vh;
  font-weight: bold;
  font-style: normal;
  font-size: calc(6px + 0.5vw + 0.5vh);
  line-height: calc((6px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((6px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(255, 255, 255, 1);
  text-align: center;
  transition: all 300ms;

  &:hover {
    background-image: linear-gradient(180deg, #f76b1c 2.04%, #d25e25 100%);
  }
`;

const LoginButton2 = styled.button`
  cursor: pointer;
  background-image: linear-gradient(180deg, #f7981c 2.04%, #f76b1c 100%);
  box-sizing: border-box;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(223, 227, 233, 0.1);
  height: auto;
  margin: 1vh;
  padding: 1vh;
  font-weight: bold;
  font-style: normal;
  font-size: calc(6px + 0.5vw + 0.5vh);
  line-height: calc((6px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((6px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(255, 255, 255, 1);
  text-align: center;
  transition: all 300ms;
  width: 100%;

  @media ${device.tablet} {
    width: 50%;
  }

  &:hover {
    background-image: linear-gradient(180deg, #f76b1c 2.04%, #d25e25 100%);
  }
`;

export default (props) => {
  const [error, setError] = useState();
  const [forgotStep, setForgotStep] = useState(0);
  const [user, setUser] = useState();

  const intl = useIntl();

  if (authentication.userValue) {
    const { from } = props.location.state || { from: { pathname: '/' } };
    props.history.push(from);
  }

  const initialValues = {
    email: ''
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'INVALID_MAIL' }))
      .required(intl.formatMessage({ id: 'REQUIRED_MAIL' }))
  });

  const handleSubmit = ({ email }, { setSubmitting }) => {
    setError();
    authentication
      .forgotPassword(email)
      .then(() => {
        setForgotStep(1);
        setUser({ email });
        setSubmitting(false);
      })
      .catch((err) => {
        setError(intl.formatMessage({ id: 'INVALID_MAIL' }));
        setSubmitting(false);
      });
  };

  const backToMain = () => {
    const { from } = props.location.state || { from: { pathname: '/' } };
    props.history.push(from);
  };

  return (
    <LoginAdmin>
      <Container forgotStep={forgotStep}>
        <CraneLogoHeader to="/login" which="login" />

        {forgotStep === 0 && (
          <FormContainer>
            <TitleContainer>
              <FormattedMessage id="FORGOT_PASSWORD" />{' '}
            </TitleContainer>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <InputContainer>
                    {error && <ErrorContainer>{error}</ErrorContainer>}
                    <LoginInput
                      placeholder={intl.formatMessage({ id: 'MAIL' })}
                      type="text"
                      name="email"
                      iserror={errors.email && touched.email ? 1 : 0}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      render={(msg) => <ErrorContainer>{msg}</ErrorContainer>}
                    />
                    <LoginButton
                      forgotStep={forgotStep}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <FormattedMessage id="RESET_PASSWORD" />
                    </LoginButton>
                  </InputContainer>
                </Form>
              )}
            </Formik>
          </FormContainer>
        )}
        {forgotStep === 1 && user && (
          <MessageContainer>
            <MessageTitleContainer>
              <Bold>{user.email}</Bold>
              <FormattedMessage id="RESET_PASSWORD_MAIL" />
              <br />
              <FormattedMessage id="CONTINUE_ON" />
            </MessageTitleContainer>
            <LoginButton2 onClick={backToMain}>
              <FormattedMessage id="BACK_TO_LOGIN" />
            </LoginButton2>
          </MessageContainer>
        )}
      </Container>
      <Footer />
    </LoginAdmin>
  );
};
