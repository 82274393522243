import React, { useState, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { useSelector } from 'react-redux';
import { formsSelector } from 'slices';
import { ProgressContainer } from 'components/Common/ProgressCircle';
import { FormattedMessage } from 'react-intl';
import { NoData } from './LeftContent';

import { dateFormat } from '../../helpers/DateFormatter';

import { findByIdUsersFunction } from '../../api/users';
import { findByIdCompaniessFunction } from '../../api/companies';
import { TableSortLabel } from '@material-ui/core';

const TableContainerStyled = styled(TableContainer)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
`;

const TableCellStyled = styled(TableCell)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: ${(props) =>
    props.datecolor ? 'rgba(250,100,0,1) !important' : '#8a849b!important'};
  display: ${(props) => (props.id ? 'flex !important' : null)};
  justify-content: ${(props) => (props.id ? 'center !important' : null)};
  align-items: ${(props) => (props.id ? 'center !important' : null)};
`;

export const Status = styled.div`
  background-image: linear-gradient(to right, #78ce1b, #549c04);
  margin: 6px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 6px;
  font-family: 'Helvetica-Bold', Helvetica, Arial, serif;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 14px;
  min-width: 10vw;
  min-height: 5vh;
`;

const Logs = () => {
  const { logs, loading } = useSelector(formsSelector);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [fetchedLogs, fetchLogs] = useState([]);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(null);

  useLayoutEffect(() => {
    console.log('*-*-* logs: ', logs);
    const doWorks = async () =>
      Promise.all(
        logs.map(async (element) => {
          const user = await findByIdUsersFunction(element.created_by);
          const userCompany = await findByIdCompaniessFunction(user.company_id);
          return {
            ...element,
            creatorUser: {
              name: user.name,
              companyName: userCompany[0]?.short_name ?? ''
            }
          };
        })
      );
    doWorks().then((result) => fetchLogs(result));
  }, [logs]);

  useEffect(() => {
    console.log('*-*-* fetchedLogs: ', fetchedLogs);
  }, [fetchedLogs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function getOperation(event_type) {
    switch (event_type) {
      case 1:
        return <FormattedMessage id="VIEWED" />;
      case 2:
        return <FormattedMessage id="CREATED" />;
      case 3:
        return <FormattedMessage id="EDITED" />;
      case 4:
        return <FormattedMessage id="STATUS_CHANGED" />;
      case 5:
        return <FormattedMessage id="SENT" />;
      default:
        return '';
    }
  }

  const createSortHandler = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }



  return loading.fetchLogs ? (
    <ProgressContainer />
  ) : !fetchedLogs.length ? (
    <NoData>
      <FormattedMessage id="LOG_NOT_FOUND" />
    </NoData>
  ) : (
    <>
      <TableContainerStyled>
        <Table aria-label="logs table">
          <TableHead>
            <TableRow>
              <TableCell align="left" sortDirection={orderBy === 'event_type' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'event_type'}
                  direction={orderBy === 'event_type' ? order : 'asc'}
                  onClick={() => createSortHandler('event_type')}
                >
                  <FormattedMessage id="EVENT_TYPE" />
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" sortDirection={orderBy === 'event_detail' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'event_detail'}
                  direction={orderBy === 'event_detail' ? order : 'asc'}
                  onClick={() => createSortHandler('event_detail')}
                >
                  <FormattedMessage id="EVENT_DETAIL" />
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" sortDirection={orderBy === 'created_by_name' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'created_by_name'}
                  direction={orderBy === 'created_by_name' ? order : 'asc'}
                  onClick={() => createSortHandler('created_by_name')}
                >
                  <FormattedMessage id="EVENT_USER" />
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" sortDirection={orderBy === 'form_version' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'form_version'}
                  direction={orderBy === 'form_version' ? order : 'asc'}
                  onClick={() => createSortHandler('form_version')}
                >
                  <FormattedMessage id="EVENT_VERSION" />
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" sortDirection={orderBy === 'created_at' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'created_at'}
                  direction={orderBy === 'created_at' ? order : 'asc'}
                  onClick={() => createSortHandler('created_at')}
                >
                  <FormattedMessage id="EVENT_DATE" />
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(fetchedLogs, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((log, index) => (
                <TableRow key={`$-log-key-${index}`} onClick={() => { }} hover>
                  <TableCellStyled align="left">
                    {getOperation(log.event_type)}
                  </TableCellStyled>
                  <TableCellStyled align="center">
                    {log.event_detail || '-'}
                  </TableCellStyled>
                  <TableCellStyled align="center">
                    {log.creatorUser
                      ? `${log.creatorUser.name ?? ''}, ${log.creatorUser.companyName ?? ''
                      }`
                      : ''}
                  </TableCellStyled>
                  <TableCellStyled align="center">
                    {log ? log.form_version : '-'}
                  </TableCellStyled>
                  <TableCellStyled align="center" datecolor="date">
                    {dateFormat(
                      log.operation === 'update'
                        ? log.updated_at
                        : log.created_at,
                      'DD.MM.YYYY HH:mm'
                    )}
                  </TableCellStyled>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainerStyled>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={logs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Logs;
