import React from 'react';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FormattedMessage } from 'react-intl';

const AlertContent = styled(DialogContentText)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
`;

const DialogButton = styled(Button)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
`;

const AlertDialog = (props) => {
  const { open, setOpen, alertText } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <AlertContent id="alert-dialog-description">{alertText}</AlertContent>
      </DialogContent>
      <DialogActions>
        <DialogButton onClick={handleClose} color="primary">
          <FormattedMessage id="OKEY" />
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
