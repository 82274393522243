import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Box } from '@material-ui/core';


export const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    bottom: '3px',
    height: '5px',
    zIndex: 200,
    backgroundColor: 'rgba(250, 100, 0, 1)',
    '& > div': {
      width: '100%',
    },
  },
})((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <div />,
    }}
  />
));

export const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    backgroundColor: '#fff',
    color: '#595469',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export const TabPanel = (props) => {
  const {
    children, value, index, fullWidth, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box width={fullWidth ? '100%' : '68vw'} p={fullWidth ? 0 : 3}>{children}</Box>}
    </div>
  );
};
