import React, { useState, useEffect, useRef } from 'react';
import { Field } from 'formik';
import { Button, Grid, Input, Paper, withStyles } from '@material-ui/core';

import SignaturePad from 'react-signature-canvas';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import OutlinedDiv from 'components/Common/OutlineDiv';
import DateFormatter from 'helpers/DateFormatter';
import { authentication } from 'helpers/authentication';

import { InputLabel } from '../Containers';

const SignatureLabel = styled.div`
  color: rgba(89, 84, 105, 1) !important;
  text-align: center;
  padding: 0.5vh 0.5vw;
  margin: 0px;
  font-weight: bold;
  font-size: calc(4px + 0.5vh + 0.5vw);
  line-height: calc((4px + 0.5vh + 0.5vw) * 1.618);
  letter-spacing: calc((4px + 0.5vh + 0.5vw) / (1.618 * 20));
`;

const SaveButton = withStyles({
  root: {
    color: 'rgba(89, 84, 105, 1)',
    width: '10vw',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    backgroundColor: '#F2F2F2',
    padding: '0.5vh 0.5vw',
    margin: '0.5vh 0.5vw',
    fontSize: 'calc(4px + 0.5vh + 0.5vw)',
    lineHeight: 'calc((4px + 0.5vh + 0.5vw) * 1.618)',
    letterSpacing: 'calc((4px + 0.5vh + 0.5vw) / (1.618 * 20))',
    '&:hover': {
      backgroundColor: 'rgba(89, 84, 105, 1)',
      color: '#F2F2F2'
    }
  }
})(Button);

const ResetButton = withStyles({
  root: {
    color: 'rgba(89, 84, 105, 1)',
    width: '10vw',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    backgroundColor: '#F2F2F2',
    padding: '0.5vh 0.5vw',
    margin: '0.5vh 0.5vw',
    fontSize: 'calc(4px + 0.5vh + 0.5vw)',
    lineHeight: 'calc((4px + 0.5vh + 0.5vw) * 1.618)',
    letterSpacing: 'calc((4px + 0.5vh + 0.5vw) / (1.618 * 20))',
    '&:hover': {
      backgroundColor: 'rgba(89, 84, 105, 1)',
      color: '#F2F2F2'
    }
  }
})(Button);

export default ({
  input,
  value,
  extraInputs,
  formName,
  controlName,
  disabled,
  stateIndex
}) => {
  const auth = authentication.userValue;

  const intl = useIntl();

  let signatureRef = useRef(null);
  const [extraInputsList, setExtraInputsList] = useState(extraInputs);
  const [val, setVal] = useState('');
  const [showError, setShowError] = useState(false);
  useEffect(() => {}, [extraInputsList]);

  const { name, label, hint, required } = input;

  const hintText = (hint && hint['0']) || '';
  const labelText = (label && label['0']) || '';

  let fieldName = `${formName}`;
  if (controlName) {
    fieldName = fieldName.concat(`.${controlName}`);
  }
  fieldName = fieldName.concat(`.${name}`);

  useEffect(() => {
    if (signatureRef) {
      let signValue;
      if (extraInputsList && extraInputsList.length > 0) {
        if (value && value[stateIndex] && value[stateIndex].signature) {
          signValue = value[stateIndex].signature;
        }
      } else if (value && value.signature) {
        signValue = value.signature;
      }
      setVal(signValue ? signValue.signedBy : '');
      signatureRef.fromDataURL(signValue);

      if (disabled) {
        signatureRef.off();
      } else {
        signatureRef.on();
      }
    }
  }, [signatureRef, disabled, value]);
  return (
    <Field name={fieldName} validate={(vv) => {}}>
      {(props) => {
        const { field, form, meta } = props;
        const signValue =
          field.value && extraInputsList && extraInputsList.length > 0
            ? field.value[stateIndex]
            : field.value;

        return (
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={2}>
              <InputLabel>{`${labelText}${required ? ' (*)' : ''}`}</InputLabel>
            </Grid>
            <Grid item xs={10}>
              <Paper elevation={3}>
                <OutlinedDiv
                  helperText={meta.error}
                  error={!!meta.error}
                  disabled={disabled}
                >
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                  >
                    <Grid
                      container
                      item
                      xs={5}
                      direction="column"
                      justify="stretch"
                      alignItems="stretch"
                      style={
                        signValue && signValue.signature
                          ? { pointerEvents: 'none' }
                          : {}
                      }
                    >
                      <SignaturePad
                        ref={(ref) => (signatureRef = ref)}
                        canvasProps={{
                          style: {
                            width: '100%',
                            height: '15vh',
                            backgroundColor: '#fff'
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      {signValue && signValue.signedBy ? (
                        <div
                          style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <div>{signValue.signedBy}</div>
                          <div>
                            {signValue.signedAt
                              ? DateFormatter(new Date(signValue.signedAt))
                              : ''}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Input
                            disabled={disabled}
                            value={val}
                            onChange={(e) => setVal(e.target.value)}
                          />
                          {showError && !val && (
                            <div style={{ color: 'red', marginTop: 10 }}>
                              {intl.formatMessage({
                                id: 'REQUIRED_THIS_FIELD'
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          height: '100%',
                          margin: '0px',
                          padding: '0px',
                          alignItems: 'center',
                          paddingRight: '1vw',
                          justifyContent: 'center'
                        }}
                      >
                        {!signValue ||
                          (signValue && !signValue.signedBy && (
                            <SaveButton
                              style={{ marginBottom: 10 }}
                              disabled={disabled}
                              onClick={() => {
                                if (val) {
                                  const signature = signatureRef
                                    .getCanvas()
                                    .toDataURL('image/png');
                                  const date = new Date();
                                  form.setFieldValue(
                                    extraInputsList &&
                                      extraInputsList.length > 0
                                      ? `${fieldName}.${stateIndex}`
                                      : fieldName,
                                    {
                                      ...signValue,
                                      signedBy: val,
                                      signedAt: date,
                                      signature
                                    }
                                  );
                                } else {
                                  setShowError(true);
                                }
                              }}
                            >
                              {intl.formatMessage({ id: 'SAVE_SIGNATURE' })}
                            </SaveButton>
                          ))}
                        <ResetButton
                          disabled={disabled}
                          onClick={() => {
                            signatureRef.clear();
                            setVal('');
                            form.setFieldValue(
                              extraInputsList && extraInputsList.length > 0
                                ? `${fieldName}.${stateIndex}`
                                : fieldName,
                              {
                                ...signValue,
                                signedBy: null,
                                signedAt: null,
                                signature: null
                              }
                            );
                          }}
                        >
                          {intl.formatMessage({ id: 'RESET' })}
                        </ResetButton>
                      </div>
                    </Grid>
                  </Grid>
                </OutlinedDiv>
              </Paper>
            </Grid>
          </Grid>
        );
      }}
    </Field>
  );
};
