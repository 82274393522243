import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Field } from 'formik';
import { Paper, Grid } from '@material-ui/core';

import {
  DateTimePicker,
  DatePicker,
  TimePicker,
} from '@material-ui/pickers';

import DateFormatter from 'helpers/DateFormatter';
import { InputLabel } from '../Containers';

const getFormat = (kind) => {
  switch (kind) {
    case 'fullDate':
      return 'DD.MM.YYYY ';
    case 'fullDateAndTime':
      return 'DD.MM.YYYY HH:mm';
    case 'monthAndYear':
      return 'MM.YYYY';
    case 'year':
      return 'YYYY';
    case 'time':
      return 'HH:mm';
    default:
      return 'DD.MM.YYYY';
  }
};
const getdivs = (kind) => {
  switch (kind) {
    case 'fullDate':
      return ['date', 'month', 'year'];
    case 'fullDateAndTime':
      return ['date', 'month', 'year', 'hours', 'minutes'];
    case 'monthAndYear':
      return ['month', 'year'];
    case 'year':
      return ['year'];
    case 'time':
      return ['hours', 'minutes'];
    default:
      return ['date', 'month', 'year'];
  }
};
const Picker = (props) => {
  const { dateMode } = props;

  switch (dateMode) {
    case 'datetime':
      return <DateTimePicker {...props} />;
    case 'date':
      return <DatePicker {...props} />;
    case 'time':
      return <TimePicker {...props} />;
    default:
      return <DatePicker {...props} />;
  }
};

export default ({
  input,
  value,
  extraInputs,
  formName,
  controlName,
  disabled,
  stateIndex
}) => {
  const [extraInputsList, setExtraInputsList] = useState(extraInputs);

  const intl = useIntl();

  useEffect(() => { }, [extraInputsList]);

  const { name, label, hint, kind, range, required } = input;

  const hintText = (hint && hint['0']) || '';
  const labelText = (label && label['0']) || '';

  let fieldName = `${formName}`;
  if (controlName) {
    fieldName = fieldName.concat(`.${controlName}`);
  }
  fieldName = fieldName.concat(`.${name}`);

  const minDate = range.min ? new Date(range.min) : null;
  const maxDate = range.max ? new Date(range.max) : null;
  if (range) {
    if (range.min) {
      if (!range.minInclusive) {
        minDate.setDate(minDate.getDate() + 1);
      }
    }

    if (range.max) {
      if (!range.maxInclusive) {
        maxDate.setDate(maxDate.getDate() - 1);
      }
    }
  }

  return (
    <Field
      name={fieldName}
      validate={(vv) => {
        if (required && !disabled) {
          if (Array.isArray(vv)) {
            let message = '';
            vv.map((vvv) => {
              if (vvv.length === 0) {
                message = intl.formatMessage({ id: 'REQUIRED_THIS_FIELD' });
              }
            });
            return message;
          }
          if (vv.length === 0) {
            return intl.formatMessage({ id: 'REQUIRED_THIS_FIELD' });
          }
        }
        if (range && !disabled) {
          if (Array.isArray(vv)) {
            let message = '';
            vv.map((vvv) => {
              const rangeMin = new Date(range.min);
              const rangeMax = new Date(range.max);

              const dateValue = new Date();

              const dateTimeParts = vvv.split(' ');
              const dateParts = dateTimeParts[0].split('.');
              const timeParts = dateTimeParts[1].split(':');

              dateValue.setDate(dateParts[0]);
              dateValue.setMonth(dateParts[1] - 1);
              dateValue.setFullYear(dateParts[2]);
              dateValue.setHours(timeParts[0]);
              dateValue.setMinutes(timeParts[1]);

              if (!(dateValue - rangeMin > 0 && dateValue - rangeMax < 0)) {
                const minString = DateFormatter(rangeMin);
                const maxString = DateFormatter(rangeMax);
                message = `${intl.formatMessage({
                  id: 'RANGE_OF_VALUE'
                })}:\nMin: ${minString}\nMax: ${maxString}`;
              }
            });
            return message;
          }
          const rangeMin = new Date(range.min);
          const rangeMax = new Date(range.max);

          const dateValue = new Date();

          const dateTimeParts = vv.split(' ');
          const dateParts = dateTimeParts[0].split('.');
          const timeParts = dateTimeParts[1].split(':');

          dateValue.setDate(dateParts[0]);
          dateValue.setMonth(dateParts[1] - 1);
          dateValue.setFullYear(dateParts[2]);
          dateValue.setHours(timeParts[0]);
          dateValue.setMinutes(timeParts[1]);

          if (!(dateValue - rangeMin > 0 && dateValue - rangeMax < 0)) {
            const minString = DateFormatter(rangeMin);
            const maxString = DateFormatter(rangeMax);
            return `${intl.formatMessage({
              id: 'RANGE_OF_VALUE'
            })}:\nMin: ${minString}\nMax: ${maxString}`;
          }
        }
      }}
    >
      {(props) => {
        const { field, form, meta } = props;

        let dateValue = '';
        let timeValue = '';

        const initialValue =
          extraInputsList && extraInputsList.length > 0
            ? field.value[stateIndex]
            : field.value;
        let dateMode;
        let _value = null;

        switch (kind) {
          case 'fullDate':
            dateMode = 'date';
            dateValue = initialValue;
            if (dateValue) dateValue = dateValue && dateValue.split('.').reverse().toString();
            break;
          case 'fullDateAndTime':
            dateMode = 'datetime';
            if (initialValue) {
              const temp = initialValue.split(' ');
              dateValue = temp[0];
              if (dateValue) dateValue = dateValue.split('.').reverse().toString();
              timeValue = temp[1];
            }

            break;
          case 'monthAndYear':
            dateMode = 'date';
            dateValue = initialValue;
            if (dateValue) dateValue = dateValue.split('.').reverse().toString();
            break;
          case 'year':
            dateMode = 'date';
            dateValue = initialValue;
            if (dateValue) dateValue = dateValue.split('.').reverse().toString();
            break;
          case 'time':
            dateMode = 'time';
            timeValue = initialValue;
            dateValue = '2020';
            break;
          default:
            dateMode = 'date';
            dateValue = initialValue;
            if (dateValue) dateValue = dateValue.split('.').reverse().toString();
            break;
        }
        if (kind === 'time')
          _value = !timeValue ? null : new Date(`${dateValue} ${timeValue}`);
        else {
          _value = !dateValue ? null : new Date(`${dateValue} ${timeValue}`);
        }
        return (
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={3}>
              <InputLabel>
                {labelText}
                {required ? ' (*)' : ''}
              </InputLabel>
            </Grid>
            <Grid item xs={9}>
              <Paper elevation={3}>
                <Picker
                  clearable
                  disabled={disabled}
                  dateMode={dateMode}
                  //variant="inline"
                  inputVariant="outlined"
                  placeholder={hintText}
                  fullWidth
                  style={{ backgroundColor: '#fff' }}
                  //ampm={false}
                  value={_value}
                  format={getFormat(kind)}
                  views={getdivs(kind)}
                  error={!!meta.error}
                  helperText={meta.error}
                  minDate={minDate || new Date('1990')}
                  maxDate={maxDate || new Date('2071')}
                  onChange={(date) => {
                    let _date = null;
                    if (date) {
                      switch (kind) {
                        case 'fullDate':
                          _date = new Date(date).toLocaleDateString();
                          break;
                        case 'fullDateAndTime':
                          _date = new Date(date).toLocaleString().substr(0, 16);
                          break;
                        case 'monthAndYear':
                          _date = new Date(date)
                            .toLocaleDateString()
                            .substring(3);
                          break;
                        case 'year':
                          _date = new Date(date)
                            .toLocaleDateString()
                            .substring(6);
                          break;
                        case 'time':
                          _date = new Date(date)
                            .toLocaleTimeString()
                            .substr(0, 5);
                          break;
                        default:
                          _date = new Date(date)
                            .toLocaleDateString()
                            .substring(0, 2);
                          break;
                      }
                    }
                    form.setFieldValue(
                      extraInputsList && extraInputsList.length > 0
                        ? `${fieldName}.${stateIndex}`
                        : fieldName,
                      _date
                    );
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
        );
      }}
    </Field>
  );
};
