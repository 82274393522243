import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { updateNotificationFunction } from 'api';
import { authentication } from 'helpers/authentication';
import NotificationSettings from './NotificationSettings';

const DialogButton = styled(Button)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
`;

const NonificationModal = ({ showModal, openModal }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('0');
  const [mobileNotify, setMobileNotify] = useState('1');
  const [emailNotify, setEmailNotify] = useState('1');

  const handleApply = async () => {
    setLoading(true);
    const { id } = authentication.userValue;
    const result = await updateNotificationFunction(
      id,
      emailNotify,
      mobileNotify,
      value,
    );
    console.log('result', result);
    setLoading(false);
    openModal(false);
    return result;
  };

  return (
    <Dialog
      open={showModal}
      onClose={() => openModal(false)}
      maxWidth="sm"
      aria-labelledby="notif-form-dialog-title"
    >
      <DialogTitle id="notif-form-dialog-title">
        <FormattedMessage id="NOTIFICATION_SETTINGS" />
      </DialogTitle>
      <DialogContent dividers>
        <NotificationSettings
          value={value}
          setValue={setValue}
          mobileNotify={mobileNotify}
          setMobileNotify={setMobileNotify}
          emailNotify={emailNotify}
          setEmailNotify={setEmailNotify}
        />
      </DialogContent>
      <DialogActions>
        <DialogButton
          color="primary"
          disabled={loading}
          onClick={() => handleApply()}
        >
          <FormattedMessage id="APPLY" />
          {loading && <CircularProgress size="calc(1vh + 1vw)" />}
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default NonificationModal;
