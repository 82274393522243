import React from 'react';
import { Link } from 'react-router-dom';

import useWindowDimensions from '../../helpers/dimensions';

import mainLogo from '../../assets/header/mainLogo.png';
import smallLogo from '../../assets/header/smallLogo.png';

const MAIN_RATIO = 356 / 294;

const SMALL_RATIO = 268 / 147;

export default ({
  to, which, width: imgWidth, height: imgHeight, ...props
}) => {
  const { width } = useWindowDimensions();

  let logo;
  let _imgWidth;
  let _imgHeight;

  if (which === 'login') {
    logo = mainLogo;
  } else {
    logo = smallLogo;
  }

  if (!(imgWidth && imgHeight)) {
    _imgWidth = width / 6;
    if (logo === mainLogo) {
      _imgHeight = _imgWidth / MAIN_RATIO;
    } else {
      _imgHeight = _imgWidth / SMALL_RATIO;
    }
  } else {
    _imgWidth = imgWidth;
    _imgHeight = imgHeight;
  }

  if (to) {
    return <Link to={to}><img {...props} width={_imgWidth} height={_imgHeight} src={logo} alt="Logo" /></Link>;
  }
  return <img {...props} width={_imgWidth} height={_imgHeight} src={logo} alt="Logo" />;
};
