import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef
} from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
  Grid
} from '@material-ui/core';
import { getSiteSetsFunction } from 'api/sets/siteSets';
import { getStatusSetsFunction } from 'api/sets/statusSets';
import { getRevisionSetsFunction } from 'api/sets/revisionSets';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { useIntl, FormattedMessage } from 'react-intl';
import { dateFormat } from '../../helpers/DateFormatter';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4)
  },
  gridTitle: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.5)',
    lineHeight: '16.0px',
    margin: '0px !important'
  },
  gridTextColored: {
    fontWeight: 'bold',
    fontSize: 16,
    color: 'rgba(0, 145, 255, 1.0)',
    lineHeight: '22.0px'
  },
  gridText: {
    fontWeight: 'bold',
    fontSize: 16,
    color: 'rgba(89, 84, 105, 1.0)',
    lineHeight: '22.0px'
  }
}));

export const GridText = styled(TextField)`
  font-weight: bold;
  font-size: 16px;
  color: ${(props) =>
    props.sideColor ? props.sideColor : 'rgba(89, 84, 105, 1.0)'};
  line-height: 22px;
  margin: 0px !important;
`;
export const Option = styled(MenuItem)`
  font-weight: bold;
  font-size: 10px;
  color: ${(props) =>
    props.sideColor ? props.sideColor : 'rgba(89, 84, 105, 1.0)'} !important;
  line-height: 12px;
`;

function EditFormInfo(props, ref) {
  const [siteSets, setSiteSets] = useState([]);
  const [statusSets, setStatusSets] = useState([]);
  const [revisionSets, setRevisionSets] = useState([]);

  const classes = useStyles();

  const intl = useIntl();

  const { values, setValues, projectId, saveTemplate, isNewTemplate } = props;
  useEffect(() => {
    if (!!projectId) {
      try {
        getSiteSetsFunction(projectId).then((res) => setSiteSets(res));
        getStatusSetsFunction(projectId).then((res) => setStatusSets(res));
        getRevisionSetsFunction(projectId).then((res) => setRevisionSets(res));
      } catch (error) {}
    }
  }, [projectId]);

  const inputRef = useRef();
  useImperativeHandle(ref, () => inputRef.current);

  const setColor = (payload) => {
    switch (payload) {
      case 1:
        return '#1c97fa';

      case 2:
        return '#fa6300';
      default:
        return '#68d407';
    }
  };

  const formInfoSchema = Yup.object().shape({
    status: Yup.number()
      .nullable()
      .required(intl.formatMessage({ id: 'REQUIRED_STATUS' })),
    template_name: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: 'REQUIRED_TEMPLATE_NAME' }))
      .test(
        'test',
        intl.formatMessage({ id: 'TEMPLATE_NAME_CAN_NOT_BE_NEW' }),
        (value) => value !== 'new'
      ),
    id_number: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: 'REQUIRED_ID_NUMBER' })),
    site_set_id: Yup.number()
      .nullable()
      .required(intl.formatMessage({ id: 'REQUIRED_SITE_SET' })),
    revision_set_id: Yup.number()
      .nullable()
      .required(intl.formatMessage({ id: 'REQUIRED_REVISION_SET' })),
    status_set_id: Yup.number()
      .nullable()
      .required(intl.formatMessage({ id: 'REQUIRED_STATUS_SET' })),
    site_level: Yup.number()
      .nullable()
      .required(intl.formatMessage({ id: 'REQUIRED_SITE_LEVEL' })),
    prefix: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: 'REQUIRED_PREFIX' })),
    counter_format: Yup.string()
      .trim()
      .required(intl.formatMessage({ id: 'REQUIRED_COUNTER_FORMAT' }))
  });

  useEffect(
    () => () => {
      setValues(inputRef.current.values);
    },
    []
  );

  return (
    <>
      <Formik
        initialValues={values}
        validationSchema={formInfoSchema}
        enableReinitialize
        innerRef={inputRef}
        onSubmit={(values, { setSubmitting }) => {
          saveTemplate(values).then(() => setSubmitting(false));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
          /* and other goodies */
        }) => (
          <Form id="form-info-editor" onSubmit={handleSubmit}>
            <Grid
              container
              spacing={1}
              style={{ textAlign: 'left', padding: '0px' }}
            >
              <Grid item xs={12} sm={6}>
                <div className={`${classes.flexRower} ${classes.leftAlign}`}>
                  <div className={classes.gridTitle}>
                    <FormattedMessage id="STATUS" />
                  </div>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={errors.status && touched.status}
                  >
                    <Select
                      margin="dense"
                      variant="outlined"
                      name="status"
                      value={values.status}
                      SelectDisplayProps={{
                        style: { color: setColor(values.status) }
                      }}
                      fullWidth
                      onChange={handleChange}
                    >
                      <Option value={1} sideColor="#1c97fa">
                        {' '}
                        DRAFT{' '}
                      </Option>
                      <Option value={2} sideColor="#fa6300">
                        {' '}
                        INACTIVE{' '}
                      </Option>
                      <Option value={3} sideColor="#68d407">
                        {' '}
                        PUBLISHED{' '}
                      </Option>
                    </Select>
                    <FormHelperText>
                      {touched.status && errors.status}
                    </FormHelperText>
                  </FormControl>
                </div>
              </Grid>
              {isNewTemplate ? null : (
                <>
                  <Grid item xs={6} sm={6}>
                    <div
                      className={`${classes.flexRower} ${classes.leftAlign}`}
                    >
                      <div className={classes.gridTitle}>
                        <FormattedMessage id="CREATED_AT" />
                      </div>
                      <GridText
                        name="created_at"
                        variant="outlined"
                        margin="dense"
                        value={dateFormat(
                          values.created_at,
                          'DD.MM.YYYY HH:mm'
                        )}
                        fullWidth
                        disabled
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div
                      className={`${classes.flexRower} ${classes.leftAlign}`}
                    >
                      <div className={classes.gridTitle}>
                        <FormattedMessage id="VERSION" />
                      </div>
                      <GridText
                        name="version"
                        variant="outlined"
                        margin="dense"
                        value={values.version}
                        fullWidth
                        disabled
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div
                      className={`${classes.flexRower} ${classes.leftAlign}`}
                    >
                      <div className={classes.gridTitle}>
                        <FormattedMessage id="CREATED_BY" />
                      </div>
                      <GridText
                        name="created_by"
                        variant="outlined"
                        margin="dense"
                        value={values.created_by}
                        fullWidth
                        disabled
                      />
                    </div>
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={6}>
                <div className={`${classes.flexRower} ${classes.leftAlign}`}>
                  <div className={classes.gridTitle}>
                    <FormattedMessage id="ID_NUMBER" />
                  </div>
                  <GridText
                    name="id_number"
                    variant="outlined"
                    margin="dense"
                    value={values.id_number}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.id_number && touched.id_number}
                    helperText={touched.id_number && errors.id_number}
                  />
                </div>
              </Grid>
              {!isNewTemplate && (
                <Grid item xs={12} sm={6}>
                  <div className={`${classes.flexRower} ${classes.leftAlign}`}>
                    <div className={classes.gridTitle}>
                      <FormattedMessage id="UPDATED_AT" />
                    </div>
                    <GridText
                      name="updated_at"
                      variant="outlined"
                      margin="dense"
                      value={dateFormat(values.updated_at, 'DD.MM.YYYY HH:mm')}
                      fullWidth
                      disabled
                    />
                  </div>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <div className={`${classes.flexRower} ${classes.leftAlign}`}>
                  <div className={classes.gridTitle}>
                    <FormattedMessage id="FORM_NAME" />
                  </div>
                  <GridText
                    name="template_name"
                    variant="outlined"
                    margin="dense"
                    value={values.template_name}
                    error={errors.template_name && touched.template_name}
                    helperText={touched.template_name && errors.template_name}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Grid>
              {!isNewTemplate && (
                <Grid item xs={12} sm={6}>
                  <div className={`${classes.flexRower} ${classes.leftAlign}`}>
                    <div className={classes.gridTitle}>
                      <FormattedMessage id="UPDATED_BY" />
                    </div>
                    <GridText
                      name="updated_by"
                      variant="outlined"
                      margin="dense"
                      value={values.updated_by}
                      fullWidth
                      disabled
                    />
                  </div>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <div className={`${classes.flexRower} ${classes.leftAlign}`}>
                  <div className={classes.gridTitle}>
                    <FormattedMessage id="SITE_SET" />
                  </div>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={errors.site_set_id && touched.site_set_id}
                  >
                    <Select
                      margin="dense"
                      name="site_set_id"
                      value={values.site_set_id}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {siteSets.map((set) => (
                        <Option value={set.id}> {set.set_name} </Option>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.site_set_id && errors.site_set_id}
                    </FormHelperText>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={`${classes.flexRower} ${classes.leftAlign}`}>
                  <div className={classes.gridTitle}>
                    <FormattedMessage id="SITE_LEVEL" />
                  </div>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={errors.site_level && touched.site_level}
                  >
                    <Select
                      margin="dense"
                      variant="outlined"
                      name="site_level"
                      value={values.site_level}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <Option value={1}>
                        {' '}
                        <FormattedMessage id="LEVEL" /> 1{' '}
                      </Option>
                      <Option value={2}>
                        {' '}
                        <FormattedMessage id="LEVEL" /> 2{' '}
                      </Option>
                      <Option value={3}>
                        {' '}
                        <FormattedMessage id="LEVEL" /> 3{' '}
                      </Option>
                      <Option value={4}>
                        {' '}
                        <FormattedMessage id="LEVEL" /> 4{' '}
                      </Option>
                      <Option value={5}>
                        {' '}
                        <FormattedMessage id="LEVEL" /> 5{' '}
                      </Option>
                    </Select>
                    <FormHelperText>
                      {touched.site_level && errors.site_level}
                    </FormHelperText>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={`${classes.flexRower} ${classes.leftAlign}`}>
                  <div className={classes.gridTitle}>
                    <FormattedMessage id="STATUS_SET" />
                  </div>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={errors.status_set_id && touched.status_set_id}
                  >
                    <Select
                      margin="dense"
                      variant="outlined"
                      name="status_set_id"
                      value={values.status_set_id}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {statusSets.map((set) => (
                        <Option value={set.id}> {set.statu_name} </Option>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.status_set_id && errors.status_set_id}
                    </FormHelperText>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={`${classes.flexRower} ${classes.leftAlign}`}>
                  <div className={classes.gridTitle}>
                    <FormattedMessage id="REVISION_SET" />
                  </div>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={errors.revision_set_id && touched.revision_set_id}
                  >
                    <Select
                      margin="dense"
                      variant="outlined"
                      name="revision_set_id"
                      value={values.revision_set_id}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {revisionSets.map((set) => (
                        <Option value={set.id}> {set.revision_name} </Option>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.revision_set_id && errors.revision_set_id}
                    </FormHelperText>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={`${classes.flexRower} ${classes.leftAlign}`}>
                  <div className={classes.gridTitle}>Ön Ek</div>
                  <GridText
                    name="prefix"
                    variant="outlined"
                    margin="dense"
                    value={values.prefix}
                    fullWidth
                    onChange={handleChange}
                    error={errors.prefix && touched.prefix}
                    helperText={touched.prefix && errors.prefix}
                    onBlur={handleBlur}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={`${classes.flexRower} ${classes.leftAlign}`}>
                  <div className={classes.gridTitle}>
                    <FormattedMessage id="COUNTER_FORMAT" />
                  </div>
                  <GridText
                    name="counter_format"
                    variant="outlined"
                    margin="dense"
                    value={values.counter_format}
                    fullWidth
                    onChange={handleChange}
                    error={errors.counter_format && touched.counter_format}
                    helperText={touched.counter_format && errors.counter_format}
                    onBlur={handleBlur}
                  />
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default forwardRef(EditFormInfo);
