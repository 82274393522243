import React, { useEffect, useRef, useState } from 'react';
import {
  Grid, RadioGroup, FormControlLabel, Radio, Divider, Select, Checkbox,
} from '@material-ui/core';
import styled from 'styled-components';

import { useSelector, useDispatch } from 'react-redux';
import {
  templatesSelector, projectsSelector, fetchTemplates, formsSelector,
} from 'slices';
import { ErrorMessage } from 'formik';

import { MdRadioButtonUnchecked, MdRadioButtonChecked } from 'react-icons/md';

import { TimePicker } from '@material-ui/pickers';

import { useIntl } from 'react-intl';

import { authentication } from 'helpers/authentication';
import { Option } from '../ProjectForms/EditFormInfo';

const Title = styled.p`
  font-size: 12px;
  margin: 3px;
  padding: 5px;
  font-weight: bold;
  color: #777;
`;

const Options = (props) => {
  const intl = useIntl();

  const auth = authentication.userValue;

  const { values, handleChange } = props;

  const [selectedColor, setSelectedColor] = useState('#000');

  const [templatesList, setTemplatesList] = useState([]);

  const { form_template } = values;

  const { templates } = useSelector(templatesSelector);

  const { selectedProject } = useSelector(projectsSelector);
  const { selectedSiteSet } = useSelector(formsSelector);

  const dispatch = useDispatch();

  const ref = useRef(true);

  // fetch template list for selected project
  const _fetchTemplates = async (project_id) => {
    dispatch(fetchTemplates());
  };

  useEffect(() => {
    if (selectedProject && !templates.length) {
      _fetchTemplates(selectedProject.id, ref.current);
    }
    return () => {
      ref.current = false;
    };
  }, []);


  useEffect(() => {
    if (selectedProject) {
      const _templates = templates
        .filter((template) => template.drafts.some((draft) => draft.project_id == selectedProject.id));
      setTemplatesList(_templates);
    }
  }, [templates, selectedProject]);

  // Set selected form templte color
  useEffect(() => {
    if (templatesList.length && form_template) {
      const selected_template = templatesList.find((temp) => temp.id === form_template);
      selected_template && setSelectedColor(setColor(selected_template.status));
    }
  }, [form_template, templatesList]);

  useEffect(() => {
  }, [templatesList]);

  const setColor = (payload) => {
    switch (payload) {
      case 1:
        return '#1c97fa';

      case 2:
        return '#fa6300';
      default:
        return '#68d407';
    }
  };

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={12}>
        <RadioGroup
          aria-label="manual-automatic"
          name="type"
          id="type"
          value={values.type}
          onChange={(handleChange)}
        >
          <FormControlLabel value="manuel" control={<Radio color="default" />} label="Manuel" />
          <FormControlLabel value="automatic" control={<Radio color="default" />} label="Otomatik" />
        </RadioGroup>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Title>{intl.formatMessage({ id: 'FORM_TEMPLATES' }).toUpperCase()} </Title>
        <Select
          margin="dense"
          variant="outlined"
          name="form_template"
          id="form_template"
          value={values.form_template}
          SelectDisplayProps={{ style: { color: selectedColor } }}
          fullWidth
          onChange={handleChange}
        >
          {
            templatesList
              .filter((el) => (el.drafts[0].site_set_id === selectedSiteSet.id && el.status === 3))
              .filter((el) => auth.groups.some((ag) => el.drafts[0].template_group_views.find((g) => g.group_id === ag.group_id)))
              .map((template) => (
                <Option
                  key={`task-template-options-${template.id}`}
                  value={template.id}
                  sideColor={setColor(template.status)}
                >
                  {template.template_name}
                </Option>
              ))
          }
        </Select>
        <ErrorMessage
          name="form_name"
          component="div"
          render={(msg) => <div style={{ color: 'red', marginTop: '8px' }}>{msg}</div>}
        />
        <Divider style={{ marginTop: '15px' }} />
      </Grid>
      <Grid container xs={12}>
        <Grid item xs={3}>
          <RadioGroup
            aria-label="interval"
            name="interval"
            id="interval"
            value={values.type}
            onChange={(handleChange)}
          >
            <FormControlLabel value="daily" control={<Radio color="default" />} label="Daily" />
            <FormControlLabel value="weekly" control={<Radio color="default" />} label="Weekly" />
            <FormControlLabel value="monthly" control={<Radio color="default" />} label="Monthly" />
          </RadioGroup>

        </Grid>
        <Grid
          container
          xs={5}
          style={{ borderLeft: '2px solid #aaa', paddingLeft: '15px' }}
          direction="column"
          wrap="nowrap"
        >

          <FormControlLabel

            control={(
              <Checkbox
                name="monday"
                id="monday"
                checked={values.monday}
                onChange={handleChange}
                icon={<MdRadioButtonUnchecked />}
                checkedIcon={<MdRadioButtonChecked />}
                color="default"
              />
            )}
            label="Pazartesi"
          />
          <FormControlLabel

            control={(
              <Checkbox
                name="tuesday"
                id="tuesday"
                checked={values.tuesday}
                onChange={handleChange}
                icon={<MdRadioButtonUnchecked />}
                checkedIcon={<MdRadioButtonChecked />}
                color="default"
              />
            )}
            label="Salı"
          />
          <FormControlLabel

            control={(
              <Checkbox
                name="wednesday"
                id="wednesday"
                checked={values.wednesday}
                onChange={handleChange}
                icon={<MdRadioButtonUnchecked />}
                checkedIcon={<MdRadioButtonChecked />}
                color="default"
              />
            )}
            label="Çarşamba"
          />
          <FormControlLabel

            control={(
              <Checkbox
                name="thursday"
                id="thursday"
                checked={values.thursday}
                onChange={handleChange}
                icon={<MdRadioButtonUnchecked />}
                checkedIcon={<MdRadioButtonChecked />}
                color="default"
              />
            )}
            label="Perşembe"
          />
          <FormControlLabel

            control={(
              <Checkbox
                name="friday"
                id="friday"
                checked={values.friday}
                onChange={handleChange}
                icon={<MdRadioButtonUnchecked />}
                checkedIcon={<MdRadioButtonChecked />}
                color="default"
              />
            )}
            label="Cuma"
          />
          <FormControlLabel

            control={(
              <Checkbox
                name="saturday"
                id="saturday"
                checked={values.saturday}
                onChange={handleChange}
                icon={<MdRadioButtonUnchecked />}
                checkedIcon={<MdRadioButtonChecked />}
                color="default"
              />
            )}
            label="Cumartesi"
          />
          <FormControlLabel

            control={(
              <Checkbox
                name="sunday"
                id="sunday"
                checked={values.sunday}
                onChange={handleChange}
                icon={<MdRadioButtonUnchecked />}
                checkedIcon={<MdRadioButtonChecked />}
                color="default"
              />
            )}
            label="Pazar"
          />
        </Grid>

        <Grid item xs={3} direction="row">
          <FormControlLabel
            labelPlacement="start"
            control={(
              <TimePicker
                ampm={false}
                style={{ width: '80px', marginRight: '10px' }}
                margin="dense"
                id="time"
                name="time"
                value={values.time}
                onChange={handleChange}
                variant="inline"
                inputVariant="outlined"
              />
            )}
            label="Saat"
          />


        </Grid>
      </Grid>
    </Grid>
  );
};

export default Options;
