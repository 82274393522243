import React, { useEffect } from 'react';
import {
  Dialog, DialogTitle, IconButton, DialogContent, DialogActions, TextField, Button, Grid, CircularProgress,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjects, projectsSelector } from 'slices';
import { FormattedMessage, useIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
  dialogGrid: {
    margin: '0px',
    padding: '0px',
    '& > p': {
      fontSize: '12px',
      margin: '3px',
      marginTop: '0px',
      padding: '0px',
      fontWeight: 'bold',
      color: '#555',
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  dialogButton: {
    margin: '5px 15px',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
}));

function CompanyForm(props) {
  const {
    showModal, setShowModal, loading, submitFunc, dialogTitle,
  } = props;

  const { initialValues } = props;

  const { projects, loading: { getProjetcs } } = useSelector(projectsSelector);
  const classes = useStyles();

  const dispatch = useDispatch();

  const intl = useIntl();

  useEffect(() => {
    if (!projects.length && !getProjetcs) {
      dispatch(fetchProjects());
    }
  }, [dispatch, projects]);

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    company_name: Yup.string()
      .required(<FormattedMessage id="REQUIRED_COMPANY_NAME" />),
    short_name: Yup.string()
      .required(<FormattedMessage id="REQUIRED_COMPANY_SHORT_NAME" />),
    telephone: Yup.string()
      .required(<FormattedMessage id="REQUIRED_PHONE" />)
      .matches(phoneRegExp, <FormattedMessage id="INVALID_PHONE" />),
    contact_email: Yup.string()
      .email(<FormattedMessage id="INVALID_MAIL" />)
      .required(<FormattedMessage id="REQUIRED_MAIL" />),
    web: Yup.string()
      .url(<FormattedMessage id="INVALID_URL" />)
      .required(<FormattedMessage id="REQUIRED_URL" />),
    address: Yup.string()
      .required(<FormattedMessage id="REQUIRED_ADDRESS" />),
  });

  return (
    <Dialog
      open={showModal || loading}
      onClose={() => {
        window.confirm(
          intl.formatMessage({ id: 'UNSAVED_FORM_ALERT' }),
        ) && setShowModal(false);
      }}
      aria-labelledby="form-dialog-title"
      fullWidth
      scroll="body"
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        <div className={classes.dialogTitle}>
          {dialogTitle}
          <IconButton onClick={() => setShowModal(false)}>
            <AiOutlineCloseCircle />
          </IconButton>
        </div>
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          submitFunc(values);
          setSubmitting(loading);
        }}
      >
        {({
          values,
          handleChange,
          handleReset,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            <DialogContent dividers>
              <Grid container spacing={3} alignItems="flex-start">
                <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
                  <p className={classes.upperCase}><FormattedMessage id="COMPANY_NAME" /> </p>
                  <TextField
                    id="company_name_id"
                    name="company_name"
                    onChange={handleChange}
                    value={values.company_name}
                    placeholder={intl.formatMessage({ id: 'ENTER_COMPANY_NAME' })}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                  <ErrorMessage
                    name="company_name"
                    component="div"
                    render={(msg) => <div style={{ color: 'red', marginTop: '8px' }}>{msg}</div>}
                  />
                </Grid>
                <Grid item xs={12} sm={6} classes={{ root: classes.dialogGrid }}>
                  <p className={classes.upperCase}><FormattedMessage id="COMPANY_SHORT_NAME" /></p>
                  <TextField
                    id="short_name"
                    name="short_name"
                    onChange={handleChange}
                    value={values.short_name || ''}
                    placeholder={intl.formatMessage({ id: 'ENTER_COMPANY_SHORT_NAME' })}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  <ErrorMessage
                    name="short_name"
                    component="div"
                    render={(msg) => <div style={{ color: 'red', marginTop: '8px' }}>{msg}</div>}
                  />
                </Grid>
                <Grid item xs={12} sm={6} classes={{ root: classes.dialogGrid }}>
                  <p className={classes.upperCase}><FormattedMessage id="PHONE_NUMBER" /> </p>
                  <TextField
                    id="telephone"
                    name="telephone"
                    onChange={handleChange}
                    value={values.telephone || ''}
                    placeholder={intl.formatMessage({ id: 'ENTER_PHONE_NUMBER' })}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  <ErrorMessage
                    name="telephone"
                    component="div"
                    render={(msg) => <div style={{ color: 'red', marginTop: '8px' }}>{msg}</div>}
                  />
                </Grid>
                <Grid item xs={12} sm={6} classes={{ root: classes.dialogGrid }}>
                  <p className={classes.upperCase}><FormattedMessage id="CONTACT_MAIL" /> </p>
                  <TextField
                    id="contact_email"
                    name="contact_email"
                    onChange={handleChange}
                    value={values.contact_email || ''}
                    placeholder={intl.formatMessage({ id: 'ENTER_CONTACT_MAIL' })}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  <ErrorMessage
                    name="contact_email"
                    component="div"
                    render={(msg) => <div style={{ color: 'red', marginTop: '8px' }}>{msg}</div>}
                  />
                </Grid>
                <Grid item xs={12} sm={6} classes={{ root: classes.dialogGrid }}>
                  <p className={classes.upperCase}><FormattedMessage id="WEB_ADDRESS" /> </p>
                  <TextField
                    id="web"
                    name="web"
                    onChange={handleChange}
                    value={values.web || ''}
                    placeholder={intl.formatMessage({ id: 'ENTER_WEB_ADDRESS' })}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  <ErrorMessage
                    name="web"
                    component="div"
                    render={(msg) => <div style={{ color: 'red', marginTop: '8px' }}>{msg}</div>}
                  />
                </Grid>
                <Grid item xs={12} my={0} classes={{ root: classes.dialogGrid }}>
                  <p className={classes.upperCase}><FormattedMessage id="COMPANY_ADDRESS" /> </p>
                  <TextField
                    id="address"
                    name="address"
                    onChange={handleChange}
                    value={values.address || ''}
                    placeholder={intl.formatMessage({ id: 'ENTER_COMPANY_ADDRESS' })}
                    variant="outlined"
                    size="small"
                    multiline
                    rows={4}
                    fullWidth
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    render={(msg) => <div style={{ color: 'red', marginTop: '8px' }}>{msg}</div>}
                  />
                </Grid>
                <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
                  <p className={classes.upperCase}><FormattedMessage id="PROJECTS" /></p>
                  <Autocomplete
                    multiple
                    id="size-small-outlined-multi"
                    size="small"
                    options={projects}
                    value={values.company_projects}
                    getOptionSelected={(option, value) => option.id === (value.project_id || value.id)}
                    getOptionLabel={(option) => option.project_name}
                    onChange={(event, value) => {
                      setFieldValue('company_projects', value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={intl.formatMessage({ id: 'ENTER_PROJECTS_OF_COMPANY' })}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div className={classes.dialogTitle}>
                <Button
                  onClick={() => {
                    window.confirm(
                      intl.formatMessage({ id: 'UNSAVED_FORM_ALERT' }),
                    ) && setShowModal(false);
                  }}
                  variant="contained"
                  className={classes.dialogButton}
                >
                  <FormattedMessage id="CANCEL" />
                </Button>
                <div>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    className={classes.dialogButton}
                  >
                    {loading.addCompany && (
                    <CircularProgress size="sm" color="inherit" />
                    )}
                    <FormattedMessage id="SAVE" />
                  </Button>
                </div>
              </div>
            </DialogActions>
          </Form>
        )}

      </Formik>

    </Dialog>
  );
}

export default CompanyForm;
