import React from 'react';
import { FormattedMessage } from 'react-intl';
import { findRoleByIdFunc } from '../../api';
import { Attributes, getType } from './EditFormStructure';

async function setRoleName(formData) {
  for (const form of formData) {
    if (form.role_id) {
      const role_name = await findRoleByIdFunc(form.role_id);
      form.role_name = role_name;
    }
  }
  return formData;
}

export default (props) => {
  const { template } = props;
  let { template: formData } = template;

  if (typeof formData === 'string') { formData = JSON.parse(formData); }

  // let _formData = setRoleName({...formData});

  const forms = formData.subForms.map((form, formIndex) => (
    <div
      key={`${form.name}`}
      style={{
        borderRadius: '5px',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'rgba(250, 100, 0, 1.0)',
        padding: '18px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
            fontSize: '14px',
            color: 'rgba(250, 100, 0, 1.0)',
            textAlign: 'left',
            lineHeight: '17.0px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          <FormattedMessage id="SUB_FORM" />: {form.name}
        </span>
        {!!form.role_id
        && (
          <span
            style={{
              fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
              fontSize: '14px',
              color: 'rgba(250, 100, 0, 1.0)',
              textAlign: 'left',
              lineHeight: '17.0px',
              fontWeight: 'bold',
            }}
          >
            <FormattedMessage id="COMPANY_ROLE" />: {form.role_name}
          </span>
        )
        }


      </div>


      {form.controls.map((control, controlIndex) => {
        if (control.type === 'group') {
          return (
            <div
              style={{
                borderRadius: '5px',
                borderStyle: 'solid',
                borderWidth: '2px',
                borderColor: 'rgba(182, 32, 224, 1.0)',
                padding: '18px',
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span
                style={{
                  fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
                  fontSize: '14px',
                  color: 'rgba(182, 32, 224, 1.0)',
                  textAlign: 'left',
                  lineHeight: '17.0px',
                  fontWeight: 'bold',
                }}
              >
                TEKRARLANABİLİR
              </span>
              {control.children.map((element) => (
                <div
                  key={`${form.name}-${control.name}-${element.name}`}
                  style={{
                    borderRadius: '5px',
                    borderStyle: 'solid',
                    borderWidth: '2px',
                    borderColor: 'rgba(109, 114, 120, 1.0)',
                    padding: '18px',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span
                    style={{
                      fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
                      fontSize: '14px',
                      color: 'rgba(109, 114, 120, 1.0)',
                      textAlign: 'left',
                      lineHeight: '17.0px',
                      fontWeight: 'bold',
                    }}
                  >
                    FORM ALANI

                    <Attributes>
                      {getType(element.type)}
                    </Attributes>
                    {element.readOnly && (
                      <Attributes>
                        Salt Okunur
                      </Attributes>
                    )}
                    {element.required && (
                      <Attributes required>
                        Zorunlu Alan*
                      </Attributes>
                    )}
                  </span>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span
                      style={{
                        fontFamily:
                              'Helvetica-Bold, Helvetica, Arial, serif',
                        fontSize: '24.0px',
                        color: 'rgba(109, 114, 120, 1.0)',
                        textAlign: 'center',
                        lineHeight: '50.0px',
                      }}
                    >
                      {element.hint['0'] ? element.hint['0'] : ''}
                    </span>

                    <span
                      style={{
                        fontFamily:
                              'Helvetica-Bold, Helvetica, Arial, serif',
                        fontSize: '20.0px',
                        color: 'rgba(109, 114, 120, 1.0)',
                        textAlign: 'center',
                        lineHeight: '17.0px',
                      }}
                    >
                      {element.name}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          );
        }
        return (
          <div
            key={`${form.name}-${control.name}`}
            style={{
              borderRadius: '5px',
              borderStyle: 'solid',
              borderWidth: '2px',
              borderColor: 'rgba(109, 114, 120, 1.0)',
              padding: '18px',
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              style={{
                fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
                fontSize: '14px',
                color: 'rgba(109, 114, 120, 1.0)',
                textAlign: 'left',
                lineHeight: '17.0px',
                fontWeight: 'bold',
              }}
            >
              FORM ALANI

              <Attributes>
                {getType(control.type)}
              </Attributes>
              {control.readOnly && (
                <Attributes>
                  Salt Okunur
                </Attributes>
              )}
              {control.required && (
                <Attributes required>
                  Zorunlu Alan*
                </Attributes>
              )}
            </span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span
                style={{
                  fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
                  fontSize: '24.0px',
                  color: 'rgba(109, 114, 120, 1.0)',
                  textAlign: 'center',
                  lineHeight: '50.0px',
                }}
              >
                {control.hint['0'] ? control.hint['0'] : ''}
              </span>

              <span
                style={{
                  fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
                  fontSize: '20.0px',
                  color: 'rgba(109, 114, 120, 1.0)',
                  textAlign: 'center',
                  lineHeight: '17.0px',
                }}
              >
                {control.name}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  ));
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '60vh',
          overflowX: 'auto',
        }}
      >
        {forms}
      </div>
    </div>
  );
};
