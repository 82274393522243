import React, {
  useState, useEffect, Fragment, useRef,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

/* import {
  FormEditorContainer, TitleContainer, MainContainer,
  HistoryChip, CancelButton, ResetButton, SaveButton,
  EditButton, ContentTitle, ContentLink, HistoryListContainer,  History
} from '../../styles/common'; */


import { Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  mailsSelector, getInboxMails, getSendboxMails, projectsSelector, companiesSelector,
} from 'slices';
import { authentication } from 'helpers/authentication';
import { ProgressContainer } from 'components/Common/ProgressCircle';
import { NoData } from 'components/Forms/LeftContent';
import DataLoading from 'components/Common/DataLoading';
import { FormattedMessage } from 'react-intl';
import MailTable from './MailTable';
import { StyledTab, StyledTabs, TabPanel } from '../Common';
import { tableHeads as sendHeads, tableBody as sendBody } from './sendBox';
import { tableHeads as fromHeads, tableBody as fromBody } from './fromBox';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },

  rightside: {
    marginLeft: theme.spacing(1),
    minHeight: '75vh',
  },
  paper: {
    flex: 1,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(3),
    height: '100%',
  },
  actions: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    alignContent: 'center',
    height: '8%',
    padding: theme.spacing(1),
  },
  header: {
    height: theme.spacing(3),
    flex: 1,
    fontFamily: 'Helvetica, Helvetica, Arial, serif',
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.5)',
    textAlign: 'center',
    lineHeight: '17.0px',
    borderBottom: `${theme.spacing(0.6)}px solid rgba(250, 100, 0, 1)`,
  },
}));


const MailList = (props) => {
  const [tabIndex, setTabIndex] = useState(0);

  const classes = useStyles();

  const history = useHistory();

  const mountedRef = useRef(true);

  const dispatch = useDispatch();

  const { id: user_id } = authentication.userValue;

  const { selectedProject, projects } = useSelector(projectsSelector);
  const { companies } = useSelector(companiesSelector);

  const {
    inBox, sendBox, loading, hasErrors,
  } = useSelector(mailsSelector);

  async function fetchMails(project_id) {
    dispatch(getInboxMails(project_id, user_id));
    dispatch(getSendboxMails(project_id, user_id));
  }

  useEffect(() => {
    if (selectedProject) {
      fetchMails(selectedProject.id, mountedRef.current);
    }
    return () => {
      mountedRef.current = false;
    };
  }, [selectedProject]);

  // Handle Tab Chjange For Right Side
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    // setInEditPage(0);
    // ref.current.scrollTo(0, 0);
  };


  const handleRow = (id) => {
    history.push(`/mails/${id}/${tabIndex ? 'sendBox' : 'inBox'}`);
  };


  return (
    <Paper className={`${classes.paper} ${classes.rightside}`}>
      <StyledTabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="mail-tabs"
      >
        <StyledTab label={<span style={{ textTransform: 'uppercase' }}> <FormattedMessage id="INBOX" /></span>} />
        <StyledTab label={<span style={{ textTransform: 'uppercase' }}> <FormattedMessage id="SENDBOX" /></span>} />

      </StyledTabs>
      <TabPanel value={tabIndex} index={0} fullWidth>
        {
          !inBox.length
            ? (
              <DataLoading
                loading={loading.getInbox}
                error={hasErrors.getInbox}
                selectedProject={selectedProject}
                list={inBox}
              />
            )
            : (
              <MailTable
                box="in"
                list={inBox}
                tableHeads={fromHeads}
                tableBody={fromBody}
                handleRow={handleRow}
                projects={projects}
              />
            )


        }

      </TabPanel>
      <TabPanel value={tabIndex} index={1} fullWidth>
        {
          !sendBox.length
            ? (
              <DataLoading
                loading={loading.getSendbox}
                error={hasErrors.getSendbox}
                selectedProject={selectedProject}
                list={sendBox}
              />
            )
            : (
              <MailTable
                box="send"
                list={sendBox}
                tableHeads={sendHeads}
                tableBody={sendBody}
                handleRow={handleRow}
                projects={projects}

              />
            )
        }


      </TabPanel>

    </Paper>
  );
};

export default MailList;
