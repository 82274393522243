import React from 'react';
import styled from 'styled-components';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const ComtentContainer = styled.div`
  width: 100%;
  height: auto;
  padding-left: 12px;
  padding-right: 12px;
  background-color: rgba(245, 245, 245, 1);
`;
const ContentTitleContainer = styled.div`
  background-color: rgba(255, 255, 255, 0);
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: rotate(0deg);
  opacity: 1;
  font-size: 22px;
  color: rgba(89, 84, 105, 1);
  line-height: 26px;
`;

const ContentTitle = styled.span`
  font-size: 22px;
  line-height: 25px;
  align-self: center;
  color: #595469;
`;

const ContentLink = styled.span`
font-size: 22px;
line-height: 25px;
align-self: center;
  color: #fa6400;
`;

export default React.forwardRef((props, ref) => {
  const { breadcrumbs } = props;
  let { containerName, itemName } = props;

  if (breadcrumbs && breadcrumbs.length > 0) {
    containerName = (
      <Breadcrumbs separator={<>&gt;</>} aria-label="breadcrumb">
        {breadcrumbs.map((breadcrump) => (
          <Link key={breadcrump.to} component={RouterLink} to={breadcrump.to}>
            <ContentLink>{breadcrump.name}</ContentLink>
          </Link>
        ))}
        <ContentTitle>{itemName || 'Test Form'}</ContentTitle>
      </Breadcrumbs>
    );
  }

  return (
    <>
      <ComtentContainer id="content-comtainer" ref={ref}>
        <ContentTitleContainer>
          <ContentTitle>{containerName}</ContentTitle>
        </ContentTitleContainer>

        {props.children}
      </ComtentContainer>
    </>
  );
});
