import React, { useState } from 'react';
import styled from 'styled-components';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import TablePagination from '@material-ui/core/TablePagination';

import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import headerLogo from '../../assets/header/smallLogo.png';

const TableContainerStyled = styled(TableContainer)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
`;

const TableCellStyled = styled(TableCell)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #8a849b !important;
  display: ${(props) => (props.id ? 'flex !important' : null)};
  justify-content: ${(props) => (props.id ? 'center !important' : null)};
  align-items: ${(props) => (props.id ? 'center !important' : null)};
  text-transform: uppercase;
`;

const StyledAvatar = withStyles((thema) => ({
  img: {
    height: thema.spacing(4),
    width: 'auto',
  },
  root: {
    height: thema.spacing(8),
    width: thema.spacing(8),
  },
}))(Avatar);

function CompanyTable(props) {
  const { companyList, handleCompany } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainerStyled component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCellStyled align="center" width="60px">
                #
              </TableCellStyled>
              <TableCellStyled>
                <FormattedMessage id="COMPANY_NAME" />
              </TableCellStyled>
              <TableCellStyled align="center">
                <FormattedMessage id="USERS" />
              </TableCellStyled>
              <TableCellStyled align="center">
                <FormattedMessage id="GROUPS" />
              </TableCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              companyList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((company, index) => (
                <TableRow key={`${company.company_name}-${index}`} onClick={() => handleCompany(company.id)} hover>
                  <TableCellStyled align="center" id width="80px">
                    <StyledAvatar
                      src={company.logo !== '' ? company.logo : headerLogo}
                      alt={company.company_name}
                    />
                  </TableCellStyled>
                  <TableCellStyled>
                    {company.company_name}
                  </TableCellStyled>
                  <TableCellStyled align="center">
                    {company.user_count}
                  </TableCellStyled>
                  <TableCellStyled align="center">
                    {company.group_count}
                  </TableCellStyled>

                </TableRow>
              ))}
          </TableBody>

        </Table>
      </TableContainerStyled>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={companyList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

export default CompanyTable;
