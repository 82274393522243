import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { FiPlusCircle } from 'react-icons/fi';
import { RiEqualizerLine } from 'react-icons/ri';

import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { FormattedMessage } from 'react-intl';

import AlertDialog from 'components/Common/AlertDialog';
import {
  Metadata,
  MetadataContainer,
  Text,
  Icon
} from '../../styles/forms/formsStyle';

import {
  templatesSelector,
  fetchTemplates,
  projectsSelector,
  formsSelector,
  findByMetadaIdtIdTemplate,
  removeMetadataSet
} from '../../slices';

import SortableTable from '../Table/SortableTable';
import LeftContent from './LeftContent';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4)
  },
  paper: {
    flex: 1
  },
  dialogGrid: {
    margin: '0px',
    padding: '0px',
    '& > p': {
      fontSize: '12px',
      margin: '3px',
      marginTop: '0px',
      padding: '0px',
      fontWeight: 'bold',
      color: '#555'
    }
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  dialogButton: {
    margin: '5px 15px'
  }
}));

const FormEditorContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(245, 245, 245, 1);
`;

const TitleContainer = styled.div`
  background-color: rgba(255, 255, 255, 0);
  height: auto;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 30px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Helvetica', Helvetica, Arial, serif;
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(89, 84, 105, 1);
  line-height: 26px;
`;

const FormListTitle = styled.span`
  align-items: center;
  justify-content: flex-start;
`;

const ButtonContainer = styled.div`
  align-items: center;
  justify-content: center;
`;

const NewProjectButton = styled(Button)`
  align-items: center;
  justify-content: center;
  margin: 10px !important;
  margin-right: 0 !important;
  opacity: 0.75;
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;

const ContentLink = styled.span`
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
  line-height: 25px;
  align-self: center;
  color: #fa6400;
`;

const ContentTitle = styled.span`
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
  align-self: center;
  color: #595469;
  text-transform: uppercase;
`;
const MainContainer = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  height: 100%;
`;
const headCells = [
  { id: 'template_name', align: 'left', label: 'FORM_NAME' },

  {
    id: 'createdDate',
    align: 'center',
    label: 'CREATION'
  },
  {
    id: 'updatedDate',
    align: 'center',
    label: 'MODIFIED_ON'
  },
  { id: 'step', align: 'center', label: 'VERSION' },
  { id: 'status', align: 'center', label: 'TEMPLATE_STATE' }
];

export default (props) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const [templatesList, setTemplatesList] = useState([]);
  const [alert, setAlert] = useState(false);

  const { templates, templateList } = useSelector(templatesSelector);

  const { selectedProject } = useSelector(projectsSelector);
  const { selectedMetadataSet } = useSelector(formsSelector);

  useEffect(() => {
    if (!templates.length) {
      dispatch(fetchTemplates());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!!selectedProject) {
      const _templates = templates.filter((template) =>
        template.drafts
          ? template.drafts.some(
              (draft) => draft.project_id == selectedProject.id
            )
          : false
      );
      setTemplatesList(_templates);
    } else {
      setTemplatesList(templates);
    }
  }, [templates]);

  useEffect(() => {
    dispatch(fetchTemplates());
  }, [templateList, selectedProject]);

  useEffect(() => {
    if (selectedMetadataSet.length) {
      let _metadatas = [];
      selectedMetadataSet.forEach((detail) => {
        if (_metadatas.some((m) => m.metadata_id === detail.metadata_id)) {
          _metadatas = _metadatas.map((meta) => {
            if (meta.metadata_id === detail.metadata_id)
              return { ...meta, details: [...meta.details, detail] };
            return meta;
          });
        } else {
          _metadatas.push({
            metadata_id: detail.metadata_id,
            details: [detail]
          });
        }
      });
      console.log('metas', _metadatas);
      dispatch(findByMetadaIdtIdTemplate(selectedProject.id, _metadatas));
    } else {
      dispatch(fetchTemplates());
    }
  }, [selectedMetadataSet]);

  const handleClick = (event, id) => {
    const { from } = props.location.state || {
      from: { pathname: `/form-templates/${encodeURIComponent(id)}` }
    };
    props.history.push(from);
  };

  const handleNewTemplete = () => {
    if (!selectedProject) {
      setAlert(true);
    } else {
      props.history.push({ pathname: '/form-templates/new' });
    }
  };

  const alertText = 'Lütfen bir proje seçin !';

  const handleRemove = (id) => {
    dispatch(removeMetadataSet(id));
  };
  return (
    <>
      <FormEditorContainer>
        <TitleContainer>
          <FormListTitle>
            <Breadcrumbs separator={<>&gt;</>} aria-label="breadcrumb">
              <Link component={RouterLink} to="/projects">
                <ContentLink>
                  {selectedProject && selectedProject.project_name}
                </ContentLink>
              </Link>
              <ContentTitle>
                <FormattedMessage id="FORM_TEMPLATES" />{' '}
              </ContentTitle>
            </Breadcrumbs>
          </FormListTitle>
          <ButtonContainer>
            <NewProjectButton
              variant="contained"
              color="primary"
              size="medium"
              style={{
                backgroundColor: '#faf8f8',
                color: '#999fb7'
              }}
            >
              <FormattedMessage id="EXPORT" />
            </NewProjectButton>
            <NewProjectButton
              variant="contained"
              color="primary"
              size="medium"
              style={{ backgroundColor: '#faf8f8', color: '#999fb7' }}
              startIcon={<RiEqualizerLine />}
            >
              <FormattedMessage id="FILTER" />
            </NewProjectButton>
            <NewProjectButton
              variant="contained"
              color="primary"
              size="medium"
              style={{ backgroundColor: '#1296fb' }}
              startIcon={<FiPlusCircle />}
              onClick={handleNewTemplete}
            >
              <FormattedMessage id="NEW_FORM_TEMPLATE" />
            </NewProjectButton>
          </ButtonContainer>
        </TitleContainer>
        <div className={classes.root}>
          <MetadataContainer>
            {selectedMetadataSet.map((metadata) => (
              <Metadata>
                <Text>{metadata.name}</Text>
                <Icon onClick={() => handleRemove(metadata.id)} />
              </Metadata>
            ))}
          </MetadataContainer>
          <MainContainer>
            <LeftContent />
            <Paper className={classes.paper}>
              {templatesList && templatesList.length > 0 && (
                <SortableTable
                  list={templatesList}
                  headCells={headCells}
                  handleClick={handleClick}
                  templateList
                />
              )}
            </Paper>
          </MainContainer>
        </div>
      </FormEditorContainer>
      <AlertDialog open={alert} setOpen={setAlert} alertText={alertText} />
    </>
  );
};
