import APIHelper from '../helpers/APIHelper';

export async function getInboxMailsByProjecFunction(project_id, user_id) {
  const response = await APIHelper().post('mails/find_inbox_by_project', null, {
    project_id,
    user_id
  });
  if (response.error) {
    throw new Error(response.error);
  }

  const newMails = await Promise.all(
    response.mails.map(async (mail) => {
      const ccJsonUsers = await Promise.all(
        mail.cc_json.users.map(async (user) => {
          if (user.companyName) return user;

          const userResponse = await APIHelper().post('companies/find', null, {
            company_id: user.companyId
          });

          const returnValue = {
            ...user,
            companyName:
              userResponse.company.length > 0
                ? userResponse.company[0].company_name
                : '',
            companyShortName:
              userResponse.company.length > 0
                ? userResponse.company[0].short_name
                : ''
          };

          return returnValue;
        })
      );
      const toJsonUsers = await Promise.all(
        mail.to_json.users.map(async (user) => {
          if (user.companyName) return user;

          const userResponse = await APIHelper().post('companies/find', null, {
            company_id: user.companyId
          });

          const returnValue = {
            ...user,
            companyName:
              userResponse.company.length > 0
                ? userResponse.company[0].company_name
                : '',
            companyShortName:
              userResponse.company.length > 0
                ? userResponse.company[0].short_name
                : ''
          };

          return returnValue;
        })
      );

      return {
        ...mail,
        cc_json: {
          users: ccJsonUsers
        },
        to_json: {
          users: toJsonUsers
        }
      };
    })
  );
  // console.log('GET INBOX RESPONSE =>', newMails);
  return newMails;
}

export async function getSendboxMailsByProjecFunction(project_id, user_id) {
  const response = await APIHelper().post(
    'mails/find_sentbox_by_project',
    null,
    { project_id, user_id }
  );
  if (response.error) {
    throw new Error(response.error);
  }

  const newMails = await Promise.all(
    response.mails.map(async (mail) => {
      const ccJsonUsers = await Promise.all(
        mail.cc_json.users.map(async (user) => {
          if (user.companyName) return user;

          const userResponse = await APIHelper().post('companies/find', null, {
            company_id: user.companyId
          });

          const returnValue = {
            ...user,
            companyName:
              userResponse.company.length > 0
                ? userResponse.company[0].company_name
                : '',
            companyShortName:
              userResponse.company.length > 0
                ? userResponse.company[0].short_name
                : ''
          };

          return returnValue;
        })
      );
      const toJsonUsers = await Promise.all(
        mail.to_json.users.map(async (user) => {
          if (user.companyName) return user;

          const userResponse = await APIHelper().post('companies/find', null, {
            company_id: user.companyId
          });

          const returnValue = {
            ...user,
            companyName:
              userResponse.company.length > 0
                ? userResponse.company[0].company_name
                : '',
            companyShortName:
              userResponse.company.length > 0
                ? userResponse.company[0].short_name
                : ''
          };

          return returnValue;
        })
      );

      return {
        ...mail,
        cc_json: {
          users: ccJsonUsers
        },
        to_json: {
          users: toJsonUsers
        }
      };
    })
  );

  //console.log('GET SENDBOX RESPONSE =>', newMails);
  return newMails;
}

export async function insertMailFunction({
  projectId,
  formId,
  formNo,
  formName,
  fromId,
  fromName,
  toId,
  toName,
  toJSON,
  ccJSON,
  description,
  fromCompanyId,
  fromCompanyName,
  toCompanyId,
  toCompanyName
}) {
  const response = await APIHelper().post('mails/insert', null, {
    project_id: projectId,
    form_id: formId,
    form_no: formNo,
    form_name: formName,
    from_id: fromId,
    from_name: fromName,
    to_id: toId,
    to_name: toName,
    to_json: JSON.parse(JSON.stringify(toJSON)),
    cc_json: JSON.parse(JSON.stringify(ccJSON)),
    description,
    from_company_id: fromCompanyId,
    from_company_name: fromCompanyName,
    to_company_id: toCompanyId,
    to_company_name: toCompanyName
  });
  if (response.error) {
    throw new Error(response.error);
  }
  // console.log('INSERT MAIL RESPONSE =>', response.mail);
  return response.mail;
}
