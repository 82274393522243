import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Grid, MenuItem } from '@material-ui/core';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import UserForm from 'components/Company/UserForm';
import { FormattedMessage } from 'react-intl';

const InfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 10px;
  border-bottom: 1px solid #555;
`;

const InfoTıtle = styled.div`
  font-size: 20px;
  margin-left: 15px;
`;

const EditButton = styled.div`
  margin-right: 15px;
`;

const Label = styled.div`
  font-size: 12px;
  color: #333;
  margin-top: 15px;
`;

const Info = styled.div`
  font-size: 16px;
  font-weight: bold;
  min-height: 35px;
`;

export const Option = styled(MenuItem)`
  font-weight: bold;
  font-size: 10px;
  color: ${(props) =>
    props.sideColor ? props.sideColor : 'rgba(89, 84, 105, 1.0)'} !important;
  line-height: 12px;
`;

export default ({ user, company }) => {
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <>
      <InfoHeader>
        <InfoTıtle>
          <FormattedMessage id="PERSONAL_INFORMATION" />
        </InfoTıtle>
        <EditButton>
          <Button
            variant="contained"
            size="small "
            onClick={() => setShowEditModal(true)}
          >
            <FormattedMessage id="UPDATE" />
          </Button>
        </EditButton>
      </InfoHeader>
      <Grid container>
        <Grid item xs={6}>
          <Label>
            <FormattedMessage id="NAME_SURNAME" />{' '}
          </Label>
          <Info>{user.name}</Info>
          <Label>
            <FormattedMessage id="MAIL" />{' '}
          </Label>
          <Info>{user.email}</Info>
        </Grid>
        <Grid item xs={6}>
          <Label>
            <FormattedMessage id="TITLE" />{' '}
          </Label>
          <Info>{user.title}</Info>
          <Label>
            <FormattedMessage id="COMPANY" />{' '}
          </Label>
          <Info>{user.company_name}</Info>
        </Grid>
      </Grid>

      <UserForm
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        user={user}
        company={company}
      />
    </>
  );
};
