import styled from 'styled-components';

export const FormContent = styled.div`
  height: 100%;
  overflow: auto;
  padding: 1vh 2.5vw;
`;

export const CaptionText = styled.div`
  color: rgba(250, 100, 0, 1);
  text-align: left;
  font-size: calc(4px + 0.75vw + 0.75vh);
  line-height: calc((4px + 0.75vh + 0.75vw) * 1.618);
  letter-spacing: calc((4px + 0.75vh + 0.75vw) / (1.618 * 20));
`;

export const Form = styled.div`
  margin: 5vh 2.5vw;
`;

export const FormTitle = styled.div`
  color: rgba(89, 84, 105, 1);
  font-weight: bold;
  text-align: left;
  font-size: calc(4px + 0.85vw + 0.85vh);
  line-height: calc((4px + 0.85vh + 0.85vw) * 1.618);
  letter-spacing: calc((4px + 0.85vh + 0.85vw) / (1.618 * 20));
`;
