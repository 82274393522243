import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12px;
  background-color: rgba(245, 245, 245, 1);
`;

export const TasksContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const NewProjectButton = styled(Button)`
  align-self: center;
  margin: 0px 0px 0px 8px !important;
  padding: 8px 14px !important;
  color: ${(props) => (props.new ? '#FFFFFF' : '#595469')} !important;
  background: ${(props) =>
    props.new ? '#0091ff' : props.inside ? '#F2F2F2' : '#FAF8F8'} !important;
  border: 1px solid ${(props) => (props.new ? '#0553e3' : '#D4D4D4')} !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  box-shadow: 0px 2px 4px rgba(219, 219, 219, 0.3);
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  text-transform: none !important;
  font-size: 18px !important;
  line-height: 21px !important;
  opacity: 0.75;
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;
