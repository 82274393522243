import { createSlice } from '@reduxjs/toolkit';
import {
  getGroupsFunction, addGroupFunction, updateGroupFunction, deleteGroupFunction, findGroupsByCompanyId,
} from 'api/groups';

const initialState = {
  loading: {
    getGroups: false,
    addGroup: false,
    updateGroup: false,
    deleteGroup: false,
  },
  hasErrors: {
    getGroups: false,
    addGroup: false,
    updateGroup: false,
    deleteGroup: false,
  },
  groups: [],
  group: null,
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    asyncStart: (state, { payload }) => {
      state.loading[payload] = true;
    },
    asyncFailure: (state, { payload }) => {
      state.loading[payload] = false;
      state.hasErrors[payload] = true;
    },
    getGroupsSuccess: (state, { payload }) => {
      state.groups = payload;
      state.loading.getGroups = false;
      state.hasErrors.getGroups = false;
    },
    addGroupSuccess: (state, { payload }) => {
      state.loading.addGroup = false;
      state.hasErrors.addGroup = false;
    },
    updateGroupSuccess: (state, { payload }) => {
      state.groups = state.groups.map((group) => {
        if (group.id === payload.group_id) { return { ...group, group_name: payload.group_name, color: payload.color }; }
        return group;
      });
      state.loading.updateGroup = false;
      state.hasErrors.updateGroup = false;
    },
    deleteGroupSuccess: (state, { payload }) => {
      state.groups = payload;
      state.loading.deleteGroup = false;
      state.hasErrors.deleteGroup = false;
    },
  },
});

export const {
  asyncStart, asyncFailure, getGroupsSuccess, addGroupSuccess, updateGroupSuccess, deleteGroupSuccess,
} = groupsSlice.actions;

export default groupsSlice.reducer;

export const groupsSelector = (state) => state.groups;

export const fetchGroups = () => async (dispatch) => {
  dispatch(asyncStart('getGroups'));

  try {
    const groups = await getGroupsFunction();
    dispatch(getGroupsSuccess(groups));
  } catch (error) {
    console.log('an error occurred while fetching groups', error);
    dispatch(asyncFailure('getGroups'));
  }
};

export const fetchGroupsByCompanyId = (companyId) => async (dispatch) => {
  dispatch(asyncStart('getGroups'));

  try {
    const groups = await findGroupsByCompanyId(companyId);
    dispatch(getGroupsSuccess(groups));
  } catch (error) {
    console.log('an error occurred while fetching groups', error);
    dispatch(asyncFailure('getGroups'));
  }
};

export const addGroup = (groupObj) => async (dispatch) => {
  dispatch(asyncStart('addGroup'));
  try {
    const group = await addGroupFunction(groupObj);
    /* const oldGroups = [...state().groups.groups];
    const tempGroups = oldGroups.unshift(group); */
    dispatch(addGroupSuccess());
    return group;
  } catch (error) {
    console.log('an error occurred while adding group', error);
    dispatch(asyncFailure('addGroup'));
    return error;
  }
};

export const updateGroup = (groupObj) => async (dispatch) => {
  dispatch(asyncStart('updateGroup'));
  try {
    await updateGroupFunction(groupObj);
    dispatch(updateGroupSuccess(groupObj));
  } catch (error) {
    console.log('an error occurred while updating company', error);
    dispatch(asyncFailure('updateGroup'));
  }
};

export const deleteGroup = (groupId) => async (dispatch, state) => {
  dispatch(asyncStart('deleteGroup'));
  try {
    await deleteGroupFunction(groupId);
    console.log(state().groups);
    console.log(state().groups.groups);
    const tempGroups = state().groups.groups.filter((group) => group.id !== groupId);
    dispatch(deleteGroupSuccess(tempGroups));
  } catch (error) {
    console.log('an error occurred while deleting company', error);
    dispatch(asyncFailure('deleteGroup'));
  }
};
