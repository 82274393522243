import React, { useState, useCallback, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CommonContainer from 'components/Common/CommonContainer';
import { useDispatch, useSelector } from 'react-redux';

import ProgressCircle from 'components/Common/ProgressCircle';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  projectsSelector,
  fetchProjects,
  addProject,
  updateProject,
  searchProjects,
} from '../../slices/projectsSlice';
import { getRandomColor } from '../../helpers';

import ProjectForm from './ProjectForm';
import ProjectBox from './ProjectBox';
import ProjectTable from './ProjectTable';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(36),
      height: theme.spacing(24),
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  dialogButton: {
    margin: '0px 15px',
  },
}));


export default () => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [] = useState([]);

  const [updateModal, setUpdateModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});

  const [viewSetting, setViewSetting] = useState(true);

  const dispatch = useDispatch();
  const { projects, loading } = useSelector(projectsSelector);


  const [searchText, setSerarchText] = useState('');

  const intl = useIntl();

  const handleSearch = useCallback(
    (search) => {
      dispatch(searchProjects(search));
    },
    [searchText],
  );


  useLayoutEffect(() => () => {
    dispatch(fetchProjects());
  }, []);

  const handleAddProject = (values) => {
    const color = getRandomColor().substring(1);

    dispatch(addProject({ ...values, color }))
      .then(() => setShowModal(false));

    setShowModal(false);
  };

  const handleUpdateProject = (values) => {
    values.project_id = values.id;
    dispatch(updateProject(values))
      .then(() => setUpdateModal(false));
  };


  const handleClick = (project) => {
    setSelectedProject(project);
    setUpdateModal(true);
  };
  return (
    <>
      <CommonContainer
        containerName={intl.formatMessage({ id: 'PROJECTS' })}
        addButton={intl.formatMessage({ id: 'NEW_PROJECT' })}
        setShowModal={setShowModal}
        handleSearchText={setSerarchText}
        viewSetting={viewSetting}
        setViewSetting={setViewSetting}
        handleSearch={handleSearch}
        search
        page="projects"
      >
        <>
          {loading.getProjects || loading.searchProjects ? (
            <ProgressCircle />
          ) : projects.length > 0
            ? viewSetting
              ? (
                <div className={classes.root}>
                  <ProjectBox
                    projectList={projects}
                    handleClick={handleClick}
                  />
                </div>
              )
              : (
                <ProjectTable
                  projectList={projects}
                  handleClick={handleClick}
                />
              )
            : (
              <h4><FormattedMessage id="NO_DATA" /></h4>
            )}
        </>
      </CommonContainer>
      <ProjectForm
        showModal={showModal}
        setShowModal={setShowModal}
        loading={loading.addProject}
        initialValues={{}}
        submitFunc={handleAddProject}
        dialogTitle={intl.formatMessage({ id: 'CREATE_NEW_PROJECT' })}
      />
      <ProjectForm
        showModal={updateModal}
        setShowModal={setUpdateModal}
        loading={loading.updateProject}
        initialValues={selectedProject}
        submitFunc={handleUpdateProject}
        dialogTitle={intl.formatMessage({ id: 'UPDATE_PROJECT' })}
      />
    </>
  );
};
