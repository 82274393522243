import React, { Fragment, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, FormControl, Select, MenuItem } from '@material-ui/core';
import { GiPositionMarker } from 'react-icons/gi';

import { AiFillCaretRight } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import {
  formsSelector,
  projectsSelector,
  tasksSelector,
  addSiteList
} from 'slices';
import ProgressCircle from 'components/Common/ProgressCircle';
import { getRevisionSetsFunction } from 'api';
import sortFnc from 'helpers/compareHelper';
import FormTable from './FormTable2';

import { authentication } from '../../helpers/authentication';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4)
  },
  rightside: {
    width: '70vw',
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
    height: '80vh',
    display: 'flex',
    flexDirection: 'column'
  },
  siteSetPaper: {
    width: '100%',
    minHeight: '60px !important',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0px 15px'
  },
  formsPaper: {
    width: '100%',
    marginTop: '20px',
    padding: '0px',
    flex: 1
  }
}));

const Option = styled(MenuItem)`
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
`;

const Set = styled.span`
  font-size: 16px;
  line-height: 12px;
  font-family: Helvetica, Helvetica, Arial, serif;
  margin: 0px 10px;
`;

const StyledFormControl = styled(FormControl)`
  margin: 0px 10px !important;
`;

const RightContent = (props) => {
  const { searchText, handleSiteLevel } = props;

  const classes = useStyles();

  const mountedRef = useRef(true);

  const dispatch = useDispatch();

  const {
    selectedMetadataSet,
    selectedSiteSet,
    forms,
    loading,
    site_list
  } = useSelector(formsSelector);
  const { selectedProject } = useSelector(projectsSelector);
  const { loading: loadingTasks } = useSelector(tasksSelector);

  const [formList, setFormList] = useState([]);
  const [list, setList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [details, setDetails] = useState([]);
  const [previousProjectId, setPreviousProjectId] = useState(0);

  async function setRevisionNames(forms) {
    const revison_sets = [];
    const projects = [];

    for (const form of forms) {
      if (!projects.includes(form.project_id) && form.project_id) {
        projects.push(form.project_id);
      }
    }

    for (const projectId of projects) {
      if (projectId) {
        try {
          const _revison_sets = await getRevisionSetsFunction(projectId);
          _revison_sets.forEach((element) => {
            revison_sets.push(...element.revision_set_details);
          });
        } catch (error) {}
      }
    }

    // forms.forEach(form => form.revision_set_name = revison_sets.filter(r => r.id === form.revision_set_id)[0]['name']);
    setList(forms);
  }

  // Fetch all forms every component rerender
  useEffect(() => {
    if (!forms.length && !loading.fetchForms && selectedProject) {
      // dispatch(fetchForms(userId, selectedProject.id, 10, 0));
    }
  }, []);

  useEffect(() => {
    if (forms.length && selectedProject) {
      setRevisionNames(forms, mountedRef.current);
    }
    return () => {
      mountedRef.current = false;
    };
  }, [forms, selectedProject]);

  useEffect(() => {
    setFormList(list);
  }, [list]);

  useEffect(() => {
    props.onSiteChange && props.onSiteChange(siteList);
  }, [siteList]);

  // if selected project change form list by project_id
  useEffect(() => {
    if (
      !loading.fetchForms &&
      selectedProject &&
      !(selectedProject.id === previousProjectId)
    ) {
      setPreviousProjectId(selectedProject.id);
      // dispatch(fetchForms(userId, selectedProject.id));
    }
    if (selectedProject) {
      setFormList(
        forms.filter((form) => form.project_id === selectedProject.id)
      );
    }
  }, [selectedProject, forms]);

  // if selected site set change form list by site_set_id
  /* useEffect(() => {
    if (selectedSiteSet) {
      const _form_list = forms.filter((form) => form.site_details.site_set.id === selectedSiteSet.id);
      setFormList(_form_list);
    }
  }, [selectedSiteSet]); */

  // if selected metadata set change form list by metadata_set_id
  useEffect(() => {
    let _forms = list;

    if (selectedProject) {
      const _form_list = forms.filter(
        (form) => form.project_id === selectedProject.id
      );
      _forms = _form_list;
    }

    if (selectedMetadataSet.length) {
      const _form_list = _forms.filter((form) => {
        const tempFormMetadata = form.metadata;
        const tempFormMetadataKeys = Object.keys(tempFormMetadata);

        const firstSol = tempFormMetadataKeys.some((metadataKeys) =>
          selectedMetadataSet.some((metadata) =>
            Array.isArray(tempFormMetadata[metadataKeys])
              ? tempFormMetadata[metadataKeys].filter(
                  (element) => element.metadata_detail_id === metadata.id
                ).length > 0
              : metadata.metadata_id ===
                  tempFormMetadata[metadataKeys].metadata_id &&
                metadata.id ===
                  tempFormMetadata[metadataKeys].metadata_detail_id
          )
        );
        return firstSol;
      });
      _forms = _form_list;
    }

    if (selectedSiteSet) {
      const _form_list = _forms.filter(
        (form) => form.site_details.site_set.id === selectedSiteSet.id
      );
      _forms = _form_list;
    }

    if (siteList.length) {
      const last_detail = siteList[siteList.length - 1];
      const _form_list = _forms.filter((form) =>
        form.site_details.site_list.some(
          (detail) => detail.id === last_detail.id
        )
      );
      _forms = _form_list;
    }

    if (searchText) {
      const _form_list = _forms.filter(
        (form) =>
          form.form_name &&
          form.form_name.toUpperCase().includes(searchText.toUpperCase())
      );
      _forms = _form_list;
    }
    setFormList(_forms);
  }, [
    selectedMetadataSet,
    selectedSiteSet,
    searchText,
    selectedProject,
    siteList,
    list
  ]);

  // if selected position (siteList) change form list by site_list
  // useEffect(() => {
  //  if (siteList.length) {
  //    const last_detail = siteList[siteList.length - 1];
  //    const _form_list = formList.filter((form) => form.site_details.site_list.some((detail) => detail.id === last_detail.id));
  //    setFormList(_form_list);
  //  }
  // }, [siteList]);

  useEffect(() => {
    setSiteList(site_list);
    handleSiteLevel(site_list.length);
  }, [site_list]);

  useEffect(() => {
    if (selectedSiteSet) {
      const nexDetails = selectedSiteSet.site_set_details.filter(
        (d) => d.parent_id === 0
      );
      setDetails(nexDetails);
      handleSiteLevel(0);
      // handleOkey(!selectedSiteSet.site_set_details.length);
    }
  }, [selectedSiteSet]);

  useEffect(() => {}, [formList]);

  // useEffect(() => {
  //  if (selectedSiteSet) {
  //    //handleOkey(!details.length);
  //  }
  // }, [details]);

  const handleSiteSet = (e) => {
    const detail_id = e.target.value;

    const detail = selectedSiteSet.site_set_details.filter(
      (d) => d.id === detail_id
    );
    const nextDetails = selectedSiteSet.site_set_details.filter(
      (d) => d.parent_id === detail_id
    );
    const _siteList = siteList.concat(detail);
    dispatch(addSiteList(_siteList));
    setSiteList(_siteList);
    setDetails(sortFnc(nextDetails, 'name'));
  };

  const handeSetClick = (parent, index) => {
    const nextDetails = selectedSiteSet.site_set_details.filter(
      (d) => d.parent_id === parent
    );

    setSiteList(siteList.slice(0, index));
    dispatch(addSiteList(siteList.slice(0, index)));

    setDetails(sortFnc(nextDetails, 'name'));
  };

  // Handle selected form in Sortable Table
  const handleClick = (id) => {
    const from = { pathname: `/forms/${id}` };

    props.history.push(from);
  };

  return (
    <div className={` ${classes.rightside}`}>
      <Paper className={` ${classes.siteSetPaper}`}>
        <GiPositionMarker color="rgba(250, 100, 0, 1)" fontSize={24} />
        {selectedSiteSet && (
          <>
            <Set onClick={() => handeSetClick(0, 0)}>
              {selectedSiteSet.set_name}
            </Set>
            <AiFillCaretRight color="#979797" />
            {siteList.map((site, index) => (
              <Fragment key={site.parent_id}>
                <Set onClick={() => handeSetClick(site.parent_id, index)}>
                  {site.name}
                </Set>
                <AiFillCaretRight color="#979797" />
              </Fragment>
            ))}
            {details.length ? (
              <StyledFormControl variant="outlined">
                <Select
                  margin="dense"
                  name="site_set_id"
                  fullWidth
                  value=""
                  onChange={handleSiteSet}
                >
                  {details.map((set) => (
                    <Option key={set.id} value={set.id}>
                      {' '}
                      {set.name}{' '}
                    </Option>
                  ))}
                </Select>
              </StyledFormControl>
            ) : null}
          </>
        )}
      </Paper>
      <Paper className={` ${classes.formsPaper}`}>
        {loading.getForms ||
        loadingTasks.getAllTasks ||
        loadingTasks.getWorkerTasks ? (
          <ProgressCircle />
        ) : (
          <FormTable
            auth={authentication.userValue}
            formList={formList}
            handleForm={handleClick}
            projectId={selectedProject?.id}
          />
        )}
      </Paper>
    </div>
  );
};

export default RightContent;
