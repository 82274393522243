import styled from 'styled-components';

export const FormEditorContainer = styled.div`
width: 100%;
height: 100%;
background-color: rgba(245, 245, 245, 1);
`;

export const TitleContainer = styled.div`
background-color: rgba(255, 255, 255, 0);
height: 48px;
width: auto;
display: flex;
justify-content: space-between;
align-items: center;
margin-left: 20px;
margin-right: 40px;
transform: rotate(0deg);
opacity: 1;
font-family: 'Helvetica', Helvetica, Arial, serif;
font-size: calc(12px + 0.5vw + 0.5vh);
line-height: calc((12px + 0.5vw + 0.5vh) * 1.618);
letter-spacing: calc((12px + 0.5vw + 0.5vh) / (1.618 * 20));
color: rgba(89, 84, 105, 1);
`;

export const MainContainer = styled.div`
display: flex;
justify-content: stretch;
align-items: stretch;
height: 100%;
`;

export const HistoryListContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: stretch;
align-content: stretch;
height: 70vh;
overflow: auto;
`;

export const InputLabel = styled.div`
  color: rgba(89, 84, 105, 1) !important;
  text-align: right;
  padding: 2px 10px;
  margin: 0px;
  font-weight: bold;
  font-size: calc(4px + 0.5vh + 0.5vw);
  line-height: calc((4px + 0.5vh + 0.5vw) * 1.618);
  letter-spacing: calc((4px + 0.5vh + 0.5vw) / (1.618 * 20));
`;