import APIHelper from '../helpers/APIHelper';

export async function getCompaniesFunction() {
  const response = await APIHelper().get('companies/list');
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('GET COMPANIES RESPONSE =>', response.companies);
  return response.companies;
}

export async function searchCompaniesFunction(search) {
  const response = await APIHelper().post('companies/search', null, { search });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('GET COMPANIES SEARCH RESPONSE =>', response.companies);
  return response.companies;
}

export async function findByIdCompaniessFunction(company_id) {
  const response = await APIHelper().post('companies/find', null, {
    company_id,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('FIND COMPANY BY ID RESPONSE =>', response.company);
  return response.company;
}

export async function addCompanyFunction(companyObj) {
  const response = await APIHelper().post('companies/insert', null, {
    ...companyObj,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('ADD COMPANY RESPONSE =>', response.company);
  return response.company;
}

export async function updateCompanyFunction(companyObj) {
  const response = await APIHelper().post('companies/update', null, {
    ...companyObj,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('UPDATE COMPANY RESPONSE =>', response);
  return response.status;
}

export async function deleteCompanyFunction(company_id) {
  const response = await APIHelper().post('companies/delete', null, {
    company_id,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('DELETE COMPANY RESPONSE =>', response);
  return response;
}

export async function addProjectToCompanyFunction(company_id, project_id) {
  const response = await APIHelper().post('companies/add_projects', null, {
    company_id,
    project_id,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('ADD PROJECT TO COMPANY RESPONSE =>', response);
  return response.status;
}

export async function deleteProjectToCompanyFunction(company_id, project_id) {
  const response = await APIHelper().post('companies/delete_project', null, {
    company_id,
    project_id,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('ADD PROJECT TO COMPANY RESPONSE =>', response);
  return response.status;
}

export async function updateCompanyLogoFunction(companyObj) {
  const response = await APIHelper().post('companies/updatelogo', null, {
    ...companyObj,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('UPDATE COMPANY LOGO RESPONSE =>', response);
  return response.status;
}

export async function findProjectToCompanyFunction(company_id) {
  const response = await APIHelper().post('companies/find_projects', null, {
    company_id,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('FIND PROJECT TO COMPANY RESPONSE =>', response.company_projects);
  return response.company_projects;
}

export async function findCompaniesByProjectIdFunction(project_id) {
  const response = await APIHelper().post('companies/find_companies', null, {
    project_id,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('FIND BY PROJECT ID COMPANY RESPONSE =>', response);
  return response.data.company_projects;
}
