import React, { useState } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import styled from 'styled-components';
import { MdClose, MdCheck } from 'react-icons/md';
import { IoIosCheckboxOutline } from 'react-icons/io';
import { Checkbox, FormControlLabel, Select } from '@material-ui/core';
import { Option } from '../ProjectForms/EditFormInfo';


const CheckBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const MetadataContainer = styled.div`
    margin: 30px 0px;
`;

const MetadataTitle = styled.div`
    font-size: calc(8px + 0.5vh + 0.5vw );
    color: rgba(0, 0, 0, 0.5);
    line-height: calc(11px + 0.5vh + 0.5vw );;
    text-align: left;
    padding-left: 8px;
    margin: 10px 0px;
    font-weight: bold;
`;
const Title = styled.div`
    font-size: calc(4px + 0.5vh + 0.5vw );
    color: rgba(0, 0, 0, 0.5);
    line-height: calc(8px + 0.5vh + 0.5vw );;
    text-align: left;
    padding-left: 8px;
    margin: 10px 0px;
`;

const DetailList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
`;

const Detail = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <MdClose onClick={onDelete} />
  </div>
))`
    font-size: 12px;
    color: #fff;
    background: #555;
    padding: 6px 24px;
    margin: 5px 10px;
    border-radius: 8px;
    display: flex;


`;


const InputWrapper = styled('div')`
  width: 100%;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  margin-right: 30px;
  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Listbox = styled('ul')`
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

const StepManagers = (props) => {
  const { values, handleChange } = props;

  const [selected, handleSelect] = useState({ id: 0, list: [] });

  return (
    <div>
      <CustomAutoComplate
        multiple
        details={[{ name: 'Ozan Küçük' }, { name: 'Tolgga Canözkan' }]}
        metadataList={[]}
        setMetadataList={handleSelect}
        defaults={[]}
        label="Sorumlular"
      />
      <CheckBoxContainer>
        <FormControlLabel

          control={(
            <Checkbox
              name="is_delege"
              id="is_delege"
              checked={values.is_delege}
              onChange={handleChange}
              checkedIcon={<IoIosCheckboxOutline />}
              color="default"
            />
          )}
          label="Delege edilebilir."
        />
        <FormControlLabel

          control={(
            <Checkbox
              name="is_start"
              id="is_start"
              checked={values.is_start}
              onChange={handleChange}
              checkedIcon={<IoIosCheckboxOutline />}
              color="default"
            />
          )}
          label="Adım Sorumluları süreç başatıldığında belirlensin."
        />
      </CheckBoxContainer>
      <Title>Birden fazla sorumlu olması durumunda </Title>
      <Select
        margin="dense"
        variant="outlined"
        name="multiple_user"
        id="multiple_user"
        value={values.multiple_user}
        fullWidth
        onChange={handleChange}
      >
        <Option
          value={0}
        >
          Herhangi bir sorumlu Onay verdiğinde Onaylansın
        </Option>
        <Option
          value={1}
        >
          Tüm sorumlular Onay verdiğinde Onaylansın
        </Option>
      </Select>

    </div>
  );
};

export default StepManagers;


const CustomAutoComplate = (props) => {
  const {
    set, details, setMetadataList, defaults, multiple, label,
  } = props;


  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    defaultValue: defaults,
    multiple,
    options: details,
    fullWidth: true,
    getOptionLabel: (option) => option.name,

  });


  return (
    <MetadataContainer {...getRootProps()}>
      <MetadataTitle {...getInputLabelProps()}>{label}</MetadataTitle>
      <InputContainer>
        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
          {groupedOptions.length > 0 ? (
            <Listbox {...getListboxProps()}>
              {groupedOptions.map((option, index) => (
                <li {...getOptionProps({ option, index })}>
                  <span>{option.name}</span>
                  <MdCheck fontSize="small" />
                </li>
              ))}
            </Listbox>
          ) : null}
          <input {...getInputProps()} />
        </InputWrapper>

      </InputContainer>
      <DetailList>
        {value && !Array.isArray(value)
          ? <Detail label={value.name} {...getTagProps({ index: 0 })} />
          : value.map((option, index) => (
            <Detail label={option.name} {...getTagProps({ index })} />
          ))}
      </DetailList>
    </MetadataContainer>
  );
};
