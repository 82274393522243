import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';


const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },

  },
  content: {
    backgroundColor: 'var(--tree-view-bg-color) !important',
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,

    },
  },
  group: {
    marginLeft: 0,

    '& $content': {
      paddingLeft: theme.spacing(2),

    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',


  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    height: '48px',

  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    textAlign: 'left',
    fontSize: 'calc(6px + 0.5vw + 0.5vh)  !important',
    lineHeight: 'calc((6px + 0.5vw + 0.5vh) * 1.618)',
    letterSpacing: 'calc((6px + 0.5vw + 0.5vh) / (1.618 * 20))',

  },
}));


export default React.forwardRef((props, ref) => {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelInfo,
    color,
    bgColor,
    value,

    ...other
  } = props;

  return (
    <TreeItem
      ref={ref}
      label={(
        <div className={classes.labelRoot} id={value || labelText}>
          <Typography variant="body2" className={classes.labelText} id={value}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
        )}
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
});
