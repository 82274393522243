import { createAction } from '@reduxjs/toolkit';

export const logout = createAction('LOGOUT');

export * from './companiesSlice';
export * from './projectsSlice';
export * from './usersSlice';
export * from './templatesSlice';
export * from './sets/siteSetsSlice';
export * from './sets/revisionSlice';
export * from './sets/metadataSlice';
export * from './sets/statusSlice';
export * from './sets/rolesSlice';
export * from './groupsSlice';
export * from './formsSlice';
export * from './mailsSlice';
export * from './tasksSlice';
export * from './i18nSlice';
