import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const ProgressContainer = styled.div`
  width: 100%;
  height:${(props) => props.height || '50vh'};
  flex:1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: ${(props) => props.overflow || 'auto'};
  background-color: rgba(245, 245, 245, 1);
`;


const ProgressCircle = ({ height, size, overflow }) => (
  <ProgressContainer height={height} overflow={overflow}>
    <CircularProgress size={size || 40} />
  </ProgressContainer>
);

export default ProgressCircle;
