import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { useDispatch, useSelector } from 'react-redux';

import {
  projectsSelector,
  selectProject,
  fetchProjects,
  getWorkerTasks,
  tasksSelector
} from 'slices';

import DataLoading from 'components/Common/DataLoading';
import { authentication } from 'helpers/authentication';
import { useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import SortableTable from '../Table/SortableTable';
import SelectMenu from '../Menu/SelectMenu';

import {
  DashboardContainer,
  SelectorContainer,
  TasksContainer,
  TaskTitle
} from '../../styles/home/homeStyle';

const useStyles = makeStyles((theme) => ({
  paddings: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1)
  },
  nodata: {
    padding: '10px'
  }
}));

export default () => {
  const mountedRef = useRef(true);

  const dispatch = useDispatch();

  const history = useHistory();

  const classes = useStyles();

  const intl = useIntl();

  const headCells = [
    { id: 'id', align: 'left', label: 'TASK_ID' },
    { id: 'from_name', align: 'center', label: 'FORM_TITLE' },
    { id: 'from_name', align: 'center', label: 'FORM_NO' },
    { id: 'status', align: 'center', label: 'STATUS' },

    {
      id: 'worker_name',
      align: 'center',
      label: 'WORKING_ON'
    },
    {
      id: 'due_date',
      align: 'center',
      label: 'DELIVERY_DATE'
    }
  ];

  const { id: worker_id } = authentication.userValue;

  const { projects, selectedProject, loading } = useSelector(projectsSelector);

  const { workerTasks, loading: taskLoading, hasErrors } = useSelector(
    tasksSelector
  );

  /* const [tasks, setTasks] = useState([]); */

  // async functions
  async function aycncFetchWorkers(project_id) {
    dispatch(getWorkerTasks(project_id, worker_id));
  }

  async function aycncFetchProjects() {
    dispatch(fetchProjects());
  }

  useEffect(() => {
    if (!projects.length && !loading.getProjects) {
      aycncFetchProjects(mountedRef.current);
    }
    return () => {
      mountedRef.current = false;
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedProject) {
      aycncFetchWorkers(selectedProject.id, mountedRef.current);
    }
    return () => {
      mountedRef.current = false;
    };
  }, [selectedProject]);

  const handleClick = (event, row) => {
    history.push(`tasks/${row.id}`);
  };

  return (
    <DashboardContainer>
      <SelectorContainer>
        <Paper className={clsx(classes.paper, classes.paddings)}>
          <SelectMenu
            label={intl.formatMessage({ id: 'SELECT_ONE_PROJECT' })}
            list={projects}
            selectedValue={selectedProject}
            valueIndicator="project_name"
            onSelect={(value) => {
              dispatch(selectProject(value));
            }}
          />
        </Paper>
      </SelectorContainer>

      <TasksContainer>
        <Paper>
          <TaskTitle>
            <FormattedMessage id="MY_TASKS" />{' '}
          </TaskTitle>
        </Paper>
        <Paper className={classes.paper}>
          {!workerTasks.length ? (
            <DataLoading
              style={{ padding: '10px' }}
              loading={taskLoading.getWorkerTasks}
              error={hasErrors.getWorkerTasks}
              selectedProject={selectedProject}
              list={workerTasks}
              noDataText={
                selectedProject
                  ? intl.formatMessage(
                      { id: 'PROJECT_JOB_NOT_FOUND' },
                      { project_name: selectedProject.project_name }
                    )
                  : intl.formatMessage({ id: 'JOB_NOT_FOUND' })
              }
            />
          ) : (
            <SortableTable
              list={workerTasks}
              headCells={headCells}
              handleClick={handleClick}
              taskList
            />
          )}
        </Paper>
      </TasksContainer>
    </DashboardContainer>
  );
};
