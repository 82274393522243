import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Grid, FormControl, Select, MenuItem, FormHelperText,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import { FaMapMarkerAlt } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(32),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  alignItemsLeft: {
    alignItems: 'flex-start',
  },
  textAlignRight: {
    textAlign: 'right',
    alignSelf: 'center',
  },
  options: {
    textAlign: 'left !important',
    color: 'rgba(89, 84, 105, 1.0)',
    fontSize: 'calc(4px + 0.5vh + 0.5vw) !important',
    lineHeight: 'calc((4px + 0.5vh + 0.5vw) * 1.618) !important',
    letterSpacing: 'calc((4px + 0.5vh + 0.5vw) / (1.618 * 20)) !important',
  },
  placeholder: {
    color: 'rgba(89, 84, 105, 0.5)'
  }
}));

const TextInput = styled(TextField)`
  font-weight: bold;
  color: rgba(89, 84, 105, 1.0);
  font-size: calc(4px + 0.5vh + 0.5vw);
  line-height: calc((4px + 0.5vh + 0.5vw) * 1.618);
  letter-spacing: calc((4px + 0.5vh + 0.5vw) / (1.618 * 20));
  margin: 0px !important;
`;
const Text = styled.div`
  font-weight: bold;
  color: rgba(89, 84, 105, 1.0);
  font-size: calc(4px + 0.5vh + 0.5vw);
  line-height: calc((4px + 0.5vh + 0.5vw) * 1.618);
  letter-spacing: calc((4px + 0.5vh + 0.5vw) / (1.618 * 20));
`;
const Option = styled(MenuItem)`
  font-weight: bold !important;
  text-align: left !important;
  color: ${(props) => (props.placeholder ? 'rgba(89, 84, 105, 0.5)' : 'rgba(89, 84, 105, 1.0)')} !important;
  font-size: calc(4px + 0.5vh + 0.5vw) !important;
  line-height: calc((4px + 0.5vh + 0.5vw) * 1.618) !important;
  letter-spacing: calc((4px + 0.5vh + 0.5vw) / (1.618 * 20)) !important;
`;

export default ({
  isEditing, allMetadataSets, metadataSets, formikProps, selectedRevision, initialRevision, siteDetails
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [location, setLocation] = useState('');

  const {
    values, errors, setFieldValue, touched, handleChange, handleBlur,
  } = formikProps;

  useEffect(() => {

  }, [siteDetails]);

  return (
    <Grid container spacing={2} className={classes.gridContainer}>
      <Grid item xs={4} className={classes.textAlignRight}>
        <Text>
          <FormattedMessage id="FORM_NAME" />
        </Text>
      </Grid>
      <Grid item xs={8} className={classes.alignItemsLeft}>
        <TextInput
          name="form_name"
          variant="outlined"
          margin="dense"
          fullWidth
          disabled={!isEditing}
          value={values.form_name}
          error={errors.form_name && touched.form_name}
          helperText={touched.form_name && errors.form_name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={4} className={classes.textAlignRight}>
        <Text>
          <FormattedMessage id="REVISION" />
        </Text>
      </Grid>
      <Grid item xs={8} className={classes.alignItemsLeft}>
        <FormControl
          fullWidth
          variant="outlined"
          error={errors.revision_set_id && touched.revision_set_id}
          disabled={!isEditing}
        >
          <Select
            margin="dense"
            variant="outlined"
            name="revision_set_id"
            value={values.revision_set_id}
            fullWidth
            onChange={(event, child) => {
              setFieldValue('revision_set_id', event.target.value);
              setFieldValue('revision_name', child.props.children[1]);
            }}
            className={clsx(classes.options, values.revision_set_id === 0 ? classes.placeholder : null)}
          >
            <Option value={0} disabled placeholder>
              {`${intl.formatMessage({ id: 'PLEASE_SELECT' })}${intl.formatMessage({ id: 'REVISION' })}${intl.formatMessage({ id: 'PLEASE_SELECT_CONTINUE' })}`}
            </Option>
            {
              selectedRevision
              && selectedRevision.revision_set_details.map((revision, index) => {
                const revIndex = selectedRevision.revision_set_details.findIndex((el) => el.id === initialRevision);
                return (
                  <Option key={revision.id} value={revision.id} disabled={formikProps.values.id === 'new' ? index !== 0 : (index !== revIndex && index !== revIndex + 1)}> {revision.name} </Option>
                );
              })
            }
          </Select>
          <FormHelperText>{touched.revision_set_id && errors.revision_set_id}</FormHelperText>
        </FormControl>
      </Grid>
      {Object.keys(metadataSets).map((metadataSet) => {
        const isMultiple = allMetadataSets.filter((element) => element.metadata_name === metadataSet)[0].multi_select;

        return (
          <React.Fragment key={metadataSet}>
            <Grid item xs={4} className={classes.textAlignRight}>
              <Text>
                {metadataSet}
              </Text>
            </Grid>
            <Grid item xs={8} className={classes.alignItemsLeft}>
              {isMultiple ? (
                <Autocomplete
                  multiple
                  disabled={!isEditing}
                  id={`metadata.${metadataSet}.metadata_detail_id`}
                  size="small"
                  options={metadataSets[metadataSet].map((detail) => ({
                    metadata_detail_name: detail.metadata_detail_name,
                    metadata_detail_id: detail.metadata_detail_id,
                    metadata_id: detail.metadata_id,
                  }))} // metadataSet.metadata_details
                  value={values.metadata[metadataSet]
                    ? (Array.isArray(values.metadata[metadataSet])
                      ? values.metadata[metadataSet]
                      : [values.metadata[metadataSet]]
                    )
                    : []} // Array.isArray(values.metadata[`${metadataSet.metadata_name}`]) ?? [values.metadata[`${metadataSet.metadata_name}`]]
                  getOptionSelected={(option, value) => value && value.metadata_detail_id && option.metadata_detail_id === value.metadata_detail_id} //
                  getOptionLabel={(option) => option?.metadata_detail_name}
                  onChange={(event, value) => setFieldValue(`metadata.${metadataSet}`, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={`${intl.formatMessage({ id: 'PLEASE_SELECT' })}${metadataSet}${intl.formatMessage({ id: 'PLEASE_SELECT_CONTINUE' })}`}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              )
                : (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    disabled={!isEditing}
                    error={errors[`metadata.${metadataSet}?.metadata_detail_id`] && touched[`metadata.${metadataSet}.metadata_detail_id`]}
                  >
                    <Select
                      margin="dense"
                      variant="outlined"
                      name={`metadata.${metadataSet}?.metadata_detail_id`}
                      value={values.metadata[`${metadataSet}`]?.metadata_detail_id ?? 0}
                      fullWidth
                      onChange={(e) => {
                        const _detail = metadataSets[metadataSet].filter((detail) => detail.metadata_detail_id === e.target.value)[0];
                        setFieldValue(`metadata.${metadataSet}`, {
                          metadata_detail_id: e.target.value,
                          metadata_detail_name: _detail.metadata_detail_name,
                          metadata_id: _detail.metadata_id,
                        });
                      }}
                      className={clsx(classes.options, values.metadata[`${metadataSet}`]?.metadata_detail_id ? null : classes.placeholder)}
                    >
                      <Option value={0} disabled>
                        {`${intl.formatMessage({ id: 'PLEASE_SELECT' })}${metadataSet}${intl.formatMessage({ id: 'PLEASE_SELECT_CONTINUE' })}`}
                      </Option>
                      {metadataSets[metadataSet].map((detail) => (
                        <Option value={detail.metadata_detail_id} key={`--key*-${detail.metadata_detail_id}`}> {detail.metadata_detail_name} </Option>
                      ))}
                    </Select>
                    <FormHelperText>{touched[`metadata.${metadataSet}.metadata_detail_id`] && errors[`metadata.${metadataSet}.metadata_detail_id`]}</FormHelperText>
                  </FormControl>
                )}
            </Grid>
          </React.Fragment>
        )
      })}
      {siteDetails &&
        <div style={{ display: 'flex', marginTop: 30, position: 'relative', top: 84, left: -70 }}>
          <div>
            <FaMapMarkerAlt color="rgba(250,100,0,1)" style={{ marginRight: 15 }} size={24} />
          </div>
          <div>
            <p style={{ margin: 0, marginBottom: 5, textAlign: 'left' }}>{siteDetails.site_list.find(x => x.parent_id === 0).name}</p>
            <p style={{ margin: 0, textAlign: 'left', color: '#A8A8A8', fontSize: 14 }}>
              {siteDetails.site_set.set_name} {` > `} {siteDetails.site_list.map(x => x.name).join(' > ')}
            </p>
          </div>
        </div>
      }
    </Grid>
  );
};
