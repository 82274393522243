import { createSlice } from '@reduxjs/toolkit';
import {
  findRoleByProjectIdFunc, findRoleByProjectIdCompanyFunc, findCompaniesByProjectIdFunc,
  addRoleSetFunc, deleteRoleSetFunc, updateRoleSetFunc,
  addRoleSetCompanyFunc, deleteRoleSetCompanyFunc,
} from '../../api/sets/rolesSets';

import { findByIdCompaniessFunction } from '../../api/companies';

const initialState = {
  loading: {
    fetchRolesSet: false, addRoleSet: false, updateRoleSet: false, deleteRoleSet: false,
  },
  hasErrors: {
    fetchRolesSet: false, addRoleSet: false, updateRoleSet: false, deleteRoleSet: false,
  },
  rolesSets: [],
  rolesSetsCompany: [],
  rolesSetsCompanyRoles: [],
};

const rolesSetsSlice = createSlice({
  name: 'rolesSets',
  initialState,
  reducers: {
    asyncStart: (state, { payload }) => {
      state.loading[payload] = true;
    },
    asyncFailure: (state, { payload }) => {
      state.loading[payload] = false;
      state.hasErrors[payload] = true;
    },
    getRolesSetSuccess: (state, { payload }) => {
      state.rolesSets = payload;
      state.loading.fetchRolesSet = false;
      state.hasErrors.fetchRolesSet = false;
    },
    getRolesSetCompanySuccess: (state, { payload }) => {
      state.rolesSetsCompany = payload;
      state.loading.fetchRolesSet = false;
      state.hasErrors.fetchRolesSet = false;
    },
    getRolesSetCompanyRolesSuccess: (state, { payload }) => {
      state.rolesSetsCompanyRoles = payload;
      state.loading.fetchRolesSet = false;
      state.hasErrors.fetchRolesSet = false;
    },
    addRolesSetSuccess: (state, { payload }) => {
      state.loading.addRoleSet = false;
      state.hasErrors.addRoleSet = false;
    },
    addRolesSetCompanySuccess: (state, { payload }) => {
      state.loading.addRoleSet = false;
      state.hasErrors.addRoleSet = false;
    },
    updateRolesSetSuccess: (state, { payload }) => {
      state.rolesSets = state.rolesSets.map((rolesSet) => {
        if (rolesSet.id === payload.role_id) { return payload; }
        return rolesSet;
      });
      state.loading.updateRoleSet = false;
      state.hasErrors.updateRoleSet = false;
    },
    deleteRolesSetSuccess: (state, { payload }) => {
      state.rolesSets = state.rolesSets.filter((rolesSet) => rolesSet.id !== payload);
      state.loading.deleteRoleSet = false;
      state.hasErrors.deleteRoleSet = false;
    },
    deleteRolesSetCompanySuccess: (state, { payload }) => {
      state.statusSetsCompany[payload.company_id].filter((statusSet) => statusSet !== payload.role);
      state.loading.deleteRoleSet = false;
      state.hasErrors.deleteRoleSet = false;
    },
  },
});

export const {
  asyncStart, asyncFailure, getRolesSetSuccess, getRolesSetCompanySuccess, getRolesSetCompanyRolesSuccess, addRolesSetSuccess, addRolesSetCompanySuccess, updateRolesSetSuccess, deleteRolesSetSuccess,
} = rolesSetsSlice.actions;

export default rolesSetsSlice.reducer;

export const roleSetsSelector = (state) => state.rolesSets;

export const fetchRoleSetsByProject = (projectId) => async (dispatch) => {
  dispatch(asyncStart('fetchRoleSet'));

  try {
    const _rolesSets = await findRoleByProjectIdFunc(projectId);

    dispatch(getRolesSetSuccess(_rolesSets));
  } catch (error) {
    console.log('an error occurred while fetching rolesSets', error);
    dispatch(asyncFailure('fetchRoleSet'));
  }
};

export const fetchCompanyByProject = (projectId) => async (dispatch) => {
  dispatch(asyncStart('fetchRoleSet'));

  try {
    const companies = await findCompaniesByProjectIdFunc(projectId);
    const newList = [];
    const justDoIt = Promise.all(companies.map(async (company) => {
      const legacyCompany = await findByIdCompaniessFunction(company.company_id);

      newList.push({
        ...company,
        company: {
          ...legacyCompany[0],
        },
      });
      return company;
    }));

    justDoIt.then(() => {
      dispatch(getRolesSetCompanySuccess(newList));
    });
  } catch (error) {
    console.log('an error occurred while fetching companies by project id ', error);
    dispatch(asyncFailure('fetchRoleSet'));
  }
};

export const fetchCompanyRoleSetsByProject = (projectId) => async (dispatch) => {
  dispatch(asyncStart('fetchRoleSet'));

  try {
    const _rolesSets = await findRoleByProjectIdCompanyFunc(projectId);
    dispatch(getRolesSetCompanyRolesSuccess(_rolesSets));
  } catch (error) {
    console.log('an error occurred while fetching rolesSets', error);
    dispatch(asyncFailure('fetchRoleSet'));
  }
};

export const addRoleSet = (roleObj) => async (dispatch) => {
  dispatch(asyncStart('addRoleSet'));

  try {
    await addRoleSetFunc(roleObj);
    dispatch(addRolesSetSuccess());
    dispatch(fetchRoleSetsByProject(roleObj.project_id));
  } catch (error) {
    console.log('an error occurred while adding rolesSets', error);
    dispatch(asyncFailure('addRoleSet'));
  }
};

export const updateRoleSet = (roleObj) => async (dispatch) => {
  dispatch(asyncStart('updateRoleSet'));

  try {
    await updateRoleSetFunc(roleObj);
    dispatch(updateRolesSetSuccess(roleObj));
  } catch (error) {
    console.log('an error occurred while updating rolesSets', error);
    dispatch(asyncFailure('updateRoleSet'));
  }
};

export const deleteRoleSet = (roleId) => async (dispatch) => {
  dispatch(asyncStart('deleteRoleSet'));

  try {
    await deleteRoleSetFunc(roleId);
    dispatch(deleteRolesSetSuccess(roleId));
  } catch (error) {
    console.log('an error occurred while deleting roleSets', error);
    dispatch(asyncFailure('deleteRoleSet'));
  }
};

export const addRoleSetCompany = (roleObj) => async (dispatch) => {
  dispatch(asyncStart('addRoleSet'));

  try {
    await addRoleSetCompanyFunc(roleObj);
    dispatch(addRolesSetCompanySuccess());
    dispatch(fetchCompanyRoleSetsByProject(roleObj.project_id));
  } catch (error) {
    console.log('an error occurred while adding rolesSets', error);
    dispatch(asyncFailure('addRoleSet'));
  }
};

export const deleteRoleSetCompany = (roleObj) => async (dispatch) => {
  dispatch(asyncStart('deleteRoleSet'));

  try {
    await deleteRoleSetCompanyFunc(roleObj);
    dispatch(addRolesSetCompanySuccess());
    dispatch(fetchCompanyRoleSetsByProject(roleObj.project_id));
  } catch (error) {
    console.log('an error occurred while adding rolesSets', error);
    dispatch(asyncFailure('deleteRoleSet'));
  }
};
