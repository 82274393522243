import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Formik, Field, Form,
} from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { IoMdClose } from 'react-icons/io';
import DatePicker, { registerLocale } from 'react-datepicker';
import tr from 'date-fns/locale/tr';

import 'react-datepicker/dist/react-datepicker.css';

import { useIntl, FormattedMessage } from 'react-intl';
import { Checkbox } from '../Form';

import { useDeepCompareEffect } from '../../helpers/deepcompare';

registerLocale('tr', tr);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    fontFamily: 'Helvetica, Helvetica, Arial, serif',
    fontSize: 20,
    color: 'rgba(89, 84, 105, 1.0)',
    lineHeight: '24px',
  },
  body: {
    flex: 1,
    maxHeight: '70vh',
    overflowY: 'scroll',
    padding: theme.spacing(2),
    display: 'block',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  bodyinside: {
    padding: theme.spacing(0, 1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formInfo: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    borderRadius: '5px',
    borderStyle: 'solid',
    borderWidth: '1.5px',
    borderColor: 'rgba(0, 0, 0, 0.25)',
  },
  dateInfo: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    borderRadius: '5px',
    borderStyle: 'solid',
    borderWidth: '1.5px',
    borderColor: 'rgba(0, 0, 0, 0.25)',
  },
  dateInfoLeft: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  },
  optionsInfo: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    borderRadius: '5px',
    borderStyle: 'solid',
    borderWidth: '1.5px',
    borderColor: 'rgba(0, 0, 0, 0.25)',
  },
  inputGroup: {
    padding: theme.spacing(1),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  },
  checkGroup: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
  },
  checkOptionsGroup: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  checkMinMaxGroup: {
    flex: 1,
    display: 'flex',
    borderRadius: '5px',
    borderStyle: 'solid',
    borderWidth: '1.5px',
    borderColor: 'rgba(0, 0, 0, 0.25)',
    flexDirection: 'row',
  },
  checkMinMaxGroupSide: {
    padding: theme.spacing(1),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  checkMinMaxGroupTop: {
    paddingBottom: theme.spacing(1),
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  checkMinMaxGroupBottom: {
    paddingTop: theme.spacing(1),
    flex: 1,
  },
  minMaxGroup: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  },
  optionsGroup: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  },
  optionGroup: {
    padding: theme.spacing(1),
    flex: 0.25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  },
  buttonGroup: {
    padding: theme.spacing(1),
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dateGroup: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  },
  inputTitle: {
    paddingBottom: theme.spacing(1),
    flex: 1,
    fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
    fontSize: 14,
    color: 'rgba(89, 84, 105, 0.7)',
    lineHeight: '17.0px',
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'uppercase',
  },
  input: {
    flex: 1,
    borderRadius: '5px',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: 'rgba(0, 0, 0, 0.25)',
    fontFamily: 'Lato, Helvetica, Arial, serif',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '16.63px',
    color: 'rgba(92, 93, 93, 1)',
    textAlign: 'left',
    lineHeight: '24.95px',
  },
}));

const AddButton = styled(Button)`
  align-items: center;
  background-image: linear-gradient(to left, #e0e0e0, #eeeeee);
  color: #000000 !important;
  border-radius: 5px !important;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;
  margin-top: 8px !important;
  margin-bottom: 8px !important;

  &:hover {
    opacity: 1;
  }
`;

const CancelButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to left, #e0e0e0, #eeeeee);
  color: #000000 !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;

  &:hover {
    opacity: 1;
  }
`;
const ResetButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to left, #e0e0e0, #eeeeee);
  color: #000000 !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;
  margin-right: 40px !important;

  &:hover {
    opacity: 1;
  }
`;
const SaveButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to left, #75cabb, #50a5e2);
  color: #ffffff !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;

  &:hover {
    opacity: 1;
  }
`;

const Input = styled(Field)`
  background-color: ${(props) => (props.disabled ? 'rgba(0,0,0, 0.25)' : 'white')};
  box-sizing: border-box;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: ${(props) => (props.iserror ? 'rgba(207, 0, 15, 1)' : 'rgba(223, 227, 233, 1)')};
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 8px;
  font-family: 'Lato', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16.63px;
  color: ${(props) => (props.disabled ? 'rgba(0,0,0, 0.25)' : 'rgba(92, 93, 93, 1)')};
  text-align: left;
  line-height: 24.95px;
  opacity: ${(props) => (props.disabled ? '1' : '0.8')};
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;

const DateInput = styled(DatePicker)`
  background-color: ${(props) => (props.disabled ? 'rgba(0,0,0, 0.25)' : 'white')};
  box-sizing: border-box;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: 'rgba(223, 227, 233, 1)'};
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 8px;
  font-family: 'Lato', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16.63px;
  color: ${(props) => (props.disabled ? 'rgba(0,0,0, 0.25)' : 'rgba(92, 93, 93, 1)')};
  text-align: left;
  line-height: 24.95px;
  opacity: ${(props) => (props.disabled ? '1' : '0.8')};
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;

const OptionInput = styled(Field)`
  flex: 1;
  background-color: white;
  box-sizing: border-box;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(223, 227, 233, 1);
  margin-top: 4px;
  margin-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-family: 'Lato', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: rgba(92, 93, 93, 1);
  text-align: left;
  line-height: 16px;
  opacity: 0.8;
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;
export default function TransitionsModal({
  willOpen,
  setWillOpen,
  addFormElement,
  repeatable,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isManyOrOne, setManyOrOne] = useState('many');
  const [options, setOptions] = useState([]);

  const intl = useIntl();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setWillOpen(false);
  };

  useDeepCompareEffect(() => { }, [options]);

  useEffect(() => {
    if (willOpen) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [willOpen, handleClose]);

  const getDateFormat = (kind) => {
    if (kind === 'fullDate') {
      return 'dd/MM/yyyy';
    }
    if (kind === 'monthAndYear') {
      return 'MM/yyyy';
    }
    if (kind === 'year') {
      return 'yyyy';
    }
    if (kind === 'time') {
      return 'HH:mm';
    }
    return 'dd/MM/yyyy HH:mm';
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className={classes.paper} width={2 / 3}>
          <Formik
            initialValues={{
              name: '',
              type: 'inputText',
              hint: '',
              defaultValue: '',
              range: false,
              required: false,
              readOnly: false,
              length: false,
              regex: '',
            }}
            onSubmit={(values) => {
              let oldValues = values;
              if (oldValues.range) {
                if (oldValues.type === 'inputDate') {
                  let newRange = oldValues.range;
                  newRange = {
                    ...oldValues.range,
                    min: startDate,
                    max: endDate,
                  };
                  oldValues = {
                    ...oldValues,
                    range: { ...newRange },
                  };
                }
              }
              if (oldValues.type === 'inputDate') {
                oldValues = {
                  ...oldValues,
                  kind: oldValues.kind || 'fullDate',
                };
              }
              if (oldValues.type === 'inputSelect') {
                oldValues = {
                  ...oldValues,
                  options: [...options],
                };
                if (isManyOrOne === 'one') {
                  oldValues = {
                    ...oldValues,
                    type: 'inputSelectOne',
                  };
                } else {
                  oldValues = {
                    ...oldValues,
                    type: 'inputSelectMany',
                  };
                }
              }
              addFormElement(oldValues);
              handleClose();
            }}
          >
            {({
              errors,
              touched,
              isSubmitting,
              values,
              setFieldValue,
              resetForm,
            }) => (
              <Form>
                <div className={classes.header}>
                  <div>
                    <div className={classes.headerTitle}>
                      <FormattedMessage id="CREATE_NEW_FIELD" />
                    </div>
                  </div>
                  <div>
                    <IoMdClose
                      size={24}
                      color="rgba(0, 0, 0, 1.0)"
                      onClick={() => {
                        resetForm();
                        handleClose();
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </div>
                <Divider />
                <Paper className={classes.body}>
                  <div className={classes.bodyinside}>
                    <div className={classes.inputGroup}>
                      <div className={classes.inputTitle}><FormattedMessage id="FIELD_NAME" /> </div>
                      <Input
                        placeholder={intl.formatMessage({ id: 'ENTER_FIELD_NAME' })}
                        type="text"
                        name="name"
                        value={values.name}
                        iserror={errors.name && touched.name ? 1 : 0}
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <div className={classes.inputTitle}><FormattedMessage id="FIELD_TYPE" /> </div>
                      <Input
                        as="select"
                        name="type"
                        value={values.type}
                        onChange={(e) => {
                          setFieldValue('type', e.target.value);
                          if (e.target.value === 'inputSelect') {
                            setOptions([]);
                            setManyOrOne('many');
                          }
                        }}
                        iserror={errors.inputName && touched.inputName ? 1 : 0}
                      >
                        <option className={classes.input} value="inputText">
                          {intl.formatMessage({ id: 'TEXT' })}
                        </option>
                        <option className={classes.input} value="inputNumeric">
                          {intl.formatMessage({ id: 'NUMBER' })}
                        </option>
                        <option className={classes.input} value="inputDate">
                          {intl.formatMessage({ id: 'DATE_AND_TIME' })}
                        </option>
                        <option className={classes.input} value="inputSelect">
                          {intl.formatMessage({ id: 'OPTIONAL' })}
                        </option>
                        <option className={classes.input} value="inputImage">
                          {intl.formatMessage({ id: 'IMAGE' })}
                        </option>
                        <option className={classes.input} value="inputSignature">
                          {intl.formatMessage({ id: 'SIGNATURE' })}
                        </option>
                        {!repeatable
                          && (
                          <option className={classes.input} value="inputTitle">
                            {intl.formatMessage({ id: 'SUBJETC' })}
                          </option>
                          )
                        }
                      </Input>
                    </div>
                  </div>
                  {values.type !== 'inputTitle' && (
                  <>
                    {values.type === 'inputNumeric' && (
                    <div className={classes.formInfo}>
                      <div className={classes.checkGroup}>
                        <label>
                          <Checkbox
                            checked={!!values.range}
                            onChange={() => {
                              setFieldValue(
                                'range',
                                values.range ? false : { min: 0, max: 0 },
                              );
                            }}
                          />
                          <span
                            style={{
                              height: '24px',
                              marginLeft: 8,
                              verticalAlign: 'middle',
                              cursor: 'pointer',
                              fontFamily: 'Helvetica, Helvetica, Arial, serif',
                              fontSize: '16.0px',
                              color: 'rgba(0, 0, 0, 0.5)',
                              lineHeight: '19.0px',
                            }}
                          >
                            <FormattedMessage id="RANGE" />

                          </span>
                        </label>
                      </div>
                      <div className={classes.minMaxGroup}>
                        <div className={classes.inputGroup}>
                          <div className={classes.inputTitle}>MİN</div>
                          <Input
                            type="text"
                            name="range.min"
                            value={values.range ? values.range.min : ''}
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10) || 0;
                              setFieldValue('range.min', value);
                            }}
                            disabled={!values.range}
                          />
                        </div>
                        <div className={classes.inputGroup}>
                          <div className={classes.inputTitle}>MAX</div>
                          <Input
                            type="text"
                            name="range.max"
                            value={values.range ? values.range.max : ''}
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10) || 0;
                              setFieldValue('range.max', value);
                            }}
                            disabled={!values.range}
                          />
                        </div>
                      </div>
                    </div>
                    )}
                    {values.type === 'inputDate' && (
                    <div className={classes.dateInfo}>
                      <div className={classes.dateInfoLeft}>
                        <div className={classes.inputTitle}><FormattedMessage id="SELECTION_TYPE" /> </div>
                        <Input
                          as="select"
                          name="type"
                          value={values.kind}
                          onChange={(e) => {
                            setFieldValue('kind', e.target.value);
                          }}
                          iserror={
                            errors.inputName && touched.inputName ? 1 : 0
                          }
                        >
                          <option className={classes.input} value="fullDate">
                            {intl.formatMessage({ id: 'FULL_DATE' })}
                          </option>
                          <option
                            className={classes.input}
                            value="monthAndYear"
                          >
                            {intl.formatMessage({ id: 'ONLY_MONTH_AND_YEAR' })}
                          </option>
                          <option className={classes.input} value="year">
                            {intl.formatMessage({ id: 'ONLY_YEAR' })}
                          </option>
                          <option className={classes.input} value="time">
                            {intl.formatMessage({ id: 'TIME' })}
                          </option>
                          <option
                            className={classes.input}
                            value="fullDateAndTime"
                          >
                            {intl.formatMessage({ id: 'FULL_DATE_TIME' })}
                          </option>
                        </Input>
                      </div>
                      <div className={classes.checkMinMaxGroup}>
                        <div className={classes.checkMinMaxGroupSide}>
                          <div className={classes.checkMinMaxGroupTop}>
                            <div className={classes.checkMinMaxGroupSide}>
                              <label>
                                <Checkbox
                                  checked={!!values.range}
                                  onChange={() => {
                                    setFieldValue(
                                      'range',
                                      values.range
                                        ? !values.range
                                        : { min: 0, max: 0 },
                                    );
                                  }}
                                />
                                <span
                                  style={{
                                    height: '24px',
                                    marginLeft: 8,
                                    verticalAlign: 'middle',
                                    cursor: 'pointer',
                                    fontFamily:
                                            'Helvetica, Helvetica, Arial, serif',
                                    fontSize: '16.0px',
                                    color: 'rgba(0, 0, 0, 0.5)',
                                    lineHeight: '19.0px',
                                  }}
                                >
                                  Min
                                </span>
                              </label>
                            </div>
                            <div className={classes.checkMinMaxGroupSide}>
                              <label>
                                <Checkbox
                                  checked={
                                          values.range
                                            ? !!values.range.minInclusive
                                            : false
                                        }
                                  onChange={() => {
                                    setFieldValue(
                                      'range.minInclusive',
                                      !values.range.minInclusive,
                                    );
                                  }}
                                  disabled={!values.range}
                                />
                                <span
                                  style={{
                                    height: '24px',
                                    marginLeft: 8,
                                    verticalAlign: 'middle',
                                    cursor: 'pointer',
                                    fontFamily:
                                            'Helvetica, Helvetica, Arial, serif',
                                    fontSize: '16.0px',
                                    color: 'rgba(0, 0, 0, 0.5)',
                                    lineHeight: '19.0px',
                                  }}
                                >
                                  Inclusive
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className={classes.checkMinMaxGroupBottom}>
                            <DateInput
                              locale="tr"
                              disabled={!values.range}
                              selected={startDate}
                              value={startDate}
                              onChange={(date) => setStartDate(date)}
                              showTimeSelect={
                                      values.kind === 'time'
                                      || values.kind === 'fullDateAndTime'
                                    }
                              timeIntervals={1}
                              timeFormat="HH:mm"
                              showTimeSelectOnly={values.kind === 'time'}
                              showMonthYearPicker={
                                      values.kind === 'monthAndYear'
                                    }
                              showYearPicker={values.kind === 'year'}
                              withPortal
                              timeCaption="Saat"
                              dateFormat={getDateFormat(values.kind)}
                            />
                          </div>
                        </div>
                        <div className={classes.checkMinMaxGroupSide}>
                          <div className={classes.checkMinMaxGroupTop}>
                            <div className={classes.checkMinMaxGroupSide}>
                              <label>
                                <Checkbox
                                  checked={!!values.range}
                                  onChange={() => {
                                    setFieldValue(
                                      'range',
                                      values.range
                                        ? !values.range
                                        : { min: 0, max: 0 },
                                    );
                                  }}
                                />
                                <span
                                  style={{
                                    height: '24px',
                                    marginLeft: 8,
                                    verticalAlign: 'middle',
                                    cursor: 'pointer',
                                    fontFamily:
                                            'Helvetica, Helvetica, Arial, serif',
                                    fontSize: '16.0px',
                                    color: 'rgba(0, 0, 0, 0.5)',
                                    lineHeight: '19.0px',
                                  }}
                                >
                                  Max
                                </span>
                              </label>
                            </div>
                            <div className={classes.checkMinMaxGroupSide}>
                              <label>
                                <Checkbox
                                  checked={
                                          values.range
                                            ? values.range.maxInclusive
                                            : false
                                        }
                                  onChange={() => {
                                    setFieldValue(
                                      'values.range.maxInclusive',
                                      values.range
                                        ? !values.range.maxInclusive
                                        : true,
                                    );
                                  }}
                                />
                                <span
                                  style={{
                                    height: '24px',
                                    marginLeft: 8,
                                    verticalAlign: 'middle',
                                    cursor: 'pointer',
                                    fontFamily:
                                            'Helvetica, Helvetica, Arial, serif',
                                    fontSize: '16.0px',
                                    color: 'rgba(0, 0, 0, 0.5)',
                                    lineHeight: '19.0px',
                                  }}
                                >
                                  Inclusive
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className={classes.checkMinMaxGroupBottom}>
                            <DateInput
                              locale="tr"
                              disabled={!values.range}
                              selected={endDate}
                              value
                              onChange={(date) => setEndDate(date)}
                              showTimeSelect={
                                      values.kind === 'time'
                                      || values.kind === 'fullDateAndTime'
                                    }
                              timeIntervals={1}
                              timeFormat="HH:mm"
                              showTimeSelectOnly={values.kind === 'time'}
                              showMonthYearPicker={
                                      values.kind === 'monthAndYear'
                                    }
                              showYearPicker={values.kind === 'year'}
                              withPortal
                              timeCaption="Saat"
                              dateFormat={getDateFormat(values.kind)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    )}
                    {values.type === 'inputSelect' && (
                    <div className={classes.optionsInfo}>
                      <div className={classes.checkOptionsGroup}>
                        <label>
                          <Checkbox
                            checked={isManyOrOne === 'many'}
                            onChange={() => {
                              setManyOrOne(isManyOrOne === 'many' ? 'one' : 'many');
                            }}
                          />
                          <span
                            style={{
                              height: '24px',
                              marginLeft: 8,
                              verticalAlign: 'middle',
                              cursor: 'pointer',
                              fontFamily: 'Helvetica, Helvetica, Arial, serif',
                              fontSize: '16.0px',
                              color: 'rgba(0, 0, 0, 0.5)',
                              lineHeight: '19.0px',
                            }}
                          >
                            <FormattedMessage id="MULTIPLE_SELECTION" />
                          </span>
                        </label>
                        <label>
                          <Checkbox
                            checked={isManyOrOne === 'one'}
                            onChange={() => {
                              setManyOrOne(isManyOrOne === 'one' ? 'many' : 'one');
                            }}
                          />
                          <span
                            style={{
                              height: '24px',
                              marginLeft: 8,
                              verticalAlign: 'middle',
                              cursor: 'pointer',
                              fontFamily: 'Helvetica, Helvetica, Arial, serif',
                              fontSize: '16.0px',
                              color: 'rgba(0, 0, 0, 0.5)',
                              lineHeight: '19.0px',
                            }}
                          >
                            <FormattedMessage id="SINGLE_SELECTION" />
                          </span>
                        </label>
                        <AddButton
                          variant="contained"
                          size="medium"
                          onClick={() => {
                            options.push({
                              name: `option-${options.length}`,
                              text: { 0: '' },
                              val: '',
                            });
                            setOptions([...options]);
                          }}
                        >
                          <FormattedMessage id="ADD_OPTION" />
                        </AddButton>
                        <AddButton
                          variant="contained"
                          size="medium"
                          onClick={() => {
                            setOptions([]);
                          }}
                        >
                          <FormattedMessage id="RESET" />
                        </AddButton>
                        <span
                          style={{
                            height: '24px',
                            marginLeft: 8,
                            verticalAlign: 'middle',
                            cursor: 'pointer',
                            fontFamily: 'Helvetica, Helvetica, Arial, serif',
                            fontSize: '16.0px',
                            color: 'rgba(0, 0, 0, 0.5)',
                            lineHeight: '19.0px',
                          }}
                        >
                          <FormattedMessage id="READY_TEMPLATES" /> :
                        </span>
                        <AddButton
                          variant="contained"
                          size="medium"
                          onClick={() => {
                            setOptions([
                              {
                                name: 'option-yes',
                                text: { 0: 'Yes' },
                                val: 'YES',
                              },
                              {
                                name: 'option-no',
                                text: { 0: 'No' },
                                val: 'NO',
                              },
                              {
                                name: 'option-na',
                                text: { 0: 'N/A' },
                                val: 'N/A',
                              },
                            ]);
                            setManyOrOne('one');
                          }}
                        >
                          Yes / No / N/A
                        </AddButton>
                        <AddButton
                          variant="contained"
                          size="medium"
                          onClick={() => {
                            setOptions([
                              {
                                name: 'option-yes',
                                text: { 0: 'Pass' },
                                val: 'PASS',
                              },
                              {
                                name: 'option-no',
                                text: { 0: 'Fail' },
                                val: 'FAIL',
                              },
                              {
                                name: 'option-na',
                                text: { 0: 'N/A' },
                                val: 'N/A',
                              },
                            ]);
                            setManyOrOne('one');
                          }}
                        >
                          Pass / Fail / N/A
                        </AddButton>
                      </div>
                      <div className={classes.optionsGroup}>
                        <Grid container spacing={2}>
                          {options.map((option, index) => (
                            <Grid
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                              item
                              xs={6}
                            >
                              <div
                                style={{
                                  flex: 1,
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <div
                                  style={{
                                    flex: 1,
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    fontSize: '14px',
                                  }}
                                >
                                  <FormattedMessage id="OPTION" />
                                </div>
                              </div>
                              <div
                                style={{
                                  flex: 1,
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <OptionInput
                                  type="text"
                                  name="optionText"
                                  value={option.text['0']}
                                  onChange={(e) => {
                                    options[index] = {
                                      text: {
                                        0: e.target.value,
                                      },
                                      val: e.target.value,
                                    };

                                    setOptions([...options]);
                                  }}
                                />
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    </div>
                    )}
                    <div className={classes.bodyinside}>
                      <div className={classes.inputGroup}>
                        <div className={classes.inputTitle}><FormattedMessage id="PLACEHOLDER" /></div>
                        <Input
                          placeholder="Alan yazısını giriniz..."
                          type="text"
                          name="hint"
                          value={values.hint}
                          iserror={errors.hint && touched.hint ? 1 : 0}
                        />
                      </div>
                    </div>
                    <div className={classes.formInfo}>
                      {(values.type !== 'inputImage' && values.type !== 'inputSignature') && (
                      <div className={classes.inputGroup}>
                        <div className={classes.inputTitle}><FormattedMessage id="DEFAULT_VALUE" /> </div>
                        <Input
                          placeholder="Varsayılan değer varsa giriniz..."
                          type="text"
                          name="defaultValue"
                          value={values.defaultValue}
                          iserror={
                                  errors.defaultValue && touched.defaultValue ? 1 : 0
                                }
                          onChange={(e) => {
                            let newValue = e.target.value;
                            if (values.type === 'inputNumeric') {
                              if (newValue.length !== 0) {
                                newValue = parseInt(newValue, 10) || 0;
                              }
                            }

                            setFieldValue('defaultValue', newValue);
                          }}
                        />
                      </div>
                      )}
                      <div className={classes.checkGroup}>
                        <label style={{ marginRight: '16px' }}>
                          <Checkbox
                            checked={values.required}
                            onChange={() => {
                              setFieldValue('required', !values.required);
                            }}
                          />
                          <span
                            style={{
                              height: '24px',
                              marginLeft: 8,
                              verticalAlign: 'middle',
                              cursor: 'pointer',
                              fontFamily: 'Helvetica, Helvetica, Arial, serif',
                              fontSize: '16.0px',
                              color: 'rgba(0, 0, 0, 0.5)',
                              lineHeight: '19.0px',
                            }}
                          >
                            <FormattedMessage id="REQUIRED_FIELD" />
                          </span>
                        </label>
                        <label style={{ marginRight: '16px' }}>
                          <Checkbox
                            checked={values.readOnly}
                            onChange={() => {
                              setFieldValue('readOnly', !values.readOnly);
                            }}
                          />
                          <span
                            style={{
                              height: '24px',
                              marginLeft: 8,
                              verticalAlign: 'middle',
                              cursor: 'pointer',
                              fontFamily: 'Helvetica, Helvetica, Arial, serif',
                              fontSize: '16.0px',
                              color: 'rgba(0, 0, 0, 0.5)',
                              lineHeight: '19.0px',
                            }}
                          >
                            <FormattedMessage id="READ_ONLY" />
                          </span>
                        </label>
                        {(values.type !== 'inputImage' && values.type !== 'inputSelect' && values.type !== 'inputDate' && values.type !== 'inputSignature') && (
                        <label style={{ marginRight: '16px' }}>
                          <Checkbox
                            checked={!!values.length}
                            onChange={() => {
                              setFieldValue(
                                'length',
                                values.length ? false : { min: 0, max: 0 },
                              );
                            }}
                          />
                          <span
                            style={{
                              height: '24px',
                              marginLeft: 8,
                              verticalAlign: 'middle',
                              cursor: 'pointer',
                              fontFamily: 'Helvetica, Helvetica, Arial, serif',
                              fontSize: '16.0px',
                              color: 'rgba(0, 0, 0, 0.5)',
                              lineHeight: '19.0px',
                            }}
                          >
                            <FormattedMessage id="LENGTH" />
                          </span>
                        </label>
                        )}
                      </div>
                      {(values.type !== 'inputImage' && values.type !== 'inputSelect' && values.type !== 'inputDate' && values.type !== 'inputSignature') && (
                      <div className={classes.minMaxGroup}>
                        <div className={classes.inputGroup}>
                          <div className={classes.inputTitle}>MİN</div>
                          <Input
                            placeholder="Varsayılan değer varsa giriniz..."
                            type="text"
                            name="length.min"
                            value={values.length ? values.length.min : ''}
                            onChange={(e) => {
                              let value = parseInt(e.target.value, 10) || 0;
                              if (value < 0) value = 0;
                              setFieldValue('length.min', value);
                            }}
                            disabled={!values.length}
                          />
                        </div>
                        <div className={classes.inputGroup}>
                          <div className={classes.inputTitle}>MAX</div>
                          <Input
                            placeholder="Varsayılan değer varsa giriniz..."
                            type="text"
                            name="length.max"
                            value={values.length ? values.length.max : ''}
                            onChange={(e) => {
                              let value = parseInt(e.target.value, 10) || 0;
                              if (value < 0) value = 0;
                              setFieldValue('length.max', value);
                            }}
                            disabled={!values.length}
                          />
                        </div>
                      </div>
                      )}
                      {(values.type !== 'inputImage' && values.type !== 'inputSelect' && values.type !== 'inputDate' && values.type !== 'inputSignature') && (
                      <div className={classes.inputGroup}>
                        <div className={classes.inputTitle}>REGEX</div>
                        <Input
                          placeholder="Lütfen doğrulama için regex değerini giriniz..."
                          type="text"
                          name="regex"
                          value={values.regex}
                          iserror={errors.regex && touched.regex ? 1 : 0}
                        />
                      </div>
                      )}
                    </div>
                  </>
                  )}
                </Paper>
                <div className={classes.buttonGroup}>
                  <CancelButton
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      resetForm();
                      handleClose();
                    }}
                  >
                    <FormattedMessage id="CANCEL" />
                  </CancelButton>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <SaveButton
                      variant="contained"
                      size="medium"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <FormattedMessage id="SAVE" />
                    </SaveButton>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Fade>
    </Modal>
  );
}
