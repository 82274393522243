import React from 'react';
import styled from 'styled-components';

import { Paper } from '@material-ui/core';

import { FormattedMessage } from 'react-intl';
import headerLogo from '../../assets/header/smallLogo.png';

const CompanyPaper = styled(Paper)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`;

const CompanyTitle = styled.span`
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  color: #595469;
  margin-top: 10px;
`;

const Info = styled.span`
  margin-top: 2px;
  font-size: 16px;
  line-height: 18px;
  color: rgba(89, 84, 105, 0.7);
`;

const ComponyInfo = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-between;
  margin-top: 25px;
`;

const CompanyLogo = styled.img`
  height: 75px;
  width: auto;
`;

function CompanyBox(props) {
  const { companyList, handleCompany } = props;
  return (
    companyList.map((company, index) => (
      <CompanyPaper
        elevation={3}
        key={`companies-page-projet-key-${index}`}
        onClick={() => handleCompany(company.id)}
      >
        <CompanyLogo src={company.logo !== '' ? company.logo : headerLogo} alt={company.company_name} />
        <CompanyTitle>{company.company_name}</CompanyTitle>
        <ComponyInfo>
          <Info><FormattedMessage id="USERS" /> :{company.user_count}</Info>
          <Info><FormattedMessage id="GROUPS" /> : {company.group_count}</Info>
        </ComponyInfo>
      </CompanyPaper>
    ))
  );
}

export default CompanyBox;
