import React, { useState } from 'react';
import styled from 'styled-components';
import { OutlinedInput, InputAdornment, IconButton } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

const SearchBarContainer = styled.div`
  display: flex;
  align-self: center;
  margin-right: 8px;
  margin-left: 20px;
`;

const SearchLabel = styled.span`
  text-align: center;
  padding-top: 5px;
  margin-right: 8px;
  font-size: 18px;
`;

const SearchInput = styled(OutlinedInput)`
  width: 20vw;
  padding: 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 5px;
`;

const SearchBar = (props) => {
  const { searchFunction, handleSearchText } = props;

  const [searchText, setSearchText] = useState('');
  const [searchInterval, setSearchInterval] = useState(null);
  let timer = null;

  const intl = useIntl();

  const handleChange = (value) => {
    setSearchText(value);
    // handlesearch funcftion invoke after 2 secconds
    clearTimeout(searchInterval);
    timer = setTimeout(() => {
      // handleSearchText(value);
      searchFunction(value);
    }, 2000);
    setSearchInterval(timer);
  };

  const handleSearch = () => {
    searchFunction(searchText);
  };
  return (
    <SearchBarContainer>
      <SearchLabel inside={props.inside}>
        <FormattedMessage id="FILTER" /> :
      </SearchLabel>
      <SearchInput
        placeholder={intl.formatMessage({ id: 'SEARCH_PLACEHOLDER' })}
        margin="none"
        variant="outlined"
        size="small"
        onChange={(e) => handleChange(e.target.value)}
        inside={props.inside}
        inputProps={{
          style: {
            padding: '6px',
          },
        }}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              style={{ fontSize: '1rem', marginRight: '0px' }}
            >
              <div className="icon-magnifier" />
            </IconButton>
          </InputAdornment>
        )}
      />
    </SearchBarContainer>
  );
};

export default SearchBar;
