import React from 'react';
import styled from 'styled-components';
import Task from 'components/Task';
// import Task from 'components/Task/Task';

const TaskContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: auto;
  background-color: rgba(245, 245, 245, 1);
`;

export default React.forwardRef((props, ref) => (
  <TaskContainer>
    <Task {...props} ref={ref} />
  </TaskContainer>
));
