import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import {
  FaPencilAlt,
  FaPlus,
  FaChevronDown,
  FaChevronUp
} from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { TiTick } from 'react-icons/ti';

import { Select, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { roleSetsSelector } from 'slices';
import { FormattedMessage } from 'react-intl';
import MenuButton from '../Menu/MenuButton';
import AddFormModal from './AddFormModal';
import EditFormModal from './EditFormModal';

import {
  useDeepCompareEffect,
  deepCompareEquals
} from '../../helpers/deepcompare';

const AddSubFormButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to right, #f34a46, rgba(250, 100, 0, 1));
  color: #ffffff !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FormContainer = styled.div`
  margin-top: 50px;
  height: 45vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Input = styled(TextField)`
  background-color: ${(props) =>
    props.disabled ? 'rgba(0,0,0, 0.25)' : 'white'};
  box-sizing: border-box;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(223, 227, 233, 1);
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 8px;
  font-family: 'Lato', Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16.63px;
  color: rgba(92, 93, 93, 1);
  text-align: left;
  line-height: 24.95px;
  opacity: 0.8;
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;

export const Attributes = styled.span`
  font-family: 'Lato', Helvetica, Arial, serif;
  font-size: calc(4px + 0.5vw + 0.5vh);
  line-height: calc((4px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((4px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: ${(props) => (props.required ? '#ff0000' : 'rgba(255,100,0,1)')};
  border: 1px solid
    ${(props) => (props.required ? '#ff0000' : 'rgba(255,100,0,1)')};
  border-radius: 5px;
  padding: 3px 6px;
  margin-left: 10px;
`;

export const getType = (type) => {
  switch (type) {
    case 'inputText':
      return 'TEXT';
    case 'inputNumeric':
      return 'NUMBER';
    case 'inputDate':
      return 'DATE_AND_TIME';
    case 'inputSelectMany':
      return 'MULTIPLE_SELECTION';
    case 'inputSelectOne':
      return 'SINGLE_SELECTION';
    case 'inputImage':
      return 'IMAGE';
    case 'inputSignature':
      return 'SIGNATURE';
    case 'inputTitle':
      return 'SUBJECT';
    default:
      return null;
  }
};

export default forwardRef((props, ref) => {
  const { template, setFormChanged, formData, setFormData } = props;

  let { template: jsonData } = template;

  if (typeof jsonData === 'string') {
    jsonData = JSON.parse(jsonData);
  }

  const [addModalValue, setAddModalValue] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [addRepModalOpen, setAddRepModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [subFormEditing, setSubFormEditing] = useState(null);
  const [subFormValue, setSubFormValue] = useState('');

  const [modalValues, setModalValues] = useState({});

  const { rolesSets } = useSelector(roleSetsSelector);

  useDeepCompareEffect(() => {}, [formData]);

  const openAddModal = (formIndex) => {
    setAddModalValue(formIndex);
    setAddModalOpen(true);
    setAddRepModalOpen(false);
  };

  const openAddRepModal = (formIndex, controlIndex) => {
    setAddModalValue({ formIndex, controlIndex });
    setAddRepModalOpen(true);
    setAddModalOpen(false);
  };

  const openEditModal = (values) => {
    setEditModalOpen(true);
    setModalValues({ ...values });
  };

  const setAllClose = () => {
    setAddModalOpen(false);
    setEditModalOpen(false);
  };

  const editFormElement = (props) => {
    const { controlIndex, elementIndex, formIndex, values } = props;
    if (elementIndex === -1) {
      const oldFormElements = formData.subForms[formIndex].controls;
      const oldSubForm = formData.subForms;

      let newFormName = values.name || 'input';
      const hint = values.hint || null;
      const label = hint;

      if (
        oldFormElements.filter(
          (formElement, index) =>
            formElement.name === newFormName && index !== controlIndex
        ).length > 0
      ) {
        let counter = 1;

        while (true) {
          if (
            oldFormElements.filter(
              (formElement) =>
                formElement.name === newFormName.concat(counter.toString())
            ).length > 0
          ) {
            counter += 1;
          } else {
            break;
          }
        }

        newFormName = newFormName.concat(counter.toString());
      }

      const newFormElement = {
        ...values,
        name: newFormName,
        label: label && typeof label !== 'object' ? { 0: label } : {},
        hint: hint && typeof hint !== 'object' ? { 0: hint } : {}
      };

      oldFormElements[controlIndex] = { ...newFormElement };

      oldSubForm[formIndex] = {
        ...oldSubForm[formIndex],
        controls: oldFormElements
      };

      setFormData({ ...formData, subForms: oldSubForm });

      setAllClose();

      if (!deepCompareEquals(formData, jsonData)) {
        setFormChanged(true);
      } else {
        setFormChanged(false);
      }
    } else {
      const oldRepeatables =
        formData.subForms[formIndex].controls[controlIndex].children;
      const oldFormElements = formData.subForms[formIndex].controls;
      const oldSubForm = formData.subForms;

      let newFormName = values.name || 'input';
      const hint = values.hint || null;
      const label = hint;

      if (
        oldRepeatables.filter(
          (formElement, index) =>
            formElement.name === newFormName && index !== elementIndex
        ).length > 0
      ) {
        let counter = 1;

        while (true) {
          if (
            oldRepeatables.filter(
              (formElement) =>
                formElement.name === newFormName.concat(counter.toString())
            ).length > 0
          ) {
            counter += 1;
          } else {
            break;
          }
        }

        newFormName = newFormName.concat(counter.toString());
      }

      const newFormElement = {
        ...values,
        name: newFormName,
        label: label && typeof label !== 'object' ? { 0: label } : {},
        hint: hint && typeof hint !== 'object' ? { 0: hint } : {}
      };

      oldRepeatables[elementIndex] = { ...newFormElement };

      oldFormElements[controlIndex] = {
        ...oldFormElements[controlIndex],
        children: oldRepeatables
      };

      oldSubForm[formIndex] = {
        ...oldSubForm[formIndex],
        controls: oldFormElements
      };

      setFormData({ ...formData, subForms: oldSubForm });

      setAllClose();

      if (!deepCompareEquals(formData, jsonData)) {
        setFormChanged(true);
      } else {
        setFormChanged(false);
      }
    }
  };

  const forms = formData.subForms.map((form, formIndex) => {
    const addRepeatable = () => {
      const oldFormElements = form.controls;
      const oldSubForm = formData.subForms;

      const newRepeatableName = 'repeatable';
      let counter = 1;

      while (true) {
        if (
          oldFormElements.filter(
            (formElement) =>
              formElement.name === newRepeatableName.concat(counter.toString())
          ).length > 0
        ) {
          counter += 1;
        } else {
          break;
        }
      }

      const newRepeatable = {
        name: newRepeatableName.concat(counter.toString()),
        type: 'group',
        children: []
      };

      oldFormElements.push(newRepeatable);

      oldSubForm[formIndex] = {
        ...oldSubForm[formIndex],
        controls: oldFormElements
      };

      setFormData({ ...formData, subForms: oldSubForm });

      if (!deepCompareEquals(formData, jsonData)) {
        setFormChanged(true);
      } else {
        setFormChanged(false);
      }
    };

    const addFormElement = (addFormProps) => {
      const oldSubForm = formData.subForms;
      const oldForm = oldSubForm[addModalValue];
      const oldFormElements = oldForm.controls;

      let newFormName = addFormProps.name || 'input';
      const hint = addFormProps.hint || null;
      const label = hint;

      if (
        oldFormElements.filter(
          (formElement) => formElement.name === newFormName
        ).length > 0
      ) {
        let counter = 1;

        while (true) {
          if (
            oldFormElements.filter(
              (formElement) =>
                formElement.name === newFormName.concat(counter.toString())
            ).length > 0
          ) {
            counter += 1;
          } else {
            break;
          }
        }

        newFormName = newFormName.concat(counter.toString());
      }

      const newFormElement = {
        ...addFormProps,
        name: newFormName,
        label: label && typeof label !== 'object' ? { 0: label } : {},
        hint: hint && typeof hint !== 'object' ? { 0: hint } : {}
      };

      oldFormElements.push(newFormElement);

      oldSubForm[addModalValue] = {
        ...oldSubForm[addModalValue],
        controls: oldFormElements
      };

      setFormData({ ...formData, subForms: oldSubForm });

      setAllClose();

      if (!deepCompareEquals(formData, jsonData)) {
        setFormChanged(true);
      } else {
        setFormChanged(false);
      }
    };

    return (
      <div
        key={`${form.name}`}
        style={{
          borderRadius: '5px',
          borderStyle: 'solid',
          borderWidth: '2px',
          borderColor: 'rgba(250, 100, 0, 1.0)',
          padding: '18px',
          marginBottom: '20px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <span
          style={{
            fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
            fontSize: '14px',
            color: 'rgba(250, 100, 0, 1.0)',
            textAlign: 'left',
            lineHeight: '17.0px',
            fontWeight: 'bold',
            textTransform: 'uppercase'
          }}
        >
          <FormattedMessage id="SUB_FORM" /> :{' '}
          {subFormEditing === formIndex ? (
            <Input
              type="text"
              name="subFormName"
              onChange={(e) => {
                setSubFormValue(e.target.value);
              }}
              value={subFormValue}
            />
          ) : (
            form.name
          )}
          {subFormEditing === formIndex && (
            <IconButton
              aria-controls="fade-menu"
              aria-haspopup="true"
              onClick={() => setSubFormEditing(null)}
            >
              <IoMdClose size={24} color="red" />
            </IconButton>
          )}
          {subFormEditing === formIndex && (
            <IconButton
              aria-controls="fade-menu"
              aria-haspopup="true"
              onClick={() => {
                setSubFormEditing(null);

                const oldSubForm = formData.subForms;

                oldSubForm[formIndex] = {
                  ...formData.subForms[formIndex],
                  name: subFormValue
                };

                setFormData({ ...formData, subForms: oldSubForm });

                if (!deepCompareEquals(formData, jsonData)) {
                  setFormChanged(true);
                } else {
                  setFormChanged(false);
                }
              }}
            >
              <TiTick size={24} color="green" />
            </IconButton>
          )}
          {subFormEditing === formIndex || (
            <IconButton
              aria-controls="fade-menu"
              aria-haspopup="true"
              onClick={() => {
                setSubFormEditing(formIndex);
                setSubFormValue(form.name);
              }}
            >
              <FaPencilAlt size={18} color="rgba(250, 100, 0, 1.0)" />
            </IconButton>
          )}
          {addModalOpen && (
            <AddFormModal
              willOpen={addModalOpen}
              setWillOpen={setAddModalOpen}
              addFormElement={addFormElement}
              formIndex={formIndex}
            />
          )}
          <MenuButton
            list={[
              {
                element: 'Tekrarlanabilir',
                clickEvent() {
                  addRepeatable();
                }
              },
              {
                element: 'Form Alanı',
                clickEvent() {
                  openAddModal(formIndex);
                }
              }
            ]}
          >
            <FaPlus size={18} color="rgba(250, 100, 0, 1.0)" />
          </MenuButton>
          <IconButton
            aria-controls="fade-menu"
            aria-haspopup="true"
            onClick={() => {
              const oldSubForm = formData.subForms;

              oldSubForm.splice(formIndex + 2, 0, form);
              oldSubForm.splice(formIndex, 1);

              setFormData({ ...formData, subForms: oldSubForm });

              if (!deepCompareEquals(formData, jsonData)) {
                setFormChanged(true);
              } else {
                setFormChanged(false);
              }
            }}
          >
            <FaChevronDown
              size={18}
              color={
                formIndex === formData.subForms.length - 1
                  ? 'rgba(250, 100, 0, 0.2)'
                  : 'rgba(250, 100, 0, 1.0)'
              }
            />
          </IconButton>
          <IconButton
            aria-controls="fade-menu"
            aria-haspopup="true"
            onClick={() => {
              const oldSubForm = formData.subForms;

              oldSubForm.splice(formIndex - 1, 0, form);
              oldSubForm.splice(formIndex + 1, 1);

              setFormData({ ...formData, subForms: oldSubForm });

              if (!deepCompareEquals(formData, jsonData)) {
                setFormChanged(true);
              } else {
                setFormChanged(false);
              }
            }}
          >
            <FaChevronUp
              size={18}
              color={
                formIndex === 0
                  ? 'rgba(250, 100, 0, 0.2)'
                  : 'rgba(250, 100, 0, 1.0)'
              }
            />
          </IconButton>
          <IconButton
            aria-controls="fade-menu"
            aria-haspopup="true"
            style={{ float: 'right' }}
            onClick={() => {
              const oldSubForm = formData.subForms;

              oldSubForm.splice(formIndex, 1);

              setFormData({ ...formData });

              if (!deepCompareEquals(formData, jsonData)) {
                setFormChanged(true);
              } else {
                setFormChanged(false);
              }
            }}
          >
            <IoMdClose size={24} color="rgba(250, 100, 0, 1.0)" />
          </IconButton>
          <div
            style={{
              float: 'right',
              fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
              fontSize: '14px',
              color: 'rgba(250, 100, 0, 1.0)',
              textAlign: 'left',
              lineHeight: '17.0px',
              fontWeight: 'bold',
              minHeight: '5vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <sapn style={{ marginRight: '10px' }}>
              <FormattedMessage id="COMPANY_ROLE" />:
            </sapn>
            <Select
              variant="outlined"
              margin="dense"
              style={{
                minWidth: '10vw'
              }}
              value={form.role_id}
              onChange={(e, child) => {
                const oldSubForm = formData.subForms;
                oldSubForm[formIndex] = {
                  ...oldSubForm[formIndex],
                  role_id: e.target.value,
                  role_name: child.props.children
                };

                setFormData({ ...formData, subForms: oldSubForm });
              }}
            >
              {rolesSets.map((role) => (
                <MenuItem value={role.id}>{role.role_name}</MenuItem>
              ))}
            </Select>
          </div>
        </span>
        {form.controls.map((control, controlIndex) => {
          if (control.type === 'group') {
            const addRepFormElement = (addFormProps) => {
              const oldSubForm = formData.subForms;
              const oldForm = oldSubForm[addModalValue.formIndex];
              const oldFormElements = oldForm.controls;
              const oldControl = oldFormElements[addModalValue.controlIndex];
              const oldRepeatables = oldControl.children;

              let newFormName = addFormProps.name || 'input';
              const hint = addFormProps.hint || null;
              const label = hint || null;

              if (
                oldRepeatables.filter(
                  (formElement) => formElement.name === newFormName
                ).length > 0
              ) {
                let counter = 1;

                while (true) {
                  if (
                    oldRepeatables.filter(
                      (formElement) =>
                        formElement.name ===
                        newFormName.concat(counter.toString())
                    ).length > 0
                  ) {
                    counter += 1;
                  } else {
                    break;
                  }
                }

                newFormName = newFormName.concat(counter.toString());
              }

              const newFormElement = {
                ...addFormProps,
                name: newFormName,
                label: label && typeof label !== 'object' ? { 0: label } : {},
                hint: hint && typeof hint !== 'object' ? { 0: hint } : {}
              };

              oldRepeatables.push(newFormElement);

              oldFormElements[addModalValue.controlIndex] = {
                ...oldFormElements[addModalValue.controlIndex],
                children: oldRepeatables
              };

              oldSubForm[addModalValue.formIndex] = {
                ...oldSubForm[addModalValue.formIndex],
                controls: oldFormElements
              };

              setFormData({ ...formData, subForms: oldSubForm });

              setAllClose();

              if (!deepCompareEquals(formData, jsonData)) {
                setFormChanged(true);
              } else {
                setFormChanged(false);
              }
            };

            return (
              <div
                style={{
                  borderRadius: '5px',
                  borderStyle: 'solid',
                  borderWidth: '2px',
                  borderColor: 'rgba(182, 32, 224, 1.0)',
                  padding: '18px',
                  marginTop: '20px',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span
                  style={{
                    fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
                    fontSize: '14px',
                    color: 'rgba(182, 32, 224, 1.0)',
                    textAlign: 'left',
                    lineHeight: '17.0px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase'
                  }}
                >
                  <FormattedMessage id="REPEATABLE" />

                  {addRepModalOpen && (
                    <AddFormModal
                      willOpen={addRepModalOpen}
                      setWillOpen={setAddRepModalOpen}
                      addFormElement={addRepFormElement}
                      formIndex={formIndex}
                      repeatable
                    />
                  )}
                  <IconButton
                    aria-controls="fade-menu"
                    aria-haspopup="true"
                    onClick={() => {
                      openAddRepModal(formIndex, controlIndex);
                    }}
                  >
                    <FaPlus size={18} color="rgba(182, 32, 224, 1.0)" />
                  </IconButton>
                  <IconButton
                    aria-controls="fade-menu"
                    aria-haspopup="true"
                    style={{ float: 'right' }}
                    onClick={() => {
                      const oldFormElements = form.controls;
                      const oldSubForm = formData.subForms;

                      oldFormElements.splice(controlIndex, 1);

                      oldSubForm[formIndex] = {
                        ...oldSubForm[formIndex],
                        controls: oldFormElements
                      };

                      setFormData({ ...formData, subForms: oldSubForm });

                      if (!deepCompareEquals(formData, jsonData)) {
                        setFormChanged(true);
                      } else {
                        setFormChanged(false);
                      }
                    }}
                  >
                    <IoMdClose size={24} color="rgba(182, 32, 224, 1.0)" />
                  </IconButton>
                  <IconButton
                    aria-controls="fade-menu"
                    aria-haspopup="true"
                    onClick={() => {
                      const oldFormElements = form.controls;
                      const oldSubForm = formData.subForms;

                      oldFormElements.splice(controlIndex + 2, 0, control);
                      oldFormElements.splice(controlIndex, 1);

                      setFormData({ ...formData, subForms: oldSubForm });

                      if (!deepCompareEquals(formData, jsonData)) {
                        setFormChanged(true);
                      } else {
                        setFormChanged(false);
                      }
                    }}
                  >
                    <FaChevronDown
                      size={18}
                      color={
                        controlIndex === form.controls.length - 1
                          ? 'rgba(182, 32, 224, 0.2)'
                          : 'rgba(182, 32, 224, 1.0)'
                      }
                    />
                  </IconButton>
                  <IconButton
                    aria-controls="fade-menu"
                    aria-haspopup="true"
                    onClick={() => {
                      const oldFormElements = form.controls;
                      const oldSubForm = formData.subForms;

                      oldFormElements.splice(controlIndex - 1, 0, control);
                      oldFormElements.splice(controlIndex + 1, 1);

                      setFormData({ ...formData, subForms: oldSubForm });

                      if (!deepCompareEquals(formData, jsonData)) {
                        setFormChanged(true);
                      } else {
                        setFormChanged(false);
                      }
                    }}
                  >
                    <FaChevronUp
                      size={18}
                      color={
                        controlIndex === 0
                          ? 'rgba(182, 32, 224, 0.2)'
                          : 'rgba(182, 32, 224, 1.0)'
                      }
                    />
                  </IconButton>
                </span>
                {control.children.map((element, elementIndex) => (
                  <div
                    key={`${form.name}-${control.name}-${element.name}`}
                    style={{
                      borderRadius: '5px',
                      borderStyle: 'solid',
                      borderWidth: '2px',
                      borderColor: 'rgba(109, 114, 120, 1.0)',
                      padding: '18px',
                      marginTop: '20px',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <span
                      style={{
                        fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
                        fontSize: '14px',
                        color: 'rgba(109, 114, 120, 1.0)',
                        textAlign: 'left',
                        lineHeight: '17.0px',
                        fontWeight: 'bold'
                      }}
                    >
                      <FormattedMessage id="FORM_FIELD" />
                      <IconButton
                        aria-controls="fade-menu"
                        aria-haspopup="true"
                        onClick={() => {
                          openEditModal({
                            control: element,
                            formIndex,
                            controlIndex,
                            elementIndex
                          });
                        }}
                      >
                        <FaPencilAlt
                          size={18}
                          color="rgba(109, 114, 120, 1.0)"
                        />
                      </IconButton>
                      <IconButton
                        aria-controls="fade-menu"
                        aria-haspopup="true"
                        onClick={() => {
                          const oldRepeatables = control.children;
                          const oldFormElements = form.controls;
                          const oldSubForm = formData.subForms;

                          oldRepeatables.splice(elementIndex + 2, 0, element);
                          oldRepeatables.splice(elementIndex, 1);

                          oldFormElements[controlIndex] = {
                            ...oldFormElements[controlIndex],
                            children: oldRepeatables
                          };

                          oldSubForm[formIndex] = {
                            ...oldSubForm[formIndex],
                            controls: oldFormElements
                          };

                          setFormData({
                            ...formData,
                            subForms: oldSubForm
                          });

                          if (!deepCompareEquals(formData, jsonData)) {
                            setFormChanged(true);
                          } else {
                            setFormChanged(false);
                          }
                        }}
                      >
                        <FaChevronDown
                          size={18}
                          color={
                            elementIndex === control.children.length - 1
                              ? 'rgba(109, 114, 120, 0.2)'
                              : 'rgba(109, 114, 120, 1.0)'
                          }
                        />
                      </IconButton>
                      <IconButton
                        aria-controls="fade-menu"
                        aria-haspopup="true"
                        onClick={() => {
                          const oldRepeatables = control.children;
                          const oldFormElements = form.controls;
                          const oldSubForm = formData.subForms;

                          oldRepeatables.splice(elementIndex - 1, 0, element);
                          oldRepeatables.splice(elementIndex + 1, 1);

                          oldFormElements[controlIndex] = {
                            ...oldFormElements[controlIndex],
                            children: oldRepeatables
                          };

                          oldSubForm[formIndex] = {
                            ...oldSubForm[formIndex],
                            controls: oldFormElements
                          };

                          setFormData({
                            ...formData,
                            subForms: oldSubForm
                          });

                          if (!deepCompareEquals(formData, jsonData)) {
                            setFormChanged(true);
                          } else {
                            setFormChanged(false);
                          }
                        }}
                      >
                        <FaChevronUp
                          size={18}
                          color={
                            elementIndex === 0
                              ? 'rgba(109, 114, 120, 0.2)'
                              : 'rgba(109, 114, 120, 1.0)'
                          }
                        />
                      </IconButton>
                      <Attributes>
                        {getType(element.type) && (
                          <FormattedMessage id={getType(element.type)} />
                        )}
                      </Attributes>
                      {element.readOnly && (
                        <Attributes>
                          <FormattedMessage id="READ_ONLY" />
                        </Attributes>
                      )}
                      {element.required && (
                        <Attributes required>
                          <FormattedMessage id="REQUIRED_FIELD" />*
                        </Attributes>
                      )}
                      <IconButton
                        aria-controls="fade-menu"
                        aria-haspopup="true"
                        style={{ float: 'right' }}
                        onClick={() => {
                          const oldRepeatables = control.children;
                          const oldFormElements = form.controls;
                          const oldSubForm = formData.subForms;

                          oldRepeatables.splice(elementIndex, 1);

                          oldFormElements[controlIndex] = {
                            ...oldFormElements[controlIndex],
                            children: oldRepeatables
                          };

                          oldSubForm[formIndex] = {
                            ...oldSubForm[formIndex],
                            controls: oldFormElements
                          };

                          setFormData({
                            ...formData,
                            subForms: oldSubForm
                          });

                          if (!deepCompareEquals(formData, jsonData)) {
                            setFormChanged(true);
                          } else {
                            setFormChanged(false);
                          }
                        }}
                      >
                        <IoMdClose size={24} color="rgba(109, 114, 120, 1.0)" />
                      </IconButton>
                    </span>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span
                        style={{
                          fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
                          fontSize: '24.0px',
                          color: 'rgba(109, 114, 120, 1.0)',
                          textAlign: 'center',
                          lineHeight: '50.0px'
                        }}
                      >
                        {element.hint['0'] ? element.hint['0'] : ''}
                      </span>

                      <span
                        style={{
                          fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
                          fontSize: '20.0px',
                          color: 'rgba(109, 114, 120, 1.0)',
                          textAlign: 'center',
                          lineHeight: '17.0px'
                        }}
                      >
                        {element.name}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            );
          }
          return (
            <div
              key={`${form.name}-${control.name}`}
              style={{
                borderRadius: '5px',
                borderStyle: 'solid',
                borderWidth: '2px',
                borderColor: 'rgba(109, 114, 120, 1.0)',
                padding: '18px',
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <span
                style={{
                  fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
                  fontSize: '14px',
                  color: 'rgba(109, 114, 120, 1.0)',
                  textAlign: 'left',
                  lineHeight: '17.0px',
                  fontWeight: 'bold'
                }}
              >
                <FormattedMessage id="FORM_FIELD" />
                <IconButton
                  aria-controls="fade-menu"
                  aria-haspopup="true"
                  onClick={() => {
                    openEditModal({
                      control,
                      formIndex,
                      controlIndex,
                      elementIndex: -1
                    });
                  }}
                >
                  <FaPencilAlt size={18} color="rgba(109, 114, 120, 1.0)" />
                </IconButton>
                <IconButton
                  aria-controls="fade-menu"
                  aria-haspopup="true"
                  onClick={() => {
                    const oldFormElements = form.controls;
                    const oldSubForm = formData.subForms;

                    oldFormElements.splice(controlIndex + 2, 0, control);
                    oldFormElements.splice(controlIndex, 1);

                    oldSubForm[formIndex] = {
                      ...oldSubForm[formIndex],
                      controls: oldFormElements
                    };

                    setFormData({
                      ...formData,
                      subForms: oldSubForm
                    });

                    if (!deepCompareEquals(formData, jsonData)) {
                      setFormChanged(true);
                    } else {
                      setFormChanged(false);
                    }
                  }}
                >
                  <FaChevronDown
                    size={18}
                    color={
                      controlIndex === form.controls.length - 1
                        ? 'rgba(109, 114, 120, 0.2)'
                        : 'rgba(109, 114, 120, 1.0)'
                    }
                  />
                </IconButton>
                <IconButton
                  aria-controls="fade-menu"
                  aria-haspopup="true"
                  onClick={() => {
                    const oldFormElements = form.controls;
                    const oldSubForm = formData.subForms;

                    oldFormElements.splice(controlIndex - 1, 0, control);
                    oldFormElements.splice(controlIndex + 1, 1);

                    oldSubForm[formIndex] = {
                      ...oldSubForm[formIndex],
                      controls: oldFormElements
                    };

                    setFormData({
                      ...formData,
                      subForms: oldSubForm
                    });

                    if (!deepCompareEquals(formData, jsonData)) {
                      setFormChanged(true);
                    } else {
                      setFormChanged(false);
                    }
                  }}
                >
                  <FaChevronUp
                    size={18}
                    color={
                      controlIndex === 0
                        ? 'rgba(109, 114, 120, 0.2)'
                        : 'rgba(109, 114, 120, 1.0)'
                    }
                  />
                </IconButton>
                <Attributes>
                  {getType(control.type) && (
                    <FormattedMessage id={getType(control.type)} />
                  )}
                </Attributes>
                {control.readOnly && (
                  <Attributes>
                    <FormattedMessage id="READ_ONLY" />
                  </Attributes>
                )}
                {control.required && (
                  <Attributes required>
                    <FormattedMessage id="REQUIRED_FIELD" />*
                  </Attributes>
                )}
                <IconButton
                  aria-controls="fade-menu"
                  aria-haspopup="true"
                  style={{ float: 'right' }}
                  onClick={() => {
                    const oldFormElements = form.controls;
                    const oldSubForm = formData.subForms;

                    oldFormElements.splice(controlIndex, 1);

                    oldSubForm[formIndex] = {
                      ...oldSubForm[formIndex],
                      controls: oldFormElements
                    };

                    setFormData({
                      ...formData,
                      subForms: oldSubForm
                    });

                    if (!deepCompareEquals(formData, jsonData)) {
                      setFormChanged(true);
                    } else {
                      setFormChanged(false);
                    }
                  }}
                >
                  <IoMdClose size={24} color="rgba(109, 114, 120, 1.0)" />
                </IconButton>
              </span>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span
                  style={{
                    fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
                    fontSize: '24.0px',
                    color: 'rgba(109, 114, 120, 1.0)',
                    textAlign: 'center',
                    lineHeight: '50.0px'
                  }}
                >
                  {control.hint['0'] ? control.hint['0'] : ''}
                </span>

                <span
                  style={{
                    fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
                    fontSize: '20.0px',
                    color: 'rgba(109, 114, 120, 1.0)',
                    textAlign: 'center',
                    lineHeight: '17.0px'
                  }}
                >
                  {control.name}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  });

  return (
    <Container>
      {editModalOpen ? (
        <EditFormModal
          value={modalValues}
          willOpen={editModalOpen}
          setWillOpen={setEditModalOpen}
          editFormElement={editFormElement}
        />
      ) : null}
      <AddSubFormButton
        variant="contained"
        size="medium"
        style={{ width: '200px' }}
        onClick={() => {
          const oldSubForm = formData.subForms;
          const newSubFormName = 'SubForm';
          let counter = 1;

          while (true) {
            if (
              formData.subForms.filter(
                (subform) =>
                  subform.name === newSubFormName.concat(counter.toString())
              ).length > 0
            ) {
              counter += 1;
            } else {
              break;
            }
          }

          const newSubForm = {
            name: newSubFormName.concat(counter.toString()),
            controls: [],
            role_id: null
          };

          oldSubForm.push(newSubForm);
          setFormData({ ...formData, subForms: oldSubForm });

          if (!deepCompareEquals(formData, jsonData)) {
            setFormChanged(true);
          } else {
            setFormChanged(false);
          }
        }}
      >
        <FormattedMessage id="ADD_SUB_FORM" />
      </AddSubFormButton>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <FormContainer>{forms}</FormContainer>
      </div>
    </Container>
  );
});
