import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Field, ErrorMessage } from 'formik';
import {
  Grid,
  Button,
  Paper,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { InputLabel } from '../Containers';

const SelectOneInputs = (props) => {
  const {
    options, value, extras, onSelect, errors, disabled,
  } = props;
  const selectedValue = value.length > 0 ? value[0] : null;
  const intl = useIntl();

  return (
    <div
      style={{
        flexGrow: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        alignContent: 'center',
        borderWidth: '1px',
        marginTop: extras ? '2px' : null,
      }}
    >
      {options.length > 3
        ? (
          <Autocomplete
            disabled={disabled}
            id="select-one-element-autocomplete"
            size="small"
            options={options}
            value={selectedValue}
            getOptionSelected={(option, val) => {
              if (!val) return false;
              return val === option.val;
            }}
            getOptionLabel={(option) => (option && option.val ? option.val : ' ')}
            onChange={(event, val) => {
              if (!val) return onSelect(null);
              return onSelect(val.val);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={`${intl.formatMessage({ id: 'PLEASE_SELECT' })}${intl.formatMessage({ id: 'OPTION' })}${intl.formatMessage({ id: 'PLEASE_SELECT_CONTINUE' })}`}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        )
        : options.map((option, index) => (
          <Button
            key={option.val}
            style={{
              padding: '1vh 2vw',
              borderTopLeftRadius: (index === 0 || (selectedValue && selectedValue.val && selectedValue.val !== option.val)) ? '0' : '8px',
              borderBottomLeftRadius: (index === 0 || (selectedValue && selectedValue.val && selectedValue.val !== option.val)) ? '0' : '8px',
              borderTopRightRadius: (index === options.length - 1 || (selectedValue && selectedValue.val && selectedValue.val !== option.val)) ? '0' : '8px',
              borderBottomRightRadius: (index === options.length - 1 || (selectedValue && selectedValue.val && selectedValue.val !== option.val)) ? '0' : '8px',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
              (selectedValue && selectedValue.val && selectedValue.val === option.val) ? 'rgba(250,100,0,1)' : '#F2F2F2',
            }}
            onClick={() => {
              onSelect(option.val)
            }}
          >
            <span
              style={{
                color: (selectedValue && selectedValue.val && selectedValue.val === option.val) ? '#F2F2F2' : 'rgba(89, 84, 105, 1)',
              }}
            >
              {option.text['0']}
            </span>
          </Button>
        ))}
    </div>
  );
};

export default ({
  input,
  value,
  extraInputs,
  formName,
  controlName,
  disabled,
  stateIndex,
}) => {
  const {
    name, label, hint, options, required,
  } = input;

  const [extraInputsList, setExtraInputsList] = useState(extraInputs);

  const intl = useIntl();
  useEffect(() => {}, [extraInputsList]);

  const hintText = (hint && hint['0']) || '';
  const labelText = (label && label['0']) || '';

  let fieldName = `${formName}`;
  if (controlName) {
    fieldName = fieldName.concat(`.${controlName}`);
  }
  fieldName = fieldName.concat(`.${name}`);

  return (
    <Field
      name={fieldName}
      validate={(vv) => {
        if (required && !disabled) {
          if (Array.isArray(vv)) {
            let message = '';
            vv.map((vvv) => {
              if (vvv === '') {
                message = intl.formatMessage({ id: 'REQUIRED_THIS_FIELD' });
              }
            });
            return message;
          }
          if (vv === '') {
            return intl.formatMessage({ id: 'REQUIRED_THIS_FIELD' });
          }
        }
      }}
    >
      {(props) => {
        const { field, form, meta } = props;

        return (
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={3}>
              <InputLabel>{`${labelText}${required ? ' (*)' : ''}`}</InputLabel>
            </Grid>
            <Grid item xs={9}>
              <Paper elevation={3}>
                <div
                  error={meta.error}
                  style={{
                    border: '1px',
                    borderStyle: 'solid',
                    borderColor: meta.error ? 'red' : '#ccc',
                    padding: '1vh 1vw',
                  }}
                >
                  <div
                    style={{
                      flexDirection: 'row',
                    }}
                  >
                    {!options ? null : (
                      <SelectOneInputs
                        disabled={disabled}
                        errors={meta.error}
                        options={options}
                        value={
                          
                          extraInputsList && extraInputsList.length > 0
                            ? options.filter((option) => option.val === field.value[stateIndex])
                            : options.filter((option) => option.val === field.value)
                        }
                        onSelect={(selected) => {
                          if (!disabled) {
                            if (!selected) {
                              form.setFieldValue(
                                extraInputsList && extraInputsList.length > 0
                                  ? `${fieldName}.${stateIndex}`
                                  : fieldName,
                                '',
                              );
                            } else {
                              let oldField;
                              let newField;
                              if (extraInputsList && extraInputsList.length > 0) {
                                oldField = field.value[stateIndex];
                              } else {
                                oldField = field.value;
                              }
                              if (oldField === selected) newField = '';
                              else newField = selected;
                              form.setFieldValue(
                                extraInputsList && extraInputsList.length > 0
                                  ? `${fieldName}.${stateIndex}`
                                  : fieldName,
                                newField,
                              );
                            }
                          }
                        }}
                      />
                    )}
                  </div>
                  {meta.error && (
                    <ErrorMessage
                      name={fieldName}
                      component="div"
                      render={(msg) => (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                          {msg}
                        </div>
                      )}
                    />
                  )}
                </div>
              </Paper>
            </Grid>
          </Grid>
        );
      }}
    </Field>
  );
};
