import React, { useState, useEffect, useLayoutEffect } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { MdRemoveRedEye, MdLock, MdTimeline } from 'react-icons/md';

import { AiFillCaretRight } from 'react-icons/ai';
import {
  IconButton,
  TextField,
  CircularProgress,
  InputAdornment
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Autocomplete } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import {
  statusSetsSelector,
  tasksSelector,
  setFormTableList,
  setFormFilters,
  fetchFormList,
  projectsSelector,
  formsSelector
} from 'slices';
import { dateFormat } from '../../helpers/DateFormatter';

import {
  TableContainerStyled,
  TableCellStyled,
  Actions,
  FormName,
  Space,
  FormType,
  Location,
  Set,
  SearchInput
} from '../../styles/forms/formTableStyle';
import { NoData } from './LeftContent';
import ProgressCircle from 'components/Common/ProgressCircle';

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  const returnArray = stabilizedThis.map((el) => el[0]);
  return returnArray;
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  const returnValue =
    order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  return returnValue;
}

const FormTable = (props) => {
  const { handleForm, auth, projectId } = props;

  const { tasks, workerTasks } = useSelector(tasksSelector);

  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [forms, setForms] = useState();

  const [status, setStatus] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [formName, setFormName] = useState('');

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');

  const dispatch = useDispatch();

  const { id: userId } = auth;

  const intl = useIntl();

  const { statusSets } = useSelector(statusSetsSelector);
  /* const { users } = useSelector(usersSelector); */
  const {
    formList: _forms,
    formsCount,
    site_list,
    loading: { getFormList: formsLoading }
  } = useSelector(formsSelector);

  const loading = open && !options.length;

  const findStatusName = (id) => {
    const _status = options.filter((status) => status.id == id);
    return _status[0] && _status[0].name;
  };

  useEffect(() => {
    if (statusSets.length) {
      let _options = [];
      statusSets.forEach((element) => {
        _options = [..._options, ...element.status_set_details];
      });
      setOptions(_options);
    }
  }, [statusSets]);

  useLayoutEffect(() => {
    if (projectId && userId) {
      const site_id = site_list.slice(-1)[0]?.id || '0';

      dispatch(
        fetchFormList(
          userId,
          projectId,
          rowsPerPage,
          page * rowsPerPage,
          formName,
          createdBy,
          site_id,
          orderBy,
          order,
          status.name
        )
      );
      dispatch(setFormFilters({ status: '', createdBy, formName, site_id }));
    }
  }, [projectId, userId, rowsPerPage, page, site_list, orderBy, order, status]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      const site_id = site_list.slice(-1)[0]?.id || '0';

      dispatch(
        fetchFormList(
          userId,
          projectId,
          rowsPerPage,
          page * rowsPerPage,
          formName,
          createdBy,
          site_id,
          orderBy,
          order,
          status.name
        )
      );
      dispatch(setFormFilters({ status: '', createdBy, formName, site_id }));
    }
  };

  useLayoutEffect(() => {
    if (_forms) {
      setForms(
        _forms.map((form) => ({
          ...form,
          location_node: getLoacation(form?.site_details?.site_list || [], 0)
        }))
      );
    }
  }, [_forms]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getActions = (form_id, isNotLocked) => {
    return (
      <>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <MdTimeline size="calc(4px + 0.5vh + 0.5vw)" />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleForm(form_id);
          }}
        >
          <MdRemoveRedEye size="calc(4px + 0.5vh + 0.5vw)" />
        </IconButton>
        {isNotLocked ? null : (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <MdLock size="calc(4px + 0.5vh + 0.5vw)" />
          </IconButton>
        )}
      </>
    );
  };

  const getLoacation = (site_list, parent) => {
    // let parent = 0;
    const _detail = site_list.filter((detail) => detail.parent_id === parent);

    if (_detail[0]) {
      return (
        <>
          {parent !== 0 && <AiFillCaretRight color="#979797" />}
          <Set>{_detail[0].name}</Set>
          {getLoacation(site_list, _detail[0].id)}
        </>
      );
    }
    return null;
  };

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return forms ? (
    <>
      <TableContainerStyled>
        <Table aria-label="form table">
          <TableHead>
            <TableRow>
              <TableCellStyled
                align="center"
                width="60px"
                sortDirection={orderBy === 'id' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'id'}
                  direction={orderBy === 'id' ? order : 'asc'}
                  onClick={createSortHandler('id')}
                  style={{
                    justifyContent: 'center !important',
                    textTransform: 'uppercase'
                  }}
                >
                  <Space />#
                </TableSortLabel>
              </TableCellStyled>
              <TableCellStyled
                align="center"
                sortDirection={orderBy === 'form_name' ? order : false}
              >
                <SearchInput
                  name="formName"
                  placeholder={intl.formatMessage({ id: 'SEARCH_PLACEHOLDER' })}
                  margin="none"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setFormName(e.target.value)}
                  onKeyDown={handleKeyDown}
                  inputProps={{
                    style: {
                      padding: '6px',
                      width: 200
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        style={{ fontSize: '1rem', marginRight: '0px' }}
                      >
                        <div className="icon-magnifier" />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <TableSortLabel
                  active={orderBy === 'form_name'}
                  direction={orderBy === 'form_name' ? order : 'asc'}
                  onClick={createSortHandler('form_name')}
                  style={{
                    justifyContent: 'center !important',
                    textTransform: 'uppercase'
                  }}
                >
                  <FormattedMessage id="FORM_NAME_ADN_TYPE" />
                </TableSortLabel>
              </TableCellStyled>
              <TableCellStyled
                align="center"
                sortDirection={orderBy === 'status_detail_name' ? order : false}
              >
                <Autocomplete
                  freeSolo
                  onChange={(event, value) => {
                    if (value) {
                      setStatus(value);
                    } else {
                      setStatus('');
                    }
                  }}
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  loading={loading}
                  id="free-solo-2-demo"
                  getOptionLabel={(option) => option.name}
                  options={options}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="none"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          padding: '6px !important',
                          width: 200
                        },
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
                <TableSortLabel
                  active={orderBy === 'status_detail_name'}
                  direction={orderBy === 'status_detail_name' ? order : 'asc'}
                  onClick={createSortHandler('status_detail_name')}
                  style={{
                    justifyContent: 'center !important',
                    textTransform: 'uppercase'
                  }}
                >
                  <FormattedMessage id="STATUS" />
                </TableSortLabel>
              </TableCellStyled>
              <TableCellStyled
                align="center"
                sortDirection={orderBy === 'created_by_name' ? order : false}
              >
                <SearchInput
                  name="createdBy"
                  placeholder={intl.formatMessage({ id: 'SEARCH_PLACEHOLDER' })}
                  margin="none"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setCreatedBy(e.target.value)}
                  onKeyDown={handleKeyDown}
                  inputProps={{
                    style: {
                      padding: '6px',
                      width: 200
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        style={{ fontSize: '1rem', marginRight: '0px' }}
                      >
                        <div className="icon-magnifier" />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <TableSortLabel
                  active={orderBy === 'created_by_name'}
                  direction={orderBy === 'created_by_name' ? order : 'asc'}
                  onClick={createSortHandler('created_by_name')}
                  style={{
                    justifyContent: 'center !important',
                    textTransform: 'uppercase'
                  }}
                >
                  <FormattedMessage id="CREATED_BY" />
                </TableSortLabel>
              </TableCellStyled>
              <TableCellStyled
                align="center"
                sortDirection={orderBy === 'site_details' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'site_details'}
                  direction={orderBy === 'site_details' ? order : 'asc'}
                  onClick={createSortHandler('site_details')}
                  style={{
                    justifyContent: 'center !important',
                    textTransform: 'uppercase'
                  }}
                >
                  <Space />
                  <FormattedMessage id="LOCATION" />
                </TableSortLabel>
              </TableCellStyled>
              <TableCellStyled
                align="center"
                sortDirection={orderBy === 'updated_at' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'updated_at'}
                  direction={orderBy === 'updated_at' ? order : 'asc'}
                  onClick={createSortHandler('updated_at')}
                  style={{
                    justifyContent: 'center !important',
                    textTransform: 'uppercase'
                  }}
                >
                  <Space />
                  <FormattedMessage id="MODIFIED_ON" />
                </TableSortLabel>
              </TableCellStyled>
              <TableCellStyled
                align="center"
                sortDirection={orderBy === 'revision_name' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'revision_name'}
                  direction={orderBy === 'revision_name' ? order : 'asc'}
                  onClick={createSortHandler('revision_name')}
                  style={{
                    justifyContent: 'center !important',
                    textTransform: 'uppercase'
                  }}
                >
                  <Space />
                  <FormattedMessage id="REVISION" />
                </TableSortLabel>
              </TableCellStyled>
              <TableCellStyled align="center">
                <Space />
                <FormattedMessage id="ACTIONS" />
              </TableCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            {formsLoading ? (
              <TableRow>
                <TableCellStyled colSpan={10}>
                  <ProgressCircle />
                </TableCellStyled>
              </TableRow>
            ) : (
              forms?.map((form, index) => {
                const isNotLocked =
                  Number(auth.role) !== 1 &&
                  ((form.updated_by === auth.id &&
                    tasks.filter(
                      (task) =>
                        Number(task.step_no) !== 3 &&
                        task.form_id === form.id &&
                        task.worker_id !== auth.id
                    ).length === 0) ||
                    (tasks.filter(
                      (task) =>
                        Number(task.step_no) === 3 &&
                        task.form_id === form.id &&
                        (task.worker_id === auth.id ||
                          task.created_by === auth.id)
                    ).length > 0 &&
                      tasks.filter(
                        (task) =>
                          Number(task.step_no) !== 3 &&
                          task.form_id === form.id &&
                          task.worker_id !== auth.id
                      ).length === 0) ||
                    workerTasks.filter(
                      (task) =>
                        Number(task.step_no) !== 3 &&
                        task.form_id === form.id &&
                        task.worker_id === auth.id
                    ).length > 0);

                return (
                  <TableRow
                    key={`${form.form_name}-${index}`}
                    onClick={() => handleForm(form.id)}
                    hover
                  >
                    <TableCellStyled align="center" width="60px">
                      {form.id}
                    </TableCellStyled>
                    <TableCellStyled align="left">
                      <FormType>{form.form_no}</FormType>
                      <FormName>{form.form_name}</FormName>
                    </TableCellStyled>
                    <TableCellStyled align="center">
                      {form.status_detail_name}
                    </TableCellStyled>
                    <TableCellStyled align="center">
                      {form.created_by_name}
                    </TableCellStyled>
                    <TableCellStyled align="left">
                      <Location>{form.location_node}</Location>
                    </TableCellStyled>
                    <TableCellStyled align="center" datecolor="true">
                      {dateFormat(form.updated_at, 'DD.MM.YYYY HH:mm')}
                    </TableCellStyled>
                    <TableCellStyled align="center">
                      {form.revision_name}
                    </TableCellStyled>
                    <TableCellStyled align="center">
                      <Actions> {getActions(form.id, isNotLocked)} </Actions>
                    </TableCellStyled>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainerStyled>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={formsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  ) : (
    <NoData style={{ textAlign: 'center' }}>
      <FormattedMessage id="FORM_NOT_FOUND" />
    </NoData>
  );
};

export default FormTable;
