import React, { Fragment } from 'react';

import { authentication } from './authentication';

class Permission {
  constructor(path) {
    this.user = authentication.userValue;
    this.path = path;
    this.rules = getRules(path);
  }

  cretaePermission() {
    if (!this.rules) return !!this.user.role;
    return this.rules.newList.includes(Number(this.user.role));
  }

  showPermission() {
    if (!this.rules) return !!this.user.role;
    return this.rules.showList.includes(Number(this.user.role));
  }

  editPermission() {
    if (!this.rules) return !!this.user.role;
    return this.rules.editList.includes(Number(this.user.role));
  }
}

const request = (path) => new Permission(path);

export default request;

const getRules = (path) => {
  switch (path) {
    case 'projects':
      return {
        newList: [5],
        showList: [5],
        editList: [5]
      };
    case 'forms':
      return {
        newList: [2, 3, 4, 5],
        showList: [1, 2, 3, 4, 5],
        editList: [2, 3, 4, 5]
      };
    case 'mails':
      return {
        newList: [1, 2, 3, 4, 5],
        showList: [1, 2, 3, 4, 5],
        editList: [1, 2, 3, 4, 5]
      };
    case 'tasks':
      return {
        newList: [2, 3, 4, 5],
        showList: [1, 2, 3, 4, 5],
        editList: [2, 3, 4, 5]
      };
    case 'templates':
      return {
        newList: [3, 4, 5],
        showList: [3, 4, 5],
        editList: [1, 2, 3, 4, 5]
      };
    case 'companies':
      return {
        newList: [5],
        showList: [4, 5],
        editList: [1, 2, 3, 4, 5]
      };
    case 'settings':
      return {
        newList: [5],
        showList: [5],
        editList: [5]
      };
    case 'users':
      return {
        newList: [5, 4],
        showList: [1, 2, 3, 4, 5],
        editList: [5, 4]
      };
    case 'groups':
      return {
        newList: [5, 4],
        showList: [5, 4, 3, 2, 1],
        editList: [5, 4]
      };
    default:
      return {
        newList: [5],
        showList: [1, 2, 3, 4, 5],
        editList: [5]
      };
  }
};

export const PermissionContainer = React.forwardRef((props, ref) => {
  const { page, create, children } = props;

  const allowed = !!create
    ? new Permission(page).cretaePermission()
    : new Permission(page).showPermission();

  if (allowed) {
    return <Fragment ref={ref}>{children}</Fragment>;
  }
  return null;
});
