import APIHelper from '../../helpers/APIHelper';

export async function findRoleByProjectIdFunc(projectId) {
  const response = await APIHelper().post('roles/find_by_project', null, { project_id: projectId });
  if (response.error) {
throw new Error(JSON.stringify(response.error));
  }
  console.log('FIND ROLES_SET BY PROJECT_ID RESPONSE =>', response.roles);
  return response.roles;
}

export async function findRoleByProjectIdCompanyFunc(projectId) {
  const response = await APIHelper().post('companies/find_roles', null, { project_id: projectId });
  if (response.error) {
throw new Error(JSON.stringify(response.error));
  }
  console.log('FIND COMPANY_ROLES_SET BY PROJECT_ID RESPONSE =>', response.company_roles);
  return response.company_roles;
}

export async function findCompaniesByProjectIdFunc(projectId) {
  const response = await APIHelper().post('companies/find_companies', null, { project_id: projectId });
  if (response.error) {
throw new Error(JSON.stringify(response.error));
  }
  console.log('FIND COMPANIES BY PROJECT_ID RESPONSE =>', response.company_projects);
  return response.company_projects;
}

export async function addRoleSetFunc(roleObj) {
  const response = await APIHelper().post('roles/insert', null, { project_id: roleObj.project_id, role_name: roleObj.role_name });
  if (response.error) {
throw new Error(JSON.stringify(response.error));
  }
  console.log('ADD ROLES_SET RESPONSE =>', response);
  return response;
}

export async function deleteRoleSetFunc(roleId) {
  const response = await APIHelper().post('roles/delete', null, { role_id: roleId });
  if (response.error) {
throw new Error(JSON.stringify(response.error));
  }
  console.log('DELETE ROLES_SET RESPONSE =>', response);
  return response;
}

export async function addRoleSetCompanyFunc(roleObj) {
  const response = await APIHelper().post('companies/add_roles', null, { project_id: roleObj.project_id, role_id: roleObj.role_id, company_id: roleObj.company_id });
  if (response.error) {
throw new Error(JSON.stringify(response.error));
  }
  console.log('ADD COMPANY_ROLES_SET RESPONSE =>', response);
  return response;
}

export async function deleteRoleSetCompanyFunc(roleObj) {
  const response = await APIHelper().post('companies/delete_roles', null, { project_id: roleObj.project_id, role_id: roleObj.role_id, company_id: roleObj.company_id });
  if (response.error) {
throw new Error(JSON.stringify(response.error));
  }
  console.log('DELETE COMPANY_ROLES_SET RESPONSE =>', response);
  return response;
}

export async function updateRoleSetFunc(roleObj) {
  const response = await APIHelper().post('roles/update', null, { role_id: roleObj.role_id, role_name: roleObj.role_name });
  if (response.error) {
throw new Error(JSON.stringify(response.error));
  }
  console.log('UPDATE ROLES_SET RESPONSE =>', response);
  return response;
}

export async function findRoleByIdFunc(role_id) {
  const response = await APIHelper().post('roles/find', null, { role_id });
  if (response.error) {
throw new Error(JSON.stringify(response.error));
  }
  console.log('FIND BY ID ROLES_SET RESPONSE =>', response);
  return response.role;
}
