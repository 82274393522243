import moment from 'moment';

export const dateFormat = (date, stringFormat, format = moment.DATETIME_LOCAL_MS) => {
  const formatted = moment(date, format).format(stringFormat);
  if (formatted == 'Invalid date') return '';
  return formatted;
};

export default (date, which) => {
  if (which === 'date') {
    const dateString = `${date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`}.${date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`}.${date.getFullYear()}`;

    return `${dateString}`;
  }
  if (which === 'time') {
    const timeString = `${date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`}`;

    return `${timeString}`;
  }
  if (which === 'monthAndYear') {
    const dateString = `${date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`}.${date.getFullYear()}`;

    return `${dateString}`;
  }
  if (which === 'year') {
    const dateString = `${date.getFullYear()}`;

    return `${dateString}`;
  }
  const dateString = `${date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`}.${date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`}.${date.getFullYear()}`;
  const timeString = `${date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`}`;

  return `${dateString} ${timeString}`;
};
