import React, {
  useEffect, useRef, Fragment, useLayoutEffect, useState,
} from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Divider } from '@material-ui/core';

import { TreeView } from '@material-ui/lab';

import {
  AiFillCaretDown,
  AiFillCaretRight,
} from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import {
  siteSetsSelector, projectsSelector, metadataSetsSelector, fetchMetadataSets, fetchSiteSets, selectMetadataSet,
} from 'slices';
import { FormattedMessage } from 'react-intl';
import StyledTreeItem from '../Forms/StyledTreeItem';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  leftside: {
    width: '20vw',
    marginRight: theme.spacing(3),
    height: '76vh',
    display: 'flex',
    flexDirection: 'column',
  },
  siteSetPaper: {
    width: '20vw',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 15px',
  },
  metadataSetPaper: {
    width: '20vw',
    overflowX: 'auto',
    padding: '0px',
    flex: 1,
  },
  treeView: {
    flexGrow: 1,
    width: '100%',
    overflow: 'auto',
  },


  header: {
    height: theme.spacing(3),
    flex: 1,
    fontFamily: 'Helvetica, Helvetica, Arial, serif',
    fontSize: 14,
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    lineHeight: '17.0px',
    paddingTop: '20px',
    height: '60px',
    width: '100%',
    borderBottom: `${theme.spacing(0.6)}px solid rgba(250, 100, 0, 1)`,
  },
  historyside: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  historyleftone: {
    fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
    fontSize: 14,
    color: 'rgba(89, 84, 105, 1.0)',
    textAlign: 'left',
    lineHeight: '17.0px',
    margin: theme.spacing(1),
    alignItems: 'center',
  },
  historyrightone: {
    fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
    fontSize: 14,
    color: 'rgba(250, 100, 105, 1.0)',
    textAlign: 'right',
    lineHeight: '17.0px',
    margin: theme.spacing(0.75),
  },
}));


const NoData = styled.div`
  font-weight: bold;
  font-size: calc(4px + 0.5vw + 0.5vh);
  line-height: calc((4px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((4px + 0.5vw + 0.5vh) / (1.618 * 20));
  text-align: center;
  margin-top: 30px;
`;


const LeftContent = () => {
  const classes = useStyles();

  const ref = useRef(null);

  const dispatch = useDispatch();

  const { selectedProject } = useSelector(projectsSelector);
  const { metadataSets } = useSelector(metadataSetsSelector);
  const { siteSets } = useSelector(siteSetsSelector);

  const [expendedList, setExpended] = useState([]);

  useEffect(() => {
    if (selectedProject) {
      if (!metadataSets.length || (metadataSets.length && metadataSets[0].project_id !== selectedProject.id)) {
        dispatch(fetchMetadataSets(selectedProject.id));
      }
      if (!siteSets.length || (siteSets.length && siteSets[0].project_id !== selectedProject.id)) {
        dispatch(fetchSiteSets(selectedProject.id));
      }
    }
  }, [selectedProject]);

  const handleMetadataSet = (e) => {
    const value = e.target.id;
    if (value.includes(',')) {
      const idArray = value.split(',');
      const metadataSet = metadataSets.filter((set) => set.id == idArray[0]);
      const metadataDetail = metadataSet[0].metadata_details.filter((detail) => detail.id == idArray[1]);
      dispatch(selectMetadataSet(metadataDetail[0]));
    } else if (expendedList.includes(value)) {
      setExpended(expendedList.filter((el) => el !== value));
    } else {
      setExpended(expendedList.concat([value]));
    }
  };

  useLayoutEffect(() => () => {
    dispatch(selectMetadataSet(null));
  }, []);

  useEffect(() => {
    setExpended([...metadataSets.map((set, index) => `${set.metadata_name}${index}`)]);
  }, [metadataSets]);


  return (
    <div className={` ${classes.leftside}`}>
      <Paper className={` ${classes.metadataSetPaper}`}>
        <div className={` ${classes.header}`}>
          METADATA
        </div>
        {metadataSets.length
          ? (
            <TreeView
              className={classes.treeView}
              defaultCollapseIcon={<AiFillCaretDown color="#979797" />}
              expanded={expendedList}
              defaultExpandIcon={<AiFillCaretRight color="#979797" />}
              onNodeSelect={handleMetadataSet}
            >
              {
              metadataSets.map((set, index) => (
                <Fragment key={`styleditem-key-${set.id}-${index}`}>
                  <StyledTreeItem
                    ref={ref}
                    nodeId={`${set.metadata_name}${index}`}
                    labelText={set.metadata_name}
                    value={`${set.metadata_name}${index}`}
                    bgColor="#EAEAEA"

                  >
                    {
                      set.metadata_details.map((detail, index) => (
                        <Fragment key={`styled-detail-fragmant-key-${detail.id}-${index}`}>
                          <Divider />
                          <StyledTreeItem
                            nodeId={`detail${detail.id}`}
                            labelText={detail.name}
                            bgColor="#FFF"
                            value={`${set.id},${detail.id}`}
                            key={`styled-detail-key-${detail.id}-${index}`}
                          />
                        </Fragment>
                      ))
                    }

                  </StyledTreeItem>
                  <Divider key={`styled-item-divider-key-${set.id}-${index}`} />
                </Fragment>
              ))
            }

            </TreeView>
          )
          : (
            <NoData>
              {selectedProject
                ? <FormattedMessage id="METADATA_NOT_FOUND" />
                : <FormattedMessage id="PROJECT_SELECTION_ALERT" />
            }
            </NoData>
          )

        }
      </Paper>
    </div>
  );
};

export default LeftContent;
