import React, { useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import { BsImageAlt, BsTrash } from 'react-icons/bs';

const useStyles = makeStyles(() => ({
  dialogGrid: {
    margin: '0px',
    padding: '0px',
    '& > p': {
      fontSize: '12px',
      margin: '3px',
      marginTop: '0px',
      padding: '0px',
      fontWeight: 'bold',
      color: '#555'
    }
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  dialogButton: {
    margin: '5px 15px'
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  imageConatiner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '30%'
  },
  image: {
    width: '8rem',
    height: '5rem',
    objectFit: 'contain'
  }
}));

function ProjectForm(props) {
  const {
    showModal,
    setShowModal,
    loading,
    initialValues,
    submitFunc,
    dialogTitle
  } = props;
  const classes = useStyles();

  const intl = useIntl();

  const inputRef = useRef();

  // handle image upload clik
  const handleClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  // handle image to convert base64 and save
  const handleLogoChange = (files, setFieldValue) => {
    let filesArray = [];
    Object.values(files).forEach((file, i) => {
      const reader = new FileReader();

      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file);

        reader.onload = async (readerEvent) => {
          if (file.type.substr(0, 6) === 'image/') {
            const imageData = reader.result.split('base64,').pop();

            setFieldValue('project_logo', imageData);
          } else {
            window.alert(intl.formatMessage({ id: 'ENTER_ONLY_IMAGE_FILE' }));
          }
        };
      }
    });
  };

  // delete image from form
  const handleDelete = (setFieldValue) => {
    setFieldValue('project_logo', null);
  };

  return (
    <Dialog
      open={showModal || loading}
      onClose={() => setShowModal(false)}
      aria-labelledby="form-dialog-title"
      fullWidth
      scroll="body"
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        <div className={classes.dialogTitle}>
          {dialogTitle}
          <IconButton onClick={() => setShowModal(false)}>
            <AiOutlineCloseCircle />
          </IconButton>
        </div>
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validate={() => {
          const errors = {};
          // if (!values.email) {
          //    errors.email = 'Required';
          // } else if (
          //    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          // ) {
          //    errors.email = 'Invalid email address';
          // }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          submitFunc(values);
          setSubmitting(loading);
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Form>
            <DialogContent dividers>
              <Grid container spacing={3} alignItems="flex-start">
                <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
                  <p className={classes.uppercase}>
                    {' '}
                    <FormattedMessage id="PROJECT_NAME" />{' '}
                  </p>
                  {values.project_logo ? (
                    <div className={classes.imageConatiner}>
                      <img
                        className={classes.image}
                        alt="project-logo"
                        src={`data:image/png;base64,${values.project_logo}`}
                      />
                      <BsTrash
                        size={24}
                        color="#525A65"
                        onClick={() => handleDelete(setFieldValue)}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        border: '2px dashed #E4E6E7',
                        padding: '1rem',
                        cursor: 'pointer',
                        width: '30%'
                      }}
                      onClick={handleClick}
                    >
                      <div
                        style={{
                          width: '80px',
                          height: '80px',
                          borderRadius: '50%',
                          backgroundColor: '#E5E5E5',
                          padding: '16px',
                          marginLeft: 'auto',
                          marginRight: 'auto'
                        }}
                      >
                        <BsImageAlt size={48} color="#525A65" />
                      </div>
                    </div>
                  )}

                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      handleLogoChange(e.target.files, setFieldValue);
                    }}
                    ref={inputRef}
                    style={{ display: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
                  <p className={classes.uppercase}>
                    {' '}
                    <FormattedMessage id="PROJECT_NAME" />{' '}
                  </p>
                  <TextField
                    id="project_name"
                    name="project_name"
                    onChange={handleChange}
                    value={values.project_name || ''}
                    placeholder={intl.formatMessage({
                      id: 'ENTER_PROJECT_NAME'
                    })}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
                  <p className={classes.uppercase}>
                    <FormattedMessage id="PROJECT_CODE" />{' '}
                  </p>
                  <TextField
                    id="project_code"
                    name="project_code"
                    onChange={handleChange}
                    value={values.project_code || ''}
                    placeholder={intl.formatMessage({
                      id: 'ENTER_PROJECT_CODE'
                    })}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
                  <p className={classes.uppercase}>
                    <FormattedMessage id="PROJECT_DESCRIPTION" />{' '}
                  </p>
                  <TextField
                    id="project_desc"
                    placeholder={intl.formatMessage({
                      id: 'ENTER_PROJECT_DESCRIPTION'
                    })}
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth
                    value={values.project_desc || ''}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div className={classes.dialogTitle}>
                <Button
                  onClick={() => setShowModal(false)}
                  variant="contained"
                  className={classes.dialogButton}
                >
                  <FormattedMessage id="CANCEL" />
                </Button>
                <div>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    className={classes.dialogButton}
                  >
                    {loading.addCompany && (
                      <CircularProgress size="sm" color="inherit" />
                    )}
                    <FormattedMessage id="APPLY" />
                  </Button>
                </div>
              </div>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default ProjectForm;
