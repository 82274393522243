import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ItemContainer from 'components/Common/ItemContainer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { FaUser } from 'react-icons/fa';
import Box from '@material-ui/core/Box';
import { Avatar, withStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { FormattedMessage, useIntl } from 'react-intl';

import { authentication } from '../../helpers/authentication';

import AccountInfo from './AccountInfo';
import AccountSettings from './AccountSettings';

import {
  updateUser, updateUserAvatar,
} from '../../slices/usersSlice';

const useStyles = makeStyles((thema) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  dialogGrid: {
    margin: '0px',
    padding: '0px',
    '& > p': {
      fontSize: '12px',
      margin: '3px',
      marginTop: '0px',
      padding: '0px',
      fontWeight: 'bold',
      color: '#555',
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',

  },
  dialogButton: {
    margin: '5px 15px',
  },
  userName: {
    marginLeft: thema.spacing(1),
  },
  avatar: {
    height: thema.spacing(10),
    width: thema.spacing(10),
  },
}));

const LeftBar = styled(Paper)`
  width: 25%;
  height: 80vh;
`;

const RightContent = styled(Paper)`
  width: 70%;
  margin-left: 3%;
`;

const LogoContainer = styled.div`
  width: 100%;
  height: 170px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
`;

const ChangeLogo = styled.a`
  font-size: 12px;
`;

const UserName = styled.p`
  font-size: 14px;
`;

const NavContainer = styled.div`
  height: 100;
`;

const TabLabel = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const TablabeLIcon = styled.div`
  margin-left: 10px;
  width: 30px;
  height: 30px;
  padding: 3px;
  border-radius: 100%;
  background: rgba(250,100,0,1);
`;

const TablabelLink = styled.div`
  margin-left: 10px;
`;

const NavLink = ({ icon, link }) => (
  <TabLabel>
    <TablabeLIcon>
      {icon}
    </TablabeLIcon>
    <TablabelLink>
      {link}
    </TablabelLink>
  </TabLabel>
);

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    left: '0 !important',
    width: '5px !important',
    zIndex: 200,
    backgroundColor: 'rgba(250, 100, 0, 1)',
    '& > div': {
      width: '100%',
    },
  },
})((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <div />,
    }}
  />
));

export default () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [avatar, setAvatar] = useState('');

  const [logoFile, setLogoFile] = useState(null);

  const dispatch = useDispatch();

  const {
    name, id: userId, avatar: userAvatar,
  } = authentication.userValue;

  const intl = useIntl();
  const fileInput = useRef(null);

  useEffect(() => {
    if (logoFile) {
      dispatch(updateUserAvatar(userId, logoFile.base64));
    }
  }, [logoFile]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = async (userObjc) => {
    await dispatch(updateUser(userObjc));
    return true;
  };

  useEffect(() => {
    const nameArray = name ? name.split(' ') : [];
    let tempAvatar = '';
    nameArray.length && nameArray.forEach((item) => tempAvatar += item.charAt(0).toUpperCase());
    setAvatar(tempAvatar);
  }, []);

  const handleLogoChange = (e) => {
    const { files } = e.target;

    const file = files[0];

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      if (file.type.substr(0, 6) === 'image/') {
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: `${Math.round(file.size / 1000)} kB`,
          base64: reader.result,
          file,
        };

        setLogoFile(fileInfo);
      } else {
        window.alert('Lütfen sadece imaj dosyalarını yükleyin ! (JPG, JPEG, PNG)');
      }
    };
  };

  // active disable input for image upload
  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  return (
    <>
      <ItemContainer breadcrumbs={[{ name: intl.formatMessage({ id: 'USERS' }), to: '/' }, { name, to: '/account'}]} itemName={value === 0 ? intl.formatMessage({ id: 'PERSONAL_INFORMATION' }) : intl.formatMessage({ id: 'ACCOUNT_SETTINGS' })}>
        <div className={classes.root}>
          <LeftBar>
            <LogoContainer>
              {logoFile ? <Avatar className={classes.avatar} src={logoFile.base64} />
                : userAvatar ? <Avatar className={classes.avatar} src={userAvatar} />
                  : <Avatar className={classes.avatar}>{avatar}</Avatar>
              }
              <div className={classes.userName}>
                <UserName>{name}</UserName>
                <ChangeLogo href="#" onClick={handleClick}>
                  <FormattedMessage id="CHANGE_YOUR_AVATAR" />
                </ChangeLogo>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleLogoChange}
                  ref={fileInput}
                  style={{ display: 'none' }}
                />
              </div>

            </LogoContainer>
            <NavContainer>
              <StyledTabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs users"
                className={classes.tabs}
              >
                <Tab
                  label={(
                    <NavLink
                      icon={<FaUser color="#fff" />}
                      link={intl.formatMessage({ id: 'PERSONAL_INFORMATION' })}
                    />
                  )}
                />
                <Tab
                  label={(
                    <NavLink
                      icon={<FaUser color="#fff" />}
                      link={intl.formatMessage({ id: 'ACCOUNT_SETTINGS' })}
                    />
                  )}
                />
              </StyledTabs>
            </NavContainer>
          </LeftBar>
          <RightContent>
            <TabPanel value={value} index={0}>
              <AccountInfo
                handleSubmit={handleSubmit}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AccountSettings
                handleSubmit={handleSubmit}
              />
            </TabPanel>
          </RightContent>
        </div>
      </ItemContainer>
    </>
  );
};
