import React from 'react';

import {
  TextField, Button, Grid, Select,
} from '@material-ui/core';


import { ErrorMessage } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { Option } from '../ProjectForms/EditFormInfo';
import { dateFormat } from '../../helpers/DateFormatter';


const useStyles = makeStyles(() => ({
  dialogGrid: {
    margin: '0px',
    padding: '0px',
    '& > p': {
      fontSize: '12px',
      margin: '3px',
      padding: '5px',
      fontWeight: 'bold',
      color: '#777',
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  dialogButton: {
    margin: '5px 15px',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
}));


const TaskInfo = (props) => {
  const { values, handleChange } = props;

  const classes = useStyles();

  const intl = useIntl();

  const setColor = (payload) => {
    switch (payload) {
      case 1:
        return '#1c97fa';

      case 2:
        return '#fa6300';
      default:
        return '#68d407';
    }
  };
  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={6}>
        <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
          <p className={classes.uppercase}> <FormattedMessage id="PROCESS_NAME" /> </p>
          <TextField
            id="form_name_id"
            name="form_name"
            onChange={handleChange}
            value={values.form_name}
            placeholder={intl.formatMessage({ id: 'ENTER_PROCESS_NAME' })}
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            size="small"
          />
          <ErrorMessage
            name="form_name"
            component="div"
            render={(msg) => <div style={{ color: 'red', marginTop: '8px' }}>{msg}</div>}
          />
        </Grid>
        <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
          <p className={classes.uppercase}><FormattedMessage id="STATUS" />  </p>
          <Select
            margin="dense"
            variant="outlined"
            name="status"
            value={values.status}
            SelectDisplayProps={{ style: { color: setColor(values.status) } }}
            fullWidth
            onChange={handleChange}
          >
            <Option value={1} sideColor="#1c97fa"> DRAFT </Option>
            <Option value={2} sideColor="#fa6300"> INACTIVE </Option>
            <Option value={3} sideColor="#68d407"> PUBLISHED </Option>
          </Select>
          <ErrorMessage
            name="form_name"
            component="div"
            render={(msg) => <div style={{ color: 'red', marginTop: '8px' }}>{msg}</div>}
          />
        </Grid>
        <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
          <p className={classes.uppercase}><FormattedMessage id="PROCESS_ID" /> </p>
          <TextField
            id="temlate_id"
            name="id"
            onChange={handleChange}
            value={values.id}
            placeholder={intl.formatMessage({ id: 'ENTER_PROCESS_ID' })}
            variant="outlined"
            fullWidth
            size="small"
          />
          <ErrorMessage
            name="id"
            component="div"
            render={(msg) => <div style={{ color: 'red', marginTop: '8px' }}>{msg}</div>}
          />
        </Grid>
        <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
          <p className={classes.uppercase}><FormattedMessage id="VERSION" /> </p>
          <TextField
            id="version_id"
            name="version"
            onChange={handleChange}
            value={values.version}
            variant="outlined"
            fullWidth
            size="small"
          />
          <ErrorMessage
            name="version"
            component="div"
            render={(msg) => <div style={{ color: 'red', marginTop: '8px' }}>{msg}</div>}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
          <p className={classes.uppercase}><FormattedMessage id="CREATED_AT" /> </p>
          <TextField
            id="created_at_id"
            name="created_at"
            onChange={handleChange}
            value={dateFormat(values.created_at || new Date(), 'DD.MM.YYYY')}
            variant="outlined"
            fullWidth
            size="small"
            disable
          />
        </Grid>
        <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
          <p className={classes.uppercase}><FormattedMessage id="CREATED_BY" /> </p>
          <TextField
            id="created_from_id"
            name="created_from"
            onChange={handleChange}
            value={values.created_from}
            variant="outlined"
            fullWidth
            size="small"
            disable
          />
        </Grid>
        <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
          <p className={classes.uppercase}><FormattedMessage id="UPDATED_AT" /> </p>
          <TextField
            id="updated_at_id"
            name="updated_at"
            onChange={handleChange}
            value={dateFormat(values.updated_at || new Date(), 'DD.MM.YYYY')}
            variant="outlined"
            fullWidth
            size="small"
            disable
          />
        </Grid>
        <Grid item xs={12} classes={{ root: classes.dialogGrid }}>
          <p className={classes.uppercase}><FormattedMessage id="UPDATED_BY" /> </p>
          <TextField
            id="updated_from_id"
            name="updated_from"
            onChange={handleChange}
            value={values.updated_from}
            variant="outlined"
            fullWidth
            size="small"
            disable
          />
        </Grid>
      </Grid>

    </Grid>
  );
};

export default TaskInfo;
