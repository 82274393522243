import React from 'react';
import styled from 'styled-components';

import CompanyList from 'components/Companies';


const CompanyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: auto;
  background-color: rgba(245, 245, 245, 1);

  
`;

export default (props) => (

  <CompanyContainer>
    <CompanyList {...props} />
  </CompanyContainer>

);
