import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiEdit3 } from 'react-icons/fi';
import { AiFillCloseCircle } from 'react-icons/ai';
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  IconButton,
  Grid,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { findByIdUsersFunction } from '../../../api/users';
import { findByIdCompaniessFunction } from '../../../api/companies';

import FormInfo from './FormInfo';
import CancelModal from './CancelModal';
import Lock from '../../../assets/lock.png';
import PDFWrapper from './PDFWrapper';

const useStyles = makeStyles((theme) => ({
  textAlignRight: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const Option = styled(MenuItem)`
  font-weight: bold;
  font-size: 10px;
  color: rgba(89, 84, 105, 1) !important;
  line-height: 12px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  padding-top: calc(4px + 0.75vw + 0.75vh);
  padding-bottom: calc(4px + 0.75vw + 0.75vh);
  padding-left: calc(8px + 1vw + 1vh);
  padding-right: calc(8px + 1vw + 1vh);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e8e1fb;
`;

const TemplateContainer = styled.div`
  font-size: calc(8px + 0.75vw + 0.75vh);
  color: rgba(250, 100, 0, 1);
  text-align: left;
`;

const FormNoText = styled.div`
  font-size: calc(6px + 0.75vw + 0.75vh);
  color: #595469;
  font-weight: bold;
`;

const InfoContainer = styled.div`
  padding-top: calc(4px + 0.75vw + 0.75vh);
  padding-bottom: calc(4px + 0.75vw + 0.75vh);
  padding-left: calc(8px + 1vw + 1vh);
  padding-right: calc(8px + 1vw + 1vh);
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e8e1fb;
`;
const InfoTitle = styled.div`
  color: rgba(250, 100, 0, 1);
  text-align: left;
  font-size: calc(4px + 0.75vw + 0.75vh);
  line-height: calc((4px + 0.75vh + 0.75vw) * 1.618);
  letter-spacing: calc((4px + 0.75vh + 0.75vw) / (1.618 * 20));
`;
const TaskInfo = styled.div`
  color: #595469;
  font-size: calc(8px + 0.25vw + 0.25vh);
  font-style: oblique;
`;
const ImgLock = styled.img`
  width: calc(8px + 3vw + 3vh);
  height: calc(8px + 3vw + 3vh);
`;

export default ({
  isNewForm,
  permission,
  isEditing,
  setEditPage,
  task,
  initials,
  formikProps,
  metadataSets,
  allMetadataSets,
  revisionSets,
  roles,
  selectedMetadata,
  selectedRevision,
  initialRevision,
  selectedStatus,
  statusRoles,
  formNo,
  state,
  ...props
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { template_name: templateName } = initials;
  const { values, touched, errors, handleChange } = formikProps;

  const [taskSender, setTaskSender] = useState(null);
  const [taskWorker, setTaskWorker] = useState(null);
  const [loadingTaskUser, setLoadingTaskUser] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    async function fetchUserInfo() {
      if (task?.length > 0) {
        setLoadingTaskUser(true);
        try {
          const senderUser = await findByIdUsersFunction(task[0].created_by);
          const senderCompany = await findByIdCompaniessFunction(
            senderUser.company_id
          );
          setTaskSender({
            ...senderUser,
            companyName: senderCompany[0]?.short_name ?? ''
          });
        } catch (error) {
          console.log('*-*-* an error occurred while fetching user: ', error);
        }
        try {
          if (task[0].workers) {
            const workerList = [];
            task[0].workers.map(async (element) => {
              const workerUser = await findByIdUsersFunction(element.worker_id);
              const workerCompany = await findByIdCompaniessFunction(
                workerUser.company_id
              );

              workerList.push({
                ...workerUser,
                companyName: workerCompany[0]?.short_name ?? ''
              });
            });
            setTaskWorker(workerList);
          } else {
            const workerUser = await findByIdUsersFunction(task[0].worker_id);
            const workerCompany = await findByIdCompaniessFunction(
              workerUser.company_id
            );
            setTaskWorker({
              ...workerUser,
              companyName: workerCompany[0]?.short_name ?? ''
            });
          }
        } catch (error) {
          console.log('*-*-* an error occurred while fetching user: ', error);
        }
        setLoadingTaskUser(false);
      }
      return true;
    }
    fetchUserInfo().then((userInfo) => {
      console.log('*-*-* fetchUserInfo worked ');
    });
  }, [task]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setReady(true);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <Grid
          container
          spacing={4}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={4}>
            <TemplateContainer>{templateName ?? ''}</TemplateContainer>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid
              container
              spacing={2}
              direction="column"
              justify="space-between"
              alignItems={isEditing ? 'flex-end' : 'center'}
            >
              <Grid item xs={12}>
                <FormNoText>{formNo ?? ''}</FormNoText>
              </Grid>
              {isEditing ? null : (
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={errors.status_set_id && touched.status_set_id}
                    disabled={!isEditing}
                  >
                    <Select
                      margin="dense"
                      variant="outlined"
                      name="status_set_id"
                      value={values.status_set_id}
                      fullWidth
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      {selectedStatus &&
                        selectedStatus.status_set_details
                          .map((setDetail) => ({
                            ...setDetail,
                            disabled:
                              statusRoles
                                .filter(
                                  (statusRole) =>
                                    statusRole.detail_id === setDetail.id
                                )[0]
                                .roles.filter(
                                  (role) =>
                                    roles.filter(
                                      (roleElement) =>
                                        roleElement.role_id === role.role_id
                                    )?.length > 0
                                )?.length > 0
                          }))
                          .map((detail) =>
                            detail.disabled ? (
                              <Option key={detail.id} value={detail.id}>
                                {' '}
                                {detail.name}{' '}
                              </Option>
                            ) : null
                          )}
                    </Select>
                    <FormHelperText>
                      {touched.status_set_id && errors.status_set_id}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
          {isEditing ? null : (
            <Grid item xs={12} sm={4}>
              <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                {isNewForm || !permission ? null : task?.length > 0 ? (
                  <>
                    <Grid item className={classes.textAlignRight}>
                      {/* <MdLock size="calc(8px + 1vw + 1vh)" /> */}
                      {task[0].ownTask || task[0].step_no === 3 ? (
                        <IconButton
                          onClick={() => {
                            setEditPage(!isEditing);
                          }}
                        >
                          {isEditing ? (
                            <AiFillCloseCircle size="calc(8px + 1vw + 1vh)" />
                          ) : (
                            <FiEdit3 size="calc(8px + 1vw + 1vh)" />
                          )}
                        </IconButton>
                      ) : (
                        <ImgLock src={Lock} alt="Lock" />
                      )}
                    </Grid>
                    <Grid item className={classes.textAlignRight}>
                      {loadingTaskUser || !taskSender || !taskWorker ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <Grid
                          container
                          item
                          direction="column"
                          justify="center"
                          alignItems="flex-start"
                        >
                          <Grid item xs={12}>
                            <TaskInfo>
                              {intl.formatMessage({ id: 'TASK' })} No:{' '}
                              {task[0].id ?? ''}
                            </TaskInfo>
                          </Grid>
                          <Grid item xs={12}>
                            <TaskInfo>
                              {intl.formatMessage({ id: 'SENDER_TASK' })}:{' '}
                              {taskSender?.name ?? ''} (
                              {taskSender?.companyName ?? ''})
                            </TaskInfo>
                          </Grid>
                          <Grid item xs={12}>
                            <TaskInfo>
                              {intl.formatMessage({ id: 'ASSIGNED' })}:{' '}
                              {Array.isArray(taskWorker)
                                ? taskWorker.map(
                                    (element) =>
                                      `\n\t${element?.name ?? ''} (${
                                        element?.companyName ?? ''
                                      })`
                                  )
                                : `${taskWorker?.name ?? ''} (${
                                    taskWorker?.companyName ?? ''
                                  })`}
                            </TaskInfo>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={8} className={classes.textAlignRight}>
                    {ready && state && state.formData?.status_name && (
                      <PDFWrapper state={state} />
                    )}
                    <IconButton
                      onClick={() => {
                        setEditPage(!isEditing);
                      }}
                    >
                      {isEditing ? (
                        <AiFillCloseCircle size="calc(8px + 1vw + 1vh)" />
                      ) : (
                        <FiEdit3 size="calc(8px + 1vw + 1vh)" />
                      )}
                    </IconButton>
                    {/** CANCEL MODAL */}
                    <CancelModal task={task} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </HeaderContainer>
      <InfoContainer>
        <InfoTitle>
          <FormattedMessage id="COMMON_INFO" />
        </InfoTitle>
        <FormInfo
          isEditing={isEditing}
          initials={initials}
          allMetadataSets={allMetadataSets}
          metadataSets={metadataSets}
          formikProps={formikProps}
          revisionSets={revisionSets}
          roles={roles}
          selectedMetadata={selectedMetadata}
          selectedRevision={selectedRevision}
          initialRevision={initialRevision}
          selectedStatus={selectedStatus}
          statusRoles={statusRoles}
          siteDetails={state.formData.site_details}
        />
      </InfoContainer>
    </Container>
  );
};
