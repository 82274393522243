import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';

const MenuButton = React.forwardRef((props, ref) => {
  const { children, list } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const listItems = list
    .map((el, index) => ({ ...el, name: index }))
    .map((link) => {
      const handleClick = () => {
        link.clickEvent();
        handleClose();
      };
      return (
        <MenuItem key={link.name} onClick={handleClick} ref={ref}>
          {link.element}
        </MenuItem>
      );
    });

  return (
    <>
      <IconButton aria-haspopup="true" onClick={handleMenu}>
        {children}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {listItems}
      </Menu>
    </>
  );
});

export default MenuButton;
