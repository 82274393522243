import React, { useState, Fragment } from 'react';
import styled from 'styled-components';

import uuid from 'uuid/dist/v4';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { MdRemoveRedEye, MdLock, MdTimeline } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

const TableContainerStyled = styled(TableContainer)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  padding: 0px;
  width: 100% !important;
`;

const TableCellStyled = styled(TableCell)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: ${(props) =>
    props.datecolor ? 'rgba(250,100,0,1) !important' : '#8a849b!important'};
  display: ${(props) => (props.id ? 'flex !important' : null)};
  justify-content: ${(props) => (props.id ? 'center !important' : null)};
  align-items: ${(props) => (props.id ? 'center !important' : null)};
  text-transform: uppercase;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-around;
`;

const MailTable = (props) => {
  const { list, handleRow, tableBody, tableHeads, projects, box } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(9);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getActions = ({ template, detail, lock }) => (
    <>
      {!!template && <MdTimeline />}
      {!!detail && <MdRemoveRedEye />}
      {!!lock && <MdLock />}
    </>
  );

  return (
    <div style={{ width: '100%' }}>
      <TableContainerStyled>
        <Table aria-label="mail table">
          <TableHead>
            <TableRow>
              {tableHeads.map((head) => (
                <TableCellStyled {...head.props} key={uuid()}>
                  <FormattedMessage id={head.title} />
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <TableRow
                  key={`Table-row-${item.id}-${index}`}
                  onClick={() => handleRow(item.id)}
                  hover
                >
                  {tableBody.map((body, index) => (
                    <TableCellStyled key={uuid()} {...body.props}>
                      {body.name === 'name' || body.name === 'companyName' ? (
                        item.to_json.users &&
                        item.to_json.users.map((user, index) =>
                          body.name === 'name' ||
                          (body.name === 'companyName' && index === 0) ? (
                            <p>{user[body.name]}</p>
                          ) : null
                        )
                      ) : body.name === 'title' ? (
                        <span>
                          {item.form_name} - {item.form_no}
                        </span>
                      ) : body.name === 'post_number' ? (
                        <span>
                          {
                            projects.filter(
                              (project) => project.id === item.project_id
                            )[0].project_code
                          }
                          -{item.short_name}-{item.form_no.split('-').pop()}
                        </span>
                      ) : (
                        item[body.name]
                      )}
                    </TableCellStyled>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainerStyled>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default MailTable;
