import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locale: 'en',
};

const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      state.locale = payload;
    },
  },
});

export const { setLanguage } = i18nSlice.actions;

export default i18nSlice.reducer;

export const i18nSelector = (state) => state.i18n;
