import React from 'react';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Formik, Form, ErrorMessage } from 'formik';
import { getRandomColor } from 'helpers';
import * as Yup from 'yup';
import {
  usersSelector,
  updateUser,
  updateUserPassword,
} from 'slices';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';

import { authentication, changeValues } from '../../helpers/authentication';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(36),
      height: theme.spacing(24),
    },
  },
  dialogGrid: {
    margin: '0px',
    padding: '0px',
    '& > p': {
      fontSize: '12px',
      margin: '3px',
      marginTop: '0px',
      padding: '0px',
      fontWeight: 'bold',
      color: '#555',
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  dialogButton: {
    margin: '5px 15px',
  },
  table: {
    minWidth: 650,
  },
  userAvatar: {
    background: getRandomColor(),
  },
}));

const UserForm = (props) => {
  const {
    showEditModal, setShowEditModal,
  } = props;

  const dispatch = useDispatch();

  const classes = useStyles();

  const intl = useIntl();

  const userValues = authentication.userValue;

  const editInitialValues = {
    name: userValues.name,
    email: userValues.email,
    password: '',
    passwordConfirmation: '',
  };

  const { loading } = useSelector(usersSelector);

  const editValidationSchema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: 'REQUIRED_NAME_AND_SURNAME' }),
    ),
    email: Yup.string().required(
      intl.formatMessage({ id: 'REQUIRED_NAME_AND_MAIL' }),
    ),
    password: Yup.string()
      .min(8, intl.formatMessage({ id: 'PASSWORD_IS_TOO_SHORT' }))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        intl.formatMessage({ id: 'PASSWORD_MUST_CONTAIN' }),
      ),
  });

  const handleUpdateUser = (values) => {
    const userObj = {
      name: values.name,
      email: values.email,
      user_id: userValues.id,
    };

    dispatch(updateUser(userObj)).then(async () => {
      if (values.password !== '') {
        const newUserPassObj = {
          user_id: userValues.id,
          password: values.passwordConfirmation,
        };
        await dispatch(updateUserPassword(newUserPassObj)).then(
          () => {
            // console.log('UPDATE USER PASSWORD DONE');
          },
        );
      }

      changeValues({ name: values.name, email: values.email });
      setShowEditModal(false);
    });
  };

  return (
    <Dialog
      open={showEditModal}
      onClose={() => {
        setShowEditModal(false);
      }}
      aria-labelledby="form-dialog-title"
      fullWidth
      scroll="body"
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        <div className={classes.dialogTitle}>
          <FormattedMessage id="UPDATE_USER" />
          <IconButton
            onClick={() => {
              setShowEditModal(false);
            }}
          >
            <AiOutlineCloseCircle />
          </IconButton>
        </div>
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={editInitialValues}
        validationSchema={editValidationSchema}
        onSubmit={(values, { setErrors }) => {
          if (values.password !== values.passwordConfirmation) {
            setErrors({
              passwordConfirmation: intl.formatMessage({
                id: 'REQUIRED_PASSWORD_HAVE_TO_MATCH',
              }),
            });
          } else {
            handleUpdateUser(values);
          }
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
        }) => (
          <Form>
            <DialogContent dividers>
              <Grid container spacing={3} alignItems="flex-start">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  classes={{ root: classes.dialogGrid }}
                >
                  <p className={classes.uppercase}>
                    <FormattedMessage id="NAME_SURNAME" />{' '}
                  </p>
                  <TextField
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({
                      id: 'ENTER_NAME_SURNAME',
                    })}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    render={(msg) => (
                      <div style={{ color: 'red', marginTop: '8px' }}>
                        {msg}
                      </div>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  classes={{ root: classes.dialogGrid }}
                >
                  <p className={classes.uppercase}>
                    <FormattedMessage id="MAIL" />{' '}
                  </p>
                  <TextField
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'ENTER_MAIL' })}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    render={(msg) => (
                      <div style={{ color: 'red', marginTop: '8px' }}>
                        {msg}
                      </div>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  classes={{ root: classes.dialogGrid }}
                >
                  <p className={classes.uppercase}>
                    <FormattedMessage id="PASSWORD" />{' '}
                  </p>
                  <TextField
                    id="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'ENTER_PASSWORD' })}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    render={(msg) => (
                      <div style={{ color: 'red', marginTop: '8px' }}>
                        {msg}
                      </div>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  classes={{ root: classes.dialogGrid }}
                >
                  <p className={classes.uppercase}>
                    <FormattedMessage id="CONFIRM_PASSWORD" />
                  </p>
                  <TextField
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    value={values.passwordConfirmation}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({
                      id: 'ENTER_PASSWORD_TO_CONFIRM_PASSWORD',
                    })}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  <ErrorMessage
                    name="passwordConfirmation"
                    component="div"
                    render={(msg) => (
                      <div style={{ color: 'red', marginTop: '8px' }}>
                        {msg}
                      </div>
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div className={classes.dialogTitle}>
                <Button
                  onClick={() => {
                    setShowEditModal(false);
                  }}
                  variant="contained"
                  className={classes.dialogButton}
                >
                  <FormattedMessage id="CANCEL" />
                </Button>
                <div>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    className={classes.dialogButton}
                  >
                    {loading.updateUser && (
                    <CircularProgress size="12" color="inherit" />
                    )}
                    <FormattedMessage id="APPLY" />
                  </Button>
                </div>
              </div>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UserForm;
