import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Field } from 'formik';
import {
  TextField,
  Grid,
  Paper,
} from '@material-ui/core';

import { InputLabel } from '../Containers';

export default ({
  input,
  value,
  extraInputs,
  formName,
  controlName,
  disabled,
  stateIndex,
}) => {
  const [extraInputsList, setExtraInputsList] = useState(extraInputs);

  useEffect(() => {}, [extraInputsList]);

  const {
    name, label, hint, defaultValue, range, required,
  } = input;

  const intl = useIntl();

  const hintText = (hint && hint['0']) || '';
  const labelText = (label && label['0']) || '';

  let fieldName = `${formName}`;
  if (controlName) {
    fieldName = fieldName.concat(`.${controlName}`);
  }
  fieldName = fieldName.concat(`.${name}`);

  return (
    <Field
      name={fieldName}
      validate={(vv) => {
        if (required && !disabled) {
          if (Array.isArray(vv)) {
            let message = '';
            vv.map((vvv) => {
              if (vvv.length === 0) {
                message = intl.formatMessage({ id: 'REQUIRED_THIS_FIELD' });
              }
            });
            return message;
          }
          if (vv.length === 0) {
            return intl.formatMessage({ id: 'REQUIRED_THIS_FIELD' });
          }
        }
        if (range && !disabled) {
          if (Array.isArray(vv)) {
            let message = '';
            vv.map((vvv) => {
              if (
                !(
                  parseInt(vvv, 10) >= range.min
                  && parseInt(vvv, 10) <= range.max
                )
              ) {
                message = `${intl.formatMessage({
                  id: 'RANGE_OF_VALUE',
                })}:\nMin: ${range.min}\nMax: ${range.max}`;
              }
            });
            return message;
          }
          if (
            !(parseInt(vv, 10) >= range.min && parseInt(vv, 10) <= range.max)
          ) {
            return `${intl.formatMessage({ id: 'RANGE_OF_VALUE' })}:\nMin: ${
              range.min
            }\nMax: ${range.max}`;
          }
        }
      }}
    >
      {(props) => {
        const { field, form, meta } = props;

        return (
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={3}>
              <InputLabel>
                {hintText}{required ? ' (*)' : ''}
                {
                  range
                    ? (
                      <>
                        <br />
                        ({intl.formatMessage({ id: 'LENGTH' })}:
                        <br />
                        Min: {range.min}, Max: {range.max})
                      </>
                    )
                    : ''
                }
              </InputLabel>
            </Grid>
            <Grid item xs={9}>
              <Paper elevation={3}>
                <TextField
                  disabled={disabled}
                  variant="outlined"
                  fullWidth
                  type="number"
                  style={{ backgroundColor: '#fff' }}
                  error={meta.error}
                  helperText={meta.error}
                  placeholder={hintText}
                  defaultValue={defaultValue}
                  onBlur={field.onBlur(name)}
                  onChange={(e) => {
                    const inputValue = parseInt(e.target.value, 10);
                    if (!isNaN(inputValue)) {
                      form.setFieldValue(
                        extraInputsList && extraInputsList.length > 0
                          ? `${fieldName}.${stateIndex}`
                          : fieldName,
                        inputValue,
                      );
                    }
                  }}
                  value={
                    extraInputsList && extraInputsList.length > 0
                      ? field.value && field.value[stateIndex]
                      : field.value
                  }
                />
              </Paper>
            </Grid>
          </Grid>
        );
      }}
    </Field>
  );
};
