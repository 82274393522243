import React, {
  useState, Fragment, useEffect, useRef,
} from 'react';
import styled from 'styled-components';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import { IoMdLock } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';

import { findCompaniesByProjectIdFunc } from 'api/sets/rolesSets';
import { findGroupsByCompanyId } from 'api/groups';
import { projectsSelector, fetchRoleSetsByProject } from 'slices';
import AlertDialog from 'components/Common/AlertDialog';
import { useIntl, FormattedMessage } from 'react-intl';
import EditFormInfo from './EditFormInfo';
import EditFormStructure from './EditFormStructure';

import FormInfo from './FormInfo';
import FormStructure from './FormStructure';

import { authentication } from '../../helpers/authentication';

import { getMetadataSetsFunction } from '../../api/sets/metadataSets';

import {
  templatesSelector,
  fetchTemplate,
  addTemplate,
  updateTemplate,
  getTemplateGroup,
  addTemplateGroup,
  getTemplateSuccess,
  fetchTemplateList,
  getTemplateMetadataSuccess,
  getTemplateMetadata,
  addTemplateMetadata,
} from '../../slices/templatesSlice';

import FormMetaData from './FormMetaData';
import EditFormMetaData from './EditFormMetaData';
import FormPermissions from './FormPermissions';
import EditFormPermission from './EditFormPermission';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  leftside: {
    width: '20vw',
    marginRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    height: '100%',
  },
  rightside: {
    marginLeft: theme.spacing(1),
    maxHeight: (props) => (props.inEditPage ? '68vh' : '75vh'),
  },
  paper: {
    flex: 1,
    marginLeft: theme.spacing(1),
    height: '100%',
  },
  actions: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    alignContent: 'center',
    height: '8%',
    padding: theme.spacing(1),
  },
  header: {
    height: theme.spacing(3),
    flex: 1,
    fontFamily: 'Helvetica, Helvetica, Arial, serif',
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.5)',
    textAlign: 'center',
    lineHeight: '17.0px',
    borderBottom: `${theme.spacing(0.6)}px solid rgba(250, 100, 0, 1)`,
  },
  historyside: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  historyleftone: {
    fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
    fontSize: 14,
    color: 'rgba(89, 84, 105, 1.0)',
    textAlign: 'left',
    lineHeight: '17.0px',
    margin: theme.spacing(1),
    alignItems: 'center',
  },
  historyrightone: {
    fontFamily: 'Helvetica-Bold, Helvetica, Arial, serif',
    fontSize: 14,
    color: 'rgba(250, 100, 105, 1.0)',
    textAlign: 'right',
    lineHeight: '17.0px',
    margin: theme.spacing(0.75),
  },
}));

export const FormEditorContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(245, 245, 245, 1);
`;

export const TitleContainer = styled.div`
  background-color: rgba(255, 255, 255, 0);
  height: 48px;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 40px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Helvetica', Helvetica, Arial, serif;
  font-size: calc(12px + 0.5vw + 0.5vh);
  line-height: calc((12px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((12px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(89, 84, 105, 1);
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  height: 100%;
`;

export const HistoryListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  height: 70vh;
  overflow: auto;
`;

export const History = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  width: 100%;
  cursor: pointer;
  transition: all 300ms;
  background-color: ${(props) => (props.now ? 'rgb(100, 104, 110, 0.2)' : null)};

  &:hover {
    background-color: rgb(100, 104, 110, 0.2);
  }
`;

export const HistoryChip = styled.div`
  background-image: ${(props) => (props.status === 1
    ? 'linear-gradient(to right, #75cabb, #50a5e2)'
    : props.status === 2
      ? 'linear-gradient(to right, #64686e, #343739)'
      : props.status === 3
        ? 'linear-gradient(to right, #78ce1b, #549c04)'
        : null)};
  margin: 6px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 6px;
  font-family: 'Helvetica-Bold', Helvetica, Arial, serif;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 14px;
`;

export const CancelButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to left, #e0e0e0, #eeeeee);
  color: #000000 !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;

  &:hover {
    opacity: 1;
  }
`;

export const ResetButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to left, #e0e0e0, #eeeeee);
  color: #000000 !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;
  margin-right: 40px !important;

  &:hover {
    opacity: 1;
  }
`;

export const SaveButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to right, #ff963e, #fa6400);
  color: #ffffff !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;

  &:hover {
    opacity: 1;
  }
`;
export const SendButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to right, #ff963e, #fa6400);
  color: #ffffff !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;

  &:hover {
    opacity: 1;
  }
`;

const HistoryList = ({ data, clickEvent, now }) => {
  const classes = useStyles();

  return data.map((datum, index) => {
    let statusName;

    switch (datum.status) {
      case 1:
        statusName = 'DRAFT';
        break;
      case 2:
        statusName = 'INACTIVE';
        break;
      default:
        statusName = 'PUBLISHED';
    }
    const update_date = datum.updated_at
      && new Date(datum.updated_at).toLocaleString().substring(0, 16);
    return (
      <Fragment key={datum.id}>
        <History onClick={() => clickEvent(index)} now={now === datum.id}>
          <div className={classes.historyside}>
            <div className={classes.historyleftone}>#{datum.version}</div>
            <div className={classes.historyleftone}>
              <IoMdLock />
            </div>
          </div>
          <div className={classes.historyside}>
            <div className={classes.historyrightone}>{update_date}</div>
            <HistoryChip status={datum.status}>{statusName}</HistoryChip>
          </div>
        </History>
        <Divider />
      </Fragment>
    );
  });
};

export const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    bottom: '3px',
    height: '5px',
    zIndex: 200,
    backgroundColor: 'rgba(250, 100, 0, 1)',
    '& > div': {
      width: '100%',
    },
  },
})((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <div />,
    }}
  />
));

export const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    backgroundColor: '#fff',
    color: '#595469',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export const TabPanel = (props) => {
  const {
    children, value, index, boxWidth, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box width={boxWidth || '68vw'} p={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

export const EditButton = styled(Button)`
  align-items: center;
  justify-content: center;
  margin: 10px !important;
  margin-right: 0 !important;
  opacity: 0.75;
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;

export const ContentTitle = styled.span`
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
  align-self: center;
  color: #595469;
`;

export const ContentLink = styled.span`
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
  align-self: center;
  color: #fa6400;
`;

export default function (props, ref) {
  const [formChanged, setFormChanged] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [newTemplate, setNewTemplate] = useState({});
  const [formData, setFormData] = useState({});
  const [metadataList, setMetadataList] = useState([]);
  const [allMetadata, setAllMetadata] = useState([]);

  const [allGroups, setAllGroups] = useState([]);
  const [permissionList, setPermissionList] = useState([]);

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const [value, setValue] = useState(0);
  const [inEditPage, setInEditPage] = useState(0);
  const [projectId, setProjectId] = useState(0);

  const [alert, setAlert] = useState(false);

  const classes = useStyles({ inEditPage });

  const dispatch = useDispatch();

  const intl = useIntl();

  let { id } = useParams();

  id = decodeURIComponent(id);

  const inputRef = useRef(null);
  const mountedRef = useRef(true);

  const { templateList, template, template_groups } = useSelector(
    templatesSelector,
  );

  const { selectedProject } = useSelector(projectsSelector);

  const initials = {
    template_name: '',
    id_number: '',
    status: null,
    prefix: '',
    site_set_id: null,
    status_set_id: null,
    revision_set_id: null,
    site_level: null,
    counter_format: '',
    project_id: selectedProject && selectedProject.id,
    template: {
      title: '',
      subForms: [],
      metadata: [],
    },
  };

  function setRoles(project_id) {
    dispatch(fetchRoleSetsByProject(project_id));
  }

  useEffect(() => {
    if (id == 'new') {
      setNewTemplate(initials);
      setInEditPage(1);
    } else {
      dispatch(fetchTemplate(id));
    }
  }, [id]);

  useEffect(() => {
    if (!!template) {
      const { project_id, original_template_id } = template;
      if (
        !templateList[0]
        || templateList[0].original_template_id !== original_template_id
      ) {
        dispatch(fetchTemplateList(project_id, original_template_id));
      }
    }
  }, [template]);

  useEffect(() => {
    if (!!template && id !== 'new') {
      let { template: jsonData } = template;
      setValues(template);
      if (typeof jsonData === 'string') {
        jsonData = JSON.parse(jsonData);
      }
      setFormData(JSON.parse(JSON.stringify(jsonData)));

      if (!!template.project_id) {
        setProjectId(template.project_id);
        setGroups(template.project_id);
        setMetadatas(template.project_id);
        setRoles(template.project_id);
      }
    }
  }, [template]);

  useEffect(() => {
    if (selectedProject && id === 'new') {
      setValues(initials);
      let { template: jsonData } = initials;
      if (typeof jsonData === 'string') {
        jsonData = JSON.parse(jsonData);
      }
      setFormData(JSON.parse(JSON.stringify(jsonData)));
      setProjectId(selectedProject.id);
      setGroups(selectedProject.id);
      setMetadatas(selectedProject.id);
      setRoles(selectedProject.id);
    } else if (!selectedProject && id === 'new') {
      setAlert(true);
    }
  }, [selectedProject]);

  async function setGroups(project_id) {
    const _allGroups = [];

    await findCompaniesByProjectIdFunc(project_id).then(async (res) => {
      const _companies = [];
      res.forEach((r) => {
        if (_companies.every((c) => c.company_id != r.company_id)) {
          _companies.push(r);
        }
      });
      for (const company of _companies) {
        await findGroupsByCompanyId(company.company_id).then((group) => {
          group = group.map((g) => ({
            ...g,
            company_name: company.company_name,
          }));

          _allGroups.push(...group);
        });
      }
    });

    setAllGroups(_allGroups);

    if (id == 'new') {
      setPermissionList([]);
    } else if (template) {
      dispatch(getTemplateGroup(template.id)).then((res) => {
        const _group = res.map((g) => ({
          ...g,
          company_name: _allGroups.filter((item) => item.id === g.group_id)[0].company_name,
        }));
        setPermissionList(_group);
      });
    }
  }

  async function setMetadatas(project_id) {
    if (id == 'new') {
      dispatch(getTemplateMetadataSuccess([]));
      setMetadataList([]);
    } else {
      dispatch(getTemplateMetadata(id)).then((res) => setMetadataList(res));
    }
    getMetadataSetsFunction(project_id).then((res) => setAllMetadata(res));
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // setInEditPage(0);
    // ref.current.scrollTo(0, 0);
  };

  const handleTemplateChange = async (index) => {
    dispatch(fetchTemplate(templateList[index].id));
    setValue(0);
    setInEditPage(0);
    // ref.current.scrollTo(0, 0);
  };

  const handleEdit = () => {
    dispatch(getTemplateSuccess(templateList[0]));
    setInEditPage(1);
  };

  useEffect(() => {
    if (inputRef.current) {
      setFormChanged(inputRef.current.dirty);
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (isSubmit) {
      inputRef.current.submitForm();
      setSubmit(false);
    }
  }, [isSubmit]);

  const saveTemplate = async (values) => {
    const { id: userId } = authentication.userValue;
    const date = new Date().toISOString().substr(0, 10);

    if (id !== 'new') {
      await dispatch(
        updateTemplate({
          ...values,
          created_by: userId,
          created_at: date,
          updated_by: userId,
          updated_at: date,
          template: formData,
          original_template_id: template.original_template_id,
          version: null,
        }),
      ).then(async (newTemplate) => {
        permissionList.forEach((newGroup) => {
          dispatch(addTemplateGroup(newTemplate.id, newGroup.group_id));
        });
        metadataList.forEach((newMetadata) => {
          dispatch(
            addTemplateMetadata(
              newTemplate.id,
              newMetadata.metadata_id,
              newMetadata.metadata_detail_id,
            ),
          );
        });
        props.history.push({ pathname: `/form-templates/${newTemplate.id}` });
      });
    } else {
      formData.title = values.template_name;
      await dispatch(
        addTemplate({
          ...values,
          id_number: userId,
          created_by: userId,
          updated_by: userId,
          template: formData,
          project_id: selectedProject.id,
        }),
      ).then(async (newTemplate) => {
        await Promise.all(
          permissionList.map((newGroup) => dispatch(addTemplateGroup(newTemplate.id, newGroup.group_id))),
          metadataList.map((newMetadata) => dispatch(
            addTemplateMetadata(
              newTemplate.id,
              newMetadata.metadata_id,
              newMetadata.metadata_detail_id,
            ),
          )),
        );
        props.history.push({ pathname: `/form-templates/${newTemplate.id}` });
        setNewTemplate({});
      });
    }

    setInEditPage(0);
    // ref.current.scrollTo(0, 0);
  };
  const alertText = intl.formatMessage({ id: 'PROJECT_SELECTION_ALERT' });
  return (
    <FormEditorContainer>
      <AlertDialog open={alert} setOpen={setAlert} alertText={alertText} />
      <TitleContainer>
        <Breadcrumbs separator={<>&gt;</>} aria-label="breadcrumb">
          <Link component={RouterLink} to="/form-templates">
            <ContentLink>
              <FormattedMessage id="FORM_TEMPLATES" />{' '}
            </ContentLink>
          </Link>
          {id === 'new' ? (
            <ContentTitle>
              {' '}
              <FormattedMessage id="NEW_TEMPLATE" />{' '}
            </ContentTitle>
          ) : (
            <ContentTitle>{template && template.template_name}</ContentTitle>
          )}
        </Breadcrumbs>
        {inEditPage ? null : (
          <EditButton
            variant="contained"
            color="primary"
            size="medium"
            style={{ backgroundColor: '#1296fb' }}
            onClick={() => handleEdit()}
          >
            <FormattedMessage id="UPDATE" />
          </EditButton>
        )}
      </TitleContainer>
      {templateList && (
        <div className={classes.root}>
          <MainContainer>
            <Paper className={` ${classes.leftside}`}>
              <div className={classes.header}>
                <span
                  style={{
                    backgroundColor: '#faf8f8',
                    color: '#595469',
                    textTransform: 'uppercase',
                  }}
                >
                  <FormattedMessage id="HISTORY_OF_FORM_TEMPLATE" />
                </span>
              </div>
              <HistoryListContainer>
                {id !== 'new' && (
                  <HistoryList
                    data={templateList}
                    clickEvent={(index) => handleTemplateChange(index)}
                    now={template ? template.id : 0}
                  />
                )}
              </HistoryListContainer>
            </Paper>
            {(template || (newTemplate && id == 'new')) && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Paper className={`${classes.paper} ${classes.rightside}`}>
                  <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="form-tabs"
                  >
                    <StyledTab
                      label={(
                        <span style={{ textTransform: 'uppercase' }}>
                          <FormattedMessage id="TEMPLATE_INFO" />{' '}
                        </span>
                      )}
                    />
                    <StyledTab
                      label={(
                        <span style={{ textTransform: 'uppercase' }}>
                          <FormattedMessage id="TEMPLATE_STRUCTURE" />{' '}
                        </span>
                      )}
                    />
                    <StyledTab
                      label={(
                        <span style={{ textTransform: 'uppercase' }}>
                          <FormattedMessage id="HEADER" />{' '}
                        </span>
                      )}
                    />
                    <StyledTab
                      label={(
                        <span style={{ textTransform: 'uppercase' }}>
                          <FormattedMessage id="AUTHORIZATION" />{' '}
                        </span>
                      )}
                    />
                  </StyledTabs>
                  <TabPanel value={value} index={0}>
                    {inEditPage ? (
                      <EditFormInfo
                        formChanged={formChanged}
                        setFormChanged={setFormChanged}
                        values={values}
                        setValues={setValues}
                        errors={errors}
                        setErrors={setErrors}
                        projectId={projectId}
                        test={isSubmit}
                        saveTemplate={saveTemplate}
                        ref={inputRef}
                        isNewTemplate={id === 'new'}
                      />
                    ) : (
                      <FormInfo
                        template={id !== 'new' ? template : newTemplate}
                      />
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {inEditPage ? (
                      <EditFormStructure
                        template={id !== 'new' ? template : { ...newTemplate }}
                        ref={ref}
                        formChanged={formChanged}
                        setFormChanged={setFormChanged}
                        formData={formData}
                        setFormData={setFormData}
                      />
                    ) : (
                      <FormStructure template={template} />
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    {inEditPage ? (
                      <EditFormMetaData
                        projectId={projectId}
                        metadataList={metadataList}
                        setMetadataList={setMetadataList}
                        allMetadata={allMetadata}
                      />
                    ) : (
                      <FormMetaData metadataList={metadataList} />
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    {inEditPage ? (
                      <EditFormPermission
                        allGroups={allGroups}
                        setAllGroups={setAllGroups}
                        permissionList={permissionList}
                        setPermissionList={setPermissionList}
                      />
                    ) : (
                      <FormPermissions groups={permissionList} />
                    )}
                  </TabPanel>
                </Paper>
                {inEditPage ? (
                  <Paper className={classes.actions}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <CancelButton
                        variant="contained"
                        size="medium"
                        onClick={() => {
                          if (formChanged) {
                            window.confirm(
                              intl.formatMessage({ id: 'UNSAVED_FORM_ALERT' }),
                            ) && setInEditPage(0);
                          } else {
                            setInEditPage(0);
                          }
                          // ref.current.scrollTo(0, 0);
                        }}
                      >
                        <FormattedMessage id="CANCEL" />
                      </CancelButton>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <SaveButton
                          variant="contained"
                          size="medium"
                          // disabled={!formChanged}
                          onClick={() => {
                            setValue(0);
                            setSubmit(true);
                          }}
                        >
                          <FormattedMessage id="SAVE" />
                        </SaveButton>
                      </div>
                    </div>
                  </Paper>
                ) : null}
              </div>
            )}
          </MainContainer>
        </div>
      )}
    </FormEditorContainer>
  );
}
