import React, { useState } from 'react';
import {
  StyledTabs,
  StyledTab,
  TabPanel,
} from 'components/ProjectForms/FormEditor';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from '@material-ui/core';

const NotificationSettings = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const {
    value,
    setValue,
    mobileNotify,
    setMobileNotify,
    emailNotify,
    setEmailNotify,
  } = props;

  const intl = useIntl();

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      <StyledTabs
        value={tabIndex}
        onChange={(event, value) => setTabIndex(value)}
        aria-label="form-tabs"
      >
        <StyledTab
          label={(
            <span style={{ textTransform: 'uppercase' }}>
              <FormattedMessage id="MAIL_NOTIFICATION" />
            </span>
          )}
        />
        <StyledTab
          label={(
            <span style={{ textTransform: 'uppercase' }}>
              <FormattedMessage id="MOBILE_NOTIFICATION" />
            </span>
          )}
        />
      </StyledTabs>
      <TabPanel value={tabIndex} index={0} boxWidth="100%">
        <FormGroup aria-label="position">
          <FormControlLabel
            value="top"
            control={<Checkbox color="primary" />}
            label={intl.formatMessage({ id: 'DONT_SEND_MAIL_NOTIFICATION' })}
            labelPlacement="end"
            onChange={(e) => {
              setEmailNotify(e.target.checked ? '0' : '1');
            }}
            checked={emailNotify === '0'}
          />
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label={intl.formatMessage({ id: 'INSTANT_NOTIFICATION' })}
              labelPlacement="end"
              disabled={emailNotify === '0'}
            />
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label={intl.formatMessage({ id: 'DAILY_NOTIFICATION' })}
              labelPlacement="end"
              disabled={emailNotify === '0'}
            />
          </RadioGroup>
        </FormGroup>
      </TabPanel>
      <TabPanel value={tabIndex} index={1} boxWidth="100%">
        <FormGroup aria-label="position">
          <FormControlLabel
            value="top"
            control={<Checkbox color="primary" />}
            label={intl.formatMessage({ id: 'DONT_SEND_MOBILE_NOTIFICATION' })}
            labelPlacement="end"
            onChange={(e) => {
              setMobileNotify(e.target.checked ? '0' : '1');
            }}
            checked={mobileNotify === '0'}
          />
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label={intl.formatMessage({ id: 'INSTANT_NOTIFICATION' })}
              labelPlacement="end"
              disabled={mobileNotify === '0'}
            />
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label={intl.formatMessage({ id: 'DAILY_NOTIFICATION' })}
              labelPlacement="end"
              disabled={mobileNotify === '0'}
            />
          </RadioGroup>
        </FormGroup>
      </TabPanel>
    </>
  );
};

export default NotificationSettings;
