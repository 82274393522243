import React from 'react';

import TextField from '@material-ui/core/TextField';

const InputComponent = ({ inputRef, ...other }) => <div style={{ width: '100%', height: '100%' }} {...other} />;
const OutlinedDiv = ({ children, label, ...others }) => (
  <TextField
    {...others}
    variant="outlined"
    fullWidth

    label={label}
    multiline
    InputLabelProps={{ shrink: true }}
    InputProps={{
      inputComponent: InputComponent,
    }}
    inputProps={{ children }}
  />
);
export default OutlinedDiv;
