import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation, NavLink } from 'react-router-dom';
import { FaTasks } from 'react-icons/fa';
import {
  MdAccountBalance,
  MdFilterNone,
  MdTimeline,
  MdMailOutline,
} from 'react-icons/md';
import { AiOutlineDatabase, AiOutlineFileSearch } from 'react-icons/ai';
import { RiStackLine } from 'react-icons/ri';
import { PermissionContainer } from 'helpers/permission';
import { FormattedMessage } from 'react-intl';

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  display: block;
  min-width: 85px;
  height: 100%;
  top: 70px;
  bottom: 0;
  overflow-y: auto;
  left: 0;
  background-color: rgba(245, 245, 245, 0);
  display: flex;
  z-index: 1000;
  flex-direction: column;
`;

const SidebarMenuItem = styled(NavLink)`
  width: ${(props) => (props.now ? '83px' : '70px')};
  height: 60px;
  background-color: ${(props) => (props.now ? 'rgba(250, 100, 0, 1)' : 'rgba(255, 255, 255, 1)')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  color: ${(props) => (props.now ? 'rgba(255, 255, 255, 1)' : 'rgba(250, 100, 0, 1)')};
  border-radius: ${(props) => (props.now ? '0px 5px 5px 0px' : '0px')};
  transition: all 300ms;

  &:hover {
    width: 150px;
    height: 75px;
    border-radius: 0px;
    background-color: ${(props) => (props.now ? 'rgba(250, 100, 0, 1)' : 'rgba(247, 209, 184, 1)')};
  }
`;

const SidebarSpacer = styled.div`
  width: 70px;
  flex: 1;
  background-color: rgba(255, 255, 255, 1);
`;
const SidebarDiveder = styled.div`
  width: 25px;
  padding: 0px;
  margin: 0px 22.5px;
  border-top: 1px solid rgba(250, 100, 0, 0.19799);
  opacity: 0.1;
`;

const Icon = styled.div`
  color: ${(props) => (props.now ? 'rgba(255,255,255,1)' : 'rgba(250,100,0,1)')};
  font-size: 1.5em;
`;

export default () => {
  const [isShown, setIsShown] = useState(false);
  let nowPath;
  const { pathname } = useLocation();
  if (pathname.includes('/projects')) nowPath = 'projects';
  else if (pathname.includes('/companies')) nowPath = 'companies';
  else if (pathname.includes('/users')) nowPath = 'users';
  else if (pathname.includes('/settings')) nowPath = 'settings';
  else if (pathname.includes('/form-templates')) nowPath = 'form-templates';
  else if (pathname.includes('/forms')) nowPath = 'forms';
  else if (pathname.includes('/tasks')) nowPath = 'tasks';
  else if (pathname.includes('/mails')) nowPath = 'mails';
  else if (pathname.includes('/reports')) nowPath = 'reports';
  else nowPath = 'home';

  const notNowColor = 'rgba(250,100,0,1)';
  const nowColor = 'rgba(255,255,255,1)';
  return (
    <SidebarContainer>
      <SidebarMenuItem
        now={nowPath === 'home' ? 1 : 0}
        to="/home"
        onMouseEnter={() => setIsShown('home')}
        onMouseLeave={() => setIsShown(null)}
      >
        <Icon now={nowPath === 'home'} className="icon-home" />
        {isShown === 'home' && (
        <span style={{ marginTop: '5px', textTransform: 'uppercase' }}>
          <FormattedMessage id="HOMEPAGE" />
        </span>
        )}
      </SidebarMenuItem>
      <SidebarDiveder />

      <PermissionContainer page="tasks" show>
        <SidebarMenuItem
          now={nowPath === 'tasks' ? 1 : 0}
          to="/tasks"
          onMouseEnter={() => setIsShown('tasks')}
          onMouseLeave={() => setIsShown(null)}
        >
          <FaTasks
            color={nowPath === 'tasks' ? nowColor : notNowColor}
            size="1.5em"
            style={{ marginLeft: nowPath === 'tasks' ? '-1em' : '0' }}
          />
          {isShown === 'tasks' && (
          <span style={{ marginTop: '5px', textTransform: 'uppercase' }}>
            <FormattedMessage id="TASKS" />
          </span>
          )}
        </SidebarMenuItem>
        <SidebarDiveder />
      </PermissionContainer>

      <PermissionContainer page="forms" show>
        <SidebarMenuItem
          now={nowPath === 'forms' ? 1 : 0}
          to="/forms"
          onMouseEnter={() => setIsShown('forms')}
          onMouseLeave={() => setIsShown(null)}
        >
          <RiStackLine
            color={nowPath === 'forms' ? nowColor : notNowColor}
            size="1.5em"
            style={{ marginLeft: nowPath === 'forms' ? '-1em' : '0' }}
          />
          {isShown === 'forms' && (
          <span style={{ marginTop: '5px', textTransform: 'uppercase' }}>
            <FormattedMessage id="FORMS" />
          </span>
          )}
        </SidebarMenuItem>
        <SidebarDiveder />
      </PermissionContainer>

      <PermissionContainer page="templates" show>
        <SidebarMenuItem
          now={nowPath === 'form-templates' ? 1 : 0}
          to="/form-templates"
          onMouseEnter={() => setIsShown('form-templates')}
          onMouseLeave={() => setIsShown(null)}
        >
          <MdTimeline
            color={nowPath === 'form-templates' ? nowColor : notNowColor}
            size="1.5em"
            style={{ marginLeft: nowPath === 'form-templates' ? '-1em' : '0' }}
          />
          {isShown === 'form-templates' && (
          <span style={{ marginTop: '5px', textTransform: 'uppercase' }}>
            <FormattedMessage id="FORM_TEPLATES" />
          </span>
          )}
        </SidebarMenuItem>
        <SidebarDiveder />
      </PermissionContainer>

      <PermissionContainer page="mails" show>
        <SidebarMenuItem
          now={nowPath === 'mails' ? 1 : 0}
          to="/mails"
          onMouseEnter={() => setIsShown('mails')}
          onMouseLeave={() => setIsShown(null)}
        >
          <MdMailOutline
            color={nowPath === 'mails' ? nowColor : notNowColor}
            size="1.5em"
            style={{ marginLeft: nowPath === 'mails' ? '-1em' : '0' }}
          />
          {isShown === 'mails' && (
          <span style={{ marginTop: '5px', textTransform: 'uppercase' }}>
            <FormattedMessage id="FORMBOX" />
          </span>
          )}
        </SidebarMenuItem>
        <SidebarDiveder />
      </PermissionContainer>

      <PermissionContainer page="companies" show>
        <SidebarMenuItem
          now={nowPath === 'companies' ? 1 : 0}
          to="/companies"
          onMouseEnter={() => setIsShown('companies')}
          onMouseLeave={() => setIsShown(null)}
        >
          <MdAccountBalance
            color={nowPath === 'companies' ? nowColor : notNowColor}
            size="1.5em"
            style={{ marginLeft: nowPath === 'companies' ? '-1em' : '0' }}
          />
          {isShown === 'companies' && (
          <span style={{ marginTop: '5px', textTransform: 'uppercase' }}>
            <FormattedMessage id="COMPANIES" />
          </span>
          )}
        </SidebarMenuItem>
        <SidebarDiveder />
      </PermissionContainer>

      {
        // <SidebarMenuItem now={nowPath === 'users' ? 1 : 0} to="/users">
        //  <Icon now={nowPath === 'users'} className="icon-people" />
        // </SidebarMenuItem>
        // <SidebarDiveder />
      }
      <PermissionContainer page="settings" show>
        <SidebarMenuItem
          now={nowPath === 'settings' ? 1 : 0}
          to="/settings"
          onMouseEnter={() => setIsShown('settings')}
          onMouseLeave={() => setIsShown(null)}
        >
          <AiOutlineDatabase
            color={nowPath === 'settings' ? nowColor : notNowColor}
            size="1.5em"
            style={{ marginLeft: nowPath === 'settings' ? '-1em' : '0' }}
          />
          {isShown === 'settings' && (
          <span style={{ marginTop: '5px', textTransform: 'uppercase' }}>
            <FormattedMessage id="SETTINGS" />
          </span>
          )}
        </SidebarMenuItem>
        <SidebarDiveder />
      </PermissionContainer>

      <PermissionContainer page="projects" show>
        <SidebarMenuItem
          now={nowPath === 'projects' ? 1 : 0}
          to="/projects"
          onMouseEnter={() => setIsShown('projects')}
          onMouseLeave={() => setIsShown(null)}
        >
          <MdFilterNone
            color={nowPath === 'projects' ? nowColor : notNowColor}
            size="1.5em"
            style={{ marginLeft: nowPath === 'projects' ? '-1em' : '0' }}
          />
          {isShown === 'projects' && (
          <span style={{ marginTop: '5px', textTransform: 'uppercase' }}>
            <FormattedMessage id="PROJECTS" />
          </span>
          )}
        </SidebarMenuItem>
      </PermissionContainer>

      <PermissionContainer page="reports" show>
        <SidebarMenuItem
          now={nowPath === 'reports' ? 1 : 0}
          to="/reports"
          onMouseEnter={() => setIsShown('reports')}
          onMouseLeave={() => setIsShown(null)}
        >
          <AiOutlineFileSearch
            color={nowPath === 'reports' ? nowColor : notNowColor}
            size="1.5em"
            style={{ marginLeft: nowPath === 'reports' ? '-1em' : '0' }}
          />
          {isShown === 'reports' && (
          <span style={{ marginTop: '5px', textTransform: 'uppercase' }}>
            <FormattedMessage id="REPORTS" />
          </span>
          )}
        </SidebarMenuItem>
      </PermissionContainer>
      <SidebarSpacer />

    </SidebarContainer>


  );
};
