import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';

import FormPDF from './New/FormPDF';

Font.register({
  family: 'Roboto',
  src:
    'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
});

// Create styles

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFF',
    margin: 10,
    border: 1,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottom: 1,
    borderBottomColor: 'black',
    borderBottomStyle: 'solid',
    width: '95%',
    height: '85px',
    paddingHorizontal: 10,
    marginBottom: 20,
  },
  image: {
    height: '80px',
    width: 'auto',
  },
  info: {
    border: 1,
    padding: 0,
    marginVertical: 5,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    flexWrap: 'wrap',
    width: '95%',
  },
  info_label: {
    width: '30%',
    backgroundColor: '#D9D9D9',
    padding: 5,
    borderRight: 1,
  },
  info_value: {
    width: '60%',
    padding: 5,
  },
  info_label_text: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  info_value_text: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  form: {
    border: 1,
    padding: 0,
    marginVertical: 10,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    flexWrap: 'wrap',
    width: '95%',
  },
  form_label: {
    width: '30%',
    padding: 5,
    borderRight: 1,
  },
  form_value: {
    width: '70%',
    padding: 5,
    paddingRight: 0,
  },
  form_value_image: {
    width: '70%',
    padding: 5,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingRight: 0,
  },
  form_label_text: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  form_value_text: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  pageNumbers: {
    fontFamily: 'Roboto',
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 8,
  },
  imagee: {
    height: 120,
    width: 100,
    objectFit: 'contain',
  },
  image_container: {
    display: 'flex',
    flexDirection: 'column',
    width: '31%',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: '2%',
    marginVertical: 5,
  },
  image_info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  created_info: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  image_text: {
    fontSize: 8,
    flexWrap: 'wrap',
    marginHorizontal: 3,
    fontFamily: 'Roboto',
  },
});

// Create Document Component
const MyDocument = ({
  formlist: { formlist, company, user },
  formInfo: {
    export_by, project_name, create_date, form_count, filters,
  },
  selectedProject,
}) => {
  console.log('**** formlist', formlist[0]);
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.header} fixed>
          <Image
            style={styles.image}
            src="/apple-touch-icon.png"
            allowDangerousPaths
            alt="crane"
          />
          <Image
            style={styles.image}
            src="/apple-touch-icon.png"
            allowDangerousPaths
            alt="crane"
          />
        </View>

        <View style={styles.info}>
          <View style={styles.info_label}>
            <Text style={styles.info_label_text}>Crane Export Name</Text>
          </View>
          <View style={styles.info_value}>
            <Text style={{ ...styles.info_value_text, fontWeight: 'bold' }}>
              Forms Export
            </Text>
          </View>

          <View style={styles.info_label}>
            <Text style={styles.info_label_text}>Project Name</Text>
          </View>
          <View style={styles.info_value}>
            <Text style={styles.info_value_text}>{project_name}</Text>
          </View>

          <View style={styles.info_label}>
            <Text style={styles.info_label_text}>Filters</Text>
          </View>
          <View style={styles.info_value}>
            <Text style={styles.info_value_text}>{filters}</Text>
          </View>

          <View style={styles.info_label}>
            <Text style={styles.info_label_text}>Export By</Text>
          </View>
          <View style={styles.info_value}>
            <Text style={styles.info_value_text}>{export_by}</Text>
          </View>

          <View style={styles.info_label}>
            <Text style={styles.info_label_text}>Date / Time of Export</Text>
          </View>
          <View style={styles.info_value}>
            <Text style={styles.info_value_text}> {create_date} </Text>
          </View>

          <View style={styles.info_label}>
            <Text style={styles.info_label_text}>Total Exported Forms</Text>
          </View>
          <View style={styles.info_value}>
            <Text style={styles.info_value_text}> {form_count} </Text>
          </View>
        </View>
      </Page>

      {formlist.map((form) => (
        <FormPDF
          state={{
            formData: form,
            formValues: form.values,
            company,
            user,
            project_name,
            selectedProject,
            template: form.template,
          }}
          multiple
        />
      ))}

    </Document>
  );
};

export default MyDocument;
