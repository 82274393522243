import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Link, Paper, Divider } from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import AlertDialog from 'components/Common/AlertDialog';

import {
  projectsSelector,
  getAllTasks,
  tasksSelector,
  getTaskTemplates
} from 'slices';

import DataLoading from 'components/Common/DataLoading';

import { authentication } from 'helpers/authentication';

import {
  TitleContainer,
  ContentLink,
  ContentTitle,
  StyledButton,
  ButtonContainer
} from 'styles/common';

import { useIntl, FormattedMessage } from 'react-intl';
import NewTask from './NewTask';
import Filters from './Filters';
import SortableTable from '../Table/SortableTable';
import { StyledTab, StyledTabs, TabPanel } from '../Common';
import { RiEqualizerLine } from 'react-icons/ri';

import {
  Container,
  TasksContainer,
  NewProjectButton
} from '../../styles/tasks/taskListStyle';

const useStyles = makeStyles((theme) => ({
  paddings: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1)
  },
  nodata: {
    padding: '10px'
  },
  paper: {
    marginTop: '15px'
  }
}));

const headCells = [
  { id: 'id', align: 'left', label: 'TASK_ID' },
  { id: 'from_name', align: 'center', label: 'FORM_TITLE' },
  { id: 'from_name', align: 'center', label: 'FORM_NO' },
  { id: 'status', align: 'center', label: 'STATUS' },
  //{
  //  id: 'step_no',
  //  align: 'center',
  //  label: 'CURRENT_STEP'
  //},
  {
    id: 'worker_name',
    align: 'center',
    label: 'WORKING_ON'
  },
  {
    id: 'due_date',
    align: 'center',
    label: 'DELIVERY_DATE'
  }
  //{ id: 'actions', align: 'center', label: 'ACTIONS' }
];

const templateHeadCells = [
  { id: 'id', align: 'left', label: 'ID' },
  { id: 'template_name', align: 'left', label: 'PROCESS_NAME' },
  { id: 'status', align: 'center', label: 'STATE' },
  {
    id: 'step_no',
    align: 'center',
    label: 'STEP'
  },
  {
    id: 'created_at',
    align: 'center',
    label: 'CREATION'
  },
  {
    id: 'updated_at',
    align: 'center',
    label: 'UPDATING'
  },
  { id: 'actions', align: 'center', label: 'ACTIONS' }
];

const TasksList = () => {
  const mountedRef = useRef(true);

  const history = useHistory();

  const dispatch = useDispatch();

  const classes = useStyles();

  const intl = useIntl();

  const { id: worker_id } = authentication.userValue;

  const { selectedProject } = useSelector(projectsSelector);

  const {
    tasks,
    loading: taskLoading,
    hasErrors,
    workerTasks,
    templates
  } = useSelector(tasksSelector);

  const [tabIndex, setTabIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [showFilter, setFilterModal] = useState(false);
  /* const [tasks, setTasks] = useState([]); */

  // async functions
  async function aycncFetchWorkers(project_id) {
    if (!taskLoading.getAllTasks) {
      dispatch(getAllTasks(project_id));
    }
    if (!taskLoading.getTemplates) {
      dispatch(getTaskTemplates(project_id));
    }
  }

  useEffect(() => {
    if (selectedProject) {
      aycncFetchWorkers(selectedProject.id, mountedRef.current);
    }
    return () => {
      mountedRef.current = false;
    };
  }, [selectedProject]);

  const handleClick = (event, row) => {
    if (row.worker_id === worker_id) {
      history.push(`tasks/${row.id}`);
    }
  };

  const handleTemplate = (event, row) => {};

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const alertText = intl.formatMessage({ id: 'PROJECT_SELECTION_ALERT' });

  return (
    <Container>
      <TasksContainer>
        <AlertDialog
          alertText={alertText}
          open={alertModal}
          setOpen={setAlertModal}
        />
        <TitleContainer>
          <Breadcrumbs separator={<>&gt;</>} aria-label="breadcrumb">
            <Link component={RouterLink} to="/projects">
              <ContentLink>
                {selectedProject && selectedProject.project_name}
              </ContentLink>
            </Link>
            <ContentTitle>
              <FormattedMessage id="TASKS" />
            </ContentTitle>
          </Breadcrumbs>

          <ButtonContainer>
            <NewProjectButton
              variant="contained"
              color="primary"
              size="medium"
              style={{ backgroundColor: '#faf8f8', color: '#999fb7' }}
              startIcon={<RiEqualizerLine />}
              onClick={() => {
                setFilterModal(true);
              }}
            >
              <FormattedMessage id="FILTER" />
            </NewProjectButton>
          </ButtonContainer>
        </TitleContainer>

        <Paper className={classes.paper}>
          <StyledTabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="mail-tabs"
          >
            <StyledTab
              label={
                <span style={{ textTransform: 'uppercase' }}>
                  <FormattedMessage id="MY_TASKS" />{' '}
                </span>
              }
            />
            <StyledTab
              label={
                <span style={{ textTransform: 'uppercase' }}>
                  <FormattedMessage id="ALL_TASKS" />{' '}
                </span>
              }
            />
          </StyledTabs>
          <Divider />
          <TabPanel value={tabIndex} index={0} fullWidth>
            {!workerTasks.length ? (
              <DataLoading
                style={{ padding: '10px' }}
                loading={taskLoading.getWorkerTasks}
                error={hasErrors.getWorkerTasks}
                selectedProject={selectedProject}
                list={workerTasks}
                noDataText={
                  selectedProject
                    ? intl.formatMessage(
                        { id: 'PROJECT_JOB_NOT_FOUND' },
                        { project_name: selectedProject.project_name }
                      )
                    : intl.formatMessage({ id: 'JOB_NOT_FOUND' })
                }
              />
            ) : (
              <SortableTable
                list={workerTasks}
                headCells={headCells}
                handleClick={handleClick}
                taskList
              />
            )}
          </TabPanel>
          <TabPanel value={tabIndex} index={1} fullWidth>
            {!tasks.length ? (
              <DataLoading
                style={{ padding: '10px' }}
                loading={taskLoading.getAllTasks}
                error={hasErrors.getAllTasks}
                selectedProject={selectedProject}
                list={tasks}
                noDataText={
                  selectedProject
                    ? intl.formatMessage(
                        { id: 'PROJECT_JOB_NOT_FOUND' },
                        { project_name: selectedProject.project_name }
                      )
                    : intl.formatMessage({ id: 'JOB_NOT_FOUND' })
                }
              />
            ) : (
              <>
                <SortableTable
                  list={tasks}
                  headCells={headCells}
                  handleClick={handleClick}
                  taskList
                />
              </>
            )}
          </TabPanel>
          <TabPanel value={tabIndex} index={2} fullWidth>
            {!templates.length ? (
              <DataLoading
                style={{ padding: '10px' }}
                loading={taskLoading.getTemplates}
                error={hasErrors.getTemplates}
                selectedProject={selectedProject}
                list={templates}
                noDataText={
                  selectedProject
                    ? intl.formatMessage(
                        { id: 'PROJECT_JOB_NOT_FOUND' },
                        { project_name: selectedProject.project_name }
                      )
                    : intl.formatMessage({ id: 'JOB_NOT_FOUND' })
                }
              />
            ) : (
              <>
                <SortableTable
                  list={templates}
                  headCells={templateHeadCells}
                  handleClick={handleTemplate}
                  taskTemplateList
                />
              </>
            )}
          </TabPanel>
        </Paper>
      </TasksContainer>

      <Filters
        showFilter={showFilter}
        setSelectedProject={selectedProject}
        workerId={worker_id}
        onCallbackFiltersShowModal={(callBackData) => {
          setFilterModal(callBackData.showFilter);
        }}
      />

      <NewTask showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
};

export default TasksList;
