import React, { useState } from 'react';
import styled from 'styled-components';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import TablePagination from '@material-ui/core/TablePagination';

import { FormattedMessage } from 'react-intl';
import { dateFormat } from '../../helpers/DateFormatter';

const TableContainerStyled = styled(TableContainer)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
`;

const TableCellStyled = styled(TableCell)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #8a849b !important;
  display: ${(props) => (props.id ? 'flex !important' : null)};
  justify-content: ${(props) => (props.id ? 'center !important' : null)};
  align-items: ${(props) => (props.id ? 'center !important' : null)};
  text-transform: uppercase;
`;
const ProjeAvatar = styled(Avatar)`
  color: #595469 !important;
  background: #efefef !important;
  fontsize: 24px !important;
  lineheight: 28px !important;
  border: 3px solid ${(props) => props.papercolor} !important;
`;

function ProjectTable(props) {
  const { projectList, handleClick } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainerStyled component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCellStyled align="center" width="60px">
                #
              </TableCellStyled>
              <TableCellStyled>
                <FormattedMessage id="PROJECT_NAME" />
              </TableCellStyled>
              <TableCellStyled align="center">
                <FormattedMessage id="CREATED_AT" />
              </TableCellStyled>
              <TableCellStyled align="center">
                <FormattedMessage id="COMPANIES" />
              </TableCellStyled>
              <TableCellStyled align="center">
                <FormattedMessage id="FORMS" />
              </TableCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((project, index) => {
                const nameArray = project.project_name.split(' ');
                let Avatar = '';
                !!project.project_name &&
                  nameArray.length &&
                  nameArray.forEach(
                    (item) => (Avatar += item.charAt(0).toUpperCase())
                  );
                return (
                  <TableRow
                    key={`${project.project_name}-${index}`}
                    onClick={() => handleClick(project)}
                    hover
                  >
                    <TableCellStyled align="center" id width="60px">
                      {project.project_logo ? (
                        <ProjeAvatar
                          papercolor={`#${project.color}` || 'f2f'}
                          src={`data:image/png;base64,${project.project_logo}`}
                        />
                      ) : (
                        <ProjeAvatar papercolor={`#${project.color}` || 'f2f'}>
                          {Avatar}
                        </ProjeAvatar>
                      )}
                    </TableCellStyled>
                    <TableCellStyled>{project.project_name}</TableCellStyled>
                    <TableCellStyled align="center">
                      {dateFormat(project.created_at, 'DD.MM.YYYY HH:mm')}
                    </TableCellStyled>
                    <TableCellStyled align="center">
                      {project.company_count}
                    </TableCellStyled>
                    <TableCellStyled align="center">
                      {project.template_count}
                    </TableCellStyled>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainerStyled>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={projectList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

export default ProjectTable;
