import APIHelper from '../helpers/APIHelper';

export async function getUsersFunction() {
  const response = await APIHelper().get('users/list');
  if (response.error) {
    throw new Error(response.error);
  }
  const allUsers = await Promise.all(
    response.users.map(async (user) => {
      const userResponse = await APIHelper().post('companies/find', null, {
        company_id: user.company_id,
      });

      const groupResponse = await APIHelper().post('users/find_groups', null, {
        user_id: user.id,
      });

      const returnValue = {
        ...user,
        companyName:
          userResponse.company.length > 0
            ? userResponse.company[0].company_name
            : '',
        companyShortName:
          userResponse.company.length > 0
            ? userResponse.company[0].short_name
            : '',
        groups: groupResponse.user_groups,
      };

      return returnValue;
    }),
  );
  console.log('GET USERS RESPONSE =>', allUsers);
  return allUsers;
}

export async function searchUsersFunction(search) {
  const response = await APIHelper().post('users/search', null, { search });
  if (response.error) {
    throw new Error(response.error);
  }
  const allUsers = await Promise.all(
    response.users.map(async (user) => {
      const userResponse = await APIHelper().post('companies/find', null, {
        company_id: user.company_id,
      });

      const groupResponse = await APIHelper().post('users/find_groups', null, {
        user_id: user.id,
      });

      const returnValue = {
        ...user,
        companyName:
          userResponse.company.length > 0
            ? userResponse.company[0].company_name
            : '',
        companyShortName:
          userResponse.company.length > 0
            ? userResponse.company[0].short_name
            : '',
        groups: groupResponse.user_groups,
      };

      return returnValue;
    }),
  );
  console.log('GET USERS SEARCH RESPONSE =>', allUsers);
  return allUsers;
}

export async function findByIdUsersFunction(user_id) {
  const response = await APIHelper().post('users/find', null, { user_id });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('FIND USERS BY ID RESPONSE =>', response.users);
  return response.users[0];
}

export async function acceptUsersFunction(user_id) {
  const response = await APIHelper().post('users/find', null, { user_id });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('ACCEPT USERS RESPONSE =>', response.users);
  return response.users;
}

export async function addUserFunction(userObj) {
  const response = await APIHelper().post('users/insert', null, { ...userObj });
  console.log('ADD USER RESPONSE =>', response);

  if (response.error) {
    throw new Error(response.error);
  }
  return response.user;
}

export async function updateUserFunction(userObj) {
  const response = await APIHelper().post('users/update', null, { ...userObj });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('UPDATE USER RESPONSE =>', response);
  return response.status;
}

export async function updateUserPasswordFunction(userObj) {
  const response = await APIHelper().post('users/update_password', null, {
    ...userObj,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('UPDATE USER PASSWORD RESPONSE =>', response);
  return response.status;
}

export async function updateUserStatusFunction(userObj) {
  const response = await APIHelper().post('users/update_status', null, {
    ...userObj,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('UPDATE USER STATUS RESPONSE =>', response);
  return response.status;
}

export async function deleteUserFunction(user_id) {
  const response = await APIHelper().post('users/delete', null, { user_id });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('DELETE USER RESPONSE =>', response);
  return response.status;
}

export async function findUsersByCompanyIdFunction(companyId) {
  const response = await APIHelper().post('users/find_by_company', null, {
    company_id: companyId,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  const allUsers = await Promise.all(
    response.users.map(async (user) => {
      const userResponse = await APIHelper().post('companies/find', null, {
        company_id: user.company_id,
      });

      const groupResponse = await APIHelper().post('users/find_groups', null, {
        user_id: user.id,
      });

      const returnValue = {
        ...user,
        companyName:
          userResponse.company.length > 0
            ? userResponse.company[0].company_name
            : '',
        companyShortName:
          userResponse.company.length > 0
            ? userResponse.company[0].short_name
            : '',
        groups: groupResponse.user_groups,
      };

      return returnValue;
    }),
  );
  console.log('FIND USERS BY COMPANY ID RESPONSE =>', allUsers);
  return allUsers;
}

export async function findUsersByProjectIdFunction(projectId) {
  const response = await APIHelper().post('users/find_by_project', null, {
    project_id: projectId,
  });

  if (response.error) {
    throw new Error(response.error);
  }
  const allUsers = await Promise.all(
    response.users.map(async (user) => {
      const userResponse = await APIHelper().post('companies/find', null, {
        company_id: user.company_id,
      });

      const groupResponse = await APIHelper().post('users/find_groups', null, {
        user_id: user.id,
      });

      const returnValue = {
        ...user,
        companyName:
          userResponse.company.length > 0
            ? userResponse.company[0].company_name
            : '',
        companyShortName:
          userResponse.company.length > 0
            ? userResponse.company[0].short_name
            : '',
        groups: groupResponse.user_groups,
      };

      return returnValue;
    }),
  );
  console.log('FIND USERS BY PROJECT ID RESPONSE =>', allUsers);
  return allUsers;
}

export async function fetchGroupOfUserFunction(userId) {
  const response = await APIHelper().post('users/find_groups', null, {
    user_id: userId,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('FIND GROUPS BY USER ID RESPONSE =>', response.user_groups);
  return response.user_groups;
}

export async function addGroupToUserFunction(userId, groupId) {
  const response = await APIHelper().post('users/add_groups', null, {
    user_id: userId,
    group_id: groupId,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('ADD GROUP TO USER RESPONSE =>', response);
  return response.status;
}

export async function updateUserAvatarFunction(user_id, avatar) {
  const response = await APIHelper().post('users/updateavatar', null, {
    user_id,
    avatar,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('UPDATE USER AVATAR RESPONSE =>', response);

  return response.status;
}

export async function deleteGroupOfUserFunction(userId, groupId) {
  const response = await APIHelper().post('users/delete_groups', null, {
    user_id: userId,
    group_id: groupId,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('DELETE GROUP OF USER RESPONSE =>', response);

  return response.status;
}

export async function updateNotificationFunction(
  user_id,
  notification_email,
  notification_push,
  instant_daily,
) {
  const response = await APIHelper().post('users/update_notification', null, {
    user_id,
    notification_push,
    notification_email,
    instant_daily,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('UPDATE NOTIFICATION FUNC RESPONSE =>', response);

  return response.status;
}

export async function updateLocaleFunction(user_id, lang) {
  const response = await APIHelper().post('users/update_lang', null, {
    user_id,
    lang,
  });
  if (response.error) {
    throw new Error(response.error);
  }
  console.log('UPDATE SET LOCALE FUNC RESPONSE =>', response);

  return response.status;
}
