import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { List, ListItem } from '@material-ui/core';
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';


const Container = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

const ListTitle = styled.div`
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 19px;
    margin: 10px 0px;

`;

const GroupList = styled(List)`
    border: 1px solid black;
    border-radius: 10px;
    margin: 5px 20px;
    width: 280px;
    height: 50vh;
    overflow: auto !important;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const ArrowButton = styled.div`
    font-size: 48px !important;
    padding: 10px;
    text-align: center;
    background: #fff !important;
    color: rgba(250,100,0,1) !important;
    margin: 12px 24px !important;
    border-radius: 5px;

`;

const ListItemStyled = styled(ListItem)`
    &:hover{
        background-color: rgba(250,100,0,0.5) !important;
    }
    &.${'Mui-selected'} {
        background-color: rgba(250,100,0,0.5) !important;
  }
`;

const GroupName = styled.span`
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
    line-height: 19px;

`;
const CompanyName = styled.span`
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 19px;

`;


const EditFormPermission = (props) => {
  const { allGroups, permissionList, setPermissionList } = props;
  const [leftList, setLeftList] = useState([]);
  const [leftSelecteds, setLeftSelecteds] = useState([]);
  const [rightSelecteds, setRightSelecteds] = useState([]);
  useEffect(() => {
    setLeftList(allGroups.filter((group) => permissionList.every((permission) => permission.group_id != group.id)));
  }, [allGroups, permissionList]);

  const moveLeft = () => {
    setPermissionList(permissionList.filter((permission) => rightSelecteds.every((selected) => selected.id != permission.id)));
    setLeftList([...leftList, ...allGroups.filter((group) => rightSelecteds.some((selected) => selected.group_id == group.id))]);
    setRightSelecteds([]);
  };

  const moveRight = () => {
    setLeftList(leftList.filter((leftItem) => leftSelecteds.every((selected) => selected.id != leftItem.id)));
    setPermissionList([...permissionList, ...leftSelecteds.map((leftItem) => ({ ...leftItem, group_id: leftItem.id, id: `new${leftItem.id}` }))]);
    setLeftSelecteds([]);
  };
  return (
    <Container>
      <div>
        <ListTitle>
          <FormattedMessage id="ALL_GROUPS" /> T
        </ListTitle>
        <GroupList>
          {leftList && leftList.map((item) => (
            <ListItemStyled
              button
              selected={leftSelecteds.some((s) => s.id == item.id)}
              onClick={() => {
                leftSelecteds.every((s) => s.id != item.id)
                  ? setLeftSelecteds([...leftSelecteds, item])
                  : setLeftSelecteds(leftSelecteds.filter((s) => s.id != item.id));
              }}
            >
              <GroupName>{item.group_name}</GroupName>
              <CompanyName>({item.company_name})</CompanyName>
            </ListItemStyled>
          ))}
        </GroupList>
      </div>
      <ButtonContainer>
        <ArrowButton onClick={moveLeft}>
          <FiChevronsLeft />
        </ArrowButton>
        <ArrowButton onClick={moveRight}>
          <FiChevronsRight />
        </ArrowButton>
      </ButtonContainer>
      <div>
        <ListTitle>
          <FormattedMessage id="AUTHORİZED_GROUPS" />
        </ListTitle>
        <GroupList>
          {permissionList && permissionList.map((item) => (
            <ListItemStyled
              button
              selected={rightSelecteds.some((s) => s.id == item.id)}
              onClick={() => {
                rightSelecteds.every((s) => s.id != item.id)
                  ? setRightSelecteds([...rightSelecteds, item])
                  : setRightSelecteds(rightSelecteds.filter((r) => r.id != item.id));
              }}
            >
              <GroupName>{item.group_name}</GroupName>
              <CompanyName>({item.company_name})</CompanyName>
            </ListItemStyled>
          ))}
        </GroupList>
      </div>
    </Container>
  );
};

export default EditFormPermission;
