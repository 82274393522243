import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';

import { MdNotificationsNone, MdMailOutline } from 'react-icons/md';
import { FaCaretDown } from 'react-icons/fa';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';

import { useLocation } from 'react-router-dom';
// import {
//   Select,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
// } from '@material-ui/core';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { updateLocaleFunction } from 'api';
import CraneLogoHeader from './CraneLogoHeader';

import SelectMenu from '../Menu/SelectMenu';

import { authentication } from '../../helpers/authentication';
import { useDeepCompareEffect } from '../../helpers/deepcompare';

import {
  projectsSelector,
  selectProject,
  fetchProjects,
  reset,
  logout,
  i18nSelector,
  setLanguage,
} from '../../slices';
import NotificationModal from './NotificationModal';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 3,
    top: 3,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const Header = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  padding: 16px;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  color: rgba(89, 84, 105, 1);
  text-align: center;
  line-height: 22px;
  z-index: 5;
`;

const ProfileContainer = styled.div`
  position: fixed;
  height: auto;
  width: auto;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NameSpan = styled.span`
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #595469;
`;

const ProjectContainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  padding-left: 24px;
`;

const Flag = styled.img`
  height: 25px;
  width: auto;
`;

export default (props) => {
  const dispatch = useDispatch();

  const intl = useIntl();

  const { pathname } = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback((status) => {
    setShowModal(status);
  }, []);

  const {
    projects,
    selectedProject,
    loading: { getProjects },
  } = useSelector(projectsSelector);

  const { locale } = useSelector(i18nSelector);

  const history = useHistory();

  useDeepCompareEffect(() => {
    if (!projects.length && !getProjects) {
      dispatch(fetchProjects());
    }
  }, [projects]);

  useDeepCompareEffect(() => {}, [selectedProject]);

  const {
    name, is2fEnabled, token, id: user_id,
  } = authentication.userValue;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());

    authentication.logout();
  };

  const setLocale = (lang) => {
    dispatch(setLanguage(lang));
    updateLocaleFunction(user_id, lang);
    localStorage.setItem('locale', lang);
  };

  const handleDisable2F = () => {
    authentication.disable2F();
  };

  const handleEnable2F = () => {
    if (token) {
      history.push('verify', { token, activate2f: true });
    }
  };

  return (
    <Header>
      <CraneLogoHeader height="100%" width="auto" />
      {pathname !== '/' && !pathname.includes('home') && (
        <ProjectContainer>
          <SelectMenu
            label={intl.formatMessage({ id: 'SELECT_ONE_PROJECT' })}
            list={projects}
            selectedValue={selectedProject}
            valueIndicator="project_name"
            onSelect={(value) => {
              dispatch(selectProject(value));
              history.push('/');
            }}
          />
        </ProjectContainer>
      )}

      <ProfileContainer>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {/* <MenuItem onClick={handleClose}>
            <FormattedMessage id="PROFILE" />{' '}
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              history.push('/account')
              handleClose();
            }}
          >
            <FormattedMessage id="MY_ACCOUNT" />{' '}
          </MenuItem>
          {/* {is2fEnabled ? (
            <MenuItem onClick={handleDisable2F}>
              <FormattedMessage id="DISABLE_2F" />{' '}
            </MenuItem>
          ) : (
            <MenuItem onClick={handleEnable2F}>
              <FormattedMessage id="ENABLE_2F" />{' '}
            </MenuItem>
          )} */}
          {/* <MenuItem onClick={() => setShowModal(true)}>
            <FormattedMessage id="NOTIFICATION_SETTINGS" />{' '}
          </MenuItem> */}
          <MenuItem onClick={handleLogout}>
            <FormattedMessage id="LOGOUT" />{' '}
          </MenuItem>
        </Menu>
        {/*
        <IconButton aria-label="cart">
          <StyledBadge badgeContent="0" color="secondary">
            <MdMailOutline />
          </StyledBadge>
        </IconButton>
        <IconButton aria-label="cart">
          <StyledBadge badgeContent="0" color="secondary">
            <MdNotificationsNone />
          </StyledBadge>
        </IconButton>
        */}

        {/* flags are under option menu now */}
      {/*   <Select
          labelId="locale-selection-label"
          id="locale-selection-id"
          value={locale}
          onChange={(event, child) => setLocale(event.target.value)}
          variant="outlined"
          margin="dense"
        >
          <MenuItem value="tr">
            <Flag src={`${process.env.PUBLIC_URL}/flags/turkey.svg`} />
          </MenuItem>
          <MenuItem value="en">
            <Flag src={`${process.env.PUBLIC_URL}/flags/united_kingdom.svg`} />
          </MenuItem>
        </Select> */}
        <NameSpan>{name}</NameSpan>
        <IconButton
          aria-controls="fade-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <FaCaretDown />
        </IconButton>
      </ProfileContainer>
      <NotificationModal openModal={openModal} showModal={showModal} />
    </Header>
  );
};
