import React from 'react';

import { MdClose } from 'react-icons/md';
import styled from 'styled-components';
// import { FormattedMessage } from 'react-intl';
// import Box from '@material-ui/core/Box';
// import { makeStyles } from '@material-ui/core/styles';
// import Backdrop from '@material-ui/core/Backdrop';
import { Button, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const CancelModal = () => {
  // const classes = useStyles();
  // const [visible, setVisible] = useState(false);

  const history = useHistory();

  return (
    <>
      <IconButton onClick={() => history.push('/forms')}>
        <MdClose />
      </IconButton>
      {/* <Modal
        open={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        title="Görev İptali"
      >
        <Box className={classes.paper} width={2 / 3}>
          <div className={classes.paper} style={{ color: '#595469' }}>
            <div
              style={{
                padding: 20,
                fontSize: 20,
                borderBottom: '1px solid #e8e1fb',
                color: '#595469'
              }}
            >
              Görev İptali
            </div>
            <div style={{ padding: 20 }}>
              {task && (
                <div style={{ marginBottom: 30 }}>
                  <div
                    style={{
                      fontSize: 'calc(8px + 0.75vw + 0.75vh)',
                      color: 'rgba(250,100,0,1)',
                      textAlign: 'center'
                    }}
                  >
                    {task.form_no}
                  </div>
                  <div style={{ textAlign: 'center', marginTop: 10 }}>
                    {task.template_name}
                  </div>
                </div>
              )}
              <div style={{ border: '1px solid #e8e1fb', padding: 10 }}>
                Görev iptal edildiğinde :
                <ol>
                  <li>Görev İPTAL edilecektir.</li>
                  <li>
                    Formun kilidi açılacak ve form üzerinde değişiklik
                    yapılabilecektir.
                  </li>
                  <li>Form durumu aşağıdaki statü olacaktır.</li>
                  <li>
                    Göreve Atanan ve Bilgi İçin listesinde olan herkese uyarı
                    gidecektir.
                  </li>
                </ol>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: 10,
                borderTop: '1px solid #e8e1fb'
              }}
            >
              <CancelButton
                variant="contained"
                size="medium"
                onClick={() => setVisible(false)}
              >
                <FormattedMessage id="CANCEL" />
              </CancelButton>
              <div style={{ flex: 1, marginLeft: 20, marginRight: 20 }}>
                <Select style={{ width: '100%' }}>
                  <MenuItem value={1}>Draft</MenuItem>
                </Select>
              </div>
              <SaveButton variant="contained" size="medium">
                <FormattedMessage id="SAVE" />
              </SaveButton>
            </div>
          </div>
        </Box>
            </Modal> */}
    </>
  );
};

export default CancelModal;

// const useStyles = makeStyles((theme) => ({
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center'
//   },
//   paper: {
//     backgroundColor: theme.palette.background.paper
//   }
// }));

export const SaveButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to right, #ff963e, #fa6400);
  color: #ffffff !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;

  &:hover {
    opacity: 1;
  }
`;

export const CancelButton = styled(Button)`
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(to left, #e0e0e0, #eeeeee);
  color: #000000 !important;
  border-radius: 5px;
  opacity: 0.8;
  transition: all 300ms;
  width: 150px;

  &:hover {
    opacity: 1;
  }
`;
