import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { FiPlusCircle } from 'react-icons/fi';
import { MdList, MdViewQuilt } from 'react-icons/md';
import {
  IconButton, OutlinedInput, Divider,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { PermissionContainer } from 'helpers/permission';
import { useIntl, FormattedMessage } from 'react-intl';

const ComtentContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
`;

const ProjectTitleContainer = styled.div`
  background-color: rgba(255, 255, 255, 0);
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: rotate(0deg);
  opacity: 1;
  border-bottom: 2px solid rgba(128, 128, 128, 0.5);
  font-size: ${(props) => (props.inside ? '18px' : '22px')};
  color: rgba(89, 84, 105, 1);
  line-height: ${(props) => (props.inside ? '22px' : '26px')};
`;

const ProjectTitle = styled.span`
  font-size: ${(props) => (props.inside ? '18px' : '22px')};
  line-height: ${(props) => (props.inside ? '22px' : '25px')};
  font-weight: bold;
  align-self: center;
  margin-left: 10px;
  color: #595469;
`;

const LeftBar = styled.div`
  display: flex;
  align-self: center;
  align-items : center;
`;

const NewProjectButton = styled(Button)`
  align-self: center;
  margin: 0px 0px 0px 8px !important;
  padding: 8px 14px !important;
  color: ${(props) => (props.new ? '#FFFFFF' : '#595469')} !important;
  background: ${(props) => (props.new ? '#0091ff' : props.inside ? '#F2F2F2' : '#FAF8F8')} !important;
  border: 1px solid ${(props) => (props.new ? '#0553e3' : '#D4D4D4')} !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  box-shadow: 0px 2px 4px rgba(219, 219, 219, 0.3);
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  text-transform: none !important;
  font-size: 18px !important;
  line-height: 21px !important;
  opacity: 0.75;
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;

const SearchBar = styled.div`
  display: flex;
  align-self: center;
  margin-right: 8px;
  margin-left: 20px;
`;

const SearchLabel = styled.span`
  text-align: center;
  padding-top: 5px;
  margin-right: 8px;
  font-size: 18px;
`;

const SearchInput = styled(OutlinedInput)`
  width: 20vw;
  padding: 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 5px;
`;

export default React.forwardRef((props, ref) => {
  const {
    containerName,
    addButton,
    setShowModal,
    viewSetting,
    setViewSetting,
    search,
    handleSearch,
    page,
  } = props;

  const [searchText, setSearchText] = useState('');
  const [searchInterval, setSearchInterval] = useState(null);

  const intl = useIntl();
  let timer = null;
  const handleChange = (value) => {
    setSearchText(value);

    // handlesearch funcftion invoke after 2 secconds
    clearTimeout(searchInterval);
    timer = setTimeout(() => {
      handleSearch(value);
    }, 2000);
    setSearchInterval(timer);
  };


  return (
    <ComtentContainer inside={props.inside} id="content-comtainer" ref={ref}>
      <ProjectTitleContainer inside={props.inside}>
        <ProjectTitle inside={props.inside}>{containerName}</ProjectTitle>
        <LeftBar>
          {
              setViewSetting && (
                <>
                  <MdViewQuilt
                    color={viewSetting ? 'rgba(250,100,0,1)' : '#ddd'}
                    onClick={() => setViewSetting(true)}
                  />
                  <Divider flexItem orientation="vertical" style={{ margin: '0px 10px' }} variant="middle" />
                  <MdList
                    color={!viewSetting ? 'rgba(250,100,0,1)' : '#ddd'}
                    onClick={() => setViewSetting(false)}
                  />
                </>
              )
            }
          {search && (
            <SearchBar>
              <SearchLabel inside={props.inside}>
                <FormattedMessage id="FILTER" /> :
              </SearchLabel>
              <SearchInput
                placeholder={intl.formatMessage({ id: 'SEARCH_PLACEHOLDER' })}
                margin="none"
                variant="outlined"
                size="small"
                onChange={(e) => handleChange(e.target.value)}
                value={searchText}
                inside={props.inside}
                inputProps={{
                  style: {
                    padding: '6px',
                  },
                }}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      style={{ fontSize: '1rem', marginRight: '0px' }}
                    >
                      <div className="icon-magnifier" />
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </SearchBar>
          )}
          {/* {location.pathname === '/notnow' || (
            )} */}
          {/* {props.inside || (
            <NewProjectButton variant="contained" size="small" export>
              Dışa Aktar
            </NewProjectButton>
            )}
            {props.inside || (
            <NewProjectButton
              variant="contained"
              size="small"
              filter
              startIcon={<FaSlidersH />}
            >
              Filtrele
            </NewProjectButton>
            )} */}
          <PermissionContainer create page={page}>
            <NewProjectButton
              variant="contained"
              size="small"
              new={props.inside ? 0 : 1}
              startIcon={<FiPlusCircle />}
              onClick={() => setShowModal(true)}
              inside={props.inside}
            >
              {addButton}
            </NewProjectButton>
          </PermissionContainer>
        </LeftBar>
      </ProjectTitleContainer>
      {props.children}
    </ComtentContainer>
  );
});
