import React, { useEffect, useState, memo } from 'react';
import { Grid } from '@material-ui/core';
import { BlobProvider } from '@react-pdf/renderer';
import FormPDF from './FormPDF';
import { useDispatch, useSelector } from 'react-redux';
import { findCompanyById } from 'slices';

const PDFWrapper = memo(({ state }) => {
  const dispatch = useDispatch();
  const selectedProject = useSelector(
    (state) => state.projects.selectedProject
  );
  const [company, setCompany] = useState(null);
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && !company) {
      dispatch(findCompanyById(user.company_id)).then((response) => {
        setCompany(response[0]);
        if (!pdfData || pdfData?.formData?.id === 'new') {
          setTimeout(() => {
            setPdfData({
              ...state,
              company: response[0],
              user,
              selectedProject
            });
          }, 100);
        }
      });
    }
  }, [state]);

  return (
    <Grid item style={{ textAlign: 'right' }}>
      {pdfData && (
        <>
          <BlobProvider document={<FormPDF state={pdfData} />}>
            {({ url }) => (
              <a
                className="button"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/pdf.png" style={{ width: 40, cursor: 'pointer' }} />
              </a>
            )}
          </BlobProvider>
        </>
      )}
    </Grid>
  );
});

export default PDFWrapper;
