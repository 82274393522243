import styled from 'styled-components';
import { Button, Grid } from '@material-ui/core';
import { AiOutlineClose } from 'react-icons/ai';

export const FormsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(245, 245, 245, 1);
`;

export const TitleContainer = styled.div`
  background-color: rgba(255, 255, 255, 0);
  height: auto;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 30px;
  transform: rotate(0deg);
  opacity: 1;
  font-family: 'Helvetica', Helvetica, Arial, serif;
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(89, 84, 105, 1);
  line-height: 26px;
`;

export const ContentLink = styled.span`
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
  line-height: 25px;
  align-self: center;
  color: #fa6400;
`;

export const ContentTitle = styled.span`
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
  align-self: center;
  color: #595469;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin: 0px;
`;

export const StyledButton = styled(Button)`
  align-items: center;
  justify-content: center;
  margin: 5px !important;
  opacity: 0.75;
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  height: 100%;
`;

export const DialogGrid = styled(Grid)`
  width: 40vw;
`;
export const Header = styled.div`
  font-size = 2vh
  height: 5vh;
  width: 100%;
  text-align: center;
  padding: 5px;
  text-transform: uppercase;
`;

export const List = styled.div`
  height: 45vh;
  width: 100%;
  overflow: auto;
`;

export const ExportButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5vh 5vw;
`;

export const ButtonLabel = styled.span`
  text-decoration: none !important;
  color: #000 !important;
  text-align: left;
`;

export const MetadataContainer = styled.div`
  display: flex;
  margin-left: 22vw;
  padding: 5px;
  flex-wrap: wrap;
`;

export const Metadata = styled.div`
  border-radius: 5px;
  display: flex;
  align-items: center;
  background: #ddd;
  height: 30px;
  margin: 5px;
`;

export const Text = styled.span`
  padding: 3px 6px;
`;
export const Icon = styled(AiOutlineClose)`
  background-color: #aaa;
  height: 100%;
  border-radius: 0px 5px 5px 0px;
  width: 25px;
  padding: 5px;
`;
