import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';

import { useIntl, FormattedMessage } from 'react-intl';
import { authentication } from '../../helpers/authentication';
import device from '../../helpers/device';

import CraneLogoHeader from '../Header/CraneLogoHeader';
import { Checkbox } from '../Form';
import { Footer } from '../Footer';

const LoginAdmin = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(138, 132, 155, 1);
  text-align: center;
  opacity: 1;
`;

const Container = styled.div`
  flex: 1;
  width: 100%;
  padding: 1vh 1vw;
  z-index: 10;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const RulesAndTermsContainer = styled.div`
  background-color: rgba(255, 255, 255, 0);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90vw;

  @media ${device.tablet} {
    width: 75vw;
  }

  @media ${device.laptop} {
    width: 50vw;
  }
`;

const ErrorContainer = styled.div`
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(207, 0, 15, 0.75);
  font-size: calc(0px + 1vw);
  line-height: calc(1px + 1vw);
  color: rgba(255, 255, 255, 1);
  text-align: center;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  color: rgba(138, 132, 155, 1);
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  padding: 4vh 0 4vh 0;
  font-size: calc(8px + 0.5vw + 0.5vh);
  line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
`;

const InputContainer = styled.div`
  background-color: rgba(255, 255, 255, 0);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90vw;

  @media ${device.tablet} {
    width: 50vw;
  }

  @media ${device.laptop} {
    width: 25vw;
  }
`;

const LoginInput = styled(Field)`
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  border-radius: 4px;
  border-style: solid;
  border-width: ${(props) => (props.iserror ? '2px' : '1px')};
  border-color: ${(props) => (props.iserror ? 'rgba(207, 0, 15, 1)' : 'rgba(138, 132, 155, 1)')};
  margin: 1vh;
  padding: 1.25vh 1.75vh 1.5vh 2vh;
  font-family: 'Lato', Helvetica, Arial, serif;
  font-weight: bold;
  font-style: normal;
  font-size: calc(4px + 0.5vw + 0.5vh);
  line-height: calc((4px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((4px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(92, 93, 93, 1);
  text-align: left;
  cursor: pointer;
  opacity: 0.75;
  transition: all 300ms;

  &:hover {
    opacity: 1;
    background-color: rgba(233, 237, 241, 1);
  }
  &:focus {
    opacity: 1;
    background-color: rgba(233, 237, 241, 1);
  }
  &:active {
    opacity: 1;
    background-color: rgba(233, 237, 241, 1);
  }
`;

const RememberContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 1vh;
`;

const RememberMe = styled.div`
  flex: 1;
  text-align: left;
  opacity: 0.75;
  cursor: pointer;
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;

const RememberMeSpan = styled.span`
  font-family: 'Lato', Helvetica, Arial, serif;
  font-weight: bold;
  font-style: normal;
  font-size: calc(4px + 0.5vw + 0.5vh);
  line-height: calc((4px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((4px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(92, 93, 93, 1);
  cursor: pointer;
  margin-left: 0.5vw;
`;

const ForgotPassword = styled(Link)`
  text-decoration: none;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  vertical-align: middle;
  font-family: 'Lato', Helvetica, Arial, serif;
  font-weight: bold;
  font-style: normal;
  font-size: calc(4px + 0.5vw + 0.5vh);
  line-height: calc((4px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((4px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(92, 93, 93, 1);
  text-align: right;
  cursor: pointer;
  opacity: 0.75;
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;

const LoginButton = styled.button`
  cursor: pointer;
  background-image: linear-gradient(180deg, #f7981c 2.04%, #f76b1c 100%);
  box-sizing: border-box;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(223, 227, 233, 0.1);
  width: 100%;
  height: auto;
  margin: 1vh;
  padding: 1vh;
  font-weight: bold;
  font-style: normal;
  font-size: calc(6px + 0.5vw + 0.5vh);
  line-height: calc((6px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((6px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(255, 255, 255, 1);
  text-align: center;
  transition: all 300ms;

  &:hover {
    background-image: linear-gradient(180deg, #f76b1c 2.04%, #d25e25 100%);
  }
`;

const LoginButton2 = styled.button`
  cursor: pointer;
  background-image: linear-gradient(180deg, #f7981c 2.04%, #f76b1c 100%);
  box-sizing: border-box;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(223, 227, 233, 0.1);
  height: auto;
  margin: 1vh;
  padding: 1vh;
  font-weight: bold;
  font-style: normal;
  font-size: calc(6px + 0.5vw + 0.5vh);
  line-height: calc((6px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((6px + 0.5vw + 0.5vh) / (1.618 * 20));
  color: rgba(255, 255, 255, 1);
  text-align: center;
  transition: all 300ms;
  width: 100%;

  @media ${device.tablet} {
    width: 50%;
  }

  &:hover {
    background-image: linear-gradient(180deg, #f76b1c 2.04%, #d25e25 100%);
  }
`;

const RulesAndTerms = styled.div`
  flex: 1;
  width: 100%;
  margin-bottom: 2vh;
  padding: 1vh 1vw;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(150, 150, 150, 1);
  font-family: 'Helvetica-Bold', Helvetica, Arial, serif;
  color: rgba(89, 84, 105, 1);
  font-style: normal;
  font-size: calc(16px + 0.5vw + 0.5vh);
  line-height: calc((16px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((16px + 0.5vw + 0.5vh) / (1.618 * 20));
  text-align: center;
`;

const AcceptTerms = styled.div`
  margin 1vh 0;
  text-align: left;
  opacity: 0.75;
  cursor: pointer;
  transition: all 300ms;

  &:hover {
    opacity: 1;
  }
`;

export default (props) => {
  const [termsChecked, setTermsChecked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState();
  const [loginStep, setLoginStep] = useState(0);
  const [user, setUser] = useState();

  const intl = useIntl();

  if (user && !user.firstLogin) {
    if (user.is2fEnabled) {
      const { from } = { from: { pathname: '/verify' } };
      props.history.push(from, { token: user.token });
    } else {
      const { from } = { from: { pathname: '/' } };
      props.history.push(from, { token: user.token });
    }
  }

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'INVALID_MAIL' }))
      .required(intl.formatMessage({ id: 'REQUIRED_MAIL' })),
    password: Yup.string().required(
      intl.formatMessage({ id: 'REQUIRED_PASSWORD' }),
    ),
  });

  const checkboxChanged = (event) => {
    setChecked(event.target.checked);
  };

  const termsChanged = (event) => {
    setTermsChecked(event.target.checked);
  };

  const handleSubmit = ({ email, password }, { setSubmitting }) => {
    setError();
    authentication
      .login(email, password)
      .then((response) => {
        setLoginStep(1);
        setError();
        setUser(response);
      })
      .catch((errorMessage) => {
        setSubmitting(false);
        if (errorMessage.message) {
          if (errorMessage.message === 'INVALID_CREDENTIALS') {
            setError(intl.formatMessage({ id: 'INVALID_LOGIN_ERROR' }));
          } else if (errorMessage.message === 'PASSIVE_USER') {
            setError(intl.formatMessage({ id: 'PASSIVE_USER_ERROR' }));
          } else if (errorMessage.message === 'NETWORK_EROR') {
            setError(intl.formatMessage({ id: 'NETWORK_ERROR' }));
          }
        }
      });
  };

  const termsAccept = () => {
    if (termsChecked) {
      const { from } = props.location.state || {
        from: { pathname: '/verify' },
      };
      props.history.push(from, { token: user.token });
    } else {
      setError(intl.formatMessage({ id: 'DO_NOT_ENTER_BEFORE_ACCEPT' }));
    }
  };

  return (
    <LoginAdmin>
      <Container>
        {/* background: linear-gradient(144.58deg, rgba(90, 48, 240, 0.4) 0%, #803AE8 100%); */}

        <CraneLogoHeader which={loginStep === 0 ? 'login' : null} />

        {loginStep === 0 && (
          <FormContainer>
            <TitleContainer>
              <FormattedMessage id="LOGIN_YOUR_ACCOUNT" />
            </TitleContainer>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <InputContainer>
                    {error && <ErrorContainer>{error}</ErrorContainer>}
                    <LoginInput
                      placeholder={intl.formatMessage({ id: 'MAIL' })}
                      type="text"
                      name="email"
                      iserror={errors.email && touched.email ? 1 : 0}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      render={(msg) => <ErrorContainer>{msg}</ErrorContainer>}
                    />
                    <LoginInput
                      placeholder={intl.formatMessage({ id: 'PASSWORD' })}
                      type="password"
                      name="password"
                      iserror={errors.password && touched.password ? 1 : 0}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      render={(msg) => <ErrorContainer>{msg}</ErrorContainer>}
                    />
                    <RememberContainer>
                      <RememberMe>
                        <label>
                          <Checkbox
                            checked={checked}
                            onChange={checkboxChanged}
                          />
                          <RememberMeSpan>
                            <FormattedMessage id="REMEMBER_ME" />
                          </RememberMeSpan>
                        </label>
                      </RememberMe>
                      <ForgotPassword to="/forgot-password">
                        <FormattedMessage id="FORGOT_PASSWORD" />
                      </ForgotPassword>
                    </RememberContainer>
                    <LoginButton type="submit" disabled={isSubmitting}>
                      <FormattedMessage id="LOGIN" />
                    </LoginButton>
                  </InputContainer>
                </Form>
              )}
            </Formik>
          </FormContainer>
        )}
        {loginStep === 1 && user && (
          <RulesAndTermsContainer>
            <TitleContainer>
              <FormattedMessage id="WELCOME" /> {user.email},
            </TitleContainer>
            <RulesAndTerms>
              <FormattedMessage id="TERMS_AND_CONDITIONS" />
            </RulesAndTerms>
            {error && <ErrorContainer>{error}</ErrorContainer>}
            <AcceptTerms>
              <label>
                <Checkbox checked={termsChecked} onChange={termsChanged} />
                <RememberMeSpan>
                  <FormattedMessage id="ACCEPT_TO_TERMS_AND_CONDITIONS" />
                </RememberMeSpan>
              </label>
            </AcceptTerms>
            <LoginButton2 onClick={termsAccept}>
              <FormattedMessage id="LOGIN" />
            </LoginButton2>
          </RulesAndTermsContainer>
        )}
      </Container>
      <Footer />
    </LoginAdmin>
  );
};
