import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { templatesSelector } from 'slices';

const Container = styled.div`
    padding: 40px;
    max-height : 65vh;
    overflow: auto;

`;
const MetadataContainer = styled.div`
    margin: 30px 0px;
`;

const MetadataTıtle = styled.div`
    font-size: calc(8px + 0.5vw + 0.5vh);
    line-height: calc((8px + 0.5vw + 0.5vh) * 1.618);
    letter-spacing: calc((8px + 0.5vw + 0.5vh) / (1.618 * 20));
    color: rgba(0, 0, 0, 0.5);
    text-align: left;
    padding-left: 8px;
    margin: 10px 0px;
    font-weight: bold;
`;

const DetailList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Detail = styled.div`
    font-size: calc(2px + 0.5vw + 0.5vh);
    line-height: calc((2px + 0.5vw + 0.5vh) * 1.618);
    letter-spacing: calc((2px + 0.5vw + 0.5vh) / (1.618 * 20));
    color: #fff;
    background: #555;
    padding: 6px 24px;
    margin: 5px 10px;
    border-radius: 8px;

`;


const FormMetaData = (props) => {
  const [metas, setMetas] = useState([]);

  const { template_metadatas } = useSelector(templatesSelector);

  useEffect(() => {
    const _metadataList = [];
    template_metadatas.forEach((metadata) => {
      if (_metadataList.every((m) => m.id !== metadata.metadata_id)) _metadataList.unshift({ id: metadata.metadata_id, metadata_name: metadata.metadata_name });
    });
    const _metas = _metadataList.map((m) => ({
      metadata_name: m.metadata_name,
      details: template_metadatas.filter((meta) => meta.metadata_id === m.id),
    }));
    setMetas(_metas);
  }, [template_metadatas]);


  return (
    <Container>
      {metas.map((set, index) => (
        <MetadataContainer key={`metadata-container-key-${index}`}>
          <MetadataTıtle>{set.metadata_name}</MetadataTıtle>
          <DetailList>
            {set.details.map((detail) => (
              <Detail key={`metadata-detail-key-${detail.id}-${index}`}>{detail.metadata_detail_name}</Detail>
            ))}
          </DetailList>
        </MetadataContainer>
      ))}
    </Container>
  );
};

export default FormMetaData;
