import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Paper, Divider, Grid, Link } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import smallLogo from '../../assets/header/smallLogo.png';
import craneLogo from '../../assets/header/smallLogo.png';

import { dateFormat } from '../../helpers/DateFormatter';
import { formsSelector } from 'slices';
import { useSelector } from 'react-redux';

const Container = styled(Paper)`
  width: 100%;
  height: 75vh;
  margin-top: 2vh;
  padding: 10px 5vw;
  text-align: left;
  overflow: auto;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0px;
`;

const CamponyLogo = styled.img``;
const SubjectContainer = styled.div`
  font-family: 'Helvetica', Helvetica, Arial, serif;
  font-size: calc(3px + 0.5vw + 0.5vh);
  line-height: calc((3px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((3px + 0.5vw + 0.5vh) / (1.618 * 20));
  padding: 5px 10px;
  color: rgba(0, 0, 0, 0.5);
`;

const InfoContainer = styled(Grid)`
  width: 100%;
  padding-left: 10px;
`;
const AttachmentsContainer = styled.div`
  width: 100%;
  padding-left: 10px;
`;
const ContentContainer = styled.div`
  width: 100%;
  padding-left: 10px;
  font-family: 'Helvetica', Helvetica, Arial, serif;
  font-size: calc(3px + 0.5vw + 0.5vh);
  line-height: calc((3px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((3px + 0.5vw + 0.5vh) / (1.618 * 20));
  margin: 2px;
  color: rgba(0, 0, 0, 0.5);
`;

const Text = styled.p`
  font-family: 'Helvetica', Helvetica, Arial, serif;
  font-size: calc(3px + 0.5vw + 0.5vh);
  line-height: calc((3px + 0.5vw + 0.5vh) * 1.618);
  letter-spacing: calc((3px + 0.5vw + 0.5vh) / (1.618 * 20));
  margin: 2px;
  color: rgba(0, 0, 0, 0.5);
`;

const StyledLink = styled(Link)`
  font-family: 'Helvetica', Helvetica, Arial, serif !important;
  font-size: calc(3px + 0.5vw + 0.5vh) !important;
  line-height: calc((3px + 0.5vw + 0.5vh) * 1.618) !important;
  letter-spacing: calc((3px + 0.5vw + 0.5vh) / (1.618 * 20)) !important;
  color: rgba(255, 100, 0, 1) !important;
  margin-top: auto !important;
  margin: 2px;
  display: block;
`;

const MailContent = (props) => {
  const { mail, fromCompany, toCompany, documents, type } = props;

  const { form } = useSelector(formsSelector);

  useEffect(() => {
    if (mail) {
      // mail && dispatch(findByIdForm(mail.form_id, null));
    }
  }, [mail]);

  return (
    <>
      {mail ? (
        <Container>
          <LogoContainer>
            <CamponyLogo
              style={{ width: 150 }}
              src={
                (type === 'sendBox'
                  ? toCompany && toCompany.logo
                  : fromCompany && fromCompany.logo) || smallLogo
              }
            />
            <div>
              <p style={{ fontSize: 26, color: 'rgba(0,0,0,0.5)' }}>
                {mail.mail_no}
              </p>
            </div>
            <CamponyLogo style={{ width: 100 }} src={craneLogo} />
          </LogoContainer>
          <Divider light style={{ height: '5px' }} />
          <SubjectContainer>
            {mail.form_no} - {mail.form_name}
          </SubjectContainer>
          <Divider light style={{ height: '5px' }} />
          <InfoContainer container spacing={2}>
            <Grid xs={2} item>
              <Text>
                {' '}
                <FormattedMessage id="SENDER" />{' '}
              </Text>
            </Grid>
            <Grid xs={9} item>
              <Text>
                {mail.from_name} - {mail.from_company_name}
              </Text>
            </Grid>
            <Grid xs={2} item>
              <Text>
                {' '}
                <FormattedMessage id="RECEIVER" />{' '}
              </Text>
            </Grid>
            <Grid xs={9} item>
              {mail.to_json.users &&
                mail.to_json.users.map((user) => (
                  <Text>
                    {user.name} - {user.companyName}
                  </Text>
                ))}
            </Grid>
            <Grid xs={2} item>
              <Text> CC </Text>
            </Grid>
            <Grid xs={9} item>
              {mail.cc_json.users &&
                mail.cc_json.users.map((user) => (
                  <Text>
                    {user.name}- {user.companyName}
                  </Text>
                ))}
            </Grid>
            <Grid xs={2} item>
              <Text>
                {' '}
                <FormattedMessage id="DATE" />{' '}
              </Text>
            </Grid>
            <Grid xs={9} item>
              <Text> {dateFormat(mail.created_at, 'DD/MM/YYYY HH:MM')} </Text>
            </Grid>
          </InfoContainer>
          <Divider light style={{ height: '5px' }} />
          {documents && documents.length > 0 && (
            <AttachmentsContainer>
              <Text>
                <FormattedMessage id="ATTACHMENTS" />{' '}
              </Text>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Text>{mail.form_no}</Text>
                </Grid>
                <Grid item xs={9}>
                  {documents.length ? (
                    documents.map((document) => (
                      <StyledLink> {document.file_name} </StyledLink>
                    ))
                  ) : (
                    <Text>
                      <FormattedMessage id="DOCUMENT_NOT_FOUND" />
                    </Text>
                  )}
                </Grid>
              </Grid>
            </AttachmentsContainer>
          )}
          {form && console.log('form jamalo', form)}
          <Divider light style={{ height: '5px' }} />
          {form && (
            <InfoContainer
              container
              spacing={2}
              style={{ paddingTop: 10, paddingBottom: 10 }}
            >
              <Grid xs={2}>
                <Text>
                  <FormattedMessage id="FORM_NO" />:
                </Text>
              </Grid>
              <Grid xs={9}>
                <Text>{form.form_no}</Text>
              </Grid>
              <Grid xs={2}>
                <Text>
                  <FormattedMessage id="REVISION" />:
                </Text>
              </Grid>
              <Grid xs={9}>
                <Text>{form.revision_name}</Text>
              </Grid>
              <Grid xs={2}>
                <Text>
                  <FormattedMessage id="VERSION" />:
                </Text>
              </Grid>
              <Grid xs={9}>
                <Text>{form.version}</Text>
              </Grid>
              <Grid xs={2}>
                <Text>
                  <FormattedMessage id="STATUS" />:
                </Text>
              </Grid>
              <Grid xs={9}>
                <Text>{form.status_name}</Text>
              </Grid>
            </InfoContainer>
          )}
          <Divider light style={{ height: '5px' }} />
          <Divider light style={{ height: '5px' }} />
          <ContentContainer>{mail.description}</ContentContainer>
        </Container>
      ) : null}
    </>
  );
};

export default MailContent;
