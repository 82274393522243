import React, { useState, useEffect, useRef } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import Layout from 'layout';
import NotFound from 'components/NotFound';
import { useDispatch } from 'react-redux';
import { setLanguage } from 'slices';
import { authentication } from '../helpers/authentication';

import { Login, ForgotPassword, RegisterMail, Verify, Repass } from './Login';
import Dashboard from './Dashboard';
import Projects from './Projects';
import Companies from './Companies';
import Company from './Company';
import Users from './Users';
import User from './User';
import Settings from './Settings';
import { FormEditor, FormList } from './FormTemplates';
import Forms from './Forms';
import Form from './Form';
import Mails from './Mails';
import Mail from './Mail';
import permissionHelper from '../helpers/permission';
import Tasks from './Tasks';
import Reports from './Reports';
import { Task, TaskDesign } from './Task';
import Account from './Account';

export default () => {
  const { pathname } = useLocation();
  const [user, setUser] = useState({});

  const ref = useRef(true);

  const dispatch = useDispatch();

  const SetLocale = async (locale) => {
    dispatch(setLanguage(locale));
  };

  useEffect(() => {
    const subscription = authentication.user.subscribe((x) => setUser(x));
    return subscription.unsubscribe;
  }, []);

  useEffect(() => {
    let _locale = localStorage.getItem('locale');
    if (!_locale) {
      const language = navigator.language || navigator.browserLanguage;
      _locale = language ? language.split('-')[0] : 'en';
      localStorage.setItem('locale', _locale);
    }
    SetLocale(_locale, ref.current);

    return () => {
      ref.current = false;
    };
  }, []);

  return (
    <div>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/repass" component={Repass} />
        <Route path="/verify" component={Verify} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/register-mail" component={RegisterMail} />
        <Route path="/register-mail/:token" component={RegisterMail} />
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <PrivateRoutes component={Layout}>
          <PermissionRoutes exact path="/" component={Dashboard} page="home" />
          <PermissionRoutes
            exact
            path="/home"
            component={Dashboard}
            page="home"
          />
          <PermissionRoutes
            exact
            path="/projects"
            component={Projects}
            page="projects"
          />
          <PermissionRoutes
            exact
            path="/project/:id"
            component={Projects}
            page="projects"
          />
          <PermissionRoutes
            exact
            path="/companies"
            component={Companies}
            page="companies"
          />
          <PermissionRoutes
            exact
            path="/companies/:id"
            component={Company}
            page="companies"
          />
          <PermissionRoutes
            exact
            path="/users"
            component={Users}
            page="users"
          />
          <PermissionRoutes
            exact
            path="/users/:id"
            component={User}
            page="users"
          />
          <PermissionRoutes
            exact
            path="/settings"
            component={Settings}
            page="settings"
          />
          <PermissionRoutes
            exact
            path="/forms"
            component={Forms}
            page="forms"
          />
          <PermissionRoutes
            exact
            path="/forms/:id"
            component={Form}
            page="forms"
          />
          <PermissionRoutes
            exact
            path="/form-templates"
            component={FormList}
            page="templates"
          />
          <PermissionRoutes
            exact
            path="/form-templates/:id"
            component={FormEditor}
            page="templates"
          />
          <PermissionRoutes
            exact
            path="/mails"
            component={Mails}
            page="mails"
          />
          <PermissionRoutes
            exact
            path="/mails/:id/:type"
            component={Mail}
            page="mails"
          />
          <PermissionRoutes
            exact
            path="/tasks"
            component={Tasks}
            page="tasks"
          />
          <PermissionRoutes
            exact
            path="/tasks/:id"
            component={Task}
            page="tasks"
          />
          <PermissionRoutes
            exact
            path="/tasks-design/"
            component={TaskDesign}
            page="design"
          />
          <PermissionRoutes
            exact
            path="/reports/"
            component={Reports}
            page="reports"
          />
          <PermissionRoutes
            exact
            path="/account/"
            component={Account}
            page="account"
          />
        </PrivateRoutes>
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

const PrivateRoutes = React.forwardRef(
  ({ component: Component, roles, children, ...rest }, ref) => {
    const user = authentication.userValue && authentication.userValue.token;
    if (!user) {
      return (
        <Route
          ref={ref}
          {...rest}
          render={
            ({ location }) => (
              <Redirect
                to={{ pathname: '/login', state: { from: location } }}
              />
            )
            /* if (roles && roles.indexOf(user.role) === -1) {
              return <Redirect to={{ pathname: '/'}} />
          } */
          }
        />
      );
    }
    return (
      <Component ref={ref} {...rest}>
        {children}
      </Component>
    );
  }
);

const PermissionRoutes = React.forwardRef(
  ({ component: Component, roles, page, ...rest }, ref) => (
    <Route
      ref={ref}
      {...rest}
      render={({
        match: {
          params: { id }
        },
        location,
        history
      }) => {
        const allowed =
          id === 'new'
            ? permissionHelper(page).cretaePermission()
            : permissionHelper(page).showPermission();
        if (!allowed) return <Redirect to={{ pathname: '/' }} />;
        return (
          <Component
            ref={ref}
            {...rest}
            location={location}
            history={history}
          />
        );
      }}
    />
  )
);
