import { createSlice } from '@reduxjs/toolkit';

import {
  getProjectsFunction, addProjectFunction, updateProjectFunction, deleteProjectFunction, searchProjectsFunction, findByIdProjectssFunction,
} from 'api/projects';
import { authentication } from 'helpers/authentication';


const initialState = {
  loading: {
    getProjects: false, addProject: false, updateProject: false, deleteProject: false, searchProjects: false,
  },
  hasErrors: {
    getProjects: false, addProject: false, updateProject: false, deleteProject: false, searchProjects: false,
  },
  projects: [],
  selectedProject: null,
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    asyncStart: (state, { payload }) => {
      state.loading[payload] = true;
    },
    asyncFailure: (state, { payload }) => {
      state.loading[payload] = false;
      state.hasErrors[payload] = true;
    },
    getProjectsSuccess: (state, { payload }) => {
      state.projects = payload;
      state.loading.getProjects = false;
      state.hasErrors.getProjects = false;
    },
    searchProjectsSuccess: (state, { payload }) => {
      state.projects = payload;
      state.loading.searchProjects = false;
      state.hasErrors.searchProjects = false;
    },
    addProjectSuccess: (state, { payload }) => {
      state.projects.unshift(payload);
      state.loading.addProject = false;
      state.hasErrors.addProject = false;
    },
    updateProjectSuccess: (state, { payload }) => {
      state.projects = state.projects.map((project) => {
        if (project.id === payload.id) { return payload; }
        return project;
      });
      state.loading.updateProject = false;
      state.hasErrors.updateProject = false;
    },
    deleteProjectSuccess: (state, { payload }) => {
      state.projects.filter((project) => project.id !== payload);
      state.loading.deleteProject = false;
      state.hasErrors.deleteProject = false;
    },
    selectProject: (state, { payload }) => {
      state.selectedProject = payload;
    },
  },
});

export const {
  asyncStart, asyncFailure, getProjectsSuccess, addProjectSuccess, updateProjectSuccess, deleteProjectSuccess, selectProject, searchProjectsSuccess,
} = projectsSlice.actions;

export default projectsSlice.reducer;

export const projectsSelector = (state) => state.projects;


export const fetchProjects = () => async (dispatch, state) => {
  dispatch(asyncStart('getProjects'));
  const { projects: userProjects } = authentication.userValue || { projects: [] };
  let _projects = [];
  try {
    if (!userProjects.length) {
      _projects = await getProjectsFunction();
    } else {
      for (const project of userProjects) {
        const _proje = await findByIdProjectssFunction(project);

        _projects = _projects.concat(_proje);
      }
      if (!state.selectedProject && _projects.length === 1) {
        dispatch(selectProject(_projects[0]));
      }
    }

    dispatch(getProjectsSuccess(_projects));
  } catch (error) {
    console.log('an error occurred while fetching projects', error);
    dispatch(asyncFailure('getProjects'));
  }
};

export const searchProjects = (search) => async (dispatch) => {
  dispatch(asyncStart('searchProjects'));
  const { projects: userProjects } = authentication.userValue || { projects: [] };
  try {
    let _projects = [];
    if (userProjects.length) {
      for (const project of userProjects) {
        const _proje = await findByIdProjectssFunction(project);

        _projects = _projects.concat(_proje);
      }
      _projects = _projects.filter((project) => project.project_name.toLowerCase().includes(search.toLowerCase()));
    } else {
      _projects = await searchProjectsFunction(search);
    }

    dispatch(searchProjectsSuccess(_projects));
  } catch (error) {
    console.log('an error occurred while searching projects', error);
    dispatch(asyncFailure('searchProjects'));
  }
};

export const addProject = (projectObj) => async (dispatch) => {
  dispatch(asyncStart('addProject'));
  try {
    const newProject = await addProjectFunction(projectObj);
    dispatch(addProjectSuccess(newProject));
  } catch (error) {
    console.log('an error occurred while adding project', error);
    dispatch(asyncFailure('addProject'));
  }
};

export const updateProject = (projectObj) => async (dispatch) => {
  dispatch(asyncStart('updateProject'));
  try {
    await updateProjectFunction(projectObj);
    dispatch(updateProjectSuccess(projectObj));
  } catch (error) {
    console.log('an error occurred while updating project', error);
    dispatch(asyncFailure('updateProject'));
  }
};

export const deleteProject = (project_id) => async (dispatch) => {
  dispatch(asyncStart('deleteProject'));
  try {
    await deleteProjectFunction(project_id);
    dispatch(deleteProjectSuccess(project_id));
  } catch (error) {
    console.log('an error occurred while deleting project', error);
    dispatch(asyncFailure('deleteProject'));
  }
};
