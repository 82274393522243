import { combineReducers } from 'redux';
import usersReducer from '../slices/usersSlice';
import projectsReducer from '../slices/projectsSlice';
import companiesReducer from '../slices/companiesSlice';
import temlatesReducer from '../slices/templatesSlice';
import siteSetsReducer from '../slices/sets/siteSetsSlice';
import revisionSetsReducer from '../slices/sets/revisionSlice';
import statusSetsReducer from '../slices/sets/statusSlice';
import metadataSetsReducer from '../slices/sets/metadataSlice';
import rolesSetsReducer from '../slices/sets/rolesSlice';
import groupsReducer from '../slices/groupsSlice';
import formsReducer from '../slices/formsSlice';
import mailsReducer from '../slices/mailsSlice';
import tasksReducer from '../slices/tasksSlice';
import i18nReducer from '../slices/i18nSlice';

const allReducer = combineReducers({
  users: usersReducer,
  projects: projectsReducer,
  companies: companiesReducer,
  templates: temlatesReducer,
  siteSets: siteSetsReducer,
  revisionSets: revisionSetsReducer,
  metadataSets: metadataSetsReducer,
  statusSets: statusSetsReducer,
  groups: groupsReducer,
  rolesSets: rolesSetsReducer,
  forms: formsReducer,
  mails: mailsReducer,
  tasks: tasksReducer,
  i18n: i18nReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return allReducer(state, action);
};

export default rootReducer;
