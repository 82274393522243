import { createSlice } from '@reduxjs/toolkit';
import {
  getStatusSetsFunction,
  addStatusSetFunction,
  updateStatusSetFunction,
  deleteStatusSetFunction,
  addStatusSetDetailFunction,
  deleteStatusSetDetailFunction,
  deleteStatusSetDetailRoleFunc,
  addStatusSetDetailRoleFunc,
  fetchStatusSetDetailRoleFunc,
  updateStatusSetDetailFunction,
  updateStatusSetTitleFunction
} from 'api/sets/statusSets';

const initialState = {
  loading: {
    getStatusSets: false,
    addStatusSet: false,
    updateStatusSet: false,
    deleteStatusSet: false,
    addStatusSetDetail: false,
    deleteStatusSetDetail: false
  },
  hasErrors: {
    getStatusSets: false,
    addStatusSet: false,
    updateStatusSet: false,
    deleteStatusSet: false,
    addStatusSetDetail: false,
    deleteStatusSetDetail: false
  },
  statusSets: [],
  statusRoles: []
};

const statusSetsSlice = createSlice({
  name: 'statusSets',
  initialState,
  reducers: {
    asyncStart: (state, { payload }) => {
      state.loading[payload] = true;
    },
    asyncFailure: (state, { payload }) => {
      state.loading[payload] = false;
      state.hasErrors[payload] = true;
    },
    getStatusSetsSuccess: (state, { payload }) => {
      state.statusSets = payload;
      state.loading.getStatusSets = false;
      state.hasErrors.getStatusSets = false;
    },
    addStatusSetSuccess: (state, { payload }) => {
      state.statusSets.unshift(payload);
      state.loading.addStatusSet = false;
      state.hasErrors.addStatusSet = false;
    },
    updateStatusSetSuccess: (state, { payload }) => {
      state.statusSets = state.statusSets.map((statusSet) => {
        if (statusSet.id === payload.id) return payload;
        return statusSet;
      });
      state.loading.updateStatusSet = false;
      state.hasErrors.updateStatusSet = false;
    },
    deleteStatusSetSuccess: (state, { payload }) => {
      state.statusSets = state.statusSets.filter(
        (statusSet) => statusSet.id !== payload
      );
      state.loading.deleteStatusSet = false;
      state.hasErrors.deleteStatusSet = false;
    },
    addStatusSet_Detail_Success: (state, { payload }) => {
      state.statusSets.forEach((statusSet) => {
        if (statusSet.id == payload.status_id) {
          statusSet.status_set_details.push(payload);
        }
      });
      state.statusRoles.push({
        set_id: payload.status_id,
        detail_id: payload.id,
        roles: []
      });
      state.loading.addStatusSetDetail = false;
      state.hasErrors.addStatusSetDetail = false;
    },
    deleteStatusSet_Detail_Success: (state, { payload }) => {
      state.statusSets.forEach((statusSet) => {
        if (
          statusSet.status_set_details.some((detail) => detail.id == payload)
        ) {
          statusSet.status_set_details = statusSet.status_set_details.filter(
            (detail) => detail.id != payload
          );
        }
      });
      state.loading.deleteStatusSetDetail = false;
      state.hasErrors.deleteStatusSetDetail = false;
    },
    updateStatusSet_Detail_Success: (state, { payload }) => {
      state.statusSets.forEach((statusSet) => {
        if (
          statusSet.status_set_details.some(
            (detail) => detail.id == payload.detail_id
          )
        ) {
          statusSet.status_set_details = statusSet.status_set_details.map(
            (detail) => {
              if (detail.id === payload.detail_id)
                return { ...detail, name: payload.name };
              return detail;
            }
          );
        }
      });
      state.loading.updateStatusSetDetail = false;
      state.hasErrors.updateStatusSetDetail = false;
    },
    addStatusSet_Role_Success: (state, { payload }) => {
      if (
        state.statusRoles.some((role) => role.detail_id === payload.status_id)
      ) {
        state.statusRoles = state.statusRoles.map((role) => {
          if (role.detail_id === payload.status_id) {
            const _role = { ...role, roles: [...role.roles, payload] };
            return _role;
          }
          return role;
        });
      } else {
        state.statusRoles = [
          ...state.statusRoles,
          {
            set_id: payload.set_id,
            detail_id: payload.status_id,
            roles: payload
          }
        ];
      }
      state.loading.addStatusSetDetail = false;
      state.hasErrors.addStatusSetDetail = false;
    },
    deleteStatusSet_Role_Success: (state, { payload }) => {
      state.statusRoles = state.statusRoles.map((role) => {
        if (role.detail_id === payload.detail_id) {
          return {
            ...role,
            roles: role.roles.filter((item) => item.role_id !== payload.role_id)
          };
        }
        return role;
      });
      state.loading.deleteStatusSetDetail = false;
      state.hasErrors.deleteStatusSetDetail = false;
    },
    setDetailRoleSuccess: (state, { payload }) => {
      if (
        state.statusRoles.some((role) => role.detail_id === payload.detail_id)
      ) {
        state.statusRoles = state.statusRoles.map((role) => {
          if (role.detail_id === payload.detail_id) {
            return payload;
          }
          return role;
        });
      } else {
        state.statusRoles.push(payload);
      }
    },
    statusSet_Title_Update_Success: (state, { payload: { set_id, name } }) => {
      state.statusSets.forEach((statusSet) => {
        if (statusSet.id === set_id) {
          statusSet.statu_name = name;
        }
      });
    }
  }
});

export const {
  asyncStart,
  asyncFailure,
  getStatusSetsSuccess,
  addStatusSetSuccess,
  updateStatusSetSuccess,
  deleteStatusSetSuccess,
  addStatusSet_Detail_Success,
  deleteStatusSet_Detail_Success,
  addStatusSet_Role_Success,
  deleteStatusSet_Role_Success,
  setDetailRoleSuccess,
  updateStatusSet_Detail_Success,
  statusSet_Title_Update_Success
} = statusSetsSlice.actions;

export default statusSetsSlice.reducer;

export const statusSetsSelector = (state) => state.statusSets;

export const fetchStatusSets = (proje_id) => async (dispatch) => {
  dispatch(asyncStart('getStatusSets'));

  try {
    const _statusSets = await getStatusSetsFunction(proje_id);
    for (const set of _statusSets) {
      for (const detail of set.status_set_details) {
        try {
          const roles = await fetchStatusSetDetailRoleFunc(detail.id);
          dispatch(
            setDetailRoleSuccess({
              set_id: set.id,
              detail_id: detail.id,
              roles
            })
          );
        } catch (error) {
          console.log('an error ocuurred while fetching detail roles', error);
        }
      }
    }
    dispatch(getStatusSetsSuccess(_statusSets));
  } catch (error) {
    console.log('an error occurred while fetching statusSets', error);
    dispatch(asyncFailure('getStatusSets'));
  }
};

export const addStatusSet = (statusSetObj) => async (dispatch) => {
  dispatch(asyncStart('addStatusSet'));
  try {
    const newStatusSet = await addStatusSetFunction(statusSetObj);
    dispatch(addStatusSetSuccess({ ...newStatusSet, status_set_details: [] }));
  } catch (error) {
    console.log('an error occurred while adding statusSet', error);
    dispatch(asyncFailure('addStatusSet'));
  }
};

export const updateStatusSet = (statusSetObj) => async (dispatch) => {
  dispatch(asyncStart('updateStatusSet'));
  try {
    await updateStatusSetFunction(statusSetObj);
    dispatch(updateStatusSetSuccess(statusSetObj));
  } catch (error) {
    console.log('an error occurred while updating statusSet', error);
    dispatch(asyncFailure('updateStatusSet'));
  }
};

export const deleteStatusSet = (statusSet_id) => async (dispatch) => {
  dispatch(asyncStart('deleteStatusSet'));
  try {
    await deleteStatusSetFunction(statusSet_id);
    dispatch(deleteStatusSetSuccess(statusSet_id));
  } catch (error) {
    console.log('an error occurred while deleting statusSet', error);
    dispatch(asyncFailure('deleteStatusSet'));
  }
};

export const addStatusSetDetail = (status_set_detail_obj) => {
  status_set_detail_obj.status_id = status_set_detail_obj.set_id;
  delete status_set_detail_obj.set_id;
  return async (dispatch) => {
    dispatch(asyncStart('addStatusSetDetail'));
    try {
      const new_detail_obj = await addStatusSetDetailFunction(
        status_set_detail_obj
      );
      dispatch(addStatusSet_Detail_Success(new_detail_obj));
    } catch (error) {
      console.log('an error occurred while adding statusSet detail', error);
      dispatch(asyncFailure('addStatusSetDetail'));
    }
  };
};

export const deleteStatusSetDetail = (status_set_detail_id) => async (
  dispatch
) => {
  dispatch(asyncStart('deleteStatusSetDetail'));
  try {
    await deleteStatusSetDetailFunction(status_set_detail_id);
    dispatch(deleteStatusSet_Detail_Success(status_set_detail_id));
  } catch (error) {
    console.log('an error occurred while deleting statusSet detail', error);
    dispatch(asyncFailure('deleteStatusSetDetail'));
  }
};

export const addStatusSetDetailRole = (status_id, role_id, set_id) => async (
  dispatch
) => {
  dispatch(asyncStart('addStatusSetDetail'));
  try {
    const new_detail_obj = await addStatusSetDetailRoleFunc(status_id, role_id);
    console.log('new_detail', new_detail_obj);
    dispatch(addStatusSet_Role_Success({ ...new_detail_obj, set_id }));
    return new_detail_obj;
  } catch (error) {
    console.log('an error occurred while adding statusSet detail role', error);
    dispatch(asyncFailure('addStatusSetDetail'));
  }
};

export const deleteStatusSetDetailRole = (status_id, role_id) => async (
  dispatch
) => {
  dispatch(asyncStart('deleteStatusSetDetail'));
  try {
    await deleteStatusSetDetailRoleFunc(status_id, role_id);
    dispatch(deleteStatusSet_Role_Success({ detail_id: status_id, role_id }));
  } catch (error) {
    console.log(
      'an error occurred while deleting statusSet detail role',
      error
    );
    dispatch(asyncFailure('deleteStatusSetDetail'));
  }
};

export const updateStatusSetDetail = (detail_id, name, Status_id) => async (
  dispatch
) => {
  //dispatch(asyncStart('updatingStatusSetDetail'));
  try {
    await updateStatusSetDetailFunction(detail_id, name);
    dispatch(updateStatusSet_Detail_Success({ detail_id, name }));
  } catch (error) {
    console.log('an error occurred while update StatusSet detail', error);
    dispatch(asyncFailure('updatingStatusSetDetail'));
  }
  return true;
};

export const updateStatusSetTitle = (set_id, name) => async (dispatch) => {
  try {
    await updateStatusSetTitleFunction(set_id, name);
    dispatch(statusSet_Title_Update_Success({ set_id, name }));
  } catch (error) {
    console.log('an error occurred while update StatusSet title', error);
  }
};
