import React, { useState } from 'react';

import { Formik, Form } from 'formik';

import * as Yup from 'yup';

import {
  Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Button, Divider,
} from '@material-ui/core';

import { AiOutlineCloseCircle } from 'react-icons/ai';

import { useIntl, FormattedMessage } from 'react-intl';
import { StyledTab, StyledTabs, TabPanel } from '../Common';

import StepManagers from './StepManagers';
import StepResults from './StepResults';
import StepCautions from './StepCautions';

const getStyle = () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

const StepConfig = (props) => {
  const { showModal, setShowModal } = props;

  const [tabIndex, setTabIndex] = useState(0);

  const intl = useIntl();

  const formInfoSchema = Yup.object().shape({

  });

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  return (
    <Formik
      enableReinitialize
      validationSchema={formInfoSchema}
      initialValues={{
      }}
      onSubmit={(values, {
        setFieldError,
      }) => {
        setShowModal(false);
      }}
    >
      {(formikProps) => (
        <Form>
          <Dialog
            open={showModal}
            onClose={() => {
              window.confirm(
                intl.formatMessage({ id: 'UNSAVED_FORM_ALERT' }),
              ) && setShowModal(false);
            }}
            aria-labelledby="form-dialog-title"
            fullWidth
            scroll="body"
            maxWidth="md"
          >
            <DialogTitle id="form-dialog-title">
              <div style={getStyle()}>
                <span> <FormattedMessage id="UPDATE_STEP" /></span>
                <IconButton onClick={() => {
                  window.confirm(
                    intl.formatMessage({ id: 'UNSAVED_FORM_ALERT' }),
                  ) && setShowModal(false);
                }}
                >
                  <AiOutlineCloseCircle />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent dividers>
              <StyledTabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="mail-tabs"
              >
                <StyledTab label={intl.formatMessage({ id: 'STEP_MANAGERS' })} />
                <StyledTab label={intl.formatMessage({ id: 'STEP_RESULTS' })} />
                <StyledTab label={intl.formatMessage({ id: 'STEP_INFORMED' })} />
              </StyledTabs>
              <Divider />
              <TabPanel value={tabIndex} index={0} fullWidth>
                <StepManagers {...formikProps} />
              </TabPanel>
              <TabPanel value={tabIndex} index={1} fullWidth>
                <StepResults {...formikProps} />
              </TabPanel>
              <TabPanel value={tabIndex} index={2} fullWidth>
                <StepCautions {...formikProps} />
              </TabPanel>
            </DialogContent>
            <DialogActions>
              <div style={getStyle()}>
                <Button
                  onClick={() => {
                    window.confirm(
                      intl.formatMessage({ id: 'UNSAVED_FORM_ALERT' }),
                    ) && setShowModal(false);
                  }}
                  variant="contained"
                  style={{ margin: '5px 15px' }}
                >
                  <FormattedMessage id="CANCEL" />
                </Button>
                <div>
                  <Button
                    onClick={() => setTabIndex(tabIndex - 1)}
                    variant="contained"
                    style={{ margin: '5px 15px' }}
                    disabled={!tabIndex}
                  >
                    {/* loading.addCompany && (
                                    <CircularProgress size="sm" color="inherit" />
                                    ) */}
                    <FormattedMessage id="PREVIOUS" />
                  </Button>
                  <Button
                    onClick={() => { tabIndex === 2 ? formikProps.handleSubmit() : setTabIndex(tabIndex + 1); }}
                    color="primary"
                    variant="contained"
                    style={{ margin: '5px 15px' }}
                  >
                    {tabIndex === 2
                      ? <span><FormattedMessage id="SAVE" /></span>
                      : <span><FormattedMessage id="NEXT" /></span>
                    }
                  </Button>
                </div>
              </div>
            </DialogActions>
          </Dialog>
        </Form>
      )
      }
    </Formik>
  );
};

export default StepConfig;
