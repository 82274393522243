import React from 'react';
import { NoData } from 'components/Forms/LeftContent';
import { FormattedMessage } from 'react-intl';
import ProgressCircle from './ProgressCircle';

const DataLoading = (props) => {
  const {
    loading, error, selectedProject, list, noDataText, ...rest
  } = props;

  return (
    <>
      {
        loading
          ? (
            <ProgressCircle {...rest} height="100%" />
          )
          : error
            ? (
              <NoData {...rest}><FormattedMessage id="FETCH_ERROR" /> </NoData>
            )
            : !selectedProject
              ? (
                <NoData {...rest}><FormattedMessage id="PROJECT_SELECTION_ALERT" /> </NoData>
              )
              : !list.length
                ? (
                  <NoData {...rest}>{noDataText || <FormattedMessage id="NO_DATA" /> }</NoData>
                )
                : null


      }
    </>
  );
};

export default DataLoading;
