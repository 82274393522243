import { BehaviorSubject } from 'rxjs';

import axios from 'axios';
import { useDispatch } from 'react-redux';
import { history } from './history';

const userSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('user'))
);

async function login(username, password) {
  const LOGIN_URL = `${process.env.REACT_APP_API_URL}login`;

  try {
    const _user = await axios.post(LOGIN_URL, { email: username, password });
    const { token, code, status: codeStatus } = _user.data;

    if (code === 400 && codeStatus === 'locked') {
      throw Error('PASSIVE_USER');
    }

    const {
      data: {
        users: { rows },
        is2fEnabled
      }
    } = _user;
    const {
      id,
      email,
      title,
      name,
      role,
      company_id,
      company_name,
      status,
      avatar,
      lang,
      instant_daily,
      notification_email,
      notification_push
    } = rows[0];

    if (status) {
      const {
        data: {
          projects: { rows: project }
        }
      } = _user;

      const projects = [];
      project.forEach((element) => {
        projects.push(element.project_id);
      });

      const groups = await axios
        .post(
          `${process.env.REACT_APP_API_URL}users/find_groups`,
          {
            user_id: id
          },
          {
            headers: {
              'x-access-token': token,
              'Content-Type': 'application/json'
            }
          }
        )
        .then(async (groupsResponse) => {
          const { data: groupsData } = groupsResponse;
          const {
            code: groupsCode,
            status: groupsStatus,
            user_groups
          } = groupsData;
          if (
            groupsCode === 200 &&
            groupsStatus === 'success' &&
            user_groups.length > 0
          ) {
            return [...user_groups];
          }

          return [];
        })
        .catch((error) => []);
      const user_values = {
        id,
        email,
        title,
        name,
        role,
        token,
        projects,
        company_id,
        company_name,
        avatar,
        status,
        is2fEnabled,
        instant_daily,
        notification_email,
        notification_push,
        lang
      };
      if (!is2fEnabled) {
        localStorage.setItem(
          'user',
          JSON.stringify({ ...user_values, groups })
        );

        userSubject.next({ ...user_values, groups });
      } else {
        localStorage.setItem(
          'user',
          JSON.stringify({ ...user_values, groups, token: null })
        );

        userSubject.next({ ...user_values, groups, token: null });
      }

      return { ...user_values, groups };
    }
  } catch (e) {
    if (e.message === 'Network Error') {
      throw Error('NETWORK_ERROR');
    } else if (e.message === 'PASSIVE_USER') {
      throw Error('PASSIVE_USER');
    }
    throw Error('INVALID_CREDENTIALS');
  }
  throw Error('PASSIVE_USER');
}
function logout() {
  // remove user from local storage and publish null to user subject
  localStorage.removeItem('user');
  userSubject.next(null);
  history.push('/account/login');
}

async function forgotPassword(email) {
  const FORGOT_PASSWORD = `${process.env.REACT_APP_API_URL}login/forgot`;
  const body = { email };
  const config = {};
  try {
    const { data } = await axios.post(FORGOT_PASSWORD, body, config);
    return data;
  } catch (err) {
    throw Error('INVALID_MAIL');
  }
}
async function resetPassword(email, token, password) {
  const RESET_PASSWORD = `${process.env.REACT_APP_API_URL}login/repass`;
  const body = { email, token, password };
  const config = {};
  try {
    const { data } = await axios.post(RESET_PASSWORD, body, config);
    return data;
  } catch (err) {
    throw Error('AUTHENTICATION ERROR');
  }
}

async function checkQR(token, code) {
  const CHECK_URL = `${process.env.REACT_APP_API_URL}users/checkqr`;
  const body = {
    token: code
  };
  const config = {
    headers: {
      'x-access-token': token
    }
  };
  try {
    const { data } = await axios.post(CHECK_URL, body, config);
    if (data) {
      localStorage.setItem(
        'user',
        JSON.stringify({ ...userSubject.value, token, is2fEnabled: true })
      );

      userSubject.next({ ...userSubject.value, token, is2fEnabled: true });
    }
    console.log('***', data);
    return data;
  } catch (err) {
    console.log('***', err);
    throw Error('AUTHENTICATION ERROR');
  }
}

// Getting QR code for 2F Authentication
async function getQR(token) {
  const QR_URL = `${process.env.REACT_APP_API_URL}users/getqr`;
  const data = {};
  const config = {
    headers: {
      'x-access-token': token
    }
  };
  try {
    const {
      data: { qrtag }
    } = await axios.post(QR_URL, data, config);
    if (qrtag) {
      return qrtag;
    }
  } catch (err) {
    throw Error('AUTHENTICATION ERROR');
  }
}

// Disable 2F Authentication
async function disable2F() {
  const QR_URL = `${process.env.REACT_APP_API_URL}users/disable2f`;
  const { token } = userSubject.value;
  const data = {};
  const config = {
    headers: {
      'x-access-token': token
    }
  };
  try {
    const response = await axios.post(QR_URL, data, config);

    localStorage.setItem(
      'user',
      JSON.stringify({ ...userSubject.value, is2fEnabled: false })
    );

    userSubject.next({ ...userSubject.value, is2fEnabled: false });

    return true;
  } catch (err) {
    throw Error('AUTHENTICATION ERROR');
  }
}

export function changeValues(newValues) {
  try {
    localStorage.setItem(
      'user',
      JSON.stringify({ ...userSubject.value, ...newValues })
    );

    userSubject.next({ ...userSubject.value, ...newValues });

    return true;
  } catch (err) {
    throw Error('CHANGE VALUES ERROR');
  }
}

export const authentication = {
  login,
  logout,
  forgotPassword,
  resetPassword,
  getQR,
  checkQR,
  disable2F,
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  }
};
