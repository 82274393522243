import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';

import {
  Breadcrumbs,
  Link,
  Grid,
  DialogContent,
  Dialog,
  CircularProgress,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { FiPlusCircle } from 'react-icons/fi';
import { AiOutlineExport } from 'react-icons/ai';

import AlertDialog from 'components/Common/AlertDialog';
import { useSelector, useDispatch } from 'react-redux';
import { TreeView } from '@material-ui/lab';
import SortableTable from 'components/Table/SortableTable';
import { PermissionContainer } from 'helpers/permission';
import { FormattedMessage, useIntl } from 'react-intl';

import { authentication } from 'helpers/authentication';

import * as XLSX from 'sheetjs-style';
import { PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment';
import ProgressCircle from 'components/Common/ProgressCircle';
import LeftContent from './LeftContent';

import {
  projectsSelector,
  formsSelector,
  removeMetadataSet,
  metadataSetsSelector,
  selectMetadataSet,
  templatesSelector,
  getWorkerTasks,
  getAllTasks,
  fetchTemplates,
  findByMetadaIdtIdTemplate,
  selectTemplate,
  fetchStatusSets,
  findCompanyById,
} from '../../slices';
import RightContent from './RightContent';

import CreatePDF from './CreatePDF';

import { findByIdFormsFunction, getFormListFnc, findByIdTemplatessFunction } from '../../api';

import {
  FormsContainer,
  TitleContainer,
  ContentLink,
  ContentTitle,
  ButtonContainer,
  StyledButton,
  MainContainer,
  DialogGrid,
  Header,
  List,
  ExportButtons,
  ButtonLabel,
  Metadata,
  MetadataContainer,
  Text,
  Icon,
} from '../../styles/forms/formsStyle';

const arr = [
  { name: 'Moran', role: 'back', wpx: 2000 },
  { name: 'Alain', role: 'front' },
  { name: 'Tony', role: 'back' },
  { name: 'Mike', role: 'back' },
  { name: 'Abo', role: 'back' },
  { name: 'Toni', role: 'back' },
];

const headCells = [];

const Forms = (props) => {
  const auth = authentication.userValue;

  const [templatesList, setTemplatesList] = useState([]);
  const [, handleOkey] = useState(false);
  const [alert, setAlert] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [images, setImages] = useState([]);
  const [isReady, setReady] = useState(false);
  // for metadata and template selectable modal
  const [show, setShow] = useState(false);
  const [locations, setLocations] = useState('');

  const { selectedProject } = useSelector(projectsSelector);
  const {
    selectedMetadataSet,
    selectedSiteSet,
    formList,
    formFilters,
  } = useSelector(formsSelector);
  const { metadataSets } = useSelector(metadataSetsSelector);
  const { templates } = useSelector(templatesSelector);

  const [searchText, setSerarchText] = useState('');

  const pdfDownload = useRef();
  const handleSearch = useCallback(
    (search) => {
      setSerarchText(search);
    },
    [searchText],
  );

  const [siteLevel, setSiteLevel] = useState(0);
  const handleSiteLevel = useCallback(
    (level) => {
      setSiteLevel(siteLevel + level);
      console.log('Search text', searchText);
    },
    [searchText],
  );
  const dispatch = useDispatch();

  const intl = useIntl();

  const alertText = (
    <>
      <span>
        <FormattedMessage id="TEMPLATE_NOT_FOUND" />
      </span>
      <br />
      <strong>
        <FormattedMessage id="CHANGE_PROJECT_OR_SITE_SET" />
      </strong>
    </>
  );

  const handleRemove = (id) => {
    dispatch(removeMetadataSet(id));
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleNewForm = () => {
    if (!templatesList.length) setAlert(true);
    else {
      setShow(true);
    }
  };

  useEffect(() => {
    if (!templates.length) {
      dispatch(fetchTemplates());
    }
  }, []);

  useEffect(() => {
    let _templates;
    if (!!selectedSiteSet) {
      _templates = templates.filter(
        (template) => template.drafts[0].project_id === selectedProject.id,
      );
      _templates = _templates.filter(
        (template) => template.drafts[0].site_set_id === selectedSiteSet.id,
      );
      _templates = _templates.filter(
        (template) => template.drafts[0].site_level <= siteLevel,
      );
      setTemplatesList(_templates);
    }
  }, [templates, selectedSiteSet, siteLevel]);

  useEffect(() => {
    if (selectedMetadataSet.length) {
      let _metadatas = [];
      selectedMetadataSet.forEach((detail) => {
        if (_metadatas.some((m) => m.metadata_id === detail.metadata_id)) {
          _metadatas = _metadatas.map((meta) => {
            if (meta.metadata_id === detail.metadata_id) { return { ...meta, details: [...meta.details, detail] }; }
            return meta;
          });
        } else {
          _metadatas.push({
            metadata_id: detail.metadata_id,
            details: [detail],
          });
        }
      });
      dispatch(findByMetadaIdtIdTemplate(selectedProject.id, _metadatas));
    } else {
      dispatch(fetchTemplates());
    }
  }, [selectedMetadataSet]);

  const handleTemplateClick = (event, id) => {
    const _template = templates.filter((template) => template.id === id)[0];
    dispatch(selectTemplate(_template));
    props.history.push('/forms/new');
  };

  useEffect(() => {
    if (selectedProject && selectedProject.id) {
      dispatch(fetchStatusSets(selectedProject.id));
      if (auth) {
        dispatch(getWorkerTasks(selectedProject.id, auth.id));
        dispatch(getAllTasks(selectedProject.id));
      }
    }
    /* let fnc = async () => {
    };
    if (selectedProject) fnc();
    return fnc; */
  }, [selectedProject]);

  // Create filter section of xlsx file
  const getFilters = () => {
    if (!formFilters) return '';
    const { status, createdBy, formName } = formFilters;
    const _filters = [];
    if (status) { _filters.push(`${intl.formatMessage({ id: 'STATUS' })}: ${status.name}`); }
    if (createdBy) {
      _filters.push(
        `${intl.formatMessage({ id: 'CREATED_BY' })}: ${createdBy}`,
      );
    }
    if (formName) { _filters.push(`${intl.formatMessage({ id: 'FORM_NAME' })}: ${formName}`); }

    _filters.push(
      Object.entries(
        selectedMetadataSet.reduce((a, b) => {
          (a[b.setName] = a[b.setName] || []).push(b);
          return a;
        }, {}),
      )
        .map(
          (el) => `${el[0]}: '${el[1]
            .map((element) => `${element.name}`)
            .join('\', \'')}'`,
        )
        .join(', '),
    );

    return _filters.join(', ');
  };

  const saveForm = () => {
    const fileName = 'crane_forms.xlsx';

    const headers = Object.keys(formList[0]).map((key) => intl.formatMessage({ id: key.toUpperCase() }));

    const ws = XLSX.utils.json_to_sheet([{}]);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'crane');
    XLSX.utils.sheet_add_json(
      wb.Sheets.crane,
      [
        { note: 'Crane Project Forms Export' },
        { note: '' },
        {
          note: `${intl.formatMessage({ id: 'PROJECT_NAME' })}: ${
            selectedProject.project_name
          }`,
        },
        {
          note: `${intl.formatMessage({ id: 'FILTERS' })}: ${getFilters()}`,
        },
        {
          note: `${intl.formatMessage({ id: 'EXPORT_BY' })}: ${auth.name}`,
        },
        {
          note: `${intl.formatMessage({
            id: 'DATE_AND_TIME_OF_EXPORT',
          })}: ${intl.formatDate(Date.now())} ${intl.formatTime(Date.now())}`,
        },
        { note: '' },
      ],
      {
        header: ['note'],
        skipHeader: true,
        origin: 'A1',
      },
    );
    XLSX.utils.sheet_add_json(wb.Sheets.crane, formList, {
      header: Object.keys(formList[0]),
      skipHeader: false,
      origin: 'A8',
    });
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    for (const key in ws) {
      if (key !== '!ref') {
        try {
          ws[key].s = {
            alignment: { horizontal: 'left' },
          };
        } catch (e) {}
      }
    }

    headers.forEach((header, index) => {
      ws[`${letters.charAt(index)}8`].v = header;
      ws[`${letters.charAt(index)}8`].s = {
        font: { bold: true },
        alignment: { horizontal: 'left' },
      };
    });

    ws.A1.s = {
      // set the style for target cell
      font: {
        bold: true,
      },
    };

    XLSX.writeFile(wb, fileName, { type: 'array', bookType: 'xlsx' });
  };

  const exportData = () => {
    setExportModal(true);

    const getData = async () => {
      // split the list into groups of 10

      const user = JSON.parse(localStorage.getItem('user'));
      const {
        status, createdBy, formName, site_id = '0',
      } = formFilters || {
        status: '',
        createdBy: '',
        formName: '',
        site_id: '0',
      };
      const { forms: list } = await getFormListFnc(
        user.id,
        selectedProject.id,
        100,
        0,
        formName,
        createdBy,
        `${site_id}`,
        'id',
        'desc',
      );

      console.log('****list', list, formFilters);

      const _formList = [];
      const templateList = [];

      Promise.allSettled(
        list.map(({ id }) =>
          // return fetchFormValuesFunction(item.join());
          findByIdFormsFunction(id).then(async (form) => {
            let template = templateList.find((t) => t.id === form.template_id);
            if (!template) {
              template = await findByIdTemplatessFunction(form.template_id);

              console.log('*** template ***', template);
              templateList.push(template);
            }
            return { ...form, template };
          })),
      ).then((responses) => {
        console.log('responses', responses);
        responses.forEach(({ status, value }) => {
          if (status === 'fulfilled') {
            // let template = templates.find(({ id }) => id === value.template_id);
            _formList.push(value);
          }
        });

        dispatch(findCompanyById(user.company_id))
          .then(([company]) => {
            setImages({ formlist: _formList, company, user });
            setReady(true);
          })
          .catch(() => {
            setImages({ formlist: _formList, user });
            setReady(true);
          });
      });
    };
    getData();
  };

  const onSiteChange = (sites) => {
    setLocations(sites.map((x) => x.name).join(' > '));
  };

  return (
    <FormsContainer>
      <TitleContainer>
        <Breadcrumbs separator={<>&gt;</>} aria-label="breadcrumb">
          <Link component={RouterLink} to="/projects">
            <ContentLink>
              {selectedProject && selectedProject.project_name}
            </ContentLink>
          </Link>
          <ContentTitle>
            <FormattedMessage id="FORMS" />
          </ContentTitle>
        </Breadcrumbs>
        <ButtonContainer>
          {/* <SearchBar searchFunction={handleSearch} /> */}
          <StyledButton
            variant="contained"
            color="default"
            size="medium"
            disabled={!formList?.length}
            startIcon={<AiOutlineExport />}
            onClick={() => exportData()}
          >
            <FormattedMessage id="EXPORT_AS" />
          </StyledButton>
          <PermissionContainer page="forms" create>
            <StyledButton
              variant="contained"
              color="primary"
              size="medium"
              style={{ backgroundColor: '#1296fb' }}
              startIcon={<FiPlusCircle />}
              onClick={handleNewForm}
            >
              <FormattedMessage id="CREATE_NEW_FORM" />
            </StyledButton>
          </PermissionContainer>
        </ButtonContainer>
      </TitleContainer>
      <MetadataContainer>
        {selectedMetadataSet.map((metadata, index) => (
          <Metadata key={`metadata-forms-key--${index}`}>
            <Text>{metadata.name}</Text>
            <Icon onClick={() => handleRemove(metadata.id)} />
          </Metadata>
        ))}
      </MetadataContainer>
      <MainContainer>
        <LeftContent />
        <RightContent
          handleOkey={handleOkey}
          handleSiteLevel={handleSiteLevel}
          history={props.history}
          searchText={searchText}
          onSiteChange={onSiteChange}
        />
      </MainContainer>
      <AlertDialog open={alert} setOpen={setAlert} alertText={alertText} />
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="form-metadata-dialog-title"
        aria-describedby="form-metadata-dialog-description"
        fullWidth
      >
        <DialogContent dividers>
          <DialogGrid container>
            <Grid item xs={12}>
              <Header>
                <FormattedMessage id="FORM_TEMPLATE" />
              </Header>
              <List>
                <SortableTable
                  list={templatesList
                    .filter(
                      (el) => el.drafts[0].site_set_id === selectedSiteSet.id
                        && el.status === 3,
                    )
                    .filter((el) => auth.groups.some((ag) => el.drafts[0].template_group_views.find(
                      (g) => g.group_id === ag.group_id,
                    )))}
                  headCells={headCells}
                  handleClick={handleTemplateClick}
                  onlyName
                />
              </List>
            </Grid>
          </DialogGrid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={exportModal}
        onClose={() => {
          setExportModal(false);
          setReady(false);
        }}
        aria-labelledby="form-metadata-dialog-title"
        aria-describedby="form-metadata-dialog-description"
        maxWidth="md"
      >
        <DialogContent dividers style={{ minHeight: '10em', minWidth: '15em' }}>
          {!isReady ? (
            <ProgressCircle height="10em" size="3em" overflow="none" />
          ) : (
            <ExportButtons>
              <div>
                <PDFDownloadLink
                  document={(
                    <CreatePDF
                      formlist={images}
                      selectedProject={selectedProject}
                      formInfo={{
                        export_by: auth.name || '',
                        project_name: selectedProject?.project_name || '',
                        create_date: moment().format('DD.MM.YYYY HH:mm'),
                        form_count: images?.formlist?.length,
                        location: locations,
                        filters: formFilters
                          ? [
                            ...Object.entries(formFilters).filter(
                              (el) => !!el[1]?.name || !!el[1],
                            ),
                            ...Object.entries(
                              selectedMetadataSet.reduce((a, b) => {
                                (a[b.setName] = a[b.setName] || []).push(b);
                                return a;
                              }, {}),
                            ).map((el) => ['metadata', el]),
                          ]
                            .map(
                              (el) => `${
                                el[0] === 'metadata'
                                  ? el[1][0]
                                  : el[0].charAt(0).toUpperCase()
                                        + el[0].slice(1)
                              }: '${
                                el[0] === 'metadata'
                                  ? el[1][1]
                                    .map((element) => `${element.name}`)
                                    .join('\', \'')
                                  : el[0] === 'status'
                                    ? el[1].name
                                    : el[1]
                              }'`,
                            )
                            .join(',\n')
                          : [
                            ...Object.entries(
                              selectedMetadataSet.reduce((a, b) => {
                                (a[b.setName] = a[b.setName] || []).push(b);
                                return a;
                              }, {}),
                            ).map((el) => ['metadata', el]),
                          ]
                            .map(
                              (el) => `${
                                el[0] === 'metadata'
                                  ? el[1][0]
                                  : el[0].charAt(0).toUpperCase()
                                        + el[0].slice(1)
                              }: '${
                                el[0] === 'metadata'
                                  ? el[1][1]
                                    .map((element) => `${element.name}`)
                                    .join('\', \'')
                                  : el[0] === 'status'
                                    ? el[1].name
                                    : el[1]
                              }'`,
                            )
                            .join(',\n'),
                      }}
                    />
                  )}
                  fileName="crane_forms.pdf"
                  style={{ textDecoration: 'none' }}
                >
                  {({
                    blob, url, loading, error,
                  }) => (
                    <StyledButton
                      variant="contained"
                      color="default"
                      size="medium"
                      disabled={!images.length || loading}
                      startIcon={<AiOutlineExport />}
                      onClick={() => {}}
                      style={{ width: '15vw', justifyContent: 'flex-start' }}
                    >
                      {loading ? (
                        <CircularProgress size={32} />
                      ) : (
                        <ButtonLabel>
                          <FormattedMessage id="SAVE_IN_PDF" />
                        </ButtonLabel>
                      )}
                    </StyledButton>
                  )}
                </PDFDownloadLink>
              </div>
              <div>
                <StyledButton
                  variant="contained"
                  color="default"
                  size="medium"
                  disabled={!formList.length}
                  startIcon={<AiOutlineExport />}
                  onClick={saveForm}
                  style={{ width: '15vw', justifyContent: 'flex-start' }}
                >
                  <ButtonLabel>
                    <FormattedMessage id="SAVE_IN_EXCEL" />
                  </ButtonLabel>
                </StyledButton>
              </div>
            </ExportButtons>
          )}
        </DialogContent>
      </Dialog>
    </FormsContainer>
  );
};

export default Forms;
