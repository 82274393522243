import React from 'react';
import uuid from 'uuid/dist/v4';
import SetItem from './SetItem';


export const determineTheType = (currentType) => {
  const isLocal = currentType === 'local';
  const isRevision = currentType === 'revision';
  const isStatus = currentType === 'status';
  const isMetadata = currentType === 'metadata';

  return {
    isLocal,
    isRevision,
    isStatus,
    isMetadata,
    currentType,
  };
};

export const renderTree = (nodes, index, group, parent, list, addDetail, deleteDetail, status, typeObject, detail_roles) => (
  <SetItem
    nodes={nodes}
    group={group}
    index={index}
    parent={parent}
    list={list}
    addDetail={addDetail}
    deleteDetail={deleteDetail}
    key={nodes.id}
    status={status}
    detail_roles={detail_roles}
    typeObject={typeObject}
  />
);

export function compare_name(a, b) {
  // a should come before b in the sorted order
  if (a.name < b.name) {
    return -1;
    // a should come after b in the sorted order
  } if (a.name > b.name) {
    return 1;
    // and and b are the same
  }
  return 0;
}
