import React, { useState } from 'react';

import { Formik, Form } from 'formik';

import * as Yup from 'yup';

import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  Divider
} from '@material-ui/core';

import { AiOutlineCloseCircle } from 'react-icons/ai';

import { useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { StyledTab, StyledTabs, TabPanel } from '../Common';

import TaskInfo from './TaskInfo';
import Options from './Options';

const getStyle = () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
});

const NewTask = (props) => {
  const { showModal, setShowModal } = props;

  const [tabIndex, setTabIndex] = useState(0);

  const history = useHistory();

  const intl = useIntl();

  const formInfoSchema = Yup.object().shape({});

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  return (
    <Formik
      enableReinitialize
      validationSchema={formInfoSchema}
      initialValues={{}}
      onSubmit={(values, { setFieldError }) => {
        history.push('tasks-design');
      }}
    >
      {(formikProps) => (
        <Form>
          <Dialog
            open={showModal}
            onClose={() => {
              window.confirm(
                intl.formatMessage({ id: 'UNSAVED_FORM_ALERT' })
              ) && setShowModal(false);
            }}
            aria-labelledby="form-dialog-title"
            fullWidth
            scroll="body"
            maxWidth="md"
          >
            <DialogTitle id="form-dialog-title">
              <div style={getStyle()}>
                <span>
                  {' '}
                  <FormattedMessage id="PROCESS_SETTINGS" />{' '}
                </span>
                <IconButton
                  onClick={() => {
                    window.confirm(
                      intl.formatMessage({ id: 'UNSAVED_FORM_ALERT' })
                    ) && setShowModal(false);
                  }}
                >
                  <AiOutlineCloseCircle />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent dividers>
              <StyledTabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="mail-tabs"
              >
                <StyledTab
                  label={intl.formatMessage({ id: 'GENERAL_INFORMATION' })}
                />
                <StyledTab label={intl.formatMessage({ id: 'OPTIONS' })} />
              </StyledTabs>
              <Divider />
              <TabPanel value={tabIndex} index={0} fullWidth>
                <TaskInfo {...formikProps} />
              </TabPanel>
              <TabPanel value={tabIndex} index={1} fullWidth>
                <Options {...formikProps} />
              </TabPanel>
            </DialogContent>
            <DialogActions>
              <div style={getStyle()}>
                <Button
                  onClick={() => {
                    window.confirm(
                      intl.formatMessage({ id: 'UNSAVED_FORM_ALERT' })
                    ) && setShowModal(false);
                  }}
                  variant="contained"
                  style={{ margin: '5px 15px' }}
                >
                  <FormattedMessage id="CANCEL" />
                </Button>
                <div>
                  <Button
                    onClick={formikProps.handleSubmit}
                    color="primary"
                    variant="contained"
                    style={{ margin: '5px 15px' }}
                  >
                    {/* loading.addCompany && (
                                    <CircularProgress size="sm" color="inherit" />
                                    ) */}
                    <FormattedMessage id="SAVE" />
                  </Button>
                </div>
              </div>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

export default NewTask;
