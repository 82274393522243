import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import CommonContainer from 'components/Common/CommonContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import { Avatar, IconButton, CircularProgress } from '@material-ui/core';
import { getRandomColor } from 'helpers';


import { MdRemoveRedEye, MdCreate, MdLock } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { searchUsersFunction } from 'api/users';

import { useDispatch, useSelector } from 'react-redux';
import { ProgressContainer } from '../../styles/common';

import { usersSelector, fetchUsers } from '../../slices/usersSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(36),
      height: theme.spacing(24),
    },
  },
  dialogGrid: {
    margin: '0px',
    padding: '0px',
    '& > p': {
      fontSize: '12px',
      margin: '3px',
      marginTop: '0px',
      padding: '0px',
      fontWeight: 'bold',
      color: '#555',
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',

  },
  dialogButton: {
    margin: '5px 15px',
  },
  table: {
    minWidth: 650,
  },
  userAvatar: {
    background: getRandomColor(),
  },
  progress: {
    marginTop: 250,
  },

}));

const UserAvatar = ({ name, img }) => {
  const classes = useStyles();
  if (!img) {
    const fullName = name.split(' ');
    const avatar = fullName.length === 0
      ? ' '
      : fullName.length === 1
        ? (fullName[0][0]?.toUpperCase() ?? '')
        : (fullName[0][0]?.toUpperCase() ?? '') + (fullName[1][0]?.toUpperCase() ?? '');
    return (
      <Avatar className={classes.userAvatar}>{avatar}</Avatar>
    );
  }
  return (
    <Avatar alt={name} src={img} />
  );
};

const Role = styled.div`
  margin: auto;
  color: #fff;
  background: ${(props) => {
    if (props.role === 'Yönetici') { return 'linear-gradient(159.27deg, #F24343 0%, #FA6400 100%)'; }
    return 'linear-gradient(159.27deg, #6236FF 0%, #0091FF 100%)';
  }};

  width: auto;
  max-width: 75px;
  padding: 8px 4px;
  box-shadow: 0px 1px 4px rgba(188, 188, 188, 0.5);
  border-radius: 5px;
`;

const Status = styled.div`
  color: ${(props) => (props.active ? '#6DD400' : '#E02020')};
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-around;
`;

const TableContainerStyled = styled(TableContainer)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
`;

const TableCellStyled = styled(TableCell)`
  font-family: Helvetica !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #8a849b !important;
  display: ${(props) => (props.id ? 'flex !important' : null)};
  justify-content: ${(props) => (props.id ? 'center !important' : null)};
  align-items: ${(props) => (props.id ? 'center !important' : null)};
`;

export default () => {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchText, setSerarchText] = useState('');

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const history = useHistory();

  const dispach = useDispatch();

  const { users, loading } = useSelector(usersSelector);

  useEffect(() => {
    if (!users.length) { dispach(fetchUsers()); }
  }, [dispach, users]);


  useEffect(() => {
    if (searchList.length > 0) { setUserList(searchList); } else if (!searchList.length && !!searchText) setUserList([]);
    else { setUserList(users); }
  }, [searchText, searchList, users]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchFunction = () => {
    setSearchLoading(true);
    searchUsersFunction(searchText)
      .then((res) => {
        setSearchList(res);
        setSearchLoading(false);
      });
  };


  const getActions = (id) => (
    <>
      <IconButton onClick={() => history.push(`/users/${id}`)}>
        <MdRemoveRedEye size={16} />
      </IconButton>
      <IconButton>
        <MdCreate size={16} />
      </IconButton>
      <IconButton>
        <MdLock size={16} />
      </IconButton>
    </>
  );

  return (

    <CommonContainer
      containerName="Kullanıcılar"
      addButton="Yeni Kullanıcı"
      searchFunction={searchFunction}
      handleSearchText={setSerarchText}
      search
    >
      {
        (loading.getUsers || searchLoading)
          ? (
            <ProgressContainer>
              <CircularProgress />
            </ProgressContainer>
          )
          : (
            <>
              {userList.length > 0
                ? (
                  <>
                    <TableContainerStyled component={Paper}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCellStyled align="center" width="60px">
                              #
                            </TableCellStyled>
                            <TableCellStyled>KULLANICI ADI</TableCellStyled>
                            <TableCellStyled>FİRMA İSMİ</TableCellStyled>
                            <TableCellStyled align="center">FİRMADAKİ ROLÜ</TableCellStyled>
                            <TableCellStyled align="center">DURUMU</TableCellStyled>
                            <TableCellStyled align="center">İŞLEMLER</TableCellStyled>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            userList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, index) => {
                              const companyName = 'Test Company';
                              const isActive = true;
                              const status = isActive ? 'AKTİF' : 'PASİF';
                              return (
                                <TableRow key={user.name}>
                                  <TableCellStyled align="center" id width="60px">
                                    <UserAvatar img={user.Img} name={user.name} />
                                  </TableCellStyled>
                                  <TableCellStyled>{user.name}</TableCellStyled>
                                  <TableCellStyled>{companyName}</TableCellStyled>
                                  <TableCellStyled align="center">
                                    <Role role={user.role}>{user.role}</Role>
                                  </TableCellStyled>
                                  <TableCellStyled align="center">
                                    <Status active={isActive}>{status}</Status>
                                  </TableCellStyled>
                                  <TableCellStyled align="center" width="60px">
                                    <Actions>{getActions(index + 1)}</Actions>
                                  </TableCellStyled>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainerStyled>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={userList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </>
                )
                : <h4>Sonuç Bulunamadı</h4>
              }
            </>
          )
      }


    </CommonContainer>

  );
};
